import * as React from 'react';
import styled from '../../../app/containers/SubmitFormApp/styled';
import FormattedErrorMessage from '../../../app/components/FormattedErrorMessage';
import TopLevelErrorBoundary from './TopLevelErrorBoundary';

const ErrorPage = styled.div`
  background-color: ${(props: any) => props.theme.style.background.color};
  font-family: ${(props: any) => props.theme.style.font.family};
  height: 100%;
  margin: 0 auto;
  width: 610px;
`;

const ErrorPanel = styled.div`
  background-color: ${(props: any) => props.theme.style.panel.color};
  border-radius: 2px;
  box-shadow: ${(props: any) => props.theme.style.boxShadow};
  color: #161616;
  display: block;
  font-size: 1.13em;
  font-weight: 300;
  margin-top: 32px;
  padding: 50px 30px;
`;

interface FormLevelErrorBoundaryProps {
  children?: React.ReactNode;
}

interface FormLevelErrorBoundaryState {
  error?: Error;
}

class FormLevelErrorBoundary extends React.Component<
  FormLevelErrorBoundaryProps
> {
  public static getDerivedStateFromError(error: Error) {
    return {
      error,
    };
  }

  public readonly state: FormLevelErrorBoundaryState = {
    error: undefined,
  };

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    TopLevelErrorBoundary.submitErrorToAPI()
  }

  public render() {
    if (this.state.error) {

      return (
        <ErrorPage>
          <ErrorPanel>
            <FormattedErrorMessage error={this.state.error} />
          </ErrorPanel>
        </ErrorPage>
      );
    }

    return this.props.children;
  }
}

export default FormLevelErrorBoundary;