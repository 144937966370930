import React from 'react';
import { ESymbolKey } from "@smartsheet/forms-model";
import EmptyEffort from "./EmptyEffort";
import One from "./One";
import Two from "./Two";
import Three from "./Three";
import Four from "./Four";
import Five from "./Five";
import { WideIconOptionImg } from "../../iconUtil";
var EffortIcon = function (_a) {
    var type = _a.type;
    var icon;
    switch (type) {
        case ESymbolKey.EFFORT_6_EMPTY:
        case "Empty":
            icon = React.createElement(EmptyEffort, null);
            break;
        case ESymbolKey.EFFORT_6_ONE:
        case "One":
            icon = React.createElement(One, null);
            break;
        case ESymbolKey.EFFORT_6_TWO:
        case "Two":
            icon = React.createElement(Two, null);
            break;
        case ESymbolKey.EFFORT_6_THREE:
        case "Three":
            icon = React.createElement(Three, null);
            break;
        case ESymbolKey.EFFORT_6_FOUR:
        case "Four":
            icon = React.createElement(Four, null);
            break;
        case ESymbolKey.EFFORT_6_FIVE:
        case "Five":
            icon = React.createElement(Five, null);
            break;
        default:
            break;
    }
    return !!icon ? (React.createElement(WideIconOptionImg, null, icon)) : null;
};
export default EffortIcon;
