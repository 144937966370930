import React from 'react';
import { CHECKBOX_SPRITE_ELEMENTS } from './CheckboxSprite.styled';
import { CheckboxType } from './types';
var CheckboxSprite = function (_a) {
    var checked = _a.checked, onClick = _a.onClick, type = _a.type;
    if (type === CheckboxType.FLAG && checked) {
        return React.createElement(CHECKBOX_SPRITE_ELEMENTS.CheckboxFlagChecked, { onClick: onClick });
    }
    if (type === CheckboxType.FLAG && !checked) {
        return React.createElement(CHECKBOX_SPRITE_ELEMENTS.CheckboxFlagUnchecked, { onClick: onClick });
    }
    if (type === CheckboxType.STAR && checked) {
        return React.createElement(CHECKBOX_SPRITE_ELEMENTS.CheckboxStarChecked, { onClick: onClick });
    }
    if (type === CheckboxType.STAR && !checked) {
        return React.createElement(CHECKBOX_SPRITE_ELEMENTS.CheckboxStarUnchecked, { onClick: onClick });
    }
    return null;
};
export default CheckboxSprite;
