import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { parse as parseQueryString } from 'query-string';

export interface PropsFromRouter {
  formKey: string;
  shouldShowConfirmation: boolean;
  wasRefreshed: boolean;
}

interface RouteParams {
  formKey: string;
}

type RouteParserProps = RouteComponentProps<RouteParams>;

export const withPropsFromRouter = (
  WrappedComponent: React.ComponentType<any>
) => {
  const RouteParser = (props: RouteParserProps) => {
    const queryParams = parseQueryString(
      props.location && props.location.search
    );

    const shouldShowConfirmation = queryParams.confirm === 'true';
    const wasRefreshed = queryParams._refreshed === 'true';

    // If possible, don't read `formKey` from the route or query parameters
    // because this can lead to a known issue (which seems to related to
    // redirects).
    // TODO: Move this logic somewhere else, as it does not have anything to
    // do with the router.
    let formKey = window.publishKey;

    // If that didn't work (likely because we're running against an old
    // version of the form service) then fall back to the "old way" of
    // deriving `formKey` (i.e. grab it from the route or query parameters).
    if (!formKey) {
      const legacyFormKey = queryParams.EQBCT;
      const modernFormKey = props.match && props.match.params.formKey;

      if (!legacyFormKey && !modernFormKey) {
        throw new Error('Expected form key to be defined');
      }

      if (legacyFormKey && modernFormKey) {
        window.console.warn(
          'Expected `formKey` to be defined as either',
          'a router param or query param but got both.',
          `Defaulting to query parameter value ("${legacyFormKey}") and`,
          `ignoring route paramater ("${modernFormKey}").`
        );
      }

      formKey = legacyFormKey || modernFormKey;
    }

    return (
      <WrappedComponent
        formKey={formKey}
        shouldShowConfirmation={shouldShowConfirmation}
        wasRefreshed={wasRefreshed}
        {...props}
      />
    );
  };

  return RouteParser;
};
