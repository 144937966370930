import * as React from 'react';
import { Route, Switch } from 'react-router';
import SubmitFormApp from '../app/containers/SubmitFormApp';
import { hot } from 'react-hot-loader';
import TopLevelErrorBoundary from '../app/containers/SubmitFormApp/TopLevelErrorBoundary';

const quillDescriptionCss = `
.rich-text-field-desc p,
.rich-text-field-desc ol,
.rich-text-field-desc ul,
.rich-text-field-desc pre,
.rich-text-field-desc blockquote,
.rich-text-field-desc h1,
.rich-text-field-desc h2,
.rich-text-field-desc h3,
.rich-text-field-desc h4,
.rich-text-field-desc h5,
.rich-text-field-desc h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.rich-text-field-desc ol,
.rich-text-field-desc ul {
  padding-left: 1.5em;
}
.rich-text-field-desc ol > li,
.rich-text-field-desc ul > li {
  list-style-type: none;
}
.rich-text-field-desc ul > li::before {
  content: '\\25CF';
}
.rich-text-field-desc ul[data-checked=true],
.rich-text-field-desc ul[data-checked=false] {
  pointer-events: none;
}
.rich-text-field-desc ul[data-checked=true] > li::before,
.rich-text-field-desc ul[data-checked=false] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
.rich-text-field-desc ul[data-checked=true] > li::before {
  content: '\\2611';
}
.rich-text-field-desc ul[data-checked=false] > li::before {
  content: '\\2610';
}
.rich-text-field-desc li::before {
  display: inline-block;
  margin-right: 0.3em;
  text-align: right;
  white-space: nowrap;
  width: 1.2em;
}
.rich-text-field-desc li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
}
.rich-text-field-desc ol li,
.rich-text-field-desc ul li {
  padding-left: 1.5em;
}
.rich-text-field-desc ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-num;
}
.rich-text-field-desc ol li:before {
  content: counter(list-num, decimal) '. ';
}
.rich-text-field-desc ol li.ql-indent-1 {
  counter-increment: list-1;
}
.rich-text-field-desc ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}
.rich-text-field-desc ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.rich-text-field-desc ol li.ql-indent-2 {
  counter-increment: list-2;
}
.rich-text-field-desc ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}
.rich-text-field-desc ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.rich-text-field-desc ol li.ql-indent-3 {
  counter-increment: list-3;
}
.rich-text-field-desc ol li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}
.rich-text-field-desc ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.rich-text-field-desc ol li.ql-indent-4 {
  counter-increment: list-4;
}
.rich-text-field-desc ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}
.rich-text-field-desc ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.rich-text-field-desc ol li.ql-indent-5 {
  counter-increment: list-5;
}
.rich-text-field-desc ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}
.rich-text-field-desc ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
.rich-text-field-desc ol li.ql-indent-6 {
  counter-increment: list-6;
}
.rich-text-field-desc ol li.ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}
.rich-text-field-desc ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}
.rich-text-field-desc ol li.ql-indent-7 {
  counter-increment: list-7;
}
.rich-text-field-desc ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}
.rich-text-field-desc ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}
.rich-text-field-desc ol li.ql-indent-8 {
  counter-increment: list-8;
}
.rich-text-field-desc ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}
.rich-text-field-desc ol li.ql-indent-8 {
  counter-reset: list-9;
}
.rich-text-field-desc ol li.ql-indent-9 {
  counter-increment: list-9;
}
.rich-text-field-desc ol li.ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}
.rich-text-field-desc .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.rich-text-field-desc li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.rich-text-field-desc .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.rich-text-field-desc li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.rich-text-field-desc .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.rich-text-field-desc li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.rich-text-field-desc .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.rich-text-field-desc li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.rich-text-field-desc .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.rich-text-field-desc li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.rich-text-field-desc .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.rich-text-field-desc li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.rich-text-field-desc .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.rich-text-field-desc li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.rich-text-field-desc .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.rich-text-field-desc li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.rich-text-field-desc .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
.rich-text-field-desc li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.rich-text-field-desc .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
.rich-text-field-desc li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.rich-text-field-desc .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
.rich-text-field-desc li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.rich-text-field-desc .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
.rich-text-field-desc li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.rich-text-field-desc .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
.rich-text-field-desc li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.rich-text-field-desc .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
.rich-text-field-desc li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.rich-text-field-desc .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
.rich-text-field-desc li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.rich-text-field-desc .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
.rich-text-field-desc li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.rich-text-field-desc .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
.rich-text-field-desc li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.rich-text-field-desc .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
.rich-text-field-desc li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
`;
export const App = hot(module)(() => (
  <TopLevelErrorBoundary>
    <style>{quillDescriptionCss}</style>
    <Switch>
      <Route path="/:prefix/form/:formKey" component={SubmitFormApp} /> 
      <Route path="/:formKey" component={SubmitFormApp} />
    </Switch>
  </TopLevelErrorBoundary>
));