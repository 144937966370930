import { css } from '@emotion/core';
import { url } from 'inspector';
import React, { ReactEventHandler } from 'react';
import styled from '../styled';

interface SplitLayoutWrapperProps {
  isRichTextEnabled?: boolean;
  backgroundColor?: string;
  fontFamily?: string;
}

const FormPage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: ${(props:any) => props.theme.style.font.family};
  background: transparent;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  width: 610px;
  overflow-wrap: break-word;
`;

export const PlainFormTitle = styled.h1`
  font-size: 32px;
  font-weight: 900;
  white-space: pre-wrap;
  letter-spacing: -0.5;
  margin-bottom: 0;
  margin-top: -10;
  max-width: 100%;
`;

export const RichFormTitle = styled.div`
  font-size: 32px;
  margin-bottom: 10px;
  & p, & ol, & ul {
    margin-block: 2px;
  }
`;

export const SplitLayoutWrapper = styled.div<SplitLayoutWrapperProps>`
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  font-family: ${props => props.fontFamily};
  max-height: 100vh;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* This IE10+ specific CSS is needed to prevent a double scroll bar from appearing in IE11
      *  We don't want to force this css onto other browsers this forces the left section of modern form
      *  layout to not be fully static/scrollable. */
    max-height: none;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    
    > section {
      padding-left: 30px;
      padding-right: 30px;
      flex: 0 0 auto;
      justify-content: center;
      overflow: visible;

      :first-of-type {
        > :first-of-type {
          align-items: flex-start;
        }
        ${props => !props.isRichTextEnabled && 
          css`p: {
            font-size: 13;
            line-height: 15px;
            margin-bottom: 30;
          }`
        }
      }
    }

    > :nth-of-type(3) {
      display: block;
    }
  }
`;

export const RichTextDescription = styled.div`
  & p {
    margin-block: 2px;
  }
  & ol, & ul {
    margin-block: 2px;
    list-style-position: inside;
  }
`;

export const PlainDescription = styled.p<{textColor?: string}>`
  font-size: 16;
  font-weight: 100;
  line-height: 19px;
  max-width: 100%;
  white-space: pre-wrap;
  a {
    color: ${props => props.textColor};
  }
`;

export const StyledStaticSection = styled.section<{backgroundImage?: string}>`
  background-image: ${props => props.backgroundImage && `url(${props.backgroundImage})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: flex-start;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  overflow-y: auto;
  padding: 0px calc(12.9% - 76px) 30px calc(12.9% - 76px);
`;

export const FormContentSection = styled.section`
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex: 1;
  overflow-y: auto;
  padding: 12px calc(12.9% - 76px) 0px calc(12.9% - 76px);
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* This IE10+ specific CSS is needed to prevent a double scroll bar from appearing in IE11
    *  We don't want to force this css onto other browsers this forces the left section of modern form
    *  layout to not be fully static/scrollable. */
      minHeight: 100vh;
    }
`;

export default FormPage;