import * as React from 'react';
import styled from '@emotion/styled/macro';
import { connect } from 'react-redux';
import { Dispatch, compose } from 'redux';
import { SubmitFormActions, UpdateFormRequestObject } from '../../../app/store/actions';
import { RootState } from '../../../app/store/state';
import { FormDefinition, FormValues, FormSubmission, FormConfirmation, FormConfirmationType, FormComponentDefinition, ThemeType } from '../../../app/types';
import { withPropsFromRouter, PropsFromRouter } from './route-parser';

import { ConnectedReduxProps } from '../../../app/store';
import Form from '../../../app/components/Form';
import { FormDescription } from '../../../app/components/FormHeader';
import FormSubmissionPopup from '../../../app/components/FormSubmissionPopup';
import { MarkDownDescription, getDisplayLocale } from '@smartsheet/forms-components';
import MobileBanner from '../../../app/components/MobileBanner'
import ImagePreloader from './ImagePreloader';
import {isHttpResponseError} from '../../../app/errors';
import MainContent from './MainContent';
import InlineError from './InlineError';

import { IntlProvider, addLocaleData } from "react-intl";
import * as en from "react-intl/locale-data/en";
import * as de from "react-intl/locale-data/de";
import * as es from "react-intl/locale-data/es";
import * as fr from "react-intl/locale-data/fr";
import * as it from "react-intl/locale-data/it";
import * as ja from "react-intl/locale-data/ja";
import * as pt from "react-intl/locale-data/pt";
import * as ru from "react-intl/locale-data/ru";
import localeDataJson from "../../../assets/languages/languages.json";
import { isModernLayout, rtnFormattedLocale } from '../../../app/utils';
import { getEngine, createEngineCompRules, addRulesToEngine, RuleEvent } from "../../../app/components/ConditionalLogic";
import _debounce from 'lodash.debounce';
import { FormStatusType } from '../../../app/store/sagas';
import { RuleProperties } from 'json-rules-engine';

addLocaleData([...en, ...de, ...es, ...fr, ...it, ...ja, ...pt, ...ru]);

const RichDescription = styled.div`
  & p {
    margin-block: 2px;
  }
  & ol {
    margin-block: 2px;
    list-style-position: inside;
  }
  & ul {
    margin-block: 2px;
    list-style-position: inside;
  }
`;

interface PropsFromState {
  loading: boolean
  form?: FormDefinition
  formToken?: string;
  initialValues?: FormValues
  validationSchema?: any
  formikRef: React.RefObject<any>
  submitting: boolean
  submission?: FormSubmission
  error?: Error;
  confirmation: FormConfirmation;
  formStatusType: FormStatusType;
  timeOfRender?: Date;
}

interface PropsFromDispatch {
  openFormRequest: typeof SubmitFormActions.openFormRequest
  submitFormRequest: typeof SubmitFormActions.submitFormRequest
  updateFormRequest: typeof SubmitFormActions.updateFormRequest
}

type AppProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRouter &
  ConnectedReduxProps

const DEFAULT_LOCALE = "en-US";

const localeData = localeDataJson as any;

type InternalState = {
  initialLoad: boolean; // to indicate if initial run of logic rules is complete
  rulesList: RuleProperties[]
}

class SubmitFormApp extends React.Component<AppProps,InternalState> {
  constructor(props: AppProps, context?: any) {
    super(props, context);
    this.state = {
      initialLoad: false, // initialize to false as no logic rules are run yet
      rulesList: []
    }
  }

  private componentUpdateMap: {[key: string]: {property: string, value: boolean, eventType: string}} = {};
  private engine = getEngine([], {allowUndefinedFacts: true})
    .on('success', (event, almanac, ruleResult) => {
      const ev = event as RuleEvent;
      const {key,property,valueOnSuccess} = ev.params;
      this.updateComponents(key, property, valueOnSuccess, ev.type);
    })
    .on('failure', (event, almanac, ruleResult) => {
      const ev = event as RuleEvent;
      const {key,property,valueOnFailure} = ev.params;
      this.updateComponents(key, property, valueOnFailure, ev.type);
    });

  public componentDidMount() {
    if (!this.props.form) {
      this.props.openFormRequest({ formKey: this.props.formKey });
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: AppProps) {
    if(this.props.form === undefined && nextProps.form !== undefined) {
      const { form, initialValues } = nextProps;
      let rulesList: RuleProperties[] = [];
      if(form && initialValues){
        rulesList = createEngineCompRules(form.components, initialValues);
      }
      // if the rulesList is empty, then we set initialLoad to true
      if(rulesList.length === 0) {
        this.setState({initialLoad: true});
      }
      this.setState({rulesList: rulesList});
      addRulesToEngine(rulesList);
      this.validateLogicRules({...initialValues!});
    }else if(this.props.submitting === true && nextProps.submitting === false) {
      const { initialValues } = nextProps;
      const { rulesList } = this.state;
      // if the rulesList is empty, then we set initialLoad to true
      if(rulesList.length === 0) {
        this.setState({initialLoad: true});
      }
      this.validateLogicRules({...initialValues!});
    }
  }

  private updateComponentState = _debounce(()=>{
    const form = {...this.props.form} as FormDefinition;
    form.components.forEach((component: FormComponentDefinition) => {
      if(component && component.key && this.componentUpdateMap[component.key]) {
        const {value, property} = this.componentUpdateMap[component.key];
        (component as any)[property] = value;
        delete this.componentUpdateMap[component.key];
      }
    });
    // Once the rules are run and components are updated, then we set initialLoad to true
    if(!this.state.initialLoad){
      this.setState({initialLoad: true});
    }
    this.props.updateFormRequest({form});
  }, 200);

  private updateComponents = (key: string, property: string, value:boolean, eventType: string) => {
    // Truth table case On hold
    //if(!this.componentUpdateMap[key]) {
      this.componentUpdateMap[key] = {property, value, eventType};
    //} else {
      //special cases if show & hide actions are both present on component
      // const prevEntry = this.componentUpdateMap[key];
      // let val = true;
      // if(prevEntry.eventType == EventType.SHOW && !prevEntry.value) {
      //   val = value;
      // }else if(prevEntry.eventType == EventType.SHOW && prevEntry.value) {
      //   val = true;
      // }else if(prevEntry.eventType == EventType.HIDE && !prevEntry.value) {
      //   val = value;
      // }else if(prevEntry.eventType == EventType.HIDE && prevEntry.value) {
      //   val = true;
      // }
      // this.componentUpdateMap[key] = {property, value:val, eventType };
    //}
    this.updateComponentState();
  };

  public validateLogicRules = _debounce((values: FormValues) => {
    let runRuleEngine  = false;
    // In case of error, don't run logic engine unless user has updates
    if (this.props.error) {
      // User has made some changes post error message
      if (JSON.stringify(values) !== JSON.stringify(this.props.initialValues)) {
        runRuleEngine = true;
      }
    } else { // No error, proceed with normal flow
      runRuleEngine = true;
    }

    // Do not run logic rule engine if there's error and user has not made any changes,
    // stick to what was in original submission
    if (runRuleEngine) {
      this.engine.run({...values});
    }

  },100);

  private get shouldRenderFullPageError() {
    const { error } = this.props;

    return (
      isHttpResponseError(error) &&
      (error.errorType === 'FORM_NOT_FOUND' ||
        error.errorType === 'FORM_NOT_ACTIVE')
    );
  }

  private get shouldRenderFullPageConfirmation() {
    const { confirmation, shouldShowConfirmation } = this.props;

    if (!confirmation || !shouldShowConfirmation) {
      return false;
    }

    return (
      confirmation.type === FormConfirmationType.MESSAGE ||
      // In the special case where the form had been set to reload but that
      // functionality has been disabled, we'll fall back to hiding the form
      // and showing this standalone confirmation instead.
      (confirmation.type === FormConfirmationType.RELOAD &&
        confirmation.reloadDisabled)
    );
  }

  private get shouldRenderInlineConfirmation() {
    const { confirmation, shouldShowConfirmation } = this.props;

    if (!confirmation || !shouldShowConfirmation) {
      return false;
    }

    if (
      confirmation.type !== FormConfirmationType.RELOAD ||
      confirmation.reloadDisabled
    ) {
      return false;
    }

    return true;
  }

  private get shouldRenderLogo() {
    const { form } = this.props;

    return Boolean(
      form &&
        form.theme &&
        form.theme.style &&
        form.theme.style.logo &&
        form.theme.style.logo.image !== 'none'
    );
  }

  public render() {
    const {
      formStatusType,
      confirmation,
      error,
      form,
      formKey,
      formToken,
      formikRef,
      initialValues,
      submitFormRequest,
      submitting,
      timeOfRender,
      validationSchema,
      wasRefreshed,
    } = this.props;

    const {initialLoad} = this.state;

    const hideTitleAndDescription = Boolean(
      form && form.hideTitleAndDescription
    );

    const description =
      (form && !hideTitleAndDescription && form.description) ? form.description : "";

    const title = form && !hideTitleAndDescription ? form.name : undefined;

    const richTitle = form && !hideTitleAndDescription ? form.richTitle : undefined;

    const richDescription = form && !hideTitleAndDescription ? form.richDescription : undefined;
    
    const isRichTextEnabled = form && !hideTitleAndDescription ? form.isRichTextEnabled : false;

    const locale =
      form && localeData[rtnFormattedLocale(form.userLocale)]
        ? rtnFormattedLocale(form.userLocale)
        : DEFAULT_LOCALE;

    const messages =
      form && localeData[getDisplayLocale(form.userLocale)]
        ? localeData[getDisplayLocale(form.userLocale)]
        : localeData[DEFAULT_LOCALE];

    const showDescriptionPlain = form &&  !isModernLayout(form) &&
        (richDescription || description) && (form.theme && form.theme.type !== ThemeType.SHEET_BRANDED) &&
        !hideTitleAndDescription;
    return (
      <IntlProvider locale={locale} messages={messages}>
        <>
          <MobileBanner
              forceHidden={form ? form.hideFooterOnForm : false}
              publishKey={formKey}
          />
          <ImagePreloader />
          {submitting && <FormSubmissionPopup />}
          <MainContent
            hideFooterOnFormConfirmationPage={form ? form.hideFooterOnForm : false}
            disableFooterFeatureFlag={form ? form.disableFooterFeatureFlag : false}
            isRichTextEnabled={form ? form.isRichTextEnabled : false}
            confirmation={confirmation}
            description={description}
            documentTitle={form && form.name}
            error={error}
            formKey={formKey}
            formTheme={form && form.theme}
            isModernLayout={isModernLayout(form)}
            renderForm={(scrollToTop: () => void) => (
              initialLoad && <Form
                form={form}
                formKey={formKey}
                formToken={formToken}
                formikRef={formikRef}
                initialValues={initialValues}
                renderFormHeader={({ formikErrors, submitCount }) => (
                  <>
                    <InlineError
                      wasRefreshed={wasRefreshed}
                      formikErrors={formikErrors}
                      generalError={error}
                      submitCount={submitCount}
                      scrollToTop={scrollToTop}
                    />
                    {/* HACK: Plain forms have their description
                        (annoyingly) positioned underneath the inline errors
                        so we'll render the description here in the form header
                        (instead of finding some complicated way of passing the
                        form errors back up into the layout component which
                        should really be responsible for rendering this).
                    */}
                    {showDescriptionPlain && (
                        ((isRichTextEnabled && richDescription) &&
                         <RichDescription className="rich-text-field-desc" dangerouslySetInnerHTML={{__html: richDescription}} data-client-id="form_description"/>) ||
                        (<FormDescription data-client-id="form_description">
                          {MarkDownDescription(description)}
                        </FormDescription>)
                      )}
                  </>
                )}
                submitFormRequest={submitFormRequest}
                submitting={submitting}
                formStatusType={formStatusType}
                confirmation={confirmation}
                timeOfRender={timeOfRender}
                validationSchema={validationSchema}
                handleValidate={this.validateLogicRules}
              />
            )}
            shouldRenderFullPageConfirmation={
              this.shouldRenderFullPageConfirmation
            }
            shouldRenderFullPageError={this.shouldRenderFullPageError}
            shouldRenderInlineConfirmation={this.shouldRenderInlineConfirmation}
            shouldRenderLogo={this.shouldRenderLogo}
            title={title}
            richTitle={richTitle}
            richDescription={richDescription}
          />
        </>
      </IntlProvider>
    );
  }
}

const mapStateToProps = ({ submitForm }: RootState) => ({
  loading: submitForm.loading,
  form: submitForm.form,
  formToken: submitForm.formToken,
  // Clear out any previous error,
  //  if form submission suceeds by leveraging confirmation on 200
  error: submitForm.confirmation != null ? undefined: submitForm.error,
  initialValues: submitForm.initialValues,
  validationSchema: submitForm.validationSchema,
  formikRef: submitForm.formikRef,
  submitting: submitForm.submitting,
  submission: submitForm.submission,
  confirmation: submitForm.confirmation,
  formStatusType: submitForm.formStatusType,
  timeOfRender: submitForm.timeOfRender,
})

export interface OpenFormRequestObject {
  formKey: string;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openFormRequest: (requestObject: OpenFormRequestObject) => dispatch(SubmitFormActions.openFormRequest(requestObject)),
  submitFormRequest: (submission: FormSubmission) => dispatch(SubmitFormActions.submitFormRequest(submission)),
  updateFormRequest: (updateObject: UpdateFormRequestObject) => dispatch(SubmitFormActions.updateFormRequest(updateObject)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPropsFromRouter
)(SubmitFormApp);
