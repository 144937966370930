import React from 'react';
var PartlySunny = function () {
    return (React.createElement("svg", { style: { marginBottom: "4px" }, xmlns: "http://www.w3.org/2000/svg", width: "24px", height: "15px", viewBox: "0 0 24 15", version: "1.1", role: "img" },
        React.createElement("title", null, "Weather Partly Sunny"),
        React.createElement("desc", null, "Partly sunny weather."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(1.000000, 0.000000)" },
                React.createElement("path", { d: "M3.5,15 C1.56700338,15 0,13.4329966 0,11.5 C0,9.7259908 1.31983451,8.26024546 3.03113375,8.03113375 C3.26024546,6.31983451 4.7259908,5 6.5,5 C7.68314501,5 8.72917444,5.58706163 9.36268613,6.48578275 C9.83348619,6.17856066 10.3958906,6 11,6 C12.6568542,6 14,7.34314575 14,9 C15.6568542,9 17,10.3431458 17,12 C17,13.6568542 15.6568542,15 14,15 L3.5,15 Z", fill: "#CCCCCC" }),
                React.createElement("path", { d: "M15,0.495388985 C15,0.221793203 15.2319336,0 15.5,0 C15.7761424,0 16,0.215752602 16,0.495388985 L16,2.50461102 C16,2.7782068 15.7680664,3 15.5,3 C15.2238576,3 15,2.7842474 15,2.50461102 L15,0.495388985 Z M20.0994546,2.19343866 C20.292916,1.99997723 20.6137493,2.00714757 20.8033009,2.19669914 C20.998563,2.39196129 21.0042941,2.70281266 20.8065613,2.90054544 L19.3858268,4.32127996 C19.1923654,4.5147414 18.8715321,4.50757106 18.6819805,4.31801948 C18.4867184,4.12275734 18.4809873,3.81190597 18.67872,3.61417318 L20.0994546,2.19343866 Z M22.504611,7 C22.7782068,7 23,7.23193359 23,7.5 C23,7.77614237 22.7842474,8 22.504611,8 L20.495389,8 C20.2217932,8 20,7.76806641 20,7.5 C20,7.22385763 20.2157526,7 20.495389,7 L22.504611,7 Z M20.8065613,12.0994546 C21.0000228,12.292916 20.9928524,12.6137493 20.8033009,12.8033009 C20.6080387,12.998563 20.2971873,13.0042941 20.0994546,12.8065613 L18.67872,11.3858268 C18.4852586,11.1923654 18.4924289,10.8715321 18.6819805,10.6819805 C18.8772427,10.4867184 19.188094,10.4809873 19.3858268,10.67872 L20.8065613,12.0994546 Z M12.32128,3.61417318 C12.5147414,3.80763462 12.5075711,4.12846791 12.3180195,4.31801948 C12.1227573,4.51328163 11.811906,4.51901275 11.6141732,4.32127996 L10.1934387,2.90054544 C9.99997723,2.70708401 10.0071476,2.38625071 10.1966991,2.19669914 C10.3919613,2.001437 10.7028127,1.99570588 10.9005454,2.19343866 L12.32128,3.61417318 Z", fill: "#F6A623" }),
                React.createElement("path", { d: "M12.7119629,5.38382955 C13.3512144,4.54292067 14.3622042,4 15.5,4 C17.4329966,4 19,5.56700338 19,7.5 C19,8.63779578 18.4570793,9.64878564 17.6161704,10.2880371 C17.0988174,9.19716637 16.1023896,8.37768089 14.898653,8.10134696 C14.6223191,6.89761044 13.8028336,5.90118256 12.7119629,5.38382955 Z", fill: "#F6A623" })))));
};
export default PartlySunny;
