import * as React from 'react';
import styled from '@emotion/styled/macro';
import { MarkDownDescription } from '@smartsheet/forms-components';
import { FormattedMessage } from 'react-intl';
import { resolveAssetUrl } from '../../../app/utils';
import checkmark from './checkmark.svg';

export type ConfirmationMessageProps = {
    message?: string,
    showCheckmark?: boolean,
}

const ConfirmationMessageContainer = styled.div`
    display: flex;
    align-items: baseline;
`

const BlockFormattedMessage = styled.div`
    text-align: center;
    font-size: 1.333em;
`

const SpanLeftJustifiedFormattedMessage = styled.span`
    text-align: left;
`
const ConfirmationMessageText = styled.h1`
    align-self: stretch;
    font-weight: 300;
    font-size: 1.13em;
    text-align: center;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    max-width: 518px;
    width:100%;
    margin-top: 1px;
    margin-bottom: 1px;
`

const ConfirmationIcon = styled.img`
    content: url(${resolveAssetUrl(checkmark)});
    margin-right: 6px;
    margin-top: 2px;
`;

interface FormatParameters  {
    showCheckmark?: boolean;
    message?: string;
}

function format({showCheckmark, message}: FormatParameters): React.ReactNode {
    if (message) {
        return MarkDownDescription(message);
    }
    if(showCheckmark){
        return (
            <>
                <SpanLeftJustifiedFormattedMessage>
                    <FormattedMessage id="lbl_success_capture_submission" />
                </SpanLeftJustifiedFormattedMessage>
            </>
        );
    }
    return (
        <>
            <BlockFormattedMessage>
                <FormattedMessage id="lbl_success_submission" />
            </BlockFormattedMessage>
            <BlockFormattedMessage>
                <FormattedMessage id="lbl_capture_submission" />
            </BlockFormattedMessage>
        </>
    );
}

const ConfirmationMessage: React.FC<ConfirmationMessageProps> = (props) => {
    const { message, showCheckmark } = props;

    return(
        <ConfirmationMessageContainer>
            {showCheckmark && <ConfirmationIcon alt="Success Checkmark"/>}
            <ConfirmationMessageText data-client-id='confirmation_msg'>{format({showCheckmark, message})}</ConfirmationMessageText>
        </ConfirmationMessageContainer>
    )
}

export default ConfirmationMessage