import React from 'react';
var Stormy = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18px", height: "16px", viewBox: "0 0 18 16", version: "1.1", role: "img" },
        React.createElement("title", null, "Weather Stormy"),
        React.createElement("desc", null, "Stormy weather."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(1.000000, 1.000000)" },
                React.createElement("path", { d: "M11.2806248,10 L12.0806248,9 L10.8685171,9 L12.8685171,6 L6.21922359,6 L5.21922359,10 L3.5,10 C1.56700338,10 0,8.43299662 0,6.5 C0,4.7259908 1.31983451,3.26024546 3.03113375,3.03113375 C3.26024546,1.31983451 4.7259908,0 6.5,0 C7.68314501,0 8.72917444,0.587061631 9.36268613,1.48578275 C9.83348619,1.17856066 10.3958906,1 11,1 C12.6568542,1 14,2.34314575 14,4 C15.6568542,4 17,5.34314575 17,7 C17,8.65685425 15.6568542,10 14,10 L11.2806248,10 Z", id: "Combined-Shape", fill: "#CCCCCC" }),
                React.createElement("polygon", { fill: "#F6A623", points: "7 7 11 7 9 10 10 10 6 15 7 11 6 11" })))));
};
export default Stormy;
