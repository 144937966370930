import { __makeTemplateObject } from "tslib";
import styled from "@emotion/styled/macro";
import React from "react";
export var PrimaryButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border-radius: 2px;\n    border: 0px;\n    background-color: #005EE0;\n    padding: 7px 10px;\n    min-width: 70px;\n    min-height: 30px;\n    max-height: fit-content;\n    text-align: center;\n    color:#FFFFFF;\n    cursor: pointer;    \n    font-family: Roboto;\n    font-size: 0.88em;\n    font-weight: normal;\n    font-style: normal;\n    font-stretch: normal;\n    line-height: 1.15;\n    letter-spacing: normal;\n"], ["\n    border-radius: 2px;\n    border: 0px;\n    background-color: #005EE0;\n    padding: 7px 10px;\n    min-width: 70px;\n    min-height: 30px;\n    max-height: fit-content;\n    text-align: center;\n    color:#FFFFFF;\n    cursor: pointer;    \n    font-family: Roboto;\n    font-size: 0.88em;\n    font-weight: normal;\n    font-style: normal;\n    font-stretch: normal;\n    line-height: 1.15;\n    letter-spacing: normal;\n"])));
export var SecondaryButton = styled(PrimaryButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background-color: #cccccc;\n"], ["\n    background-color: #cccccc;\n"])));
export var OutlineButton = styled(PrimaryButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background: none;\n    border: none;\n    color:#005EE0;\n    &:hover {\n        border-radius: 2px;\n        background-color: rgba(0, 94, 224, 0.1);\n    }\n"], ["\n    background: none;\n    border: none;\n    color:#005EE0;\n    &:hover {\n        border-radius: 2px;\n        background-color: rgba(0, 94, 224, 0.1);\n    }\n"])));
export var OutlineBorderButton = styled(PrimaryButton)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    background: none;\n    border-radius: 2px;\n    border: solid 1px #005ee0;\n    color:#005EE0;\n    &:hover {\n        border-radius: 2px;\n        background-color: rgba(0, 94, 224, 0.1);\n    }\n"], ["\n    background: none;\n    border-radius: 2px;\n    border: solid 1px #005ee0;\n    color:#005EE0;\n    &:hover {\n        border-radius: 2px;\n        background-color: rgba(0, 94, 224, 0.1);\n    }\n"])));
export var OutlineSmallButton = styled(PrimaryButton)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 85px;\n    border: solid 1px #005ee0;\n    background: none;\n    color: #005ee0;\n    &:hover {\n        border: solid 1px #004fbe;\n        background-color: rgba(0, 94, 224, 0.1);\n    }\n"], ["\n    width: 85px;\n    border: solid 1px #005ee0;\n    background: none;\n    color: #005ee0;\n    &:hover {\n        border: solid 1px #004fbe;\n        background-color: rgba(0, 94, 224, 0.1);\n    }\n"])));
export var OutlineBigButton = styled(OutlineSmallButton)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    width: 320px;\n"], ["\n    width: 320px;\n"])));
export var CloseButton = function (props) {
    //@TODO Move svgs to separate file/folder
    var CloseSvg = function () { return (React.createElement("svg", { role: "img", xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16" },
        React.createElement("title", null, "Close"),
        React.createElement("desc", null, "Close"),
        React.createElement("path", { fill: "#161616", fillRule: "evenodd", d: "M7.996 6.723l3.146-3.147a.5.5 0 0 1 .707 0l.566.566a.5.5 0 0 1 0 .707L9.268 7.996l3.147 3.146a.5.5\n                0 0 1 0 .707l-.566.566a.5.5 0 0 1-.707 0L7.996 9.268l-3.147 3.147a.5.5 0 0 1-.707 0l-.566-.566a.5.5 0 0\n                1 0-.707l3.147-3.146-3.147-3.147a.5.5 0 0 1 0-.707l.566-.566a.5.5 0 0 1 .707 0l3.147 3.147z" }))); };
    var CloseIcon = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        cursor: pointer;\n        &:hover {\n                svg path {\n                fill: #005EE0;\n                }\n            }\n        "], ["\n        cursor: pointer;\n        &:hover {\n                svg path {\n                fill: #005EE0;\n                }\n            }\n        "])));
    return (React.createElement(CloseIcon, { onClick: props.onClick },
        React.createElement(CloseSvg, null)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
