import { __assign } from "tslib";
import React from "react";
import { Util } from "./TextInputValidationUtil";
import NumberFormat from "react-number-format";
var CurrencyValidation = function (_a) {
    var _b;
    var ariaInvalid = _a.ariaInvalid, tabIndex = _a.tabIndex, id = _a.id, dataClientId = _a.dataClientId, describedByIds = _a.describedByIds, prefix = _a.prefix, suffix = _a.suffix, onValueChange = _a.onValueChange;
    var ValidationTypes = Util.ValidationTypes, thousandSeparator = Util.thousandSeparator, decimalSeparator = Util.decimalSeparator;
    return (React.createElement(NumberFormat, __assign({ "aria-invalid": ariaInvalid }, (describedByIds && (_b = {}, _b['aria-describedby'] = describedByIds, _b)), { tabIndex: tabIndex, id: id, "data-client-id": dataClientId, "data-client-type": ValidationTypes.CURRENCY, style: { width: '282px', padding: '6px' }, thousandSeparator: thousandSeparator, decimalSeparator: decimalSeparator, prefix: prefix, suffix: suffix, onValueChange: onValueChange })));
};
export default CurrencyValidation;
