import styled from '../styled';

const FormPanel = styled.section`
  display: block;
  background-color: ${(props: any) => props.theme.style.panel.color};
  flex-direction: column;
  box-shadow: ${(props: any) => props.theme.style.boxShadow};
  padding: 12px 30px 30px;
  align-self: stretch;
  margin-bottom: 1.5rem;
  border-radius: 2px;
  word-break: break-word;
`;

export default FormPanel;