import { __assign, __makeTemplateObject, __rest } from "tslib";
import * as React from "react";
import styled from "@emotion/styled/macro";
import { createPropsGetter } from "../utils";
import InputLabel from "../InputLabel";
import LogicTag from "../LogicTag";
import SourceLogicTag from "../SourceLogicTag";
var defaultProps = {
    hasSourceLogic: false,
};
var getProps = createPropsGetter(defaultProps);
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    padding-bottom: 4px;\n"], ["\n    display: flex;\n    align-items: center;\n    padding-bottom: 4px;\n"])));
var noOp = function () { return; };
var InputLabelWithLogic = function (props) {
    var _a = getProps(props), hasSourceLogic = _a.hasSourceLogic, logic = _a.logic, onSourceLogicTagClick = _a.onSourceLogicTagClick, otherProps = __rest(_a, ["hasSourceLogic", "logic", "onSourceLogicTagClick"]);
    return (React.createElement(Container, null,
        React.createElement("div", null,
            React.createElement(InputLabel, __assign({}, otherProps))),
        hasSourceLogic && (React.createElement(SourceLogicTag, { onClickHandler: onSourceLogicTagClick || noOp })),
        logic && React.createElement(LogicTag, { logic: logic })));
};
export default InputLabelWithLogic;
var templateObject_1;
