import * as React from "react";
import { createPropsGetter, rtnFormattedLocale, submitMetrics } from "../../../app/utils";
import styled from "@emotion/styled/macro";
import { FormattedMessage, InjectedIntl, injectIntl } from "react-intl";
import ModernLogoOnLight from "./ModernLogoOnLight";
import {FormOutlineBorderButton} from "../FormButton";

interface FormFooterWrapperProps {
  disableFooterFeatureFlag?: boolean;
}

const defaultProps = {
    hidePoweredBySmartsheet: false,
    hidePrivacyPolicyReportAbuse: false,
    hideCreateYourOwnForm: true,
};

type DefaultProps = Readonly<typeof defaultProps>;

const getProps = createPropsGetter(defaultProps);

export type FormFooterProps = {
  intl: InjectedIntl;
  publishKey: string;
  hideRecaptchaMessage: boolean;
  onConfirmationPage?: boolean;
  disableFooterFeatureFlag?: boolean;
} & Partial<DefaultProps>;

export const FormFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 0.88em;
  margin-top: 28px;
  height: auto;
  flex: auto;
`;

export const FormFooterRow = styled.div`
  color: #161616;
  width: 100%;
  text-align: center;
  margin-bottom: 0.125rem;
  display: flex
  &:nth-of-type(2) {
    margin-top: 5px;
  }
`;

export const FormFooterLink = styled.a`
  color: #005EE0;
  text-decoration: none;
`;

export const FormFooterMessageLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-bottom: -6.5px;
`;

export const FormFooterReCaptchaLink = styled.a`
  color: #005EE0;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const FormFooterReCAPTCHA = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  color: #767676;
  font-size: 0.6em;
`
export const FormFooterWrapper= styled.div<FormFooterWrapperProps>`
  display: ${props => props.disableFooterFeatureFlag ? 'none': ''};
`;

export const FormCallToActionButton = styled(FormOutlineBorderButton)`
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 0.75rem;
`

//verify that this function continues to work in the future as new languages are added
function getPrivacyPolicyLink(locale:string) {
  if(locale.length > 1) {
    const langSupported = rtnFormattedLocale(locale)
    const language = locale.substr(0,2).toLowerCase();
    if(langSupported && language !== "en") {
      // Note: for ja-JP, this appears to be the only case where privacy notice
      // subdomain does not use the language, and instead uses the region code
      const subdomain = language === 'ja' ? 'jp' : language;
      return "https://" + subdomain + ".smartsheet.com/legal/privacy";
    }
  }
  return "https://www.smartsheet.com/legal/privacy";
}

const captureMetrics = (key: string, val: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
  submitMetrics({
    id: key,
    event: "Mouse_Left_Click",
    element: "Render_Footer",
    value: val
  });
};

const FormFooter: React.FC<FormFooterProps> = props => {

    const { hidePoweredBySmartsheet,  hidePrivacyPolicyReportAbuse, hideCreateYourOwnForm, intl,
      disableFooterFeatureFlag, publishKey, hideRecaptchaMessage, onConfirmationPage} = getProps(props);
      
    /**
     * reportAbuseUrl uses `window.location.origin` to get the base url of the current environment dynamically. 
     * This is to support app.smartsheet.com/b/reportabuse vs app.smartsheetgov.com/b/reportabuse. 
     * Legacy forms automatically update to the new address, but Form Service forms were preivously 
     * hard-coded to app.smartsheet.com/b/reportabuse. The related automation checks for [base url]/b/reportabuse to confirm 
     * the URL is updating appropriately. 
     * 
     * This approach will need to be revisited when custom base url support is released. We will need to inject
     * a property to define where abuse reports will be sent per environment.
     */
    const reportAbuseURL = window.location.origin + "/b/reportabuse?EQBCT=" + publishKey;
    const {cookie} = document;
    const isLoggedIn = cookie.indexOf('S3S_F=') > -1;
    const showTrialFooter = (!hideCreateYourOwnForm && !isLoggedIn);

    return (
        <FormFooterContainer data-client-id="footer">
            <FormFooterRow>
                {!hidePoweredBySmartsheet && (
                    <FormFooterMessageLinkContainer>
                        <FormattedMessage id="elements.lbl_webForm_form_poweredBy" />
                        <FormFooterLink
                            onClick={captureMetrics(publishKey, `${onConfirmationPage ? "Confirmation Page - " : ""}Smartsheet logo click`)}
                            target="_blank"
                            href="https://www.smartsheet.com/platform?s=154"
                            rel="noopener noreferrer"
                            data-client-id="footer_poweredBy"
                            aria-label="logo"
                        >
                            <ModernLogoOnLight />
                        </FormFooterLink>
                    </FormFooterMessageLinkContainer>
                )}
                {showTrialFooter && (
                   // Hide footer if feature flag set to turn off footer
                    <FormFooterWrapper disableFooterFeatureFlag={disableFooterFeatureFlag}>
                        <FormFooterRow>
                            <FormattedMessage id="lbl_forms_collect" />
                        </FormFooterRow>
                        <FormFooterLink
                            onClick={captureMetrics(publishKey, "Trial button click")}
                            target="_blank"
                            href="https://www.smartsheet.com/try-it?s=417"
                            rel="noopener noreferrer"
                            data-client-id="footer_call_to_action"
                            aria-label="call to action"
                        >
                            <FormCallToActionButton>
                                <FormattedMessage id="lbl_forms_try" />
                            </FormCallToActionButton>
                        </FormFooterLink>
                    </FormFooterWrapper>
                )}
                {!hidePrivacyPolicyReportAbuse && (
                    <React.Fragment>
                        <FormFooterLink
                            onClick={captureMetrics(publishKey, "privacy link")}
                            target="_blank"
                            href={getPrivacyPolicyLink(intl.locale)}
                            rel="noopener noreferrer"
                            data-client-id="footer_privacy"
                        >
                            <FormattedMessage id="elements.lbl_webForm_form_privacyPolicy" />
                        </FormFooterLink>
                        <span style={{ margin: "0 5px" }}>|</span>
                        <FormFooterLink
                            onClick={captureMetrics(publishKey, "report abuse link")}
                            target="_blank"
                            href={reportAbuseURL}
                            rel="noopener noreferrer"
                            data-client-id="footer_reportAbuse"
                        >
                            <FormattedMessage id="elements.lbl_reportAbuse_linkText" />
                        </FormFooterLink>
                    </React.Fragment>
                )}
            </FormFooterRow>

            {!hideRecaptchaMessage &&
                <FormFooterReCAPTCHA data-client-id='footer_recaptcha' data-testid="footer_recaptcha">
                  <FormattedMessage id="elements.lbl_webForm_form_invisibleRecaptcha_footer"
                                    values={{
                                      0: <FormFooterReCaptchaLink onClick={captureMetrics(publishKey, 'recaptcha privacy link')} data-client-id='footer_recaptcha_privacy' target="_blank" href="https://policies.google.com/privacy">
                                          <FormattedMessage id="elements.lbl_webForm_form_invisibleRecaptcha_footer_privacyPolicy" />
                                         </FormFooterReCaptchaLink>,
                                      1: <FormFooterReCaptchaLink onClick={captureMetrics(publishKey, 'recaptcha terms link')} data-client-id='footer_recaptcha_policy' target="_blank" href="https://policies.google.com/terms">
                                          <FormattedMessage id="elements.lbl_webForm_form_invisibleRecaptcha_footer_termsOfService" />
                                         </FormFooterReCaptchaLink>,
                                    }}
                                    />
                </FormFooterReCAPTCHA>}
        </FormFooterContainer>
    );
};

export default injectIntl(FormFooter);
