import React from 'react';
import { ESymbolType } from "@smartsheet/forms-model";
import PriorityHmlIcon from "./PriorityHml";
import DecisionSymbolIcon from "./DecisionSymbol";
import DecisionShapeIcon from "./DecisionShape";
import VcrIcon from "./VCR";
import RybgIcon from "./RYGB";
import WeatherIcon from "./Weather";
import HarveyBallIcon from "./HarveyBalls";
import ProgressIcon from "./Progress";
import ArrowIcon from "./Arrows";
import DirectionsThreeWayIcon from "./DirectionsThreeWay";
import DirectionsFourWayIcon from "./DirectionsFourWay";
import SkiIcon from "./SKI";
import SignalIcon from "./Signal";
import StarRatingIcon from "./StarRating";
import HeartIcon from "./Hearts";
import MoneyIcon from "./Money";
import EffortIcon from "./Effort";
import PainIcon from "./Pain";
export var selectIcon = function (symbolType, symbolKey) {
    switch (symbolType) {
        case ESymbolType.PRIORITY:
        case ESymbolType.PRIORITY_HML:
            return (React.createElement(PriorityHmlIcon, { type: symbolKey }));
        case ESymbolType.DECISION_SYMBOLS:
            return (React.createElement(DecisionSymbolIcon, { type: symbolKey }));
        case ESymbolType.DECISION_SHAPES:
            return (React.createElement(DecisionShapeIcon, { type: symbolKey }));
        case ESymbolType.VCR:
            return (React.createElement(VcrIcon, { type: symbolKey }));
        case ESymbolType.RYG:
        case ESymbolType.RYGB:
        case ESymbolType.RYGG:
            return (React.createElement(RybgIcon, { type: symbolKey }));
        case ESymbolType.WEATHER:
            return (React.createElement(WeatherIcon, { type: symbolKey }));
        case ESymbolType.HARVEY_BALLS:
            return (React.createElement(HarveyBallIcon, { type: symbolKey }));
        case ESymbolType.PROGRESS:
            return (React.createElement(ProgressIcon, { type: symbolKey }));
        case ESymbolType.ARROWS_3_WAY:
        case ESymbolType.ARROWS_4_WAY:
        case ESymbolType.ARROWS_5_WAY:
            return (React.createElement(ArrowIcon, { type: symbolKey }));
        case ESymbolType.DIRECTIONS_3_WAY:
            return (React.createElement(DirectionsThreeWayIcon, { type: symbolKey }));
        case ESymbolType.DIRECTIONS_4_WAY:
            return (React.createElement(DirectionsFourWayIcon, { type: symbolKey }));
        case ESymbolType.SKI:
            return (React.createElement(SkiIcon, { type: symbolKey }));
        case ESymbolType.SIGNAL:
            return (React.createElement(SignalIcon, { type: symbolKey }));
        case ESymbolType.STAR_RATING:
            return (React.createElement(StarRatingIcon, { type: symbolKey }));
        case ESymbolType.HEARTS:
            return (React.createElement(HeartIcon, { type: symbolKey }));
        case ESymbolType.MONEY:
            return (React.createElement(MoneyIcon, { type: symbolKey }));
        case ESymbolType.EFFORT:
            return (React.createElement(EffortIcon, { type: symbolKey }));
        case ESymbolType.PAIN:
            return (React.createElement(PainIcon, { type: symbolKey }));
        default:
            return React.createElement("div", null);
    }
};
var SymbolIcon = function (_a) {
    var symbolType = _a.symbolType, symbolKey = _a.symbolKey;
    return selectIcon(symbolType, symbolKey);
};
export default SymbolIcon;
