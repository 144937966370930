import { Moment } from "moment";
import { EmailValue } from '@smartsheet/forms-components';
import { StringValue } from "@smartsheet/forms-model";
import { CheckboxOption } from "@smartsheet/forms-components/esm/Checkbox/MultiCheckboxInput";

//import { MultiCheckboxInputDefinition } from "@smartsheet-forms/form-model/dist/lib/form";

export interface FormDefinition {
  readonly name: string
  readonly richTitle?: string
  readonly richDescription?: string
  readonly description?: string
  readonly components: FormComponentDefinitionType[]
  readonly theme?: FormTheme
  readonly hideTitleAndDescription: boolean
  readonly hideFooterOnForm: boolean
  readonly disableFooterFeatureFlag: boolean
  readonly hideFooterOnConfirmation: boolean
  readonly isRichTextEnabled?: boolean
  readonly userLocale: string
  captcha?: CaptchaDefinition
  readonly captchaSiteKey?: string
}

export interface FormTheme {
  readonly type?: string
  readonly layout?: string
  readonly style?: FormStyle
}

export interface FormStyle {
  readonly background?: BackgroundStyle
  readonly logo?: LogoStyle
  readonly font?: { family: string };
}

export interface BackgroundStyle {
  readonly color?: string
  readonly image?: string
}

export interface LogoStyle {
  readonly image: string
}

export enum ThemeType {
  DEFAULT = "DEFAULT",
  MODERN = "MODERN",
  SHEET_BRANDED= "SHEET_BRANDED"
}

export enum FormComponentType {
  CHECKBOX_INPUT = "CHECKBOX_INPUT",
  DATE_INPUT = "DATE_INPUT",
  DIVIDER = "DIVIDER",
  FILE_UPLOAD = "FILE_UPLOAD",
  HEADING = "HEADING",
  SECTION = "SECTION",
  MULTI_CHECKBOX_INPUT = "MULTI_CHECKBOX_INPUT",
  SELECT_INPUT = "SELECT_INPUT",
  RADIO_INPUT = "RADIO_INPUT",
  TEXT_INPUT = "TEXT_INPUT",
  EMAIL_RECEIPT_INPUT = "EMAIL_RECEIPT_INPUT",
  CAPTCHA = "CAPTCHA"
}

export enum SelectDataType {
  MULTI_PICKLIST = "MULTI_PICKLIST",
  MULTI_CONTACT = "MULTI_CONTACT",
  PICKLIST = "PICKLIST"
}

export enum ValueType {
	STRING = "STRING",
	CONTACT = "CONTACT",
}

export enum ValidationTypes {
  CURRENCY = "CURRENCY",
  PERCENTAGE = "PERCENTAGE",
  EMAIL =  "EMAIL",
  NUMERIC = "NUMERIC",
  PHONE = "PHONE"
}

export interface DataValue<T> {
  readonly type: string
  value: T
}

export type FormValueType =
    string | boolean | number | Moment | Contact | Contact[] | CheckboxOption[] | EmailValue | File[] | CaptchaValue | string[];

export interface FormValues {
  [field: string]: FormValueType;
}

export interface Contact {
  readonly email: string
  readonly name: string
}

export function isContact(value: any): value is Contact {
  // value.preview is checked to guarantee File Uploads aren't evaluated to be Contact Values
  if ((value as Contact) && value.preview === undefined) {
    if ((value as Contact).email && (value as Contact).email !== undefined) {
      return true;
    } else if (
      (value as Contact).name &&
      (value as Contact).name !== undefined
    ) {
      return true;
    }
  }
  return false;
}

export const isContactArray = (arr: any): arr is Contact[] => {
  if (Array.isArray(arr) && arr.length) {
    return arr.every((val) => isContact(val));
  }
  return false;
}

export const isStringArray = (arr: any): arr is string[] => {
  if (Array.isArray(arr) && arr.length) {
    return arr.every((val) => typeof val == 'string');
  }
  return false;
}

export interface FileDefinition {
  readonly uploadKey: string
  readonly name: string
  readonly size: number
  readonly type: string
}

export type FormComponentDefinitionType =
  CheckboxInputDefinition |
  MultiCheckboxInputDefinition |
  DateInputDefinition |
  DividerDefinition |
  HeadingDefinition |
  TextInputDefinition |
  SelectInputDefinition |
  RadioInputDefinition |
  FileUploadDefinition |
  EmailReceiptInputDefinition |
  CaptchaDefinition

export interface FormComponentDefinition {
  readonly type: FormComponentType
  readonly hidden?: boolean
  readonly key?: string
  logic?: string | LogicDefinition[]
  isHiddenByConditionalLogic?: boolean
}



export interface InputComponentDefinition<T> extends FormComponentDefinition {
  readonly key: string
  readonly label: string
  readonly description?: string
  readonly richDescription?: string
  readonly value?: DataValue<T>
  readonly defaultValue?: DataValue<T>
  readonly required?: Boolean
  readonly checkboxType?: string
  readonly validateField?: Boolean
  readonly isSubmitting?: Boolean
}

export enum CheckboxCaptionLocation {
  ABOVE, RIGHT
}

export interface CheckboxInputDefinition extends InputComponentDefinition<boolean> {
  readonly type: FormComponentType.CHECKBOX_INPUT
  readonly captionLocation: CheckboxCaptionLocation
}

export interface DateInputDefinition extends InputComponentDefinition<string> {
  readonly type: FormComponentType.DATE_INPUT

}

export interface DividerDefinition extends FormComponentDefinition {
  readonly type: FormComponentType.DIVIDER
  readonly key: string
  readonly title?: string
}

export interface FileUploadDefinition extends FormComponentDefinition {
  readonly type: FormComponentType.FILE_UPLOAD

  readonly key: string
  readonly label: string
  readonly description?: string
  readonly richDescription?: string
  readonly required?: boolean
  readonly hidden?: boolean
  readonly forbidFileTypes?: string[]
  readonly maxFileCount?: number
  readonly maxFileSize?: number
}

export interface HeadingDefinition extends FormComponentDefinition {
  readonly type: FormComponentType.HEADING
  readonly key: string
  readonly richTitle?: string
  readonly title: string
  readonly description?: string
  readonly richDescription?: string
}

export interface SectionDefinition extends FormComponentDefinition {
  readonly type: FormComponentType.SECTION

  readonly label?: string
  readonly description?: string
}

export interface SelectInputDefinition extends InputComponentDefinition<string | Contact> {
  readonly type: FormComponentType.SELECT_INPUT
  readonly options: (StringValue | DataValue<Contact>)[]
  readonly allowUnset?: boolean
  readonly selectDataType?: SelectDataType
  readonly symbolType?: string
  readonly valueType?: ValueType
  readonly validation?: Validation
  readonly multivalue?: boolean
  readonly formBuilder?: boolean
}

export type RadioOptionsDirection = "HORIZONTAL" | "VERTICAL"

export interface MultiCheckboxInputDefinition extends InputComponentDefinition<string | Contact> {
  readonly type: FormComponentType.MULTI_CHECKBOX_INPUT
  readonly options: (StringValue | DataValue<Contact>)[]
  readonly selectDataType?: SelectDataType
  readonly direction: RadioOptionsDirection
  readonly symbolType?: string
}

export interface RadioInputDefinition extends InputComponentDefinition<string | Contact> {
  readonly type: FormComponentType.RADIO_INPUT
  readonly options: (StringValue | DataValue<Contact>)[]
  readonly direction: RadioOptionsDirection
  readonly symbolType?: string
}

interface Validation {
  type: ValidationTypes;
  code?: string;
  country?: string;
  format: string;
  isPostFix?: boolean;
}
export interface TextInputDefinition extends InputComponentDefinition<string> {
  readonly type: FormComponentType.TEXT_INPUT
  readonly validation?: Validation
  readonly valueType?: ValueType
  readonly lines?: number
}

export interface EmailReceiptInputDefinition extends FormComponentDefinition {
  readonly type: FormComponentType.EMAIL_RECEIPT_INPUT
  readonly defaultValue?: DataValue<string>
  readonly displayDefaultValue?: boolean
  readonly key: string
  // enableCaptchaOnSendCopy should no longer be optional after minimum version is bumped to 1.50.0 or higher
  readonly enableCaptchaOnSendCopy?: boolean
}

export interface CaptchaDefinition extends FormComponentDefinition {
  readonly type: FormComponentType.CAPTCHA
  readonly key: string
}

export interface CaptchaValue {
  token: string,
  siteKey: string,
  submitCaptchaTimeElapsed: number
}

// Note - do not update this without updating corresponding format in form-components/src/Textinput/index.tsx
export const countryPhoneFormat: { [key: string]: string; } = {
  us: '^\\+1 \\(\\d{3}\\) \\d{3}-\\d{4}$', //+1 (###) ###-####
  ae: '^\\+971 \\d{1} \\d{3} \\d{4}$', //'+971 # ### #### 
  ar: '^\\+54 \\(\\d{2}\\) \\d{8}$',//'+54 (##) ########',
  at: '^\\+43 \\d{3} \\d{7}$',//'+43 ### #######',
  au: '^\\+61 \\(\\d{2}\\) \\d{4} \\d{4}$',//'+61 (##) #### ####',//https://regex101.com/r/eepL0v/1
  be: '^\\+32 \\d{3} \\d{2} \\d{2} \\d{2}$',//'+32 ### ## ## ##',
  br: '^\\+55 \\(\\d{2}\\) \\d{9}$',//'+55 (##) #########',
  bg: '^\\+359 \\d{2} \\d{3} \\d{4}$',//'+359 ## ### ####',//
  bh: '^\\+973 \\d{4} \\d{4}$', //+973 #### #### 
  ca: '^\\+1 \\(\\d{3}\\) \\d{3}-\\d{4}$',//'+1 (###) ###-####',
  ch: '^\\+41 \\d{2} \\d{3} \\d{2} \\d{2}$',//'+41 ## ### ## ##',
  cl: '^\\+56 \\d{2} \\d{3} \\d{4}$',//'+56 ## ### ####',//
  cn: '^\\+86 \\d{2}-\\d{9}$',//'+86 ##-#########',
  cy: '^\\+357 \\d{2} \\d{6}$',//'+357 ## ######',
  cz: '^\\+420 \\d{3} \\d{3} \\d{3}$',//'+420 ### ### ###',//
  de: '^\\+49 \\d{4} \\d{8}$',//'+49 #### ########',
  dk: '^\\+45 \\d{2} \\d{2} \\d{2} \\d{2}$',//'+45 ## ## ## ##',
  ee: '^\\+372 \\d{4} \\d{6}$',//'+372 #### ######',
  es: '^\\+34 \\d{3} \\d{3} \\d{3}$',//'+34 ### ### ###',
  fi: '^\\+358 \\d{2} \\d{3} \\d{2} \\d{2}$',//'+358 ## ### ## ##',
  fr: '^\\+33 \\d{1} \\d{2} \\d{2} \\d{2} \\d{2}$',//'+33 # ## ## ## ##',
  gb: '^\\+44 \\d{4} \\d{6}$',//'+44 #### ######',
  gr: '^\\+30 \\d{2} \\d{4} \\d{4}$',//'+30 ## #### ####',//
  hk: '^\\+852 \\d{4} \\d{4}$',//'+852 #### ####',
  hu: '^\\+36 \\d{2} \\d{3} \\d{3}$',//'+36 ## ### ###',//
  in: '^\\+91 \\d{5}-\\d{5}$',//'+91 #####-#####',
  ie: '^\\+353 \\d{2} \\d{7}$',//'+353 ## #######',
  il: '^\\+972 \\d{3} \\d{3} \\d{4}$',//'+972 ### ### ####',
  it: '^\\+39 \\d{3} \\d{7}$',//'+39 ### #######',
  jp: '^\\+81 \\d{2} \\d{4} \\d{4}$',//'+81 ## #### ####',
  kr: '^\\+82 \\d{3} \\d{4} \\d{4}$',//'+82 ### #### ####',
  kw: '^\\+965 \\d{4} \\d{4}$', // '+965 #### ####',
  lt: '^\\+370 \\d{3} \\d{5}$',//'+370 ### #####',//
  lv: '^\\+371 \\d{2} \\d{3} \\d{3}$',//'+371 ## ### ###',//
  lu: '^\\+352 \\d{2} \\d{2} \\d{2} \\d{2}$',//'+352 ## ## ## ##',//
  mt: '^\\+356 \\d{4} \\d{4}$',//'+356 #### ####',//
  mx: '^\\+52 \\d{3} \\d{3} \\d{4}$',//'+52 ### ### ####',
  my: '^\\+60 \\d{1}-\\d{3}-\\d{4}', //+60 #-###-####',
  nl: '^\\+31 \\d{2} \\d{8}$',//'+31 ## ########',
  nz: '^\\+64 \\d{3}-\\d{3}-\\d{4}$',//'+64 ###-###-####',
  no: '^\\+47 \\d{3} \\d{2} \\d{3}$',//'+47 ### ## ###',
  pl: '^\\+48 \\d{3}-\\d{3}-\\d{3}$',//'+48 ###-###-###',
  pt: '^\\+351 \\d{3} \\d{3} \\d{3}$',//'+351 ### ### ###',//
  qa: '^\\+974 \\d{4} \\d{4}$', //+974 #### ####
  ro: '^\\+40 \\d{3} \\d{3} \\d{3}$',//'+40 ### ### ###',//
  ru: '^\\+7 \\(\\d{3}\\) \\d{3}-\\d{2}-\\d{2}$',//'+7 (###) ###-##-##',
  sa: '^\\+966 \\d{2} \\d{3} \\d{4}$', //+966 ## ### ####
  se: '^\\+46 \\(\\d{3}\\) \\d{3}-\\d{3}$',//'+46 (###) ###-###',
  sg: '^\\+65 \\d{4}-\\d{4}$',//'+65 ####-####',
  si: '^\\+386 \\d{1} \\d{3} \\d{2} \\d{2}$',//'+386 # ### ## ##',//
  sk: '^\\+421 \\d{3} \\d{3} \\d{3}$',//'+421 ### ### ###',//
  ua: '^\\+380 \\(\\d{3}\\) \\d{3}-\\d{4}$', //'+380 (###) ###-####'
  za: '^\\+27 \\d{2} \\d{3} \\d{4}$',//'+27 ## ### ####'//
};

export const areaCodeToCountryCode: {[key: string]: string;} =  {
  "+1": "ca",
  "+7": "ru",
  "+27": "za",
  "+30": "gr",
  "+31": "nl",
  "+32": "be",
  "+33": "fr",
  "+34": "es",
  "+36": "hu",
  "+39": "it",
  "+40": "ro",
  "+41": "ch",
  "+43": "at",
  "+44": "gb",
  "+45": "dk",
  "+46": "se",
  "+47": "no",
  "+48": "pl",
  "+49": "de",
  "+52": "mx",
  "+54": "ar",
  "+55": "br",
  "+56": "cl",
  "+60": "my",
  "+61": "au",
  "+64": "nz",
  "+65": "sg",
  "+81": "jp",
  "+82": "kr",
  "+86": "cn",
  "+91": "in",
  "+351": "pt",
  "+352": "lu",
  "+353": "ie",
  "+356": "mt",
  "+357": "cy",
  "+358": "fi",
  "+359": "bg",
  "+370": "lt",
  "+371": "lv",
  "+372": "ee",
  "+380": "ua",
  "+386": "si",
  "+420": "cz",
  "+421": "sk",
  "+852": "hk",
  "+965": "kw",
  "+966": "sa",
  "+971": "ae" ,
  "+972": "il",
  "+973": "bh",
  "+974": "qa"
}

export enum EventType {
  SHOW = "SHOW_COMPONENT",
  HIDE = "HIDE_COMPONENT"
}

export enum BooleanOperatorType {
  AND =   'AND',
  OR  =   'OR',
  NOT =   'NOT'
}

export enum NonBooleanOperatorType {
  IS              = "IS",
  IS_NOT          = "IS_NOT",
  IS_ANY_OF       = "IS_ANY_OF",
  IS_NONE_OF      = "IS_NONE_OF",
  IS_BLANK        = "IS_BLANK",
  IS_NOT_BLANK    = "IS_NOT_BLANK",
  HAS_ANY_OF      = "HAS_ANY_OF",
  HAS_ALL_OF      = "HAS_ALL_OF",
  IS_EXACTLY      = "IS_EXACTLY",
  HAS_NONE_OF     = "HAS_NONE_OF",
  IS_CHECKED      = "IS_CHECKED",
  IS_NOT_CHECKED  = "IS_NOT_CHECKED",
  IS_BEFORE       = "IS_BEFORE",
  IS_AFTER        = "IS_AFTER",
  IS_BETWEEN      = "IS_BETWEEN",
  IS_NOT_BETWEEN  = "IS_NOT_BETWEEN",
  IS_WITHIN       = "IS_WITHIN",
  IS_NOT_WITHIN   = "IS_NOT_WITHIN",
  CONTAINS        = "CONTAINS",
  DOES_NOT_CONTAIN= "DOES_NOT_CONTAIN",
  RUNTIME_IS      = "RUNTIME_IS"
}

export enum DateSelection {
  TODAY                   =   0,
  TOMORROW                =   1,
  YESTERDAY               =   -1,
  ONE_WEEK_AGO            =   -7,
  ONE_WEEK_FROM_NOW       =   7,
  ONE_MONTH_AGO           =   -31,
  ONE_MONTH_FROM_NOW      =   31,
  PAST_WEEK               =   -7,
  PAST_MONTH              =   -31,
  PAST_YEAR               =   -365,
  NEXT_WEEK               =   7,
  NEXT_MONTH              =   31,
  NEXT_YEAR               =   365
}

export type FactValue = Contact | string | CheckboxOption | CheckboxOption[] | boolean | string[] | Contact[] | LogicDateValue;

export type LogicDateValue = number | LogicExactDateValue | LogicInBetweenDateValue;

export interface NonBooleanPredicate {
  readonly type: NonBooleanOperatorType | BooleanOperatorType.NOT,
  readonly predicate?: NonBooleanPredicate | NonBooleanPredicate[]
  readonly key?: string,
  readonly value?: FactValue
}

export interface LogicExactDateValue {
  readonly type: string,
  readonly value: string,
  readonly epochValue: number
}

export interface LogicInBetweenDateValue {
  readonly start: LogicExactDateValue,
  readonly end: LogicExactDateValue
}

export interface BooleanPredicate {
  readonly type: BooleanOperatorType.AND | BooleanOperatorType.OR,
  readonly predicates: BooleanPredicate[] | NonBooleanPredicate[]
}
export interface LogicDefinition {
  readonly type: EventType,
  readonly predicate: BooleanPredicate
}

export const isLogicDefinitionArray = (arr: any, initialFormValues: FormValues): arr is LogicDefinition[] => {
  if (Array.isArray(arr) && arr.length) {
    return arr.some((val) => isLogicDefinition(val, initialFormValues));
  }
  return false;
}

export const isLogicDefinition = (value:any, initialFormValues: FormValues): value is LogicDefinition => {
  if ((value as LogicDefinition) && value.type !== '' && Object.values(EventType).includes(value.type) && isBooleanPredicate(value.predicate, initialFormValues)) {
    return true;
  }  
  return false;
}

const isBooleanPredicate = (value: any, initialFormValues: FormValues): value is BooleanPredicate => {
  if((value as BooleanPredicate) && value.type !== '' && (value.type === BooleanOperatorType.AND || value.type === BooleanOperatorType.OR)) {
    if((value as BooleanPredicate) && (isBooleanPredicateArray(value.predicates, initialFormValues)|| isNonBooleanPredicateArray(value.predicates, initialFormValues))) {
      return true;
    }
  }
  return false;
}

export const isNonBooleanPredicate = (value: any, initialFormValues: FormValues): value is NonBooleanPredicate => {
  if((value as NonBooleanPredicate) && value.type !== '' && (Object.values(NonBooleanOperatorType).includes(value.type) || value.type === BooleanOperatorType.NOT)) {
    if((value as NonBooleanPredicate) && ((typeof value.key === 'string' && value.key !== '' && value.key in initialFormValues
         && isFactValue(value.value, value.type))|| isNonBooleanPredicateArray(value.predicate, initialFormValues))) {
      return true;
    }
  }
  return false;
}

const isBooleanPredicateArray = (arr: any, initialFormValues: FormValues): arr is BooleanPredicate[] => {
  if(Array.isArray(arr) && arr.length) {
    return arr.every((val)=> isBooleanPredicate(val, initialFormValues));
  }
  return false; //This check is to pass empty boolean predicate array
}

const isNonBooleanPredicateArray = (arr: any, initialFormValues: FormValues): arr is NonBooleanPredicate[] => {
  if(Array.isArray(arr) && arr.length) {
    return arr.some((val)=> isNonBooleanPredicate(val, initialFormValues));
  }
  return false;
}

const isFactValue = (value: any, operator: string): value is FactValue => {  
  return isLogicCheckboxValue(value, operator) || isLogicTextValue(value, operator) ||
        isLogicSingleSelectValue(value, operator) || isLogicMultiSelectValue(value, operator) ||
        isLogicDateValue(value, operator);
}

const isLogicCheckboxValue = (val: any, operator: string): boolean => {
  return operator === NonBooleanOperatorType.IS_CHECKED;
}

const isLogicTextValue = (val: any, operator: string): boolean => {
  return operator === NonBooleanOperatorType.IS_BLANK ||
      (operator === NonBooleanOperatorType.CONTAINS && typeof val === 'string' && val.trim() !== '');
}

const isLogicSingleSelectValue = (val: any, operator: string): boolean => {
  return operator === NonBooleanOperatorType.IS_BLANK ||
      (operator === NonBooleanOperatorType.IS && ((typeof val === 'string' && val.trim() !== '') || isContact(val))) ||
      ((operator === NonBooleanOperatorType.IS_ANY_OF || operator === NonBooleanOperatorType.IS_NONE_OF) && (isStringArray(val) || isContactArray(val)));
}

const isLogicMultiSelectValue = (val: any, operator: string): boolean => {
  return operator === NonBooleanOperatorType.IS_BLANK ||      
      ((operator === NonBooleanOperatorType.IS_EXACTLY || operator === NonBooleanOperatorType.HAS_ALL_OF || operator === NonBooleanOperatorType.HAS_ANY_OF
        || operator === NonBooleanOperatorType.HAS_NONE_OF) 
      &&(isStringArray(val) || isContactArray(val)));
}

export const isLogicDateValue = (value: any, operator: string): boolean => {
  if((value as LogicExactDateValue) && value.type === 'DATE_TIME' && typeof value.value === 'string' && value.value.trim() !== '') {
    return true;
  }else if((value as LogicInBetweenDateValue) && operator === NonBooleanOperatorType.IS_BETWEEN) {
    return value.start && value.end && value.start.type === 'DATE_TIME' && value.end.type === 'DATE_TIME' && value.start.value.trim() !== '' && value.end.value.trim() !== '';
  }
  return typeof value === 'number';
}
