import React from 'react';
var Pause = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "10px", height: "12px", viewBox: "0 0 10 12", version: "1.1", role: "img" },
        React.createElement("title", null, "Decision Pause"),
        React.createElement("desc", null, "Pause VCR."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(0.000000, 1.000000)", fill: "#444444" },
                React.createElement("rect", { id: "Rectangle", x: "0", y: "0", width: "4", height: "10" }),
                React.createElement("rect", { id: "Rectangle", x: "6", y: "0", width: "4", height: "10" })))));
};
export default Pause;
