import React from 'react';
import { ESymbolKey } from "@smartsheet/forms-model";
import Full from "./Full";
import Half from "./Half";
import Quarter from "./Quarter";
import ThreeQuarters from "./ThreeQuarters";
import EmptyBall from "./EmptyBall";
import { IconOptionImg } from "../../iconUtil";
var HarveyBallIcon = function (_a) {
    var type = _a.type;
    var icon;
    switch (type) {
        case ESymbolKey.HARVEY_100:
        case "Full":
            icon = React.createElement(Full, null);
            break;
        case ESymbolKey.HARVEY_075:
        case "Three Quarter":
            icon = React.createElement(ThreeQuarters, null);
            break;
        case ESymbolKey.HARVEY_050:
        case "Half":
            icon = React.createElement(Half, null);
            break;
        case ESymbolKey.HARVEY_025:
        case "Quarter":
            icon = React.createElement(Quarter, null);
            break;
        case ESymbolKey.HARVEY_000:
        case "Empty":
            icon = React.createElement(EmptyBall, null);
            break;
        default:
            break;
    }
    return !!icon ? (React.createElement(IconOptionImg, null, icon)) : null;
};
export default HarveyBallIcon;
