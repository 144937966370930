import * as React from 'react';
import FormattedErrorMessage from '../../../../app/components/FormattedErrorMessage';
import {
  FormFooterContainer,
  FormFooterRow,
  FormFooterLink,
} from '../../../../app/components/FormFooter';
import ConfirmationContainer from '../StyledComponents/ConfirmationContainer';
import ErrorPanel from '../StyledComponents/ErrorPanel';
import FormContent from '../StyledComponents/FormContent';
import FormPage from '../StyledComponents/FormPage';
import styled from '../styled';

interface FullPageErrorProps {
  error: Error;
}

export const ErrorMessageContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const ErrorMessageTextContainer = styled.span`
  align-self: stretch;
  white-space: pre-wrap;
  font-weight: 300;
  font-size: 1.13em;
  color: #161616;
`;

const FullPageError: React.FC<FullPageErrorProps> = ({ error }) => {
  return (
    <FormPage>
      <ErrorPanel>
        <FormContent>
          <ConfirmationContainer>
            <ErrorMessageContainer>
              <ErrorMessageTextContainer>
                <FormattedErrorMessage error={error} />
              </ErrorMessageTextContainer>
            </ErrorMessageContainer>
          </ConfirmationContainer>
          <FormFooterContainer>
            <FormFooterRow>
              <FormFooterLink target="_blank" href="https://www.smartsheet.com">
                Smartsheet
              </FormFooterLink>
            </FormFooterRow>
          </FormFooterContainer>
        </FormContent>
      </ErrorPanel>
    </FormPage>
  );
};

export default FullPageError;
