import React from 'react';
var EmptyHeart = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "60px", height: "11px", viewBox: "0 0 60 11", version: "1.1", role: "img" },
        React.createElement("title", null, "Heart Empty"),
        React.createElement("desc", null, "Empty heart."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(25.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M10,3.5 C10,2.11928813 8.88071187,1 7.5,1 C6.11928813,1 5,2 5,3 C5,2 3.88071187,1 2.5,1 C1.11928813,1 0,2.11928813 0,3.5 C0,5.44077146 1.56941412,7.53848434 4.70824235,9.79313863 L4.70825045,9.79312734 C4.88259072,9.91835784 5.1174115,9.91833206 5.29172426,9.79306326 C8.43057475,7.53734767 10,5.43965992 10,3.5 Z", id: "Combined-Shape" })),
            React.createElement("g", { transform: "translate(13.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M10,3.5 C10,2.11928813 8.88071187,1 7.5,1 C6.11928813,1 5,2 5,3 C5,2 3.88071187,1 2.5,1 C1.11928813,1 0,2.11928813 0,3.5 C0,5.44077146 1.56941412,7.53848434 4.70824235,9.79313863 L4.70825045,9.79312734 C4.88259072,9.91835784 5.1174115,9.91833206 5.29172426,9.79306326 C8.43057475,7.53734767 10,5.43965992 10,3.5 Z", id: "Combined-Shape" })),
            React.createElement("g", { transform: "translate(1.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M10,3.5 C10,2.11928813 8.88071187,1 7.5,1 C6.11928813,1 5,2 5,3 C5,2 3.88071187,1 2.5,1 C1.11928813,1 0,2.11928813 0,3.5 C0,5.44077146 1.56941412,7.53848434 4.70824235,9.79313863 L4.70825045,9.79312734 C4.88259072,9.91835784 5.1174115,9.91833206 5.29172426,9.79306326 C8.43057475,7.53734767 10,5.43965992 10,3.5 Z", id: "Combined-Shape" })),
            React.createElement("g", { transform: "translate(37.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M10,3.5 C10,2.11928813 8.88071187,1 7.5,1 C6.11928813,1 5,2 5,3 C5,2 3.88071187,1 2.5,1 C1.11928813,1 0,2.11928813 0,3.5 C0,5.44077146 1.56941412,7.53848434 4.70824235,9.79313863 L4.70825045,9.79312734 C4.88259072,9.91835784 5.1174115,9.91833206 5.29172426,9.79306326 C8.43057475,7.53734767 10,5.43965992 10,3.5 Z", id: "Combined-Shape" })),
            React.createElement("g", { transform: "translate(49.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M10,3.5 C10,2.11928813 8.88071187,1 7.5,1 C6.11928813,1 5,2 5,3 C5,2 3.88071187,1 2.5,1 C1.11928813,1 0,2.11928813 0,3.5 C0,5.44077146 1.56941412,7.53848434 4.70824235,9.79313863 L4.70825045,9.79312734 C4.88259072,9.91835784 5.1174115,9.91833206 5.29172426,9.79306326 C8.43057475,7.53734767 10,5.43965992 10,3.5 Z", id: "Combined-Shape" })))));
};
export default EmptyHeart;
