import React from 'react';
import { ESymbolKey } from "@smartsheet/forms-model";
import Up from "./Up";
import Down from "./Down";
import Sideways from "./Sideways";
import AngleUp from "./AngleUp";
import AngleDown from "./AngleDown";
import { IconOptionImg } from "../../iconUtil";
var ArrowIcon = function (_a) {
    var type = _a.type;
    var icon;
    switch (type) {
        case ESymbolKey.ARROWS_3_UP:
        case ESymbolKey.ARROWS_4_UP:
        case ESymbolKey.ARROWS_5_UP:
        case "Up":
            icon = React.createElement(Up, null);
            break;
        case ESymbolKey.ARROWS_3_DOWN:
        case ESymbolKey.ARROWS_4_DOWN:
        case ESymbolKey.ARROWS_5_DOWN:
        case "Down":
            icon = React.createElement(Down, null);
            break;
        case ESymbolKey.ARROWS_3_SIDEWAYS:
        case ESymbolKey.ARROWS_5_SIDEWAYS:
        case "Sideways":
            icon = React.createElement(Sideways, null);
            break;
        case ESymbolKey.ARROWS_4_ANGLEUP:
        case ESymbolKey.ARROWS_5_ANGLEUP:
        case "Angle Up":
            icon = React.createElement(AngleUp, null);
            break;
        case ESymbolKey.ARROWS_4_ANGLEDOWN:
        case ESymbolKey.ARROWS_5_ANGLEDOWN:
        case "Angle Down":
            icon = React.createElement(AngleDown, null);
            break;
        default:
            break;
    }
    return !!icon ? (React.createElement(IconOptionImg, null, icon)) : null;
};
export default ArrowIcon;
