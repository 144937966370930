import React from 'react';
var ExpertsOnly = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "19px", height: "14px", viewBox: "0 0 19 14", version: "1.1", role: "img" },
        React.createElement("title", null, "Difficulty Experts Only"),
        React.createElement("desc", null, "Experts Only."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#444444" },
                React.createElement("path", { d: "M5.89315917,0.500384397 L10.7572844,6.69108922 C10.8997388,6.87239488 10.8997388,7.12760512 10.7572844,7.30891078 L5.89315917,13.4996156 C5.72255246,13.7167514 5.40822504,13.7544707 5.19108922,13.583864 C5.15974891,13.5592395 5.13146536,13.5309559 5.10684083,13.4996156 L0.24271561,7.30891078 C0.100261169,7.12760512 0.100261169,6.87239488 0.24271561,6.69108922 L5.10684083,0.500384397 C5.27744754,0.283248584 5.59177496,0.245529293 5.80891078,0.416136004 C5.84025109,0.440760535 5.86853464,0.469044086 5.89315917,0.500384397 Z", id: "Rectangle" }),
                React.createElement("path", { d: "M10.1358748,9.71838608 L12.2717496,7 L10.1358748,4.28161392 L13.1068408,0.500384397 C13.2774475,0.283248584 13.591775,0.245529293 13.8089108,0.416136004 C13.8402511,0.440760535 13.8685346,0.469044086 13.8931592,0.500384397 L18.7572844,6.69108922 C18.8997388,6.87239488 18.8997388,7.12760512 18.7572844,7.30891078 L13.8931592,13.4996156 C13.7225525,13.7167514 13.408225,13.7544707 13.1910892,13.583864 C13.1597489,13.5592395 13.1314654,13.5309559 13.1068408,13.4996156 L10.1358748,9.71838608 Z", id: "Combined-Shape" })))));
};
export default ExpertsOnly;
