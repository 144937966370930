import React from 'react';
var Play = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "13px", height: "15px", viewBox: "0 0 13 15", version: "1.1", role: "img" },
        React.createElement("title", null, "Decision Play"),
        React.createElement("desc", null, "Play VCR."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(1.000000, 1.000000)", fill: "#444444" },
                React.createElement("path", { d: "M0.751935513,0.438629049 L11.2596181,6.56811055 C11.498144,6.70725069 11.5787122,7.01340956 11.439572,7.25193551 C11.3961126,7.32643733 11.3341199,7.38843006 11.2596181,7.43188945 L0.751935513,13.561371 C0.513409556,13.7005111 0.207250691,13.619943 0.0681105496,13.381417 C0.0235041989,13.304949 -8.4641129e-16,13.2180088 -8.57252759e-16,13.1294815 L-8.57252759e-16,0.8705185 C-8.91070447e-16,0.594376125 0.223857625,0.3705185 0.5,0.3705185 C0.588527318,0.3705185 0.675467483,0.394022698 0.751935513,0.438629049 Z" })))));
};
export default Play;
