import { __assign, __makeTemplateObject } from "tslib";
import styled from "@emotion/styled/macro";
import { css, jsx } from "@emotion/core";
import { createPropsGetter } from "../utils";
import { generateAriaDescribedBy, sanitizeHtmlID } from "../utils/stringUtils";
import InputDescription from "../InputDescription";
import LabelContainer from "../LabelContainer";
import { FormattedMessage } from "react-intl";
import WarningImageIcon from "../WarningImageIcon";
import LogicTag from "../LogicTag";
import SourceLogicTag from "../SourceLogicTag";
import { IconRadioOption, IconOptionLabel } from "../utils/iconUtil";
import SymbolIcon from "../utils/Icons/SymbolIcon";
var defaultProps = {
    value: "",
    readonly: false,
    required: false,
    disabled: false,
    touched: false,
    horizontal: false,
    hidden: false,
    defaultValue: "",
    onChange: function (value, event) { }
};
var getProps = createPropsGetter(defaultProps);
var FieldSet = styled.fieldset(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0;\n    margin-top: 25px;\n    pointer-events: auto;\n    border: none;\n    padding-left: 0px;\n    & > input:not([type=\"checkbox\"]){\n        margin-top: 11px;\n    }\n"], ["\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0;\n    margin-top: 25px;\n    pointer-events: auto;\n    border: none;\n    padding-left: 0px;\n    & > input:not([type=\"checkbox\"]){\n        margin-top: 11px;\n    }\n"])));
var RadioGroup = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-top: 6px;\n    width: 100%;\n"], ["\n    margin-top: 6px;\n    width: 100%;\n"])));
var displayContentClass = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n"], ["\n"])));
var Legend = styled.legend(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 100%;\n    margin-left: -2px;\n    display: block;\n    font-weight: bold;\n    font-size: 0.88em;\n    color: #161616;\n    white-space: pre-wrap;\n    word-wrap: break-all;\n"], ["\n    width: 100%;\n    margin-left: -2px;\n    display: block;\n    font-weight: bold;\n    font-size: 0.88em;\n    color: #161616;\n    white-space: pre-wrap;\n    word-wrap: break-all;\n"])));
var RequiredFlagSpan = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    color: #d0011b;\n    margin-left: 4px;\n"], ["\n    color: #d0011b;\n    margin-left: 4px;\n"])));
var blockClass = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    display: block;\n"], ["\n    display: block;\n"])));
var RadioLabelHorizontal = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    position: relative;\n    display: inline-flex;\n    align-items: center;\n    font-size: 0.88em;\n    line-height: 23px;\n    margin-right: 20px;\n    vertical-align: middle;\n"], ["\n    position: relative;\n    display: inline-flex;\n    align-items: center;\n    font-size: 0.88em;\n    line-height: 23px;\n    margin-right: 20px;\n    vertical-align: middle;\n"])));
var RadioLabelVertical = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    position: relative;\n    display: flex;\n    align-items: center;\n    font-size: 0.88em;\n    line-height: 25px;\n    padding-top: 1px;\n\n    &:first-of-type {\n        margin-top: -6px;\n    }\n\n    &:last-child {\n        margin-bottom: -4px;\n    }\n"], ["\n    position: relative;\n    display: flex;\n    align-items: center;\n    font-size: 0.88em;\n    line-height: 25px;\n    padding-top: 1px;\n\n    &:first-of-type {\n        margin-top: -6px;\n    }\n\n    &:last-child {\n        margin-bottom: -4px;\n    }\n"])));
var CustomRadioButtonLabel = styled.label(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    margin-right: 7px;\n    cursor: pointer;\n    display: flex;\n"], ["\n    margin-right: 7px;\n    cursor: pointer;\n    display: flex;\n"])));
var Radio = styled.input(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    min-width: 16px;\n    max-width: 16px;\n    height: 16px;\n    margin-right: 6px;\n    cursor: pointer;\n    &:hover + ", " {\n        border: 1px solid #a0a0a0;\n        background-color: #f3f3f3;\n    }\n    &:checked:hover + ", " {\n        background-color: #005cbc;\n        border: 1px solid #005cbc;\n    }\n    &:checked + ", " {\n        border: 1px solid #4d97ff;\n        background-color: #4d97ff;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        &:after {\n            content: \"\";\n            width: 6px;\n            height: 6px;\n            background-color: #ffffff;\n            border-radius: 50%;\n        }\n    }\n"], ["\n    min-width: 16px;\n    max-width: 16px;\n    height: 16px;\n    margin-right: 6px;\n    cursor: pointer;\n    &:hover + ", " {\n        border: 1px solid #a0a0a0;\n        background-color: #f3f3f3;\n    }\n    &:checked:hover + ", " {\n        background-color: #005cbc;\n        border: 1px solid #005cbc;\n    }\n    &:checked + ", " {\n        border: 1px solid #4d97ff;\n        background-color: #4d97ff;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        &:after {\n            content: \"\";\n            width: 6px;\n            height: 6px;\n            background-color: #ffffff;\n            border-radius: 50%;\n        }\n    }\n"])), CustomRadioButtonLabel, CustomRadioButtonLabel, CustomRadioButtonLabel);
var RadioSpan = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    line-height: 1.6;\n    word-wrap: break-all;\n    margin-top: 2px;\n"], ["\n    line-height: 1.6;\n    word-wrap: break-all;\n    margin-top: 2px;\n"])));
var HiddenAria = styled.span(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    display: none;\n"], ["\n    display: none;\n"])));
var ErrorSpan = styled.span(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    max-width: 100%;\n    display: block;\n    font-size: 0.88em;\n    color: #d0011b;\n    margin-top: 16px;\n    margin-left: 0.3rem;\n    margin-bottom: 0.25rem;\n    display: flex;\n    align-items: center;\n"], ["\n    max-width: 100%;\n    display: block;\n    font-size: 0.88em;\n    color: #d0011b;\n    margin-top: 16px;\n    margin-left: 0.3rem;\n    margin-bottom: 0.25rem;\n    display: flex;\n    align-items: center;\n"])));
var WarningImageStyled = css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    margin-right: 0.3rem;\n"], ["\n    margin-right: 0.3rem;\n"])));
var LogicInputLabel = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    word-wrap: break-all;\n"], ["\n    word-wrap: break-all;\n"]))); //Div is needed around label when logic tag is present
var getOptionDisplayValue = function (option) {
    if (typeof option === "string") {
        return option;
    }
    else {
        var contact = option;
        if (contact.name != contact.email) {
            return contact.name && contact.email
                ? contact.name + " (" + contact.email + ")"
                : contact.email || contact.name;
        }
        return contact.email;
    }
};
var getOptionDisplayValueWithImg = function (option, symbolType, symbolKeys) {
    if (typeof option === "string" && symbolType && symbolKeys) {
        var symbol = symbolKeys[option];
        return (jsx(IconRadioOption, null,
            jsx(SymbolIcon, { symbolType: symbolType, symbolKey: symbol }),
            jsx(IconOptionLabel, null, option)));
    }
    return null;
};
// settting a type for the option causes react-select to break
var optionWithLabelOnly = function (option) { return option.label; };
//Switched away from using just compareValue to using both compareValue and checkDefault
// to get around a minor UI bug where identical options would both be selected
var compareValue = function (value, option) {
    if (typeof value === "string" && typeof option === "string") {
        return value === option;
    }
    else if (typeof value === "string") {
        return value === option.name;
    }
    else if (typeof option === "string") {
        if (value) {
            return value.email === option;
        }
        return false;
    }
    else {
        var contactValue = value;
        if (contactValue) {
            // Builder has email: null whereas render doesn't have email property
            if (contactValue.email === null) {
                contactValue.email = undefined;
            }
            return (contactValue.name === option.name &&
                contactValue.email === option.email);
        }
        return false;
    }
};
var checkDefault = function (defaultValue, value, option) {
    if (defaultValue && defaultValue != "") {
        if (value === defaultValue) {
            return compareValue(defaultValue, option);
        }
    }
    return undefined;
};
// This prevents the form from being submitted when a user hits Enter on
// unrestricted single select dropdowns displayed as radio buttons
var onKeyDownHandler = function (e) {
    if (e.key === "Enter") {
        e.preventDefault();
    }
};
var RadioInput = function (props) {
    var _a = getProps(props), name = _a.name, value = _a.value, label = _a.label, logic = _a.logic, hasSourceLogic = _a.hasSourceLogic, onSourceLogicTagClick = _a.onSourceLogicTagClick, description = _a.description, isRichTextEnabled = _a.isRichTextEnabled, richDescription = _a.richDescription, horizontal = _a.horizontal, options = _a.options, required = _a.required, disabled = _a.disabled, onChange = _a.onChange, error = _a.error, componentKey = _a.componentKey, defaultValue = _a.defaultValue, symbolType = _a.symbolType, symbolKeys = _a.symbolKeys, isHiddenByConditionalLogic = _a.isHiddenByConditionalLogic;
    var hasLogic = logic != null;
    var dataClientIdSuffix = label != null ? label : name;
    var errorElemId = "error_msg_" + sanitizeHtmlID(dataClientIdSuffix);
    var descriptionElemId = "description_" + sanitizeHtmlID(dataClientIdSuffix);
    var describedByIds = generateAriaDescribedBy(descriptionElemId, !!description, errorElemId, !!error);
    var labelValue;
    labelValue = (jsx(Legend, { "data-client-id": dataClientIdSuffix != null
            ? "label_" + dataClientIdSuffix
            : "label" },
        label,
        required && (jsx(RequiredFlagSpan, { "data-client-id": "required_indicator" }, "*"))));
    return (jsx(FieldSet, { "data-client-id": "container_" + dataClientIdSuffix, "data-client-type": "radio", "data-client-align": horizontal ? "horizontal" : "vertical", "data-client-symbol-set": symbolType, css: css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n                margin-bottom: ", ";\n                & > div { padding-bottom: 6px; }\n            "], ["\n                margin-bottom: ", ";\n                & > div { padding-bottom: 6px; }\n            "])), horizontal ? "-11px" : "0"), id: componentKey },
        label && !hasLogic && !hasSourceLogic && labelValue,
        label && (hasLogic || hasSourceLogic) && (jsx(LabelContainer, { "data-client-id": dataClientIdSuffix != null
                ? "label_" + dataClientIdSuffix
                : "label" },
            jsx(LogicInputLabel, null, labelValue),
            hasSourceLogic && (jsx(SourceLogicTag, { onClickHandler: onSourceLogicTagClick })),
            logic && jsx(LogicTag, { logic: logic }))),
        (isRichTextEnabled && richDescription) && jsx("div", { className: "rich-text-field-desc", css: {
                width: '-webkit-fill-available',
                '& p': {
                    marginBlock: '2px',
                    fontSize: '14.12px',
                },
                '& ul': {
                    listStylePosition: 'inside',
                    fontSize: '14.12px',
                    marginBlock: '2px'
                },
                '& ol': {
                    listStylePosition: 'inside',
                    fontSize: '14.12px',
                    marginBlock: '2px'
                },
            }, id: descriptionElemId, dangerouslySetInnerHTML: { __html: richDescription } }) ||
            description && (jsx(InputDescription, { id: descriptionElemId }, description)),
        jsx(RadioGroup, { css: horizontal ? displayContentClass : blockClass }, options.map(function (opt, index) {
            var _a;
            var RadioContainer = horizontal
                ? RadioLabelHorizontal
                : RadioLabelVertical;
            var displayValue = getOptionDisplayValue(opt);
            var displayValueWithImg = getOptionDisplayValueWithImg(opt, symbolType, symbolKeys);
            var renderedValue = displayValueWithImg || displayValue;
            return (jsx(RadioContainer, { key: index },
                jsx(CustomRadioButtonLabel, null,
                    jsx(Radio, __assign({ "aria-label": displayValue }, (describedByIds && (_a = {}, _a['aria-describedby'] = describedByIds, _a)), { tabIndex: isHiddenByConditionalLogic ? -1 : 0, "data-client-value": displayValue, "data-client-id": "radio_" + dataClientIdSuffix, type: "radio", checked: value
                            ? checkDefault(defaultValue, value, opt)
                            : false, title: displayValue, name: name, disabled: disabled, onChange: function (event) {
                            event.target.checked &&
                                onChange(opt, event);
                        }, 
                        // This prevents the form from being submitted when a user hits Enter on unrestricted
                        // single select dropdowns displayed as radio buttons
                        onKeyDown: onKeyDownHandler })),
                    jsx(RadioSpan, null, renderedValue))));
        })),
        error && (jsx(ErrorSpan, { "data-client-id": "component_error_msg" },
            jsx(WarningImageIcon, { customCss: WarningImageStyled }),
            jsx("span", { id: errorElemId, role: "alert" },
                jsx(FormattedMessage, { id: "messages.msg_fieldRequired" }))))));
};
export default RadioInput;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
