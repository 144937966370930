import { __makeTemplateObject } from "tslib";
import * as React from "react";
import styled from "@emotion/styled/macro";
import MarkDownDescription from "../MarkDownDescription";
var DescriptionText = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: #161616;\n    display: block;\n    font-size: 0.88em;\n    font-weight: 400;\n    white-space: pre-wrap;\n    width: 100%;\n    margin: 0;\n    padding-bottom: 4px;\n    margin-block: 2px;\n"], ["\n    color: #161616;\n    display: block;\n    font-size: 0.88em;\n    font-weight: 400;\n    white-space: pre-wrap;\n    width: 100%;\n    margin: 0;\n    padding-bottom: 4px;\n    margin-block: 2px;\n"])));
var InputLabel = function (_a) {
    var children = _a.children, id = _a.id;
    return (React.createElement(DescriptionText, { id: id }, typeof children === "string"
        ? MarkDownDescription(children)
        : children));
};
export default InputLabel;
var templateObject_1;
