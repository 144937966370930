import { __makeTemplateObject } from "tslib";
/*
This component used only for symbol right caption label. Hard to align nested label with bg image.
Making two as siblings and having bg img as an input really creats a lot of issues across browsers,
like bg img as readonly input will have the text cursor when on focus.
*/
import * as React from 'react';
import styled from '@emotion/styled/macro';
import { createPropsGetter } from '../utils';
var defaultProps = {
    required: false
};
var getProps = createPropsGetter(defaultProps);
var Label = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nfont-size: 0.88em;\nfont-weight: bold;\ndisplay: block;\nmargin-left: 7px;\nmargin-top:10px;\nword-wrap: break-word;\nflex: 1;\n"], ["\nfont-size: 0.88em;\nfont-weight: bold;\ndisplay: block;\nmargin-left: 7px;\nmargin-top:10px;\nword-wrap: break-word;\nflex: 1;\n"])));
var RequiredFlagSpan = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: #d0011b;\n  margin-left: 4px;\n"], ["\n  color: #d0011b;\n  margin-left: 4px;\n"])));
var SymbolCheckboxLabelRight = function (props) {
    var children = getProps(props).children;
    return (React.createElement(Label, { htmlFor: props.forElement },
        children,
        props.required && (React.createElement(RequiredFlagSpan, null, "*"))));
};
export default SymbolCheckboxLabelRight;
var templateObject_1, templateObject_2;
