import React from 'react';
var Hold = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14px", height: "14px", viewBox: "0 0 14 14", version: "1.1", role: "img" },
        React.createElement("title", null, "Decision Hold"),
        React.createElement("desc", null, "Hold."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", null,
                React.createElement("g", { fill: "#F6A623" },
                    React.createElement("circle", { id: "Oval", cx: "7", cy: "7", r: "7" })),
                React.createElement("path", { d: "M6,3 C6,2.44771525 6.44386482,2 7,2 L7,2 C7.55228475,2 8,2.44386482 8,3 L8,4 L7.79702309,7.2476306 C7.77105295,7.66315274 7.41710138,8 7,8 L7,8 C6.58578644,8 6.22868514,7.65896225 6.20297691,7.2476306 L6,4 L6,3 Z", id: "Rectangle", fill: "#FFFFFF" }),
                React.createElement("rect", { id: "Rectangle", fill: "#FFFFFF", x: "6", y: "9", width: "2", height: "2", rx: "1" })))));
};
export default Hold;
