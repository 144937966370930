import React from 'react';
var No = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14px", height: "14px", viewBox: "0 0 14 14", version: "1.1", role: "img" },
        React.createElement("title", null, "Decision No"),
        React.createElement("desc", null, "No."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", null,
                React.createElement("g", null,
                    React.createElement("g", { fill: "#D0011B" },
                        React.createElement("circle", { id: "Oval", cx: "7", cy: "7", r: "7" })),
                    React.createElement("path", { d: "M3.43933983,9.85355339 L4.14644661,10.5606602 C4.34170876,10.7559223 4.65829124,10.7559223 4.85355339,10.5606602 L7,8.41421356 L9.14644661,10.5606602 C9.34170876,10.7559223 9.65829124,10.7559223 9.85355339,10.5606602 L10.5606602,9.85355339 C10.7559223,9.65829124 10.7559223,9.34170876 10.5606602,9.14644661 L8.41421356,7 L10.5606602,4.85355339 C10.7559223,4.65829124 10.7559223,4.34170876 10.5606602,4.14644661 L9.85355339,3.43933983 C9.65829124,3.24407768 9.34170876,3.24407768 9.14644661,3.43933983 L7,5.58578644 L4.85355339,3.43933983 C4.65829124,3.24407768 4.34170876,3.24407768 4.14644661,3.43933983 L3.43933983,4.14644661 C3.24407768,4.34170876 3.24407768,4.65829124 3.43933983,4.85355339 L5.58578644,7 L3.43933983,9.14644661 C3.24407768,9.34170876 3.24407768,9.65829124 3.43933983,9.85355339 Z", id: "Combined-Shape", fill: "#FFFFFF" }))))));
};
export default No;
