/**
 * Application constants
 */
var AppConstants = /** @class */ (function () {
    function AppConstants() {
    }
    AppConstants.DEFAULT_LOCALE = "en-US";
    AppConstants.LONG_DATE_FORMAT = "dddd, LL";
    AppConstants.EMBED_FRAME_WIDTH = "900";
    AppConstants.EMBED_FRAME_HEIGHT = "600";
    AppConstants.DEBOUNCE_DELAY_MS = 250;
    /**
     * Created a specific debounce delay for contact list because
     * it is being in Send options modal and with higher delay
     * it is not registering some keystrokes.
     */
    AppConstants.CONTACT_LIST_DEBOUNCE_DELAY_MS = 50;
    AppConstants.SEND_OPTION_CONTACT_MENU_MAX_COUNT = 50;
    AppConstants.SPECIAL_CHARACTER_REGEX_CHECK = /[!@#$%^&*(),.?":{}|<> ]/g; // regex to check special character and spaces
    AppConstants.MAX_INPUT_CHAR_LIMIT = 4000;
    AppConstants.FORM_TITLE_CHAR_LIMIT = 100;
    AppConstants.LOGIC_VALUE_CHAR_LIMIT = 100;
    AppConstants.FORM_REDIRECT_URL_CHAR_LIMIT = 2048;
    AppConstants.BRANDING_URL = 'https://help.smartsheet.com/articles/2478916';
    AppConstants.CAPTCHA_URL = 'https://help.smartsheet.com/articles/2481836';
    AppConstants.HIDDEN_FIELD_URL = 'https://help.smartsheet.com/articles/522221';
    AppConstants.LOGIC_URL = 'https://help.smartsheet.com/articles/2481701';
    AppConstants.CUSTOMIZE_APPEARANCE_URL = "https://help.smartsheet.com/articles/2482491-customize-form-appearance";
    AppConstants.SECURITY_CONTROLS_ENTERPRISE_ONLY = "https://help.smartsheet.com/articles/855284";
    /**
     * To ensure this is in sync with app-core, this value is borrowed from `ContactManager.js`
     * See link at:
     * https://git.lab.smartsheet.com/team-core-dev/app-core/-/blob/6b6d6b223bbbcdacf27d9012f5784cafdbde4ea8/dev2/web/javascript/legacyApp/src/app1/contactManager.js#L68
     * */
    AppConstants.VALID_EMAIL_REGEX = /^[a-z0-9!#\$%&'\*\+\/=\?\^_\{\|}~-]+(\.[a-z0-9,!#\$%&'\*\+\/=\?\^_\{\|}~-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*\.([a-z]{2,})$/i;
    return AppConstants;
}());
export { AppConstants };
