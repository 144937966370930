import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { push as pushRoute } from 'react-router-redux';
import {
  OpenFormError,
  NetworkError,
  HttpResponseError,
  HttpResponseErrorType,
} from '../../app/errors';
import axios from 'axios';
import { submitMetrics } from "../../app/utils";
import { parse as parseQueryString } from 'query-string';
import { action } from 'typesafe-actions'
import { FormDefinition, FormSubmission, FormConfirmation, FormSubmissionResult, FormConfirmationType, FormComponentDefinitionType, FormValues } from '../../app/types';
import { OpenFormRequestObject } from '../../app/containers/SubmitFormApp';

const API_ENDPOINT = process.env.FORM_API_ENDPOINT || window.formEndpoint ||  "http://localhost:17610"
const APP_VERSION = process.env.FORM_APP_VERSION || window.formAppVersion || "0.1.0-UNKNOWN"

const TOKEN_REGEX = /window\.formToken = "(\w+)";/
const FORM_DEFINITION_REGEX = /window\.formDefinition = "([\w!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+)";/

// reads a cookie by name.  This is being done to pass along that cookie as a header because we aren't able to natively retrieve cookies due to mismatches with the subdomain names
function readCookie(name:string) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');
  for(let i=0; i<ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0)===' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export enum FormStatusType {
    SUCCESS = "OPEN_FORM_SUCCESS",
    FAILURE = "SUBMIT_FORM_FAILURE",
    EMPTY = "",
}

export enum SubmitFormActionType {
  OPEN_FORM_REQUEST = 'OPEN_FORM_REQUEST',
  OPEN_FORM_SUCCESS = 'OPEN_FORM_SUCCESS',
  OPEN_FORM_FAILURE = 'OPEN_FORM_FAILURE',

  SUBMIT_FORM_REQUEST = 'SUBMIT_FORM_REQUEST',
  SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCESS',
  SUBMIT_FORM_FAILURE = 'SUBMIT_FORM_FAILURE'
}

export interface OpenFormSuccessObject {
  form: FormDefinition
  confirmation: FormConfirmation
  formToken: string
  initialValues?: FormValues
}

export interface SubmitFormSuccessObject {
  submissionResult: FormSubmissionResult
  rawValues: FormValues
  updatedFormDefinition: FormDefinition
}

export class SubmitFormActions {
  static openFormRequest = (requestObject:OpenFormRequestObject) => action(SubmitFormActionType.OPEN_FORM_REQUEST,requestObject)
  static openFormSuccess = (successObject: OpenFormSuccessObject) => action(SubmitFormActionType.OPEN_FORM_SUCCESS, successObject)
  static openFormFailure = (error: Error) => action(SubmitFormActionType.OPEN_FORM_FAILURE, error)

  static submitFormRequest = (submission: FormSubmission) => action(SubmitFormActionType.SUBMIT_FORM_REQUEST, submission)
  static submitFormSuccess = (successObject: SubmitFormSuccessObject) => action(SubmitFormActionType.SUBMIT_FORM_SUCCESS, successObject);
  static submitFormFailure = (error: Error) => action(SubmitFormActionType.SUBMIT_FORM_FAILURE, error);
}

const callSubmitForm = (formKey: string, data: FormData, submissionToken: string, formDefinition: string) => axios.request<FormSubmissionResult>({
    baseURL: API_ENDPOINT,
    url: `/api/submit/${formKey}`,
    method: "POST",
    data: data,
    headers: {
        "x-smar-submission-token": submissionToken,
        "x-smar-forms-version": APP_VERSION,
        "x-smar-is-user": readCookie("user_id") ? "true" : "false"
    }
})

const callTokenRefresh = (formUrl: string) => axios.request<string>({
  baseURL: formUrl,
  method: "GET",
  responseType: "text",
  transformResponse: (response: string) => {
    const body: string = response

    const tokenRegexResult = TOKEN_REGEX.exec(body)
    const formDefinitionRegexResult = FORM_DEFINITION_REGEX.exec(body)

    if (!tokenRegexResult || tokenRegexResult.length !== 2 || !formDefinitionRegexResult || formDefinitionRegexResult.length !== 2) {
      throw new Error('Failed to refresh submission token');
    }

    return {submissionToken: tokenRegexResult[1], formDefinition: formDefinitionRegexResult[1]}
  }
})

function* handleOpen(
  action: ReturnType<typeof SubmitFormActions.openFormRequest>
) {
  try {
    // HACK: The `window.formDefinition` object includes confirmation info.
    // We want to conceptualize that as a distinct thing so we'll split it
    // out from the rest of the definition and from now on treat it as a
    // separate type. A cleaner solution would be to modify the back end to
    // send back a `confirmation` object separately (or wrapped in a parent
    // object) rather than nested in the form definition.
    const {
      confirmation,
      ...form
    } = JSON.parse(atob(window.formDefinition)) as FormDefinition & {
      confirmation: FormConfirmation;
    };
    const formToken = window.formToken;

    if (!formToken) {
      throw new Error('Expected `window.formToken` to be defined');
    }

    yield put(
      SubmitFormActions.openFormSuccess({ form, confirmation, formToken, initialValues: {} })
    );
  } catch (error) {
    window.console.error(error.message);

    yield put(
      SubmitFormActions.openFormFailure(new OpenFormError(error.message))
    );
  }
}

const getObjectEntryString = (value: any): String => {
  if(value && value.email) {
    return value.email
  }
  if(value && value.name) {
    return value.name;
  }
  return value.toString()
}

const getStringValues = (value: any): String[] => {
  if(typeof(value) === "string") {
    // handles string, checkbox, and date cases
    return [value]
  } else if(typeof(value) === "object") {
    if(Array.isArray(value)) {
      // handles multipicklist and multicontact cases
      let objectEntriesArray: String[] = []
      value.forEach(element => {
        const objectEntryString: String = getObjectEntryString(element)
        objectEntriesArray.push(objectEntryString)
      })
      return objectEntriesArray
    } else{
     // handles ECA email cases
     if(value.emailRequested && value.email) {
       return [value.email]
     } else if(value.emailRequested === false) {
        return [""]
     }
     // handles contact list and picklist cases
     return [getObjectEntryString(value)]
    }
  }
  return [value.toString()]
}

const getQueryParamsString = (formComponents: FormComponentDefinitionType[] , submissionValues: any): string => {
  let queryParamsString = "?"
  // Build a map of each component containing its field name and field key
  let componentLabelMap = new Map()
  formComponents.forEach((component:any) => {
    if(component.label) {
      componentLabelMap.set(component.key, component.label)
    }
  });
  // for each submission value, get its string values and pass it into the query string
  submissionValues.forEach((submissionValueArray:any) => {
    const submissionKey = submissionValueArray[0]
    const componentLabel = (submissionKey === "EMAIL_RECEIPT") ? "ECA" : componentLabelMap.get(submissionKey)
    if(componentLabel) {
      const componentValues = getStringValues(submissionValueArray[1])
      componentValues.forEach(componentValue => {
        if(componentValue !== "") {
          queryParamsString = queryParamsString + encodeURIComponent(componentLabel) + "=" + encodeURIComponent(componentValue.toString()) + "&"
        }
      });
    }
  })
  queryParamsString = queryParamsString + "_refreshed=true"
  return queryParamsString
}

const captureClickStream  = (formKey: string, hideFooterOnForm: boolean) => {
  const {cookie} = document;
  const isLoggedIn = cookie.indexOf('S3S_F=') > -1;
  // Fire if trial button shown
  if (!isLoggedIn && !hideFooterOnForm) {
    submitMetrics({
      id: formKey,
      event: "Page_Load",
      element: "Render_Footer",
      value: "Trial Button Displayed"
    });
  }
  // Customer hasn't disabled footer in Settings
  if (!hideFooterOnForm) {
    submitMetrics({
      id: formKey,
      event: "Page_Load",
      element: "Render_Footer",
      value: "Footer Displayed"
    });
  }
  // Fire as confirmation page displayed
  submitMetrics({
    id: formKey,
    event: "Page_Load",
    element: "Render_Footer",
    value: "Confirmation Page Displayed"
  });
}

function* handleSubmit(
  action: ReturnType<typeof SubmitFormActions.submitFormRequest>
) {
  try {
    const submission = action.payload;
    const dataJson = JSON.stringify(submission.data);
    const dataBlob = new Blob([dataJson], {
      type: 'application/json',
    });

    const formData = new FormData();
    formData.append('data', dataBlob);

    for (const [key, value] of Object.entries(submission.files)) {
      formData.append(key, value);
    }

    let submissionToken
    let updatedFormDefinition

    // The submission token will now not exist only on hitting the back button
    // for forms with MESSAGE and REDIRECT confirmation.
    if (submission.formToken) {
      submissionToken = submission.formToken
      updatedFormDefinition = submission.form
    } else {
      //ts-ignore
      const refreshResponse: { data: { [k: string]: any } } = yield callTokenRefresh(window.location.href)
      submissionToken = refreshResponse.data.submissionToken
      updatedFormDefinition = JSON.parse(atob(refreshResponse.data.formDefinition ))
    }

    let response: { data: { [k: string]: any } } = {data: {}};

    try {
      response = yield callSubmitForm(
        action.payload.formKey,
        formData,
        submissionToken,
        updatedFormDefinition
      );
      if (action.payload && action.payload.form) {
        //Quick check if form has validation
        if ((action.payload.form as any).featureLevel && (action.payload.form as any).featureLevel === 3) {
          submitMetrics({
            id: action.payload.formKey,
            event: "Form_Render_Validation",
            element: "Submit_Form",
            value: ""
          });
        }
      }
      // Capture only on confirmation message
      if (response.data && response.data.confirmation && response.data.confirmation.type === FormConfirmationType.MESSAGE) {
        captureClickStream(action.payload.formKey, updatedFormDefinition.hideFooterOnForm);
      } else if (response.data && response.data.confirmation && response.data.confirmation.type === FormConfirmationType.REDIRECT) {
        submitMetrics({
          id: action.payload.formKey,
          event: "Page_Redirect",
          element: "Render_Footer",
          value: ""
        });
      } else if (response.data && response.data.confirmation && response.data.confirmation.type === FormConfirmationType.RELOAD){
        submitMetrics({
          id: action.payload.formKey,
          event: "Page_Reload",
          element: "Render_Footer",
          value: ""
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const { message, errorType, timeStamp, messageParams } = error.response.data;
        if (errorType === HttpResponseErrorType.INVALID_REQUEST_CLIENT_VERSION_UPDATE_REQUIRED || errorType === HttpResponseErrorType.INVALID_SUBMISSION_TOKEN) {
          //If there is a version or submission token related error returned, reload the form with the user entered data saved and passed back in as query params
          if(action.payload.form) {
            // Pulls the part of the form payload that holds the "components data"
            let formComponents = action.payload.form.components
            const submissionValues = Object.entries(action.payload.rawValues)
            window.location.assign("/b/form/" + action.payload.formKey + getQueryParamsString(formComponents, submissionValues))
          } else {
            //this case should not be possible but handling for code completeness
            window.location.assign("/b/form/" + action.payload.formKey)
          }
        } else {
          throw new HttpResponseError(message, errorType, timeStamp, messageParams);
        }
      } else {
        throw new NetworkError(error.message);
      }
    }

    const { confirmation } = response.data;

    // If the form confirmation is RELOAD then we want to refresh the form submission
    // and get the updated form definition and the submission token.
    let refreshedFormTokenForRelaoadableForm = undefined;
    if (confirmation.type === FormConfirmationType.RELOAD) {
      const refreshResponse: {[k: string]: any} = yield callTokenRefresh(window.location.href)
      refreshedFormTokenForRelaoadableForm = refreshResponse.data.submissionToken
      updatedFormDefinition = JSON.parse(atob(refreshResponse.data.formDefinition))
    }

    yield put(
      SubmitFormActions.openFormSuccess({
        initialValues: submission.rawValues,
        confirmation: response.data.confirmation,
        form: updatedFormDefinition,
        formToken: refreshedFormTokenForRelaoadableForm
      })
    );

    const shouldLoadConfirmationRoute =
      (confirmation.type === FormConfirmationType.MESSAGE ||
        confirmation.type === FormConfirmationType.RELOAD) &&
      parseQueryString(window.location.search).confirm !== 'true';

    const shouldRedirectToCustomRoute =
      confirmation.type === FormConfirmationType.REDIRECT;

    if (shouldLoadConfirmationRoute) {
      const confirmationUrl = `/b/form/${submission.formKey}?confirm=true`;
      yield put(pushRoute(confirmationUrl));
    }

    if (shouldRedirectToCustomRoute && confirmation.redirectUrl) {
      yield call(() => window.location.assign(confirmation.redirectUrl));
    }
  } catch (error) {
    window.console.error(error);
    yield put(SubmitFormActions.submitFormFailure(error));
  }
}

function* watchOpenForm(){
    yield takeEvery(SubmitFormActionType.OPEN_FORM_REQUEST, handleOpen)
}

function* watchFormSubmit(){
    yield takeEvery(SubmitFormActionType.SUBMIT_FORM_REQUEST, handleSubmit)
}

function* submitFormSaga() {
    yield all([fork(watchOpenForm), fork(watchFormSubmit)])
}

export default submitFormSaga
