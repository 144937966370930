import { __makeTemplateObject } from "tslib";
import styled from '@emotion/styled/macro';
import * as React from 'react';
import LabelContainer from '../LabelContainer';
import LogicTag from '../LogicTag';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n"], ["\n"])));
var Title = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: #161616;\n  font-size: 1.4em;\n  font-weight: 300;\n  margin: 0;\n  margin-top: 3px;\n  white-space: pre-wrap;\n"], ["\n  color: #161616;\n  font-size: 1.4em;\n  font-weight: 300;\n  margin: 0;\n  margin-top: 3px;\n  white-space: pre-wrap;\n"])));
var DividerLine = styled.hr(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-color: #e4e4e4;\n  border-style: solid;\n  border-width: 0.5px;\n  margin: 0;\n  margin-top: 16px;\n  margin-bottom: -7px;\n"], ["\n  border-color: #e4e4e4;\n  border-style: solid;\n  border-width: 0.5px;\n  margin: 0;\n  margin-top: 16px;\n  margin-bottom: -7px;\n"])));
// TODO: Add unique ID somehow for DividerLine - data-client-id={"divider_" + uniqueID}
var Divider = function (_a) {
    var logic = _a.logic, isBuilder = _a.isBuilder, title = _a.title;
    var dividerDataClientId = title || "divider";
    var lineDataClientId = title || "divider_line";
    if (isBuilder) { // On builder show logic icon for logic building purposes, not on render
        return (React.createElement(Container, { "data-client-id": "container_" + dividerDataClientId, "data-client-type": "divider" },
            React.createElement(LabelContainer, null, logic && React.createElement(LogicTag, { logic: logic })),
            React.createElement(DividerLine, { "data-client-id": lineDataClientId })));
    }
    return (React.createElement(Container, { "data-client-id": "container_" + dividerDataClientId, "data-client-type": "divider" },
        React.createElement(DividerLine, { "data-client-id": lineDataClientId })));
};
export default Divider;
var templateObject_1, templateObject_2, templateObject_3;
