import { Store, createStore, applyMiddleware, Action, AnyAction, Dispatch } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware from 'redux-saga'
import { History } from 'history'
import { logger } from '../../app/middleware'
import { RootState } from '../../app/store/state'
import { rootReducer } from '../../app/store/reducers'
import rootSaga from '../../app/store/sagas'

export function configureStore(history: History, initialState?: RootState): Store<RootState> {
  const sagaMiddleware = createSagaMiddleware()
  let middleware = applyMiddleware(logger, routerMiddleware(history), sagaMiddleware);

  if (process.env.NODE_ENV !== 'production') {
    middleware = composeWithDevTools(middleware);
  }

  const store = createStore(rootReducer as any, initialState as any, middleware) as Store<RootState>;

  if (module.hot) {
    module.hot.accept('../../app/store/reducers', () => {
      const nextReducer = require('../../app/store/reducers');
      store.replaceReducer(nextReducer);
    });
  }

  sagaMiddleware.run(rootSaga)

  return store;
}

export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>
}
