import { jsx } from '@emotion/core';
var MsPowerpointIcon = function () {
    return (jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", role: "img" },
        jsx("title", null, "MS Powerpoint Attachment Icon"),
        jsx("desc", null, "Ms Powerpoint Icon"),
        jsx("g", { fill: "none", fillRule: "nonzero" },
            jsx("path", { fill: "#C43E1C", d: [
                    'M1.333 0h13.334C15.403 0 16 .597 16 1.333v13.334c0 .736-.597 1.33',
                    '3-1.333 1.333H1.333A1.333 1.333 0 0 1 0 14.667V1.333C0 .597.597 0',
                    '1.333 0z',
                ].join('') }),
            jsx("path", { fill: "#F9F7F7", d: [
                    'M8.454 4.008a2.917 2.917 0 0 1 2.035.643c.488.47.749 1.158.707 1.',
                    '863.008.452-.114.895-.35 1.269-.24.413-.584.742-.993.948-.467.235',
                    '-.977.35-1.492.339H6.948V12H5.5V4.008h2.954zM6.948 7.75h1.247c.39',
                    '5.03.787-.096 1.102-.356a.91.91 0 0 0 .373-.82c0-.883-.476-1.325-',
                    '1.428-1.325H6.948v2.5z',
                ].join('') }))));
};
export default MsPowerpointIcon;
