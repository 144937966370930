import { __assign } from "tslib";
import React from 'react';
import NumberFormat from 'react-number-format';
import PhoneInput from 'react-phone-input-2';
import { Util } from './TextInputValidationUtil';
export var PhoneValidation = function (_a) {
    var _b;
    var name = _a.name, defaultValue = _a.defaultValue, phoneCountry = _a.phoneCountry, displayMask = _a.displayMask, phoneNumber = _a.phoneNumber, describedByIds = _a.describedByIds, ariaInvalid = _a.ariaInvalid, tabIndex = _a.tabIndex, id = _a.id, dataClientId = _a.dataClientId, onChange = _a.onChange, onValueChange = _a.onValueChange, onBlur = _a.onBlur;
    var countryList = Util.countryList, ValidationTypes = Util.ValidationTypes;
    return (React.createElement("div", { style: { display: 'flex' } },
        React.createElement(PhoneInput, { inputStyle: { display: 'none' }, containerStyle: { height: '35px', width: '40px' }, onlyCountries: countryList, inputProps: {
                name: name,
                "aria-hidden": true,
                "aria-label": "phone picker"
            }, country: phoneCountry, onChange: onChange }),
        React.createElement(NumberFormat, __assign({ format: displayMask, value: phoneNumber, mask: "_", "aria-invalid": ariaInvalid }, (describedByIds && (_b = {}, _b['aria-describedby'] = describedByIds, _b)), { tabIndex: tabIndex, id: id, "data-client-id": dataClientId, "data-client-type": ValidationTypes.PHONE, allowEmptyFormatting: true, isNumericString: true, defaultValue: defaultValue, onBlur: onBlur, onValueChange: onValueChange }))));
};
