export enum ESymbolType  {
    TEXTNUMBER = "TEXTNUMBER",
    CONTACT = "CONTACT",
    DATE = "DATE",
    PICKLIST = "PICKLIST",
    CHECKBOX = "CHECKBOX",
    PRIORITY = "PRIORITY",
    PRIORITY_HML = "PRIORITY_HML",
    DECISION_SYMBOLS = "DECISION_SYMBOLS",
    DECISION_SHAPES = "DECISION_SHAPES",
    VCR = "VCR",
    RYG = "RYG",
    RYGB = "RYGB",
    RYGG = "RYGG",
    WEATHER = "WEATHER",
    HARVEY_BALLS = "HARVEY_BALLS",
    PROGRESS = "PROGRESS",
    ARROWS_3_WAY = "ARROWS_3_WAY",
    ARROWS_4_WAY = "ARROWS_4_WAY",
    ARROWS_5_WAY = "ARROWS_5_WAY",
    DIRECTIONS_3_WAY = "DIRECTIONS_3_WAY",
    DIRECTIONS_4_WAY = "DIRECTIONS_4_WAY",
    SKI = "SKI",
    SIGNAL = "SIGNAL",
    STAR_RATING = "STAR_RATING",
    HEARTS = "HEARTS",
    MONEY = "MONEY",
    EFFORT = "EFFORT",
    PAIN = "PAIN",
    STAR = "STAR",
    FLAG = "FLAG",
    SYS_INSERTBY = "SYS_INSERTBY",
    SYS_INSERTDATE = "SYS_INSERTDATE",
    SYS_MODIFYBY = "SYS_MODIFYBY",
    SYS_MODIFYDATE = "SYS_MODIFYDATE",
    SYS_UNIQUEID = "SYS_UNIQUEID",
    SYS_SHEETNAME = "SYS_SHEETNAME",
    ABSTRACTDATETIME = "ABSTRACTDATETIME",
    DURATION = "DURATION",
    PREDECESSOR = "PREDECESSOR",
    MULTICONTACT = "MULTICONTACT",
}

export enum ESymbolKey {
    ARROWS_3_DOWN = "arrows3Down",
    ARROWS_3_SIDEWAYS = "arrows3Sideways",
    ARROWS_3_UP = "arrows3Up",
  
    ARROWS_4_DOWN = "arrows4Down",
    ARROWS_4_ANGLEDOWN = "arrows4AngleDown",
    ARROWS_4_ANGLEUP = "arrows4AngleUp",
    ARROWS_4_UP = "arrows4Up",
  
    ARROWS_5_DOWN = "arrows5Down",
    ARROWS_5_ANGLEDOWN = "arrows5AngleDown",
    ARROWS_5_SIDEWAYS = "arrows5Sideways",
    ARROWS_5_ANGLEUP = "arrows5AngleUp",
    ARROWS_5_UP = "arrows5Up",
    
    CHECKBOX_0 = "checkBox0",
    CHECKBOX_1 = "checkBox1",
    
    DECISION_SHAPES_YES = "decisionshapesYes",
    DECISION_SHAPES_HOLD = "decisionshapesHold",
    DECISION_SHAPES_NO = "decisionshapesNo",
    
    DECISION_SYMBOLS_YES = "decisionsymbolsYes",
    DECISION_SYMBOLS_HOLD = "decisionsymbolsHold",
    DECISION_SYMBOLS_NO = "decisionsymbolsNo",
    
    DECISION_VCR_STOP = "decisionvcrStop",
    DECISION_VCR_REWIND = "decisionvcrRewind",
    DECISION_VCR_PLAY = "decisionvcrPlay",
    DECISION_VCR_FASTFORWARD = "decisionvcrFastForward",
    DECISION_VCR_PAUSE = "decisionvcrPause",
  
    DIRECTIONS_3_UP = "directions3Up",
    DIRECTIONS_3_UNCHANGED = "directions3Unchanged",
    DIRECTIONS_3_DOWN = "directions3Down",
  
    DIRECTIONS_4_DOWN = "directions4Down",
    DIRECTIONS_4_RIGHT = "directions4Right",
    DIRECTIONS_4_UP = "directions4Up",
    DIRECTIONS_4_LEFT = "directions4Left",

    DIFFICULTY_4_EASY = "difficulty4Easy",
    DIFFICULTY_4_INTERMEDIATE = "difficulty4Intermediate",
    DIFFICULTY_4_HARD = "difficulty4Hard",
    DIFFICULTY_4_EXPERTSONLY = "difficulty4ExpertsOnly",
  
    EFFORT_6_EMPTY = "effort6Empty",
    EFFORT_6_ONE = "effort6One",
    EFFORT_6_TWO = "effort6Two",
    EFFORT_6_THREE = "effort6Three",
    EFFORT_6_FOUR = "effort6Four",
    EFFORT_6_FIVE = "effort6Five",
    
    FLAG_0 = "flag0",
    FLAG_1 = "flag1",
    
    HARVEY_000 = "harvey000",
    HARVEY_025 = "harvey025",
    HARVEY_050 = "harvey050",
    HARVEY_075 = "harvey075",
    HARVEY_100 = "harvey100",
  
    HEART_6_EMPTY = "heart6Empty",
    HEART_6_ONE = "heart6One",
    HEART_6_TWO = "heart6Two",
    HEART_6_THREE = "heart6Three",
    HEART_6_FOUR = "heart6Four",
    HEART_6_FIVE = "heart6Five",
  
    MONEY_6_EMPTY = "money6Empty",
    MONEY_6_ONE = "money6One",
    MONEY_6_TWO = "money6Two",
    MONEY_6_THREE = "money6Three",
    MONEY_6_FOUR = "money6Four",
    MONEY_6_FIVE = "money6Five",
    
    PAIN_6_NOPAIN = "pain6NoPain",
    PAIN_6_MILD = "pain6Mild",
    PAIN_6_MODERATE = "pain6Moderate",
    PAIN_6_SEVERE = "pain6Severe",
    PAIN_6_VERYSEVERE = "pain6VerySevere",
    PAIN_6_EXTREME = "pain6Extreme",
  
    PRIORITY_HIGH = "priorityHigh",
    PRIORITY_LOW = "priorityLow",
  
    PRIORITY_HML_HIGH = "priorityhmlHigh",
    PRIORITY_HML_MEDIUM = "priorityhmlMedium",
    PRIORITY_HML_LOW = "priorityhmlLow",
  
    PROGRESS_5_000 = "progress5000",
    PROGRESS_5_025 = "progress5025",
    PROGRESS_5_050 = "progress5050",
    PROGRESS_5_075 = "progress5075",
    PROGRESS_5_100 = "progress5100",
  
    RYG_RED = "rygRed",
    RYG_YELLOW = "rygYellow",
    RYG_GREEN = "rygGreen",
  
    RYGB_RED = "rygbRed",
    RYGB_YELLOW = "rygbYellow",
    RYGB_GREEN = "rygbGreen",
    RYGB_BLUE = "rygbBlue",
  
    RYGG_RED = "ryggRed",
    RYGG_YELLOW = "ryggYellow",
    RYGG_GREEN = "ryggGreen",
    RYGG_GRAY = "ryggGray",
  
    SIGNAL_5_000 = "signal5000",
    SIGNAL_5_025 = "signal5025",
    SIGNAL_5_050 = "signal5050",
    SIGNAL_5_075 = "signal5075",
    SIGNAL_5_100 = "signal5100",
  
    STAR_0 = "star0",
    STAR_1 = "star1",
  
    STAR_6_EMPTY = "star6Empty",
    STAR_6_ONE = "star6One",
    STAR_6_TWO = "star6Two",
    STAR_6_THREE = "star6Three",
    STAR_6_FOUR = "star6Four",
    STAR_6_FIVE = "star6Five",
  
    WEATHER_SUNNY = "weatherSunny",
    WEATHER_PARTLY_SUNNY = "weatherPartlySunny",
    WEATHER_CLOUDY = "weatherCloudy",
    WEATHER_RAINY = "weatherRainy",
    WEATHER_STORMY = "weatherStormy",
  }