import React from 'react';
import { ESymbolKey } from "@smartsheet/forms-model";
import Up from "./Up";
import Down from "./Down";
import Left from "./Left";
import Right from "./Right";
import { IconOptionImg } from "../../iconUtil";
var DirectionsFourWayIcon = function (_a) {
    var type = _a.type;
    var icon;
    switch (type) {
        case ESymbolKey.DIRECTIONS_4_UP:
        case "Up":
            icon = React.createElement(Up, null);
            break;
        case ESymbolKey.DIRECTIONS_4_DOWN:
        case "Down":
            icon = React.createElement(Down, null);
            break;
        case ESymbolKey.DIRECTIONS_4_LEFT:
        case "Left":
            icon = React.createElement(Left, null);
            break;
        case ESymbolKey.DIRECTIONS_4_RIGHT:
        case "Right":
            icon = React.createElement(Right, null);
            break;
        default:
            break;
    }
    return !!icon ? (React.createElement(IconOptionImg, null, icon)) : null;
};
export default DirectionsFourWayIcon;
