import * as React from 'react';

interface ConditionalUpdateProps {
  value: any;
  values?: any;
  isSubmitting?: boolean;
  formStatusType?: string;
  error?: string;
  isHiddenByConditionalLogic? : boolean;
}

const conditionalUpdate = <T extends object>(
  WrappedComponent: React.ComponentType<T>
) => {
  return class ConditionalUpdate extends React.Component<
    T & ConditionalUpdateProps
  > {
    shouldComponentUpdate(nextProps: ConditionalUpdateProps) {
      return (
        nextProps.value !== this.props.value ||
        nextProps.values !== this.props.values ||
        nextProps.isSubmitting !== this.props.isSubmitting ||
        nextProps.formStatusType !== this.props.formStatusType ||
        nextProps.isHiddenByConditionalLogic !== this.props.isHiddenByConditionalLogic ||
        nextProps.error !== this.props.error
      );
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default conditionalUpdate;