import { DataValue, StringValue } from './value';

export interface FormDefinition {
  readonly name: string
  readonly description?: string
  readonly components: FormComponentDefinitionType[]
  readonly theme?: FormTheme
  readonly hideTitleAndDescription: boolean
  readonly hideFooterOnForm: boolean
  readonly isRichTextEnabled: boolean
  readonly hideAllLogo: string
  readonly richTitle: string
  readonly richDescription: string
  readonly disableFooterFeatureFlag: boolean
  readonly hideFooterOnConfirmation: boolean
  readonly userLocale: string
  readonly captcha: CaptchaDefinition
  readonly captchaSiteKey: string
  readonly imageAssets: FormImageAsset[]
}

export interface FormImageAsset {
  readonly assetKey: string
  readonly imageId: string
  readonly imageUrl: string
}

export interface FormTheme {
  readonly type?: string
  readonly layout?: string
  readonly style?: FormStyle
}

export interface FormStyle {
  readonly background?: BackgroundStyle
  readonly logo?: LogoStyle
  readonly font?: { family: string };
}

export interface BackgroundStyle {
  readonly color?: string
  readonly image?: string
}

export interface LogoStyle {
  readonly image: string
}

export enum FormComponentType {
  CHECKBOX_INPUT = "CHECKBOX_INPUT",
  DATE_INPUT = "DATE_INPUT",
  DIVIDER = "DIVIDER",
  FILE_UPLOAD = "FILE_UPLOAD",
  HEADING = "HEADING",
  MULTI_CHECKBOX_INPUT = "MULTI_CHECKBOX_INPUT",
  SECTION = "SECTION",
  SELECT_INPUT = "SELECT_INPUT",
  RADIO_INPUT = "RADIO_INPUT",
  TEXT_INPUT = "TEXT_INPUT",
  EMAIL_RECEIPT_INPUT = "EMAIL_RECEIPT_INPUT",
  CAPTCHA = "CAPTCHA"
}

export enum SelectDataType {
  MULTI_PICKLIST = "MULTI_PICKLIST",
  MULTI_CONTACT = "MULTI_CONTACT",
  PICKLIST = "PICKLIST"
}

export enum ValueType {
	STRING = "STRING",
	CONTACT = "CONTACT",
}

export const EMAIL_REGEX = "^[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*.([a-zA-Z]{2,})$";

// needed the toString because the reducer serializes the contacts into strings for validation
export interface Contact {
  readonly email: string
  readonly name: string
  readonly toString?: () => string
}

/** Remove name/email pairs if name only contact is chosen and vice versa
   * Can have [{name: '', email: ''}, {email: ''}, {name: '', email: ''}] 
   * Or [{name: ''}]
   * But not [{name: '', email: ''}, {name: ''}] or [{name: ''}, {name: ''}]
  */
export const applyMultiContactListLogic = (value: Contact[], fieldValue: Contact[]): Contact[] => {
  // Sheet Limit max 20 multi contacts
  const MAX_DEFAULT_VALUES = 20;
    if (Array.isArray(value) && value.length > 1) {

      var fieldArr: Contact[] = [];
  
      if (!Array.isArray(fieldValue)) {
        fieldArr.push(fieldValue);
      }
      else {
        fieldArr = fieldValue.slice(0);
      }

      /**Based on recent selection get the last selected option */
      const selectedOpt = value.filter(a => {
        return !fieldArr.some(b => {
          if (a.email && b.email) {
            return a.email === b.email;              
          } else {
            return a.name === b.name;              
          }
        });
      });

      //no matter what we have, once a name-only being select, convert to single
      if (selectedOpt.length > 0) {
        if (!(selectedOpt[0]).email || !(fieldArr[0]).email) {
          value = selectedOpt.slice(0, 1);
        }
      }
    }

    return value.slice(0, MAX_DEFAULT_VALUES);
}

export function isContact(value: any): value is Contact {
  // value.preview is checked to guarantee File Uploads aren't evaluated to be Contact Values
  if ((value as Contact) && value.preview === undefined) {
    if ((value as Contact).email && (value as Contact).email !== undefined) {
      return true;
    } else if (
      (value as Contact).name &&
      (value as Contact).name !== undefined
    ) {
      return true;
    }
  }
  return false;
}

export const isContactArray = (arr: any): arr is Contact[] => {
  if (Array.isArray(arr) && arr.length) {
    return arr.every((val) => isContact(val));
  }
  return false;
}

export const isStringArray = (arr: any): arr is string[] => {
  if (Array.isArray(arr) && arr.length) {
    return arr.every((val) => typeof val == 'string');
  }
  return false;
}

export interface FileDefinition {
  readonly uploadKey: string
  readonly name: string
  readonly size: number
  readonly type: string
}

export type FormComponentDefinitionType =
  CheckboxInputDefinition |
  DateInputDefinition |
  DividerDefinition |
  HeadingDefinition |
  TextInputDefinition |
  SelectInputDefinition |
  RadioInputDefinition |
  FileUploadDefinition |
  EmailReceiptInputDefinition |
  CaptchaDefinition

export interface FormComponentDefinition {
  readonly type: FormComponentType
  readonly hidden?: Boolean
  readonly key?: string
}

export interface InputComponentDefinition<T> extends FormComponentDefinition {
  readonly key: string
  readonly label: string
  readonly description?: string
  readonly value?: DataValue<T>
  readonly defaultValue?: DataValue<T>
  readonly required?: Boolean
  readonly checkboxType?: string
  readonly validateField?: Boolean
}

export enum CheckboxCaptionLocation {
  ABOVE, RIGHT
}

export interface CheckboxInputDefinition extends InputComponentDefinition<boolean> {
  readonly type: FormComponentType.CHECKBOX_INPUT
  readonly captionLocation: CheckboxCaptionLocation
}

export interface DateInputDefinition extends InputComponentDefinition<string> {
  readonly type: FormComponentType.DATE_INPUT

}

export interface DividerDefinition extends FormComponentDefinition {
  readonly type: FormComponentType.DIVIDER
  readonly key: string
  readonly title?: string
}

export interface MultiCheckboxInputDefinition extends InputComponentDefinition<string | Contact> {
  type: FormComponentType.MULTI_CHECKBOX_INPUT
  readonly options: (StringValue | DataValue<Contact>)[]
  direction: RadioOptionsDirection
  readonly multivalue?: boolean
}

export interface FileUploadDefinition extends FormComponentDefinition {
  readonly type: FormComponentType.FILE_UPLOAD

  readonly key: string
  readonly label: string
  readonly description?: string
  readonly required?: boolean
  readonly hidden?: boolean
  readonly forbidFileTypes?: string[]
  readonly maxFileCount?: number
  readonly maxFileSize?: number
}

export interface HeadingDefinition extends FormComponentDefinition {
  readonly type: FormComponentType.HEADING
  readonly key: string
  readonly title: string
  readonly description?: string
}

export interface SectionDefinition extends FormComponentDefinition {
  readonly type: FormComponentType.SECTION

  readonly label?: string
  readonly description?: string
}

// these fields are passed into the select input determining how it should render.
// options is the initial options passed in from the form builder
// symbolType will determine if the dropdown should have symbol support
// validateField will determine whether the form should restrict to the values placed in the formbuilder
// valueType determines if the dropdown will have strings or contacts
// multivalue will determine if the dropdown should be a single select or multiselect
// selectDataType detemines the type of the dropdown: PICKLIST, MULTI_PICKLIST, or MULTI_CONTACT. From what I've been told this is being phased out and should be avoided
export interface SelectInputDefinition extends InputComponentDefinition<string | Contact> {
  readonly type: FormComponentType.SELECT_INPUT
  readonly options: DataValue<string | Contact>[]
  readonly allowUnset?: boolean
  readonly symbolType?: string
  readonly validateField?: boolean
  readonly valueType?: ValueType
  readonly selectDataType?: SelectDataType
  readonly direction?: RadioOptionsDirection
  readonly multivalue?: boolean
  readonly formBuilder?: boolean 
}

export type RadioOptionsDirection = "HORIZONTAL" | "VERTICAL"

export interface RadioInputDefinition extends InputComponentDefinition<string | Contact> {
  readonly type: FormComponentType.RADIO_INPUT
  readonly options: DataValue<string | Contact>[]
  readonly direction: RadioOptionsDirection
  readonly symbolType?: string
}


export interface TextInputDefinition extends InputComponentDefinition<string> {
  readonly type: FormComponentType.TEXT_INPUT

  readonly lines?: number
}

export interface EmailReceiptInputDefinition extends FormComponentDefinition {
  readonly type: FormComponentType.EMAIL_RECEIPT_INPUT
  readonly defaultValue?: DataValue<string>
  readonly displayDefaultValue?: boolean
  readonly key: string
  // enableCaptchaOnSendCopy should no longer be optional after min version is 1.50.0 or higher
  readonly enableCaptchaOnSendCopy?: boolean
}

export interface CaptchaDefinition extends FormComponentDefinition {
  readonly type: FormComponentType.CAPTCHA

  readonly key: string
  readonly siteKey: string
}

export interface CaptchaValue {
  token:string,
  siteKey:string
}

export enum NonBooleanOperatorType {
  IS              = "IS",
  IS_NOT          = "IS_NOT",
  IS_ANY_OF       = "IS_ANY_OF",
  IS_NONE_OF      = "IS_NONE_OF",
  IS_BLANK        = "IS_BLANK",
  IS_NOT_BLANK    = "IS_NOT_BLANK",
  HAS_ANY_OF      = "HAS_ANY_OF",
  HAS_ALL_OF      = "HAS_ALL_OF",
  IS_EXACTLY      = "IS_EXACTLY",
  HAS_NONE_OF     = "HAS_NONE_OF",
  IS_CHECKED      = "IS_CHECKED",
  IS_NOT_CHECKED  = "IS_NOT_CHECKED",
  IS_BEFORE       = "IS_BEFORE",
  IS_AFTER        = "IS_AFTER",
  IS_BETWEEN      = "IS_BETWEEN",
  IS_NOT_BETWEEN  = "IS_NOT_BETWEEN",
  IS_WITHIN       = "IS_WITHIN",
  IS_NOT_WITHIN   = "IS_NOT_WITHIN",
  CONTAINS        = "CONTAINS",
  DOES_NOT_CONTAIN= "DOES_NOT_CONTAIN"
}
