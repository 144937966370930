import React from 'react';
var Up = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "12px", height: "14px", viewBox: "0 0 12 14", version: "1.1", role: "img" },
        React.createElement("title", null, "Directions Up"),
        React.createElement("desc", null, "Up."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#444444" },
                React.createElement("path", { d: "M11.4,7.2 L6.4,0.533333333 C6.23431458,0.312419433 5.9209139,0.267647908 5.7,0.433333333 C5.66209717,0.461760458 5.62842712,0.4954305 5.6,0.533333333 L0.6,7.2 C0.434314575,7.4209139 0.4790861,7.73431458 0.7,7.9 C0.786548085,7.96491106 0.891814893,8 1,8 L4,8 L4,13.5 C4,13.7761424 4.22385763,14 4.5,14 L7.5,14 C7.77614237,14 8,13.7761424 8,13.5 L8,8 L11,8 C11.2761424,8 11.5,7.77614237 11.5,7.5 C11.5,7.39181489 11.4649111,7.28654809 11.4,7.2 Z", id: "Combined-Shape" })))));
};
export default Up;
