import { __assign, __makeTemplateObject } from "tslib";
import ReactTooltip from 'react-tooltip';
import * as React from 'react';
import styled from '@emotion/styled/macro';
var StyledReactTooltip = styled(ReactTooltip)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: #FFFFFF !important;\n    color: #888888 !important;\n    border: 1px solid #005ee0 !important;    \n    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;\n    padding: 0 10px !important;    \n    width: 408px;    \n    opacity: 1 !important;\n    &.place-top:after {\n        border-top-color: #fff !important; \n        left: 15% !important;       \n    }\n    &.place-top:before {\n        border-top-color: #005ee0 !important;\n        border-top-width: 8px !important;\n        border-top-style: solid !important;\n        left: 15% !important;      \n    }    \n"], ["\n    background-color: #FFFFFF !important;\n    color: #888888 !important;\n    border: 1px solid #005ee0 !important;    \n    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;\n    padding: 0 10px !important;    \n    width: 408px;    \n    opacity: 1 !important;\n    &.place-top:after {\n        border-top-color: #fff !important; \n        left: 15% !important;       \n    }\n    &.place-top:before {\n        border-top-color: #005ee0 !important;\n        border-top-width: 8px !important;\n        border-top-style: solid !important;\n        left: 15% !important;      \n    }    \n"])));
var Tooltip = function (props) {
    return (React.createElement(StyledReactTooltip, __assign({}, props, { offset: { top: 5, left: -150 } })));
};
export var TooltipRebuild = function () { ReactTooltip.rebuild(); };
export default Tooltip;
var templateObject_1;
