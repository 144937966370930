import React from 'react';
import { ESymbolKey } from "@smartsheet/forms-model";
import Up from "./Up";
import Down from "./Down";
import Unchanged from "./Unchanged";
import { IconOptionImg } from "../../iconUtil";
var DirectionsThreeWayIcon = function (_a) {
    var type = _a.type;
    var icon;
    switch (type) {
        case ESymbolKey.DIRECTIONS_3_UP:
        case "Up":
            icon = React.createElement(Up, null);
            break;
        case ESymbolKey.DIRECTIONS_3_DOWN:
        case "Down":
            icon = React.createElement(Down, null);
            break;
        case ESymbolKey.DIRECTIONS_3_UNCHANGED:
        case "Unchanged":
            icon = React.createElement(Unchanged, null);
            break;
        default:
            break;
    }
    return !!icon ? (React.createElement(IconOptionImg, null, icon)) : null;
};
export default DirectionsThreeWayIcon;
