import * as React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { injectGlobal } from 'emotion';
import styled, { wrapTheme, DEFAULT_THEME } from '../styled';
import { FormTheme } from '../../../../app/types';

interface Props {
  formTheme?: FormTheme;
  documentTitle?: string;
  children: React.ReactNode;
}

const AppView = styled.div`
  min-height: 100vh;
  font-size: 16px;
  overflow-y: auto;
  display: flex;
  justify-content: ${(props:any) => props.theme.style.justifyContent};
  background: ${(props:any) => props.theme.style.background.color} url(${(props:any) => props.theme.style.background.image});
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  width: 100%;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    height: auto !important;
  }
`;

injectGlobal(`
  font-family: 'Roboto', sans-serif;
`);

class ThemedAppView extends React.Component<Props> {
  public componentDidMount() {
    const { documentTitle } = this.props;

    if (documentTitle) {
      document.title = documentTitle;
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const { documentTitle } = this.props;

    if (documentTitle && !prevProps.documentTitle) {
      document.title = documentTitle;
    }
  }

  public render() {
    const { formTheme, children } = this.props;

    return (
      <ThemeProvider theme={formTheme ? wrapTheme(formTheme) : DEFAULT_THEME}>
        <AppView
          role="main"
          data-client-theme={formTheme ? formTheme.type : "DEFAULT"}>
            {children}
        </AppView>
      </ThemeProvider>
    );
  }
}

export default ThemedAppView;