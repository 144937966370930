import React from 'react';
import { ESymbolKey } from "@smartsheet/forms-model";
import Cloudy from "./Cloudy";
import PartlySunny from "./PartlySunny";
import Rainy from "./Rainy";
import Stormy from "./Stormy";
import Sunny from "./Sunny";
import { IconOptionImg } from "../../iconUtil";
var WeatherIcon = function (_a) {
    var type = _a.type;
    var icon;
    switch (type) {
        case ESymbolKey.WEATHER_CLOUDY:
        case "Cloudy":
            icon = React.createElement(Cloudy, null);
            break;
        case ESymbolKey.WEATHER_PARTLY_SUNNY:
        case "Partly Sunny":
            icon = React.createElement(PartlySunny, null);
            break;
        case ESymbolKey.WEATHER_RAINY:
        case "Rainy":
            icon = React.createElement(Rainy, null);
            break;
        case ESymbolKey.WEATHER_STORMY:
        case "Stormy":
            icon = React.createElement(Stormy, null);
            break;
        case ESymbolKey.WEATHER_SUNNY:
        case "Sunny":
            icon = React.createElement(Sunny, null);
            break;
        default:
            break;
    }
    return !!icon ? (React.createElement(IconOptionImg, null, icon)) : null;
};
export default WeatherIcon;
