import React from 'react';
var Advanced = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "12px", height: "14px", viewBox: "0 0 12 14", version: "1.1", role: "img" },
        React.createElement("title", null, "Difficulty Hard"),
        React.createElement("desc", null, "Hard."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(1.000000, 0.000000)", fill: "#444444" },
                React.createElement("path", { d: "M5.89315917,0.500384397 L10.7572844,6.69108922 C10.8997388,6.87239488 10.8997388,7.12760512 10.7572844,7.30891078 L5.89315917,13.4996156 C5.72255246,13.7167514 5.40822504,13.7544707 5.19108922,13.583864 C5.15974891,13.5592395 5.13146536,13.5309559 5.10684083,13.4996156 L0.24271561,7.30891078 C0.100261169,7.12760512 0.100261169,6.87239488 0.24271561,6.69108922 L5.10684083,0.500384397 C5.27744754,0.283248584 5.59177496,0.245529293 5.80891078,0.416136004 C5.84025109,0.440760535 5.86853464,0.469044086 5.89315917,0.500384397 Z", id: "Rectangle" })))));
};
export default Advanced;
