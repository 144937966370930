/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/core';
import { FormattedMessage } from 'react-intl';
import { Form as FormikForm, Formik, FormikErrors, FormikProps } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitFormActions } from '../../../app/store/actions';
import { FormDefinition, EmailReceiptInputDefinition, FormValues, FormConfirmation } from '../../../app/types';
import { FormButtonContainer, FormButton } from '../../../app/components/FormButton';
import FormFooter from '../../../app/components/FormFooter';
import { createFieldFromDefinition } from './fields';
import {normalizeFormValuesForSubmission, filterConditionallyHiddenFormValues} from './normalizeFormValuesForSubmission';
import noValuesSet from './noValuesSet';
import { FormStatusType } from '../../../app/store/sagas';
import { EmailValue } from '@smartsheet/forms-components';

export interface FormProps {
  form?: FormDefinition;
  formikRef: React.RefObject<Formik<FormValues>>;
  formKey: string;
  formToken?: string;
  initialValues?: FormValues;
  confirmation: FormConfirmation;
  formStatusType: FormStatusType;
  renderFormHeader: (options: {
    formikErrors?: FormikErrors<FormValues>;
    submitCount: number;
  }) => React.ReactNode;
  submitFormRequest: typeof SubmitFormActions.submitFormRequest;
  submitting: boolean;
  timeOfRender?: Date;
  validationSchema?: any;
  handleValidate?: (values: FormValues) => void;
}

class Form extends React.Component<FormProps> {
  // We need to store the formValues temporarily between calling ReCAPTCHA
  // and the callback from ReCAPTCHA.
  formValues?: FormValues;
  private captchaRef = React.createRef<ReCAPTCHA>();
  private timeOfSubmitClickedWithCaptchaOn : number;  
  
  constructor(props: FormProps) {
    super(props);
    this.formValues = undefined;
    this.timeOfSubmitClickedWithCaptchaOn = 0;
}
  private handleSubmit = (values: FormValues) => {
    if (this.captchaRef && this.captchaRef.current) {
      this.timeOfSubmitClickedWithCaptchaOn = Date.now();
      this.formValues = values;
      this.captchaRef.current.execute();
    } else {
      this.submitFormRequest(values, this.props.form);
    }
  };
  
  private handleCaptchaVerifiedEvent = (
    token: string | null,
    siteKey: string,
  ) => {
    if (token) {
      const submitCaptchaTimeElapsed = this.timeOfSubmitClickedWithCaptchaOn === 0 ? 0 : (Date.now() - this.timeOfSubmitClickedWithCaptchaOn);
      const formValuesWithCaptchaKey = {
        ...this.formValues,
        CAPTCHA_KEY: { token, siteKey, submitCaptchaTimeElapsed }
      };

      this.submitFormRequest(formValuesWithCaptchaKey,  this.props.form);
    } else {
      throw new Error('Error: Captcha token is null.');
    }
  };

  private submitFormRequest = (values: FormValues, form?: FormDefinition) => {
    this.props.submitFormRequest({
      ...normalizeFormValuesForSubmission(filterConditionallyHiddenFormValues(values, this.props.form), this.props.form),
      formKey: this.props.formKey,
      formToken: this.props.formToken as string,
      form: form,
      // Send through the raw form values so that we can repopulate a new form
      // with them when appropriate (i.e. when the user navigates back from the
      // the confirmation page).
      rawValues: values,
    });
  };

  public render() {
    const {
      form,
      formikRef,
      initialValues,
      renderFormHeader,
      submitting,
      timeOfRender,
      validationSchema,
      handleValidate,
      confirmation,
      formStatusType
    } = this.props;

    if (!form || !initialValues) {
      return null;
    }    
  
    return (
      <React.Fragment>
        <Formik
          ref={formikRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
          validate={handleValidate}
          render={(formikBag: FormikProps<FormValues>) => {
            const emailReceipt = formikBag.values.EMAIL_RECEIPT as EmailValue
            const emailComponent = form.components.find(component => component.type === "EMAIL_RECEIPT_INPUT") as EmailReceiptInputDefinition
            const captchaEnabled:boolean = emailReceipt !== undefined && emailReceipt.emailRequested === true && emailComponent !== undefined && emailComponent.enableCaptchaOnSendCopy === true
            const captchaSiteKey = form.captchaSiteKey ;
            const renderCaptchaComponent:boolean = captchaSiteKey !== undefined && (form.captcha !== undefined || captchaEnabled)

            return (
              <FormikForm>
                {renderFormHeader({
                  formikErrors: formikBag.errors,
                  submitCount: formikBag.submitCount,
                })}
                {form && form.components
                  .filter(component => !component.hidden)
                  .map((component, index) => createFieldFromDefinition(form.isRichTextEnabled, component, index, confirmation, formStatusType))}
                {/* Check captchaSiteKey again here to make typescript to realize the key exists*/}
                {captchaSiteKey !== undefined && renderCaptchaComponent && (
                  <div
                    css={{
                      marginTop: 20,
                      '.grecaptcha-badge': {
                        visibility: 'hidden'
                      },
                    }}
                    key={timeOfRender && timeOfRender.toString()}
                  >
                    <ReCAPTCHA
                      key="CAPTCHA"
                      ref={this.captchaRef}
                      onChange={token =>
                        this.handleCaptchaVerifiedEvent(
                          token,
                          captchaSiteKey,
                        )
                      }
                      sitekey={captchaSiteKey}
                      size="invisible"
                    />
                  </div>
                )}
                <FormButtonContainer>
                  <FormButton
                    data-client-id="form_submit_btn"
                    disabled={
                      submitting ||
                      noValuesSet(
                        formikBag.values,
                        form.components.filter(component => component.hidden || component.isHiddenByConditionalLogic)
                      )
                    }
                    type="submit"
                    value="submit"
                  >
                    <FormattedMessage id="elements.btn_submit" />
                  </FormButton>
                </FormButtonContainer>
                <FormFooter
                  hideCreateYourOwnForm
                  hidePoweredBySmartsheet={form.hideFooterOnForm}
                  disableFooterFeatureFlag={form.disableFooterFeatureFlag}
                  publishKey={this.props.formKey}
                  hideRecaptchaMessage={!renderCaptchaComponent}
                  />
              </FormikForm>
            );
          }}
        />
      </React.Fragment>
    );
  }
}

export default Form;
