import { Moment, isMoment } from 'moment';
import { Contact, DataValue, FileDefinition, isContact, FormDefinition, FormComponentType, FormValues, SelectDataType, FormComponentDefinitionType, ValidationTypes } from '../../../app/types';
import {
  isEmailValue,
  EmailValue,
} from '@smartsheet/forms-components';

interface FileWithUploadKey {
  uploadKey: string;
  file: File;
}

function randomKey(): string {
  return Math.random()
    .toString(36)
    .substring(7);
}

export function filterConditionallyHiddenFormValues(values: FormValues, form? : FormDefinition) {
  const displayedFields = form ? form.components.filter(c => !c.isHiddenByConditionalLogic).map(o => o.key): [];
  return Object.keys(values)
          .filter(key => displayedFields.includes(key) || key === 'CAPTCHA_KEY')
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: values[key]
            }
          }, {});
}

export function normalizeFormValuesForSubmission(values: FormValues, form?: FormDefinition) {
  const data: { [key: string]: DataValue<any> } = {};
  const files: { [key: string]: File } = {};

  Object.keys(values).forEach(key => {
    if (typeof values[key] === 'number') {
      let value = values[key];

      const field: any  = form!.components.filter(field => {
        return field.type === 'TEXT_INPUT' && field.key === key;
      })[0];
      // Currency formatting
      if (field.validation && field.validation.type && field.validation.type === 'CURRENCY') {
        if (field.validation.isPostFix) {
          value = value +  ' ' + field.validation.code;
        } else {
          value = field.validation.code + ' ' + value;
        }
      } else if (field.validation && field.validation.type && field.validation.type === 'PERCENTAGE') {
              // Percentage formatting
          value = value +  '%';
      }
      data[key] = {
        type: 'STRING',
        value: value,
      };
    } else if (typeof values[key] === 'string') {

      let value = values[key];
      let components: FormComponentDefinitionType[] = [];

      if (form) {
        components = form.components.filter(field => {
          return field.type === FormComponentType.TEXT_INPUT && field.key === key;
        })
      }

      if (components.length) {
        const field = components[0];
        if (value && 'validation' in field && field.validation?.type === ValidationTypes.PERCENTAGE) {
          // Percentage formatting
          value = value +  '%';
        }
      }
  
      data[key] = {
        type: 'STRING',
        value: value,
      };
    } else if (typeof values[key] === 'boolean') {
      data[key] = {
        type: 'BOOLEAN',
        value: values[key],
      };
    } else if (isEmailValue(values[key]) && key === 'EMAIL_RECEIPT') {
      data[key] = {
        type: 'STRING',
        value: (values[key] as EmailValue).emailRequested
          ? (values[key] as EmailValue).email
          : '',
      };
    } else if (isContact(values[key])) {
      data[key] = {
        type: 'CONTACT',
        value: values[key] as Contact,
      };
    } else if (isMoment(values[key])) {
      data[key] = {
        type: 'DATE_TIME',
        value: (values[key] as Moment).toISOString(),
      };
    } else if (Array.isArray(values[key])) {
      const valuesArray = values[key] as any[];

      if (valuesArray.length > 0) {
        if (valuesArray[0].checked != null) {
          const values: string[] = [];
          valuesArray.forEach(val => {
            if (val.checked) {
              values.push(val.value);
            }
          })
          data[key] = {
            type: 'MULTI_PICKLIST',
            value: values,
          };
        } else if (isContact(valuesArray[0])) {
          data[key] = {
            type: 'MULTI_CONTACT',
            value: valuesArray as Contact[],
          };
        } else if(typeof valuesArray[0] === 'string') {
          data[key] = {
            type: 'MULTI_PICKLIST',
            value: valuesArray as string[],
          };
        } else {
          const filesWithKey: FileWithUploadKey[] = (valuesArray as File[]).map(
            file => ({ uploadKey: randomKey(), file } as FileWithUploadKey)
          );
          data[key] = {
            type: 'FILES',
            value: filesWithKey.map(
              ({ uploadKey, file }) =>
                ({
                  name: file.name,
                  size: file.size,
                  type: file.type,
                  uploadKey,
                } as FileDefinition)
            ),
          };
          filesWithKey.forEach(({ uploadKey, file }) => {
            files[uploadKey] = file;
          });
        }
      } else if(form && form.components.filter( (component) => component.key === key ).length > 0){
        const selectComponent = form.components.filter( (component) => component.key === key )[0];
        if(selectComponent.type === FormComponentType.SELECT_INPUT && selectComponent.selectDataType === SelectDataType.MULTI_PICKLIST) {
          data[key] = {
            type: 'MULTI_PICKLIST',
            value: [],
          };
        }
      }
    } else if (key === 'CAPTCHA_KEY') {
      data[key] = {
        type: 'CAPTCHA_VALUE',
        value: values[key],
      };
    } else {
      data[key] = {
        type: 'STRING',
        value: (values[key] as Contact).name,
      };
    }
  });

  return { data, files };
}
