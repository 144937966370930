import { __assign, __extends, __makeTemplateObject } from "tslib";
/** @jsx jsx */
import * as React from "react";
import { createPropsGetter } from "../utils";
import { generateAriaDescribedBy, sanitizeHtmlID } from "../utils/stringUtils";
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled/macro";
import InputLabel from "../InputLabel";
import InputDescription from "../InputDescription";
import InputContainer from "../InputContainer";
import LabelContainer from "../LabelContainer";
import Dropzone from "react-dropzone";
import { FormattedMessage } from "react-intl";
import WarningImageIcon from "../WarningImageIcon";
import AttachmentsIcon from "./AttachmentsIcon";
import SmallCrossIcon from "./SmallCrossIcon";
import LogicTag from "../LogicTag";
var DUPLICATE_COUNTER = 0;
var IOS_CAMERA_IMG = "image.jpg";
var defaultProps = {
    readonly: false,
    required: false,
    disabled: false,
    touched: false,
    hidden: false,
    onFilesChanged: function (files) { },
    forbidFileTypes: []
};
var OpenButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex: initial;\n    border-color: transparent;\n    border-style: none;\n    background-color: #fff;\n    border-width: 1px;\n    &:hover {\n        text-decoration: underline;\n        color: #2779bd;\n        cursor: pointer;\n    }\n    color: #0073ec;\n    font-size: 1em;\n    font-weight: regular;\n    padding-left: 0;\n    padding-right: 0;\n"], ["\n    flex: initial;\n    border-color: transparent;\n    border-style: none;\n    background-color: #fff;\n    border-width: 1px;\n    &:hover {\n        text-decoration: underline;\n        color: #2779bd;\n        cursor: pointer;\n    }\n    color: #0073ec;\n    font-size: 1em;\n    font-weight: regular;\n    padding-left: 0;\n    padding-right: 0;\n"])));
var DropLabel = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: inline;\n    text-align: center;\n    font-size: 0.88em;\n    font-weight: regular;\n    color: #767676;\n"], ["\n    display: inline;\n    text-align: center;\n    font-size: 0.88em;\n    font-weight: regular;\n    color: #767676;\n"])));
var FileStack = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: block;\n    align-items: baseline;\n    justify-content: space-between;\n    max-width: 100%;\n    border-radius: 5px;\n    margin-top: 0px;\n    margin-bottom: 10px;\n"], ["\n    display: block;\n    align-items: baseline;\n    justify-content: space-between;\n    max-width: 100%;\n    border-radius: 5px;\n    margin-top: 0px;\n    margin-bottom: 10px;\n"])));
var RemoveButton = styled.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 16px;\n    height: 16px;\n    margin-left: 1px;\n    visibility: hidden;\n    &:hover {\n        cursor: pointer;\n    }\n"], ["\n    width: 16px;\n    height: 16px;\n    margin-left: 1px;\n    visibility: hidden;\n    &:hover {\n        cursor: pointer;\n    }\n"])));
var FileEntry = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    align-items: center;\n    display: flex;\n    text-align: left;\n    box-sizing: border-box;\n    margin-bottom: 10px;\n    margin-top: 0;\n    width: fit-content;\n\n    &:hover ", " {\n        visibility: visible;\n    }\n"], ["\n    align-items: center;\n    display: flex;\n    text-align: left;\n    box-sizing: border-box;\n    margin-bottom: 10px;\n    margin-top: 0;\n    width: fit-content;\n\n    &:hover ", " {\n        visibility: visible;\n    }\n"])), RemoveButton);
var FileEntryText = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 0.88em;\n    padding: 0rem;\n    margin-left: 5px;\n"], ["\n    font-size: 0.88em;\n    padding: 0rem;\n    margin-left: 5px;\n"])));
var ErrorContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    font-size: 0.88em;\n    color: #d0011b;\n    margin-top: 11px;\n    margin-bottom: 0.25rem;\n"], ["\n    display: flex;\n    font-size: 0.88em;\n    color: #d0011b;\n    margin-top: 11px;\n    margin-bottom: 0.25rem;\n"])));
var ErrorStack = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    display: block;\n"], ["\n    display: block;\n"])));
var WarningImageStyled = css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    margin-right: 0.3rem;\n"], ["\n    margin-right: 0.3rem;\n"])));
var dropzoneContainerClass = css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    align-items: center;\n    background-color: #fff;\n    border-radius: 2px;\n    border: dashed 1px #ccc;\n    display: flex;\n    flex-direction: column;\n    line-height: 15px;\n    margin-top: 0px;\n    padding: 25px 0px 25px 0px;\n    width: 100%;\n"], ["\n    align-items: center;\n    background-color: #fff;\n    border-radius: 2px;\n    border: dashed 1px #ccc;\n    display: flex;\n    flex-direction: column;\n    line-height: 15px;\n    margin-top: 0px;\n    padding: 25px 0px 25px 0px;\n    width: 100%;\n"])));
var dropzoneContainerErrorClass = css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    ", "\n    border-color: #d0011b;\n"], ["\n    ", "\n    border-color: #d0011b;\n"])), dropzoneContainerClass);
var DropzoneLabelContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    display: block;\n    max-width: 100%;\n    font-size: large;\n    padding: 0;\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    display: block;\n    max-width: 100%;\n    font-size: large;\n    padding: 0;\n"])));
var LogicInputLabel = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    word-wrap: break-all;\n"], ["\n    word-wrap: break-all;\n"]))); //Div is needed around label when logic tag is present
var getProps = createPropsGetter(defaultProps);
var formatBytes = function (bytes, decimals) {
    if (bytes == 0)
        return "0 Bytes";
    var k = 1024, dm = !decimals || decimals <= 0 ? 0 : decimals || 2, sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
var fileCountOverflowFilter = function (fileUploadState, maxCount) {
    if (fileUploadState.files && fileUploadState.files.length >= maxCount) {
        fileUploadState.disabled = true;
        fileUploadState.files.splice(maxCount);
    }
    return fileUploadState;
};
// If name is image.jpg temporarily on all platforms rename to image(1), image(2) etc
// Why? => To allow iphone camera photo multi upload,
// Check in at later period to see if iOS has this fixed and remove it.
var deriveFileName = function (files, filename) {
    var hasImageFile = false;
    if (filename === IOS_CAMERA_IMG) {
        files.map(function (file) {
            if (file.name === IOS_CAMERA_IMG) {
                hasImageFile = true;
            }
        });
        // First file with name image.jpg, let through
        if (!hasImageFile || DUPLICATE_COUNTER === 0) {
            DUPLICATE_COUNTER++;
            return filename;
        }
        else {
            // Duplicate image.jpg so append (count)
            return "image(" + DUPLICATE_COUNTER++ + ").jpg";
        }
    }
    return filename;
};
var fileValidationChecker = function (accepted, currentQueue, fileExtFilter, fileMaxSize) {
    var rejected = accepted
        .map(function (file) { return ({ name: file.name, size: file.size }); })
        .map(function (file) {
        var errorMessageArr = [];
        var fileExt = file.name.indexOf(".") > 0 ? file.name.split(".").pop() : "";
        currentQueue.forEach(function (_file) {
            if (file.name !== IOS_CAMERA_IMG && _file.name === file.name) {
                errorMessageArr.push({
                    messageId: "validations.uploadField_duplicateFoundInQueue",
                    placeHolderReplacement: file.name
                });
            }
        });
        if (fileExt && fileExtFilter.indexOf(fileExt.toLowerCase()) > -1) {
            errorMessageArr.push({
                messageId: "validations.uploadField_unsupportedFileType",
                placeHolderReplacement: file.name
            });
        }
        if (file.size > fileMaxSize) {
            errorMessageArr.push({
                messageId: "validations.uploadField_fileTooLarge",
                placeHolderReplacement: formatBytes(fileMaxSize)
            });
        }
        else if (file.size === 0) {
            errorMessageArr.push({
                messageId: "messages.invalid_request_file_empty",
                placeHolderReplacement: file.name
            });
        }
        return __assign(__assign({}, file), { error: errorMessageArr });
    })
        .filter(function (file) { return file.error.length > 0; });
    return rejected;
};
var onFilesRejectedHandler = function (accepted, rejected) {
    var fileUploadErrorMessageArr = [];
    var filteredAccepted = accepted
        .map(function (file) {
        var _dupFlag = false;
        rejected.forEach(function (_file) {
            if (_file.name === file.name) {
                _dupFlag = true;
            }
        });
        return _dupFlag ? null : file;
    })
        .filter(function (file) { return file != null; });
    rejected.forEach(function (_file) {
        if (_file.error) {
            fileUploadErrorMessageArr = fileUploadErrorMessageArr.concat(_file.error);
        }
    });
    return { accepted: filteredAccepted, error: fileUploadErrorMessageArr };
};
function fileToFileDescription(file) {
    return {
        file: file,
        name: file.name,
        size: file.size
    };
}
var FileUpload = /** @class */ (function (_super) {
    __extends(FileUpload, _super);
    function FileUpload(props) {
        var _this = _super.call(this, props) || this;
        _this.browseFiles = function () {
            _this.dropzoneRef.current ? _this.dropzoneRef.current.open() : null;
        };
        _this.dropzoneRef = React.createRef();
        var files = Array.isArray(props.value)
            ? props.value.map(fileToFileDescription)
            : [];
        _this.state = {
            files: files,
            disabled: false,
            error: []
        };
        return _this;
    }
    FileUpload.getDerivedStateFromProps = function (nextProps) {
        // If the value of the form field has been reset then clear the state.
        if (nextProps.value === "") {
            return {
                disabled: false,
                files: [],
                error: []
            };
        }
        return null;
    };
    FileUpload.prototype.onDrop = function (accepted) {
        var _this = this;
        if (this.state.disabled) {
            return;
        }
        var _a = getProps(this.props), forbidFileTypes = _a.forbidFileTypes, maxFileSize = _a.maxFileSize, maxFileCount = _a.maxFileCount, onFilesChanged = _a.onFilesChanged;
        var fileUploadErrorMessageArr = [];
        if (!forbidFileTypes || !maxFileSize || !maxFileCount) {
            fileUploadErrorMessageArr.push({
                messageId: "messages.msg_ur_uploadError",
                placeHolderReplacement: ""
            });
        }
        var rejected = fileValidationChecker(accepted, this.state.files, forbidFileTypes, maxFileSize);
        if (rejected.length > 0) {
            var result = onFilesRejectedHandler(accepted, rejected);
            accepted = result.accepted;
            fileUploadErrorMessageArr = fileUploadErrorMessageArr.concat(result.error);
        }
        var newState = fileCountOverflowFilter({
            files: this.state.files.concat(accepted.map(function (file) {
                var derivedFileName = deriveFileName(_this.state.files, file.name);
                var updatedFile;
                // File immutable, so create a new file with new name
                try {
                    updatedFile = new File([file], derivedFileName, {
                        type: file.type
                    });
                }
                catch (e) {
                    // IE & Edge
                    updatedFile = new Blob([file], { type: file.type });
                    updatedFile.name = derivedFileName;
                }
                Object.assign(updatedFile, file);
                return {
                    name: derivedFileName,
                    size: file.size,
                    file: updatedFile
                };
            })),
            disabled: false,
            error: fileUploadErrorMessageArr
        }, maxFileCount);
        this.setState(newState);
        onFilesChanged(newState.files);
    };
    FileUpload.prototype.onRemoveFile = function (index) {
        var newState = {
            files: this.state.files.filter(function (file, i) { return index != i; }),
            disabled: false,
            error: []
        };
        this.setState(newState);
        var onFilesChanged = getProps(this.props).onFilesChanged;
        onFilesChanged(newState.files);
    };
    FileUpload.prototype.reset = function () {
        var newState = {
            files: [],
            disabled: false,
            error: []
        };
        this.setState(newState);
    };
    FileUpload.prototype.render = function () {
        var _a;
        var _this = this;
        var _b = getProps(this.props), label = _b.label, logic = _b.logic, description = _b.description, richDescription = _b.richDescription, isRichTextEnabled = _b.isRichTextEnabled, required = _b.required, isHiddenByConditionalLogic = _b.isHiddenByConditionalLogic, disabled = _b.disabled, error = _b.error, componentKey = _b.componentKey, maxFileCount = _b.maxFileCount;
        var hasLogic = logic != null;
        var dataClientIdSuffix = label != null ? label : "File Upload";
        var uploadLabel = "file_upload_" + dataClientIdSuffix;
        var descriptionElemId = "description_file_upload_" + sanitizeHtmlID(dataClientIdSuffix);
        var errorElemId = "error_msg_file_upload_" + sanitizeHtmlID(dataClientIdSuffix);
        var describedByIds = generateAriaDescribedBy(descriptionElemId, !!description, errorElemId, !!error);
        var toggleTabAction = isHiddenByConditionalLogic ? -1 : 0;
        return (jsx(InputContainer, { "data-client-id": "container_" + dataClientIdSuffix, "data-client-type": "file_upload", id: componentKey },
            !hasLogic && (jsx(React.Fragment, null, label ? (jsx(InputLabel, { dataClientIdSuffix: dataClientIdSuffix, forElement: uploadLabel, required: required }, label)) : (jsx(InputLabel, { dataClientIdSuffix: dataClientIdSuffix, forElement: uploadLabel, required: required, localizationLabelId: "elements.lbl_webForm_uploadField_defaultCaption" })))),
            hasLogic && (jsx(LabelContainer, null,
                label ? (jsx(LogicInputLabel, null,
                    jsx(InputLabel, { dataClientIdSuffix: dataClientIdSuffix, forElement: uploadLabel, required: required }, label))) : (jsx(LogicInputLabel, null,
                    jsx(InputLabel, { dataClientIdSuffix: dataClientIdSuffix, forElement: uploadLabel, required: required, localizationLabelId: "elements.lbl_webForm_uploadField_defaultCaption" }))),
                logic && jsx(LogicTag, { logic: logic }))),
            (isRichTextEnabled && richDescription) &&
                jsx("div", { className: "rich-text-field-desc", css: {
                        width: '-webkit-fill-available',
                        '& p': { fontSize: '14.12px', marginBlock: '2px', paddingTop: '6px' },
                        '& ul': {
                            listStylePosition: 'inside',
                            fontSize: '14.12px',
                            marginBlock: '2px'
                        },
                        '& ol': {
                            listStylePosition: 'inside',
                            fontSize: '14.12px',
                            marginBlock: '2px'
                        },
                    }, id: descriptionElemId, dangerouslySetInnerHTML: { __html: richDescription } }) ||
                description && (jsx(InputDescription, { id: descriptionElemId }, description)),
            this.state.files.length > 0 && (jsx(FileStack, null, this.state.files.map(function (fileDescription, index) {
                var fileExtension = fileDescription.name.includes(".")
                    ? fileDescription.name.split(".").pop()
                    : "";
                return (jsx(FileEntry, { key: fileDescription.name + "_" + index, "data-client-id": "attached_file_" + index },
                    jsx(AttachmentsIcon, { fileExtension: fileExtension || "" }),
                    jsx(FileEntryText, null, fileDescription.name),
                    jsx(RemoveButton, { type: "button", onClick: function () { return _this.onRemoveFile(index); } },
                        jsx(SmallCrossIcon, null))));
            }))),
            jsx(Dropzone, __assign({ tabIndex: toggleTabAction, inputProps: {
                    id: uploadLabel,
                    tabIndex: toggleTabAction
                }, "data-client-id": "file_upload_dropzone", ref: this.dropzoneRef, css: error ||
                    (this.state.error && this.state.error.length > 0)
                    ? dropzoneContainerErrorClass
                    : dropzoneContainerClass, onDrop: this.onDrop.bind(this), disabled: disabled, disableClick: true }, (describedByIds && (_a = {}, _a['aria-describedby'] = describedByIds, _a))),
                jsx(DropzoneLabelContainer, null,
                    !this.state.disabled && (jsx(DropLabel, null,
                        jsx(FormattedMessage, { id: "elements.lbl_uploadField_dragHere" }),
                        " ",
                        jsx(OpenButton, { tabIndex: isHiddenByConditionalLogic ? -1 : 0, type: "button", disabled: disabled, onClick: this.browseFiles },
                            jsx(FormattedMessage, { id: "elements.btn_webForm_uploadFile" })))),
                    this.state.disabled && (jsx(DropLabel, null,
                        jsx(FormattedMessage, { id: "validation.uploadField_maximumLimit", values: { 0: maxFileCount } }))))),
            this.state.error && this.state.error.length > 0 && (jsx(ErrorStack, null, this.state.error.map(function (errorFormattedMsg, i) {
                return (jsx(ErrorContainer, { key: "file_upload_unsupported_" + i },
                    jsx("div", null,
                        jsx(WarningImageIcon, { customCss: WarningImageStyled })),
                    jsx("span", { role: "alert", id: errorElemId },
                        jsx(FormattedMessage, { id: errorFormattedMsg.messageId, values: {
                                0: errorFormattedMsg.placeHolderReplacement
                            } }))));
            }))),
            error && (jsx(ErrorContainer, { key: "file_upload_error" },
                jsx("div", null,
                    jsx(WarningImageIcon, { customCss: WarningImageStyled })),
                jsx("span", { role: "warning", id: errorElemId },
                    jsx(FormattedMessage, { id: "validation.uploadField_required" }))))));
    };
    return FileUpload;
}(React.Component));
export default FileUpload;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
