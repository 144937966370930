import { __makeTemplateObject } from "tslib";
import * as React from "react";
import styled from "@emotion/styled/macro";
import BlueLogicIcon from "../LogicTag/logicBlueIcon";
import { FormattedMessage, injectIntl } from 'react-intl';
var Tag = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  /*Only for IE*/\n  min-width: 40px;\n  /*Only for IE*/\n  border-radius: 4px;\n  background-color: #FFFFFF;\n  border: 1px solid #CCCCCC;\n  display: flex;\n  align-items: center;\n  font-size: 0.9em;\n  font-family: 'Roboto';\n  font-stretch: 'normal';\n  font-style: 'normal';\n  line-height: '1.18';\n  letter-spacing: 'normal';\n  margin-left: 8px;  \n  min-height: 18px;  \n  min-width: 50px;\n  text-align: center;\n  padding: 0 8px;\n  & > span {\n    color: #767676;\n    font-size: 0.88em;\n    font-weight: 400;\n    line-height: 13px;\n    margin-left: 5px;    \n  }\n  cursor: pointer;   \n"], ["\n  /*Only for IE*/\n  min-width: 40px;\n  /*Only for IE*/\n  border-radius: 4px;\n  background-color: #FFFFFF;\n  border: 1px solid #CCCCCC;\n  display: flex;\n  align-items: center;\n  font-size: 0.9em;\n  font-family: 'Roboto';\n  font-stretch: 'normal';\n  font-style: 'normal';\n  line-height: '1.18';\n  letter-spacing: 'normal';\n  margin-left: 8px;  \n  min-height: 18px;  \n  min-width: 50px;\n  text-align: center;\n  padding: 0 8px;\n  & > span {\n    color: #767676;\n    font-size: 0.88em;\n    font-weight: 400;\n    line-height: 13px;\n    margin-left: 5px;    \n  }\n  cursor: pointer;   \n"])));
var SourceLogicTag = function (props) {
    var onClickHandler = props.onClickHandler;
    return (React.createElement(Tag, { "data-client-id": "component_logic_label_parent", onClick: onClickHandler },
        React.createElement(BlueLogicIcon, null),
        React.createElement(FormattedMessage, { id: "lbl_conditional_settings" })));
};
export default injectIntl(SourceLogicTag);
var templateObject_1;
