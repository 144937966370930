import styled from '@emotion/styled/macro';

export const FormTitle = styled.h1`
  font-size: 2em;
  font-weight: 900;
  letter-spacing: -0.5px;
  line-height: 38px;
  margin-top: -6px;
  margin-bottom: 0;
`;

export const FormSubmissionMessage = styled.p`
    font-size: 1rem;
    justify-content: center;
    display: flex;
`

export const FormDescription = styled.p`
  font-size: 0.88em;
  white-space: pre-wrap;
  overflow-wrap: normal;
  max-width: 610px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 0;
`;