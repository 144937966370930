import React from 'react';
import { ESymbolKey } from "@smartsheet/forms-model";
import Red from "./Red";
import Yellow from "./Yellow";
import Green from "./Green";
import Blue from "./Blue";
import Gray from "./Gray";
import { IconOptionImg } from "../../iconUtil";
var RygbIcon = function (_a) {
    var type = _a.type;
    var icon;
    switch (type) {
        case ESymbolKey.RYG_RED:
        case ESymbolKey.RYGB_RED:
        case ESymbolKey.RYGG_RED:
        case "Red":
            icon = React.createElement(Red, null);
            break;
        case ESymbolKey.RYG_YELLOW:
        case ESymbolKey.RYGB_YELLOW:
        case ESymbolKey.RYGG_YELLOW:
        case "Yellow":
            icon = React.createElement(Yellow, null);
            break;
        case ESymbolKey.RYG_GREEN:
        case ESymbolKey.RYGB_GREEN:
        case ESymbolKey.RYGG_GREEN:
        case "Green":
            icon = React.createElement(Green, null);
            break;
        case ESymbolKey.RYGB_BLUE:
        case "Blue":
            icon = React.createElement(Blue, null);
            break;
        case ESymbolKey.RYGG_GRAY:
        case "Gray":
            icon = React.createElement(Gray, null);
            break;
        default:
            break;
    }
    return !!icon ? (React.createElement(IconOptionImg, null, icon)) : null;
};
export default RygbIcon;
