import React from 'react';
var Gray = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14px", height: "14px", viewBox: "0 0 14 14", version: "1.1", role: "img" },
        React.createElement("title", null, "Gray"),
        React.createElement("desc", null, "RYGG Gray."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#888888" },
                React.createElement("circle", { id: "Oval", cx: "7", cy: "7", r: "7" })))));
};
export default Gray;
