import React from 'react';
var Left = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14px", height: "12px", viewBox: "0 0 14 12", version: "1.1", role: "img" },
        React.createElement("title", null, "Directions Left"),
        React.createElement("desc", null, "Left."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#444444" },
                React.createElement("path", { d: "M7.2,11.4 L0.533333333,6.4 C0.312419433,6.23431458 0.267647908,5.9209139 0.433333333,5.7 C0.461760458,5.66209717 0.4954305,5.62842712 0.533333333,5.6 L7.2,0.6 C7.4209139,0.434314575 7.73431458,0.4790861 7.9,0.7 C7.96491106,0.786548085 8,0.891814893 8,1 L8,4 L13.5,4 C13.7761424,4 14,4.22385763 14,4.5 L14,7.5 C14,7.77614237 13.7761424,8 13.5,8 L8,8 L8,11 C8,11.2761424 7.77614237,11.5 7.5,11.5 C7.39181489,11.5 7.28654809,11.4649111 7.2,11.4 Z", id: "Combined-Shape" })))));
};
export default Left;
