import * as React from 'react';
import styled from '@emotion/styled/macro';
import { css, jsx } from "@emotion/core";
import { FormConfirmation, FormTheme } from '../../../../app/types';
import { FormTitle } from '../../../../app/components/FormHeader';
import FormPage, { RichFormTitle, RichTextDescription } from '../StyledComponents/FormPage';
import { FormDescription } from '../../../../app/components/FormHeader';
import FormPanel from '../StyledComponents/FormPanel';
import FormLogo from './FormLogo';
import InlineConfirmation from './InlineConfirmation';
import { MarkDownDescription } from '@smartsheet/forms-components';
import { isDarkBackground } from '../../../../app/utils';

// ClasssicLayout is used to render the Classic
// (also known as Branded).
interface ClassicLayoutProps {
  confirmation?: FormConfirmation;
  renderForm: (scrollToTop: () => void) => React.ReactNode;
  shouldRenderInlineConfirmation: boolean;
  shouldRenderLogo: boolean;
  description?: string;
  title?: string;
  formTheme?: FormTheme;
  richTitle?: string;
  richDescription?: string;
  isRichTextEnabled?: boolean;
}

interface ClassicTheme {
  formTheme?: FormTheme;
  hasRichText?: string;
}

const ClassicContainer = styled.section<ClassicTheme>`
  text-align: ${props => props.hasRichText ? 'initial' : 'center'};
  padding-bottom: 16px;
  width: inherit;
  max-width: 100vw;
  color: ${props => 
  (props.formTheme && props.formTheme.style
    && props.formTheme.style.background && props.formTheme.style.background.image) ? 'rgb(0, 0, 0)' : (isDarkBackground(
    (props.formTheme && props.formTheme.style
    && props.formTheme.style.background && props.formTheme.style.background.color)) ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)')};
`;

const ClassicLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 42px;
`;

const ClassicTitleAndDescriptionContainer = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

const ClassicLayout: React.FC<ClassicLayoutProps> = ({
  confirmation,
  description,
  renderForm,
  shouldRenderInlineConfirmation,
  shouldRenderLogo,
  title,
  richTitle,
  richDescription,
  isRichTextEnabled,
  formTheme
}) => (
  <FormPage>
    <ClassicContainer hasRichText={richTitle || richDescription} formTheme={formTheme}>
      <ClassicLogoContainer>
        {shouldRenderLogo && <FormLogo />}
      </ClassicLogoContainer>
      <ClassicTitleAndDescriptionContainer>
        {(isRichTextEnabled && richTitle &&
            <RichFormTitle
              dangerouslySetInnerHTML={{__html: richTitle}}
              data-client-id="form_title"/>)
        ||
        (title && <FormTitle data-client-id="form_title">{title}</FormTitle>)}
        {(isRichTextEnabled && richDescription &&
          <RichTextDescription className="rich-text-field-desc" dangerouslySetInnerHTML={{__html: richDescription}} data-client-id="form_description"/>) ||
          (description &&
          <FormDescription data-client-id="form_description">
            {MarkDownDescription(description)}
          </FormDescription>
        )}
      </ClassicTitleAndDescriptionContainer>
    </ClassicContainer>
    <FormPanel role="form">
      {shouldRenderInlineConfirmation && (
        <InlineConfirmation confirmation={confirmation} />
      )}
      {renderForm( () => window.scroll(0,0))}
    </FormPanel>
  </FormPage>
);

export default ClassicLayout;