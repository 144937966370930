import React from 'react';
var Sideways = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14px", height: "12px", viewBox: "0 0 14 12", version: "1.1", role: "img" },
        React.createElement("title", null, "Arrow Sideways"),
        React.createElement("desc", null, "Sideways arrow."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#F6A623" },
                React.createElement("path", { d: "M12.4,5.8 L7.4,12.4666667 C7.23431458,12.6875806 6.9209139,12.7323521 6.7,12.5666667 C6.66209717,12.5382395 6.62842712,12.5045695 6.6,12.4666667 L1.6,5.8 C1.43431458,5.5790861 1.4790861,5.26568542 1.7,5.1 C1.78654809,5.03508894 1.89181489,5 2,5 L5,5 L5,-0.5 C5,-0.776142375 5.22385763,-1 5.5,-1 L8.5,-1 C8.77614237,-1 9,-0.776142375 9,-0.5 L9,5 L12,5 C12.2761424,5 12.5,5.22385763 12.5,5.5 C12.5,5.60818511 12.4649111,5.71345191 12.4,5.8 Z", id: "Combined-Shape", transform: "translate(7.000000, 6.000000) rotate(-90.000000) translate(-7.000000, -6.000000) " })))));
};
export default Sideways;
