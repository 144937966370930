import { __assign, __makeTemplateObject } from "tslib";
import styled from '@emotion/styled';
import React from 'react';
import { Input } from '../TextInput';
import { Util } from './TextInputValidationUtil';
var TextAddOn = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-left: 8px;\n    padding-top: 6px;\n"], ["\n    padding-left: 8px;\n    padding-top: 6px;\n"])));
/**
 * Validate user input to accept only numbers.
 * Accepts positive and negative numbers, decimals, and scientific notation formatted input.
 */
var inputNumberValidation = /^-?\d*\.?\d*(e-?\d*)?$/;
var EmailNumberValidation = function (_a) {
    var _b;
    var title = _a.title, ariaInvalid = _a.ariaInvalid, tabIndex = _a.tabIndex, id = _a.id, dataClientId = _a.dataClientId, describedByIds = _a.describedByIds, type = _a.type, ref = _a.ref, name = _a.name, disabled = _a.disabled, value = _a.value, error = _a.error, validationType = _a.validationType, style = _a.style, onBlur = _a.onBlur, onChange = _a.onChange, handleKeyDown = _a.handleKeyDown;
    var InputTypes = Util.InputTypes, MaxLength = Util.MaxLength, ValidationTypes = Util.ValidationTypes;
    var width;
    var isPercentage = validationType === ValidationTypes.PERCENTAGE;
    // % and numeric field should match phone width
    if (type === InputTypes.NUMBER) {
        if (isPercentage) {
            width = '213px';
        }
        else {
            width = '193px';
        }
        /**
         * Form builder can set a default value.
         * If the input type is number,
         * only set this value if it passes number regex.
         */
        value = value && inputNumberValidation.test(value) ? value : "";
    }
    /**
     * If the input type is number, validate user input to only allow numbers.
     * Otherwise, allow any text input
     *
     * @param e the React.ChangeEvent
     */
    var onInput = function (e) {
        if (type !== InputTypes.NUMBER || inputNumberValidation.test(e.target.value)) {
            onChange(e);
        }
    };
    return (React.createElement("div", { style: { display: 'flex', width: width } },
        React.createElement(Input, __assign({ title: title, "aria-invalid": ariaInvalid }, (describedByIds && (_b = {}, _b['aria-describedby'] = describedByIds, _b)), { tabIndex: tabIndex, id: id, "data-testid": id, "data-client-id": dataClientId, "data-client-type": validationType }, ((type === InputTypes.NUMBER) && { pattern: "^-?\\d*\\.?\\d*(e-?\\d+)?$" }), { ref: ref, name: name, disabled: disabled, value: value, maxLength: MaxLength, onBlur: onBlur, onChange: onInput, onKeyDown: handleKeyDown, error: error, style: style })),
        isPercentage && React.createElement(TextAddOn, null, "%")));
};
export default EmailNumberValidation;
var templateObject_1;
