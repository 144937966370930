import React from 'react';
var No = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14px", height: "14px", viewBox: "0 0 14 14", version: "1.1", role: "img" },
        React.createElement("title", null, "Decision No"),
        React.createElement("desc", null, "No."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#D0011B" },
                React.createElement("polygon", { id: "Polygon", points: "9.89949494 -2.27373675e-13 14 4.10050506 14 9.89949494 9.89949494 14 4.10050506 14 0 9.89949494 0 4.10050506 4.10050506 -2.27373675e-13" })))));
};
export default No;
