import React from 'react';
var Severe = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18px", height: "18px", viewBox: "0 0 18 18", version: "1.1", role: "img" },
        React.createElement("title", null, "Pain Severe"),
        React.createElement("desc", null, "Severe Pain."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(1.000000, 1.000000)" },
                React.createElement("path", { d: "M8,0 C12.42,0 16,3.58 16,8 C16,12.42 12.42,16 8,16 C3.58,16 0,12.42 0,8 C0,3.58 3.58,0 8,0 Z", id: "Path", fill: "#444444" }),
                React.createElement("path", { d: "M8,1 C4.13228475,1 1,4.13228475 1,8 C1,11.8677153 4.13228475,15 8,15 C11.8677153,15 15,11.8677153 15,8 C15,4.13228475 11.8677153,1 8,1 Z", id: "Stroke-306", fill: "#EBAA67" }),
                React.createElement("path", { d: "M11.0839749,11.0273501 C11.0760794,11.0155069 10.980774,10.9375298 10.7405404,10.8345725 C10.2607652,10.6289546 9.53002254,10.5 8.5,10.5 C7.4309981,10.5 6.28021907,10.5920623 5.12858186,10.745614 C4.72483921,10.7994463 4.35007361,10.8571025 4.01416941,10.9146861 C3.81379526,10.949036 3.67181412,10.9755391 3.59805807,10.9902903 C3.32727819,11.0444463 3.06386564,10.8688379 3.00970966,10.5980581 C2.95555369,10.3272782 3.13116206,10.0638656 3.40194193,10.0097097 C3.48443588,9.99321087 3.63542349,9.96502652 3.84520559,9.92906387 C4.19289514,9.86945995 4.57984829,9.8099287 4.99641814,9.75438605 C6.18853093,9.59543768 7.3815019,9.5 8.5,9.5 C9.65747746,9.5 10.5204848,9.65229542 11.1344596,9.91542748 C11.550476,10.0937202 11.7989206,10.2969931 11.9160251,10.4726499 C12.0692014,10.7024142 12.0071144,11.0128489 11.7773501,11.1660251 C11.5475858,11.3192014 11.2371511,11.2571144 11.0839749,11.0273501 Z M6,6 C6.55333333,6 7,6.44666667 7,7 C7,7.55333333 6.55333333,8 6,8 C5.44666667,8 5,7.55333333 5,7 C5,6.44666667 5.44666667,6 6,6 Z M10,6 C10.5533333,6 11,6.44666667 11,7 C11,7.55333333 10.5533333,8 10,8 C9.44666667,8 9,7.55333333 9,7 C9,6.44666667 9.44666667,6 10,6 Z M10.2763932,3.9472136 C10.029404,3.82371897 9.92929178,3.52338245 10.0527864,3.2763932 C10.176281,3.02940395 10.4766175,2.92929178 10.7236068,3.0527864 L12.7236068,4.0527864 C12.970596,4.17628103 13.0707082,4.47661755 12.9472136,4.7236068 C12.823719,4.97059605 12.5233825,5.07070822 12.2763932,4.9472136 L10.2763932,3.9472136 Z M5.7236068,3.9472136 L3.7236068,4.9472136 C3.47661755,5.07070822 3.17628103,4.97059605 3.0527864,4.7236068 C2.92929178,4.47661755 3.02940395,4.17628103 3.2763932,4.0527864 L5.2763932,3.0527864 C5.52338245,2.92929178 5.82371897,3.02940395 5.9472136,3.2763932 C6.07070822,3.52338245 5.97059605,3.82371897 5.7236068,3.9472136 Z", id: "Combined-Shape", fillOpacity: "0.7", fill: "#000000" })))));
};
export default Severe;
