import React from 'react';
var Rainy = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18px", height: "16px", viewBox: "0 0 18 16", version: "1.1", role: "img" },
        React.createElement("title", null, "Weather Rainy"),
        React.createElement("desc", null, "Rainy Weather."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(1.000000, 1.000000)" },
                React.createElement("g", { fill: "#CCCCCC" },
                    React.createElement("path", { d: "M3.5,10 C1.56700338,10 0,8.43299662 0,6.5 C0,4.7259908 1.31983451,3.26024546 3.03113375,3.03113375 C3.26024546,1.31983451 4.7259908,0 6.5,0 C7.68314501,0 8.72917444,0.587061631 9.36268613,1.48578275 C9.83348619,1.17856066 10.3958906,1 11,1 C12.6568542,1 14,2.34314575 14,4 C15.6568542,4 17,5.34314575 17,7 C17,8.65685425 15.6568542,10 14,10 L3.5,10 Z" })),
                React.createElement("g", { transform: "translate(1.000000, 10.000000)", fill: "#0073EC" },
                    React.createElement("path", { d: "M2.64644661,0.646446609 C2.84170876,0.451184464 3.15829124,0.451184464 3.35355339,0.646446609 C3.54881554,0.841708755 3.54881554,1.15829124 3.35355339,1.35355339 L1.35355339,3.35355339 C1.15829124,3.54881554 0.841708755,3.54881554 0.646446609,3.35355339 C0.451184464,3.15829124 0.451184464,2.84170876 0.646446609,2.64644661 L2.64644661,0.646446609 Z M5.64644661,0.646446609 C5.84170876,0.451184464 6.15829124,0.451184464 6.35355339,0.646446609 C6.54881554,0.841708755 6.54881554,1.15829124 6.35355339,1.35355339 L4.35355339,3.35355339 C4.15829124,3.54881554 3.84170876,3.54881554 3.64644661,3.35355339 C3.45118446,3.15829124 3.45118446,2.84170876 3.64644661,2.64644661 L5.64644661,0.646446609 Z M8.64644661,0.646446609 C8.84170876,0.451184464 9.15829124,0.451184464 9.35355339,0.646446609 C9.54881554,0.841708755 9.54881554,1.15829124 9.35355339,1.35355339 L7.35355339,3.35355339 C7.15829124,3.54881554 6.84170876,3.54881554 6.64644661,3.35355339 C6.45118446,3.15829124 6.45118446,2.84170876 6.64644661,2.64644661 L8.64644661,0.646446609 Z M11.6464466,0.646446609 C11.8417088,0.451184464 12.1582912,0.451184464 12.3535534,0.646446609 C12.5488155,0.841708755 12.5488155,1.15829124 12.3535534,1.35355339 L10.3535534,3.35355339 C10.1582912,3.54881554 9.84170876,3.54881554 9.64644661,3.35355339 C9.45118446,3.15829124 9.45118446,2.84170876 9.64644661,2.64644661 L11.6464466,0.646446609 Z" }))))));
};
export default Rainy;
