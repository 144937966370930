import React from 'react';
var AngleUp = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "13px", height: "13px", viewBox: "0 0 13 13", version: "1.1", role: "img" },
        React.createElement("title", null, "Arrow Angle Up"),
        React.createElement("desc", null, "Angle Up."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(1.000000, 0.000000)", fill: "#F6A623" },
                React.createElement("path", { d: "M4.59997559,6.18545403 L2.2044044,4.37975023 C1.98388992,4.21353358 1.93987284,3.90002605 2.10608949,3.67951157 C2.17115837,3.59318671 2.26245195,3.53027508 2.36628729,3.50020578 L10.360921,1.18506858 C10.6261655,1.10825738 10.9034565,1.26101276 10.9802677,1.52625727 C10.993357,1.5714574 11,1.61827902 11,1.66533625 L11,9.99901868 C11,10.2751611 10.7761424,10.4990187 10.5,10.4990187 C10.3916842,10.4990187 10.2862975,10.463845 10.1996945,10.3987894 L7.79542241,8.59271413 L4.48589421,12.9846064 C4.31970758,13.2051435 4.00620604,13.2492033 3.78566894,13.0830166 C3.78546808,13.0828653 3.78526733,13.0827138 3.7850667,13.0825621 L1.39768298,11.2778847 C1.17785836,11.1117143 1.13391951,10.7990111 1.29943776,10.578695 L4.59997559,6.18545403 Z", id: "Combined-Shape" })))));
};
export default AngleUp;
