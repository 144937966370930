/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/core';
import { MarkDownDescription } from '@smartsheet/forms-components';
import FormLogo from '../FormLogo';
import { PlainDescription, PlainFormTitle, RichFormTitle, RichTextDescription, StyledStaticSection } from '../../StyledComponents/FormPage';

interface StaticSectionProps {
  backgroundImage?: string;
  description?: string;
  textColor: string;
  title?: string;
  richTitle?: string;
  richDescription?: string;
  isRichTextEnabled?: boolean;
}

const StaticSection: React.FC<StaticSectionProps> = ({
  backgroundImage,
  description,
  textColor,
  title,
  richTitle,
  richDescription,
  isRichTextEnabled
}) => (
  <StyledStaticSection
    backgroundImage={backgroundImage}
    data-client-id="form_static_section"
    aria-label="form title and description"
  >
    <div
      css={{
        alignItems: 'flex-start',
        color: textColor,
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto 0',
        maxWidth: 576,
        width: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-start',
          minHeight: '80px',
        }}
      >
        <FormLogo backgroundPosition="left" />
      </div>

      {/* Form Title */}
      {(isRichTextEnabled && richTitle &&
      <RichFormTitle dangerouslySetInnerHTML={{__html: richTitle}} data-client-id="form_title"/>) ||
        (title &&
        <PlainFormTitle data-client-id="form_title">
          {title}
        </PlainFormTitle>)}

      {/* Form Description */}
      {(isRichTextEnabled && richDescription &&
        <RichTextDescription
          className="rich-text-field-desc"
          dangerouslySetInnerHTML={{__html: richDescription}}
          data-client-id="form_description"/>
        ) ||
        (description &&
        <PlainDescription data-client-id='form_description'>
          {MarkDownDescription(description)}
        </PlainDescription>
      )}
    </div>
  </StyledStaticSection>
);

export default StaticSection;