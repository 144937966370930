import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface ModalPortalProps {
  children?: React.ReactNode;
}

class ModalPortal extends React.Component<ModalPortalProps> {
  private el: Element = document.createElement('div');

  public componentDidMount() {
    const modalRoot = document.getElementById('modal-root') as Element;
    modalRoot.appendChild(this.el);
  }

  public componentWillUnmount() {
    const modalRoot = document.getElementById('modal-root') as Element;
    modalRoot.removeChild(this.el);
  }

  public render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default ModalPortal;
