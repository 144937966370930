import React from 'react';

const ModernLogoOnLight: React.FC = () => {
    return(
        <svg role="img" xmlns="http://www.w3.org/2000/svg" version="1.1" width="80" height="35" viewBox="0 -55 406 179" style={{marginTop: "2px", marginLeft: "5px"}}>
            <title id="smartsheet-modern-logo-light">Smartsheet Modern Logo On Light</title>
  <path fill="#003059" d="M85.4,29.7c-0.8-0.9-1.7-1.7-2.8-2.3c-1.2-0.6-2.6-1-4-0.9c-1.4,0-2.7,0.3-3.9,0.9c-1,0.5-1.7,1.6-1.6,2.7     c0,0.9,0.3,1.7,1,2.3c0.6,0.6,1.4,1.1,2.2,1.4c0.9,0.4,1.8,0.7,2.8,0.8l2.5,0.5c1.3,0.3,2.6,0.7,3.8,1.2c1.1,0.4,2.2,1,3.1,1.8     c0.9,0.7,1.5,1.7,2,2.7c0.5,1.2,0.8,2.5,0.7,3.9c0,1.7-0.4,3.3-1.2,4.7c-0.8,1.3-1.8,2.4-3.1,3.2c-1.3,0.9-2.8,1.5-4.3,1.8     c-1.6,0.4-3.2,0.6-4.9,0.5c-2.5,0.1-4.9-0.4-7.2-1.2c-2.2-1-4.2-2.5-5.6-4.6l4.8-3.9c1,1,2.2,1.9,3.4,2.7c1.4,0.8,3,1.2,4.6,1.2     c0.8,0,1.6-0.1,2.4-0.2c0.8-0.1,1.5-0.4,2.2-0.8c0.6-0.3,1.1-0.8,1.5-1.3c0.4-0.5,0.6-1.2,0.6-1.8c0-0.8-0.3-1.7-0.9-2.3     c-0.6-0.6-1.3-1.1-2.1-1.4c-0.8-0.3-1.7-0.6-2.6-0.8c-0.9-0.2-1.7-0.4-2.4-0.5c-1.4-0.3-2.6-0.7-3.9-1.1c-1.2-0.4-2.2-0.9-3.2-1.7     c-0.9-0.7-1.7-1.6-2.2-2.6c-0.6-1.2-0.9-2.6-0.8-3.9c0-1.6,0.3-3.1,1.1-4.4c0.7-1.3,1.7-2.3,2.9-3.1c1.2-0.9,2.6-1.5,4.1-1.9     c1.5-0.4,3-0.6,4.6-0.6c2.2,0,4.4,0.4,6.4,1.2c2,0.8,3.8,2.3,4.9,4.1L85.4,29.7z"/>
  <path fill="#003059" d="M94.1,21.5h5.9v5.1h0.1c0.3-0.6,0.6-1.2,1.1-1.7c0.7-0.8,1.4-1.4,2.2-2c1-0.7,2.1-1.2,3.2-1.6     c1.3-0.5,2.7-0.7,4.1-0.7c2.2-0.1,4.3,0.5,6.2,1.5c1.8,1.1,3.2,2.7,4.1,4.6c1-2,2.6-3.6,4.6-4.6c1.8-1,3.9-1.5,6-1.5     c2.2-0.1,4.3,0.3,6.3,1.2c1.5,0.7,2.9,1.9,3.9,3.3c1,1.4,1.6,3,1.9,4.6c0.4,1.8,0.5,3.6,0.5,5.4v19h-6.3V35.9c0-1.2-0.1-2.4-0.2-3.6     c-0.1-1.1-0.5-2.1-1-3c-0.5-0.9-1.3-1.6-2.2-2.1c-1.2-0.6-2.4-0.8-3.7-0.8c-3,0-5.2,0.9-6.5,2.8c-1.4,2.1-2.1,4.6-2,7.1v17.9H116     V37.1c0-1.4-0.1-2.9-0.2-4.3c-0.1-1.2-0.5-2.3-1-3.4c-0.5-0.9-1.2-1.7-2.1-2.2c-1.1-0.6-2.4-0.8-3.7-0.8c-1.1,0-2.2,0.2-3.3,0.7     c-1.1,0.4-2,1.1-2.8,2c-0.9,1-1.5,2.1-1.9,3.4c-0.5,1.6-0.7,3.2-0.7,4.8v17h-6.3V21.5z"/>
  <path fill="#003059" d="M148.9,25.5c1.8-1.6,3.9-2.8,6.2-3.6c2.3-0.8,4.6-1.2,7-1.2c2.1,0,4.2,0.3,6.3,0.9c1.6,0.5,3.1,1.3,4.3,2.4     c1.1,1,1.9,2.1,2.5,3.5c0.5,1.3,0.8,2.7,0.8,4.1v16.7c0,1.1,0,2.2,0.1,3.2s0.1,1.9,0.2,2.8h-5.6c-0.1-1.6-0.2-3.3-0.2-5h-0.1     c-1.2,1.9-2.9,3.5-5,4.5c-2.1,0.9-4.4,1.4-6.6,1.3c-1.5,0-3-0.2-4.4-0.6c-1.3-0.4-2.6-1-3.7-1.9c-1-0.8-1.9-1.9-2.5-3.1     c-0.6-1.3-0.9-2.8-0.9-4.2c-0.1-1.9,0.4-3.7,1.4-5.3c1-1.5,2.3-2.7,3.9-3.5c1.8-1,3.8-1.6,5.8-1.9c2.3-0.4,4.7-0.6,7.1-0.6h4.7v-1.4     c0-0.9-0.2-1.7-0.5-2.5c-0.3-0.8-0.8-1.6-1.5-2.2c-0.7-0.7-1.5-1.2-2.4-1.6c-1.1-0.4-2.3-0.6-3.5-0.6c-1.1,0-2.1,0.1-3.2,0.4     c-0.9,0.2-1.7,0.5-2.5,0.9c-0.7,0.3-1.4,0.7-2,1.2l-1.8,1.3L148.9,25.5z M166.7,38.5c-1.5,0-3.1,0.1-4.6,0.2     c-1.5,0.1-2.9,0.4-4.3,0.9c-1.2,0.4-2.3,1.1-3.1,1.9c-0.8,0.8-1.3,2-1.2,3.2c-0.1,1.6,0.6,3.2,1.9,4.1c1.6,0.9,3.4,1.3,5.2,1.2     c1.5,0,3-0.3,4.4-0.9c1.1-0.5,2.1-1.3,2.9-2.2c0.7-0.9,1.3-2,1.6-3.1c0.3-1.1,0.5-2.2,0.5-3.3v-2.1L166.7,38.5z"/>
  <path fill="#003059" d="M250.9,29.7c-0.8-0.9-1.7-1.7-2.8-2.3c-1.2-0.6-2.6-1-4-0.9c-1.4,0-2.7,0.3-3.9,0.9c-1,0.5-1.7,1.6-1.6,2.7     c0,0.9,0.3,1.7,1,2.3c0.7,0.6,1.4,1.1,2.2,1.4c0.9,0.4,1.8,0.7,2.8,0.8l2.5,0.5c1.3,0.3,2.6,0.7,3.8,1.2c1.1,0.4,2.2,1,3.1,1.8     c0.9,0.7,1.5,1.7,2,2.7c0.5,1.2,0.8,2.5,0.7,3.9c0,1.7-0.4,3.3-1.2,4.7c-0.8,1.3-1.8,2.4-3.1,3.2c-1.3,0.9-2.8,1.5-4.3,1.8     c-1.6,0.4-3.2,0.6-4.9,0.5c-2.5,0.1-4.9-0.4-7.2-1.2c-2.2-1-4.2-2.5-5.6-4.6l4.8-3.9c1,1,2.2,1.9,3.4,2.7c1.4,0.8,3,1.2,4.6,1.2     c0.8,0,1.6-0.1,2.4-0.2c0.8-0.1,1.5-0.4,2.2-0.8c0.6-0.3,1.1-0.8,1.5-1.3c0.4-0.5,0.6-1.2,0.6-1.8c0-0.8-0.3-1.7-0.9-2.3     c-0.6-0.6-1.3-1.1-2.1-1.4c-0.8-0.3-1.7-0.6-2.6-0.8c-0.9-0.2-1.7-0.4-2.4-0.5c-1.4-0.3-2.6-0.7-3.9-1.1c-1.2-0.4-2.2-0.9-3.2-1.7     c-0.9-0.7-1.7-1.6-2.2-2.6c-0.6-1.2-0.9-2.6-0.8-3.9c0-1.6,0.3-3.1,1.1-4.4c0.7-1.3,1.7-2.3,2.9-3.1c1.2-0.9,2.6-1.5,4.1-1.9     c1.5-0.4,3-0.6,4.6-0.6c2.2,0,4.4,0.4,6.4,1.2c2,0.8,3.8,2.3,4.9,4.1L250.9,29.7z"/>
  <path fill="#003059" d="M259.8,2h6.3v24.5h0.1c0.9-1.8,2.3-3.3,4.1-4.2c2.1-1.1,4.4-1.7,6.8-1.6c1.6,0,3.1,0.2,4.6,0.7     c1.4,0.5,2.7,1.2,3.9,2.2c1.2,1.1,2,2.4,2.6,3.9c0.7,1.8,1,3.7,1,5.6v21.1H283V34.8c0-1.3-0.2-2.6-0.6-3.9c-0.4-1-0.9-1.9-1.7-2.6     c-0.7-0.7-1.5-1.2-2.4-1.4c-0.9-0.3-1.9-0.5-2.8-0.4c-1.2,0-2.5,0.2-3.6,0.6c-1.1,0.4-2.1,1.1-2.9,2c-0.9,1-1.6,2.1-2,3.4     c-0.5,1.6-0.7,3.2-0.7,4.9v16.8h-6.3L259.8,2z"/>
  <path fill="#003059" d="M299.2,40.1c0,1.4,0.3,2.7,0.9,3.9c0.6,1.2,1.5,2.2,2.5,3c1.1,0.9,2.3,1.5,3.6,1.9c1.4,0.5,2.8,0.7,4.2,0.7     c1.8,0,3.6-0.4,5.1-1.4c1.5-1,2.9-2.2,4-3.5l4.8,3.6c-3.5,4.5-8.4,6.7-14.7,6.7c-2.4,0-4.8-0.4-7.1-1.3c-2-0.8-3.9-2-5.4-3.6     c-1.5-1.6-2.6-3.4-3.4-5.5c-0.8-2.2-1.2-4.5-1.2-6.8c0-2.3,0.4-4.6,1.3-6.8c0.8-2,2-3.9,3.6-5.4c1.5-1.6,3.4-2.8,5.4-3.6     c2.2-0.9,4.5-1.3,6.9-1.3c2.6-0.1,5.1,0.4,7.5,1.5c1.9,0.9,3.6,2.3,5,4c1.3,1.6,2.3,3.5,2.8,5.5c0.6,2,0.9,4.1,0.9,6.2v2.2     L299.2,40.1z M319.1,35.1c0-1.3-0.3-2.6-0.7-3.8c-0.4-1.1-1-2.1-1.8-3c-0.8-0.9-1.9-1.6-3-2c-1.3-0.5-2.7-0.8-4.2-0.7     c-1.5,0-2.9,0.3-4.2,0.9c-1.2,0.5-2.3,1.3-3.3,2.2c-0.9,0.9-1.6,1.9-2.1,3.1c-0.5,1.1-0.7,2.2-0.7,3.3L319.1,35.1z"/>
  <path fill="#003059" d="M336.1,40.1c0,1.4,0.3,2.7,1,3.9c0.6,1.2,1.5,2.2,2.5,3c1.1,0.9,2.3,1.5,3.6,1.9c1.4,0.5,2.8,0.7,4.2,0.7     c1.8,0,3.6-0.4,5.1-1.4c1.5-1,2.9-2.2,4-3.5l4.8,3.6c-3.5,4.5-8.4,6.7-14.7,6.7c-2.4,0-4.8-0.4-7.1-1.3c-2-0.8-3.9-2-5.4-3.6     c-1.5-1.6-2.6-3.4-3.4-5.5c-0.8-2.2-1.2-4.5-1.2-6.8c0-2.3,0.4-4.6,1.3-6.8c0.8-2,2-3.9,3.6-5.4c1.5-1.6,3.4-2.8,5.4-3.6     c2.2-0.9,4.5-1.3,6.9-1.3c2.6-0.1,5.1,0.4,7.5,1.5c1.9,1,3.6,2.3,5,4c1.3,1.6,2.3,3.5,2.8,5.5c0.6,2,0.9,4.1,0.9,6.2v2.2L336.1,40.1     z M355.9,35.1c0-1.3-0.2-2.6-0.7-3.8c-0.4-1.1-1-2.1-1.8-3c-0.8-0.9-1.9-1.6-3-2c-1.3-0.5-2.7-0.8-4.2-0.7c-1.5,0-2.9,0.3-4.2,0.9     c-1.2,0.5-2.3,1.3-3.3,2.2c-0.9,0.9-1.6,1.9-2.1,3.1c-0.5,1-0.7,2.2-0.7,3.3L355.9,35.1z"/>
  <path fill="#003059" d="M200.5,21.5c-1.9-0.3-3.8-0.4-5.8-0.4c-1.6,0-3.1,0.4-4.5,1.2c-0.9,0.5-1.7,1.1-2.4,1.8     c-0.7,0.7-1.3,1.5-1.7,2.4h-0.2v-5h-6.3v32.6h6.3c0,0-0.2-13.5,0-16.7s0.8-6.7,2.5-8.3c1.2-1.4,3-2.2,4.8-2.3c1.6-0.2,3.2-0.2,4.7,0     L200.5,21.5z"/>
  <path fill="#003059" d="M223.7,49c-0.8,0.1-1.5,0.2-2.3,0.2c-1,0.1-1.9-0.2-2.8-0.6c-0.6-0.4-1.1-1-1.4-1.7c-0.3-0.8-0.5-1.6-0.5-2.4     c0-0.9-0.1-1.8-0.1-2.7V26.8h9v-5.4h-9v-9.2h-6.3v9.2h-6.3l-2.4,5.4h8.7v17c0,1.3,0.1,2.7,0.2,4c0.1,1.3,0.5,2.5,1.1,3.5     c0.7,1.1,1.7,2,2.9,2.6c1.8,0.8,3.7,1.1,5.6,1c0.9,0,1.8-0.1,2.8-0.2c1-0.1,2-0.4,2.9-0.9v-5.6C225.3,48.6,224.5,48.9,223.7,49z"/>
  <path fill="#003059" d="M384.8,49c-0.8,0.1-1.5,0.2-2.3,0.2c-1,0.1-1.9-0.2-2.8-0.6c-0.6-0.4-1.1-1-1.4-1.7c-0.3-0.8-0.5-1.6-0.5-2.4     c0-0.9-0.1-1.8-0.1-2.7V26.8h9v-5.4h-9v-9.2h-6.3v9.2h-6.3l-2.4,5.4h8.7v17c0,1.3,0.1,2.7,0.2,4c0.1,1.3,0.5,2.5,1.1,3.5     c0.7,1.1,1.7,2,2.9,2.6c1.8,0.8,3.7,1.1,5.6,1c0.9,0,1.8-0.1,2.8-0.2c1-0.1,2-0.4,2.9-0.9v-5.6C386.3,48.6,385.5,48.9,384.8,49z"/>
  <path fill="#003059" d="M8.4,32.9c0,0,2.1-3.2,5-3.2c4.4-0.1,8,8.1,8.6,9.3c4.9-11.3,13-20.9,19.6-27.3H1.1c0,3.9,0,43.5,0,46.8     c0,1.8-0.2,3.5-0.5,5.2c5.3-0.9,10.5-2.2,15.5-4c12.3-4.6,17.1-9.4,17.1-9.4l0.3-0.3l0.2,0.4c0.8,1.9,1.9,3.7,3.1,5.4     c1.7,2.1,2.6,2.3,3.2,2.1c0.7-0.2,1.1-1.4,1.3-3.3s0.3-6.1,0.3-11.3V15.3C29.8,30.4,21,53.1,21,53.1S15.2,32.8,8.4,32.9z"/>
</svg>
    )
}

export default ModernLogoOnLight;