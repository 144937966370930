import { __makeTemplateObject } from "tslib";
import { css } from "@emotion/core";
import _isEqual from "lodash.isequal";
import _cloneDeep from "lodash.clonedeep";
import React from "react";
import { FormattedMessage } from "react-intl";
import { createPropsGetter } from "../utils";
import { sanitizeHtmlID } from "../utils/stringUtils";
import InputLabelWithLogic from "../InputLabelWithLogic";
import InputDescription from "../InputDescription";
import WarningImageIcon from "../WarningImageIcon";
import CheckboxElement from "./CheckboxElement";
import { CheckboxGroup, ErrorSpan, FieldLegend, FieldSet, HorizontalCheckboxGroup, MultiCheckboxLabel } from "./MultiCheckboxInput.styled";
export var MultiCheckboxDirection;
(function (MultiCheckboxDirection) {
    MultiCheckboxDirection["HORIZONTAL"] = "HORIZONTAL";
    MultiCheckboxDirection["VERTICAL"] = "VERTICAL";
})(MultiCheckboxDirection || (MultiCheckboxDirection = {}));
var defaultProps = {
    /** Should it show a source logic tag in builder. */
    hasSourceLogic: false,
    /** If required, will show required asterisk */
    required: false,
    /** Formik param to check dirty state */
    touched: false,
    /** If disabled, the input will be read only. */
    disabled: false,
    /** If the component is hidden, remove it from tab order
     * NOTE: This doesn't effect the rendering. The parent is responsible for
     * hiding the element.
     * See ConditionalDisplay/index.tsx for how this happens.
     */
    hidden: false,
    /** Determine if the checkboxes should be stacked vertically or horizontally
     *  in rows. */
    direction: MultiCheckboxDirection.VERTICAL
};
var getProps = createPropsGetter(defaultProps);
var MultiCheckboxInput = function (props) {
    var _a = getProps(props), disabled = _a.disabled, error = _a.error, hidden = _a.hidden, id = _a.id, isHiddenByConditionalLogic = _a.isHiddenByConditionalLogic, label = _a.label, logic = _a.logic, hasSourceLogic = _a.hasSourceLogic, name = _a.name, onChange = _a.onChange, onSourceLogicTagClick = _a.onSourceLogicTagClick, description = _a.description, richDescription = _a.richDescription, isRichTextEnabled = _a.isRichTextEnabled, values = _a.values, direction = _a.direction, required = _a.required, componentKey = _a.componentKey;
    // Sheet doesn't allow duplicates in multi dropdown - so filter out
    var uniqueValues = values.filter(function (value, index, array) { return array.findIndex(function (t) { return (t.value === value.value); }) === index; });
    var dataClientIdSuffix = !!label ? label : name;
    var descriptionElemId = "description_" + sanitizeHtmlID(dataClientIdSuffix);
    var errorElemId = "error_msg_" + sanitizeHtmlID(dataClientIdSuffix);
    var allowLabelClick = function (e) {
        e.stopPropagation();
    };
    // Curried change handler which generates a change handler function for the
    // given value. Returned function accepts a boolean.
    var createChangeHandler = function (value, selectedIndex) { return function (checked) {
        // Ignore if `onChange` not set.
        if (!onChange)
            return;
        var valuesClone = _cloneDeep(uniqueValues);
        var option = valuesClone.find(function (opt, index) { return _isEqual(index, selectedIndex); });
        if (option) {
            option.checked = checked;
        }
        onChange(valuesClone);
    }; };
    var mapOption = function (option, index) {
        var value = option.value, checked = option.checked;
        var key = typeof value == "string" ? value : value.email;
        var label = typeof value == "string" ? value : ((value.name ? value.name : '') + (value.email ?
            ' (' + value.email + ')' : ''));
        var labelId = componentKey + '_multi_checkbox_value_' + index + '_' + key;
        // TODO FIX Keys: we have key, id, label, and name. Way tooooo many.
        return (React.createElement(CheckboxElement, { key: labelId, id: labelId, label: label, name: name, checked: checked, disabled: disabled, hidden: hidden, onChange: createChangeHandler(value, index), errorMessageElemId: error ? errorElemId : undefined, descriptionElemId: description ? descriptionElemId : undefined },
            React.createElement(MultiCheckboxLabel, { onClick: allowLabelClick, htmlFor: labelId }, label)));
    };
    var CheckboxGroupComponent = direction === MultiCheckboxDirection.HORIZONTAL
        ? HorizontalCheckboxGroup
        : CheckboxGroup;
    return (React.createElement(FieldSet, { tabIndex: isHiddenByConditionalLogic ? -1 : 0, id: id, "data-client-id": "container_" + dataClientIdSuffix, "data-client-type": "multicheckbox" },
        React.createElement(FieldLegend, null,
            React.createElement(InputLabelWithLogic, { required: required, logic: logic, hasSourceLogic: hasSourceLogic, onSourceLogicTagClick: onSourceLogicTagClick, dataClientIdSuffix: dataClientIdSuffix }, label || name)),
        (isRichTextEnabled && richDescription) && React.createElement("div", { className: "rich-text-field-desc", id: descriptionElemId, dangerouslySetInnerHTML: { __html: richDescription } }) ||
            description && (React.createElement(InputDescription, { id: descriptionElemId }, description)),
        React.createElement(CheckboxGroupComponent, null, uniqueValues.map(mapOption)),
        error && (React.createElement(ErrorSpan, null,
            React.createElement(WarningImageIcon, { customCss: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n                            margin-right: 0.3rem;\n                        "], ["\n                            margin-right: 0.3rem;\n                        "]))) }),
            React.createElement("span", { id: errorElemId, role: "alert" },
                React.createElement(FormattedMessage, { id: "validations.checkbox_required", values: { 0: label } }))))));
};
export default MultiCheckboxInput;
var templateObject_1;
