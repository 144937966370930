/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/core';
import { FormConfirmation, FormConfirmationType } from '../../../../app/types';
import ConfirmationMessage from '../../../../app/components/ConfirmationMessage';

interface InlineConfirmationProps {
  confirmation?: FormConfirmation;
}

const InlineConfirmation: React.FC<InlineConfirmationProps> = ({
  confirmation,
}) => {
  React.useEffect(() => {window.scroll(0,0)}, [confirmation]);

  const message =
    confirmation && confirmation.type !== FormConfirmationType.REDIRECT
      ? confirmation.message
      : '';

  return (
    <div
      css={{
        alignItems: 'center',
        backgroundColor: '#cce3fb',
        borderRadius: '2px',
        color: '#161616',
        display: 'flex',
        fontFamily: "'Roboto', sans-serif",
        fontSize: '13px',
        fontWeight: 'normal',
        marginBottom: '1.5rem',
        padding: '7px 10px',
        textAlign: 'left',
      }}
    >
      <ConfirmationMessage message={message} showCheckmark />
    </div>
  );
};

export default InlineConfirmation;
