import React from 'react';
var Unchanged = function () {
    return (React.createElement("svg", { style: { marginRight: "1px" }, xmlns: "http://www.w3.org/2000/svg", width: "13px", height: "3px", viewBox: "0 0 13 3", version: "1.1", role: "img" },
        React.createElement("title", null, "Directions Unchanged"),
        React.createElement("desc", null, "Unchanged."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(2.000000, 0.000000)", fill: "#F6A623" },
                React.createElement("rect", { id: "Rectangle", x: "0", y: "0", width: "10", height: "3" })))));
};
export default Unchanged;
