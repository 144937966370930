import React from 'react';
var EmptyStar = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "59px", height: "12px", viewBox: "0 0 59 12", version: "1.1", role: "img" },
        React.createElement("title", null, "Star Empty"),
        React.createElement("desc", null, "Empty Star."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(36.000000, 1.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M5.5,8.83928571 L3.1700891,10.1516857 C2.92949073,10.2872107 2.62458246,10.202032 2.48905742,9.96143363 C2.43359089,9.8629636 2.41312082,9.74860682 2.43098508,9.63701045 L2.88459458,6.80335364 L0.932143943,4.764244 C0.741166051,4.5647896 0.748037615,4.2482817 0.947492011,4.0573038 C1.021406,3.98653104 1.11525585,3.94014198 1.21637084,3.92439962 L3.88359056,3.50914636 L5.04586303,0.985909285 C5.16139437,0.735096365 5.45837507,0.625428973 5.70918799,0.740960311 C5.81741703,0.790813588 5.90428369,0.877680245 5.95413697,0.985909285 L7.11640944,3.50914636 L9.78362916,3.92439962 C10.0564845,3.96687984 10.2432405,4.22250982 10.2007602,4.49536517 C10.1850179,4.59648016 10.1386288,4.69033001 10.0678561,4.764244 L8.11540542,6.80335364 L8.56901492,9.63701045 C8.61266385,9.90968128 8.42700492,10.1661091 8.15433409,10.209758 C8.04273772,10.2276223 7.92838093,10.2071522 7.8299109,10.1516857 L5.5,8.83928571 Z", id: "Star" })),
            React.createElement("g", { transform: "translate(48.000000, 1.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M5.5,8.83928571 L3.1700891,10.1516857 C2.92949073,10.2872107 2.62458246,10.202032 2.48905742,9.96143363 C2.43359089,9.8629636 2.41312082,9.74860682 2.43098508,9.63701045 L2.88459458,6.80335364 L0.932143943,4.764244 C0.741166051,4.5647896 0.748037615,4.2482817 0.947492011,4.0573038 C1.021406,3.98653104 1.11525585,3.94014198 1.21637084,3.92439962 L3.88359056,3.50914636 L5.04586303,0.985909285 C5.16139437,0.735096365 5.45837507,0.625428973 5.70918799,0.740960311 C5.81741703,0.790813588 5.90428369,0.877680245 5.95413697,0.985909285 L7.11640944,3.50914636 L9.78362916,3.92439962 C10.0564845,3.96687984 10.2432405,4.22250982 10.2007602,4.49536517 C10.1850179,4.59648016 10.1386288,4.69033001 10.0678561,4.764244 L8.11540542,6.80335364 L8.56901492,9.63701045 C8.61266385,9.90968128 8.42700492,10.1661091 8.15433409,10.209758 C8.04273772,10.2276223 7.92838093,10.2071522 7.8299109,10.1516857 L5.5,8.83928571 Z", id: "Star" })),
            React.createElement("g", { transform: "translate(24.000000, 1.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M5.5,8.83928571 L3.1700891,10.1516857 C2.92949073,10.2872107 2.62458246,10.202032 2.48905742,9.96143363 C2.43359089,9.8629636 2.41312082,9.74860682 2.43098508,9.63701045 L2.88459458,6.80335364 L0.932143943,4.764244 C0.741166051,4.5647896 0.748037615,4.2482817 0.947492011,4.0573038 C1.021406,3.98653104 1.11525585,3.94014198 1.21637084,3.92439962 L3.88359056,3.50914636 L5.04586303,0.985909285 C5.16139437,0.735096365 5.45837507,0.625428973 5.70918799,0.740960311 C5.81741703,0.790813588 5.90428369,0.877680245 5.95413697,0.985909285 L7.11640944,3.50914636 L9.78362916,3.92439962 C10.0564845,3.96687984 10.2432405,4.22250982 10.2007602,4.49536517 C10.1850179,4.59648016 10.1386288,4.69033001 10.0678561,4.764244 L8.11540542,6.80335364 L8.56901492,9.63701045 C8.61266385,9.90968128 8.42700492,10.1661091 8.15433409,10.209758 C8.04273772,10.2276223 7.92838093,10.2071522 7.8299109,10.1516857 L5.5,8.83928571 Z", id: "Star" })),
            React.createElement("g", { transform: "translate(12.000000, 1.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M5.5,8.83928571 L3.1700891,10.1516857 C2.92949073,10.2872107 2.62458246,10.202032 2.48905742,9.96143363 C2.43359089,9.8629636 2.41312082,9.74860682 2.43098508,9.63701045 L2.88459458,6.80335364 L0.932143943,4.764244 C0.741166051,4.5647896 0.748037615,4.2482817 0.947492011,4.0573038 C1.021406,3.98653104 1.11525585,3.94014198 1.21637084,3.92439962 L3.88359056,3.50914636 L5.04586303,0.985909285 C5.16139437,0.735096365 5.45837507,0.625428973 5.70918799,0.740960311 C5.81741703,0.790813588 5.90428369,0.877680245 5.95413697,0.985909285 L7.11640944,3.50914636 L9.78362916,3.92439962 C10.0564845,3.96687984 10.2432405,4.22250982 10.2007602,4.49536517 C10.1850179,4.59648016 10.1386288,4.69033001 10.0678561,4.764244 L8.11540542,6.80335364 L8.56901492,9.63701045 C8.61266385,9.90968128 8.42700492,10.1661091 8.15433409,10.209758 C8.04273772,10.2276223 7.92838093,10.2071522 7.8299109,10.1516857 L5.5,8.83928571 Z", id: "Star" })),
            React.createElement("g", { transform: "translate(0.000000, 1.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M5.5,8.83928571 L3.1700891,10.1516857 C2.92949073,10.2872107 2.62458246,10.202032 2.48905742,9.96143363 C2.43359089,9.8629636 2.41312082,9.74860682 2.43098508,9.63701045 L2.88459458,6.80335364 L0.932143943,4.764244 C0.741166051,4.5647896 0.748037615,4.2482817 0.947492011,4.0573038 C1.021406,3.98653104 1.11525585,3.94014198 1.21637084,3.92439962 L3.88359056,3.50914636 L5.04586303,0.985909285 C5.16139437,0.735096365 5.45837507,0.625428973 5.70918799,0.740960311 C5.81741703,0.790813588 5.90428369,0.877680245 5.95413697,0.985909285 L7.11640944,3.50914636 L9.78362916,3.92439962 C10.0564845,3.96687984 10.2432405,4.22250982 10.2007602,4.49536517 C10.1850179,4.59648016 10.1386288,4.69033001 10.0678561,4.764244 L8.11540542,6.80335364 L8.56901492,9.63701045 C8.61266385,9.90968128 8.42700492,10.1661091 8.15433409,10.209758 C8.04273772,10.2276223 7.92838093,10.2071522 7.8299109,10.1516857 L5.5,8.83928571 Z", id: "Star" })))));
};
export default EmptyStar;
