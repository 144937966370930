import { jsx } from '@emotion/core';
var HtmlIcon = function () {
    return (jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", role: "img" },
        jsx("title", null, "HTML Attachment Icon"),
        jsx("desc", null, "HTML Icon"),
        jsx("g", { fill: "#005EE0", fillRule: "evenodd" },
            jsx("rect", { width: "16", height: "16", fillOpacity: ".1", rx: "1" }),
            jsx("path", { d: [
                    'M8.514 2.99a.5.5 0 1 1 .974.229l-2.315 9.898a.5.5 0 0 1-.974-.228',
                    'L8.514 2.99zM5.257 6.43L2.972 7.8l2.285 1.371a.5.5 0 0 1-.514.858',
                    'L2.1 8.443a.75.75 0 0 1 0-1.286L4.743 5.57a.5.5 0 0 1 .514.858zm5',
                    '.722 0a.5.5 0 1 1 .514-.858l2.643 1.586a.75.75 0 0 1 0 1.286l-2.6',
                    '43 1.586a.5.5 0 0 1-.514-.858L13.264 7.8l-2.285-1.371z',
                ].join('') }))));
};
export default HtmlIcon;
