import { __makeTemplateObject } from "tslib";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/core";
import sprites from "./sprites";
var BG_IMAGE = "url(" + sprites + ")";
var __base__ = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-image: ", ";\n    height: 15px;\n    width: 15px;\n    margin: 10px 0px 0px -17px;\n"], ["\n    background-image: ", ";\n    height: 15px;\n    width: 15px;\n    margin: 10px 0px 0px -17px;\n"])), BG_IMAGE);
export var CheckboxImgBase = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    @media screen and (-webkit-min-device-pixel-ratio: 0) {\n        margin-left: -8px !important;\n    }\n    @supports (-ms-ime-align: auto) {\n    }\n    @media screen and (-ms-high-contrast: active),\n        screen and (-ms-high-contrast: none) {\n        margin-left: -21.5px !important;\n    }\n    @-moz-document url-prefix() {\n        margin-left: -21.5px !important;\n    }\n    input:focus + & {\n        outline-color: #0175ff;\n        outline-style: auto;\n        outline-width: 1px;\n        outline-offset: 2px;\n    }\n    display: block;\n"], ["\n    @media screen and (-webkit-min-device-pixel-ratio: 0) {\n        margin-left: -8px !important;\n    }\n    @supports (-ms-ime-align: auto) {\n    }\n    @media screen and (-ms-high-contrast: active),\n        screen and (-ms-high-contrast: none) {\n        margin-left: -21.5px !important;\n    }\n    @-moz-document url-prefix() {\n        margin-left: -21.5px !important;\n    }\n    input:focus + & {\n        outline-color: #0175ff;\n        outline-style: auto;\n        outline-width: 1px;\n        outline-offset: 2px;\n    }\n    display: block;\n"])));
export var CHECKBOX_SPRITE_ELEMENTS = {
    CheckboxFlagUnchecked: styled(CheckboxImgBase)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        ", "\n        background-position: -10px -314px;\n\n        &:hover {\n            background-position: -10px -366px;\n        }\n    "], ["\n        ", "\n        background-position: -10px -314px;\n\n        &:hover {\n            background-position: -10px -366px;\n        }\n    "])), __base__),
    CheckboxFlagChecked: styled(CheckboxImgBase)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        ", "\n        background-position: -10px -394px;\n\n        &:hover {\n            background-position: -10px -420px;\n        }\n    "], ["\n        ", "\n        background-position: -10px -394px;\n\n        &:hover {\n            background-position: -10px -420px;\n        }\n    "])), __base__),
    CheckboxStarUnchecked: styled(CheckboxImgBase)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        ", "\n        background-position: -10px -160px;\n\n        &:hover {\n            background-position: -10px -186px;\n        }\n    "], ["\n        ", "\n        background-position: -10px -160px;\n\n        &:hover {\n            background-position: -10px -186px;\n        }\n    "])), __base__),
    CheckboxStarChecked: styled(CheckboxImgBase)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        ", "\n        background-position: -10px -238px;\n\n        &:hover {\n            background-position: -10px -264px;\n        }\n    "], ["\n        ", "\n        background-position: -10px -238px;\n\n        &:hover {\n            background-position: -10px -264px;\n        }\n    "])), __base__)
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
