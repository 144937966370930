import React from "react";
export var CalendarSvg = function () { return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", role: "img", width: "16", height: "16", viewBox: "0 0 16 16" },
    React.createElement("title", null, "Calendar Icon"),
    React.createElement("desc", null, "Calendar"),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("rect", { width: "13", height: "13", x: "2", y: "2", fill: "#005EE0", rx: "1" }),
        React.createElement("path", { fill: "#FFF", d: "M11.648 12h-.96V8.38c-.351.328-.765.571-1.241.728v-.871c.25-.082.523-.238.817-.467.294-.229.495-.496.605-.801h.779V12zm-6.385-1.33l.93-.112c.03.237.11.418.24.543.129.125.286.188.47.188a.648.648 0 0 0 .502-.226c.135-.15.203-.353.203-.608 0-.241-.065-.433-.195-.574a.621.621 0 0 0-.475-.212c-.123 0-.27.024-.44.072l.105-.783c.26.007.458-.05.595-.17a.602.602 0 0 0 .205-.476.545.545 0 0 0-.15-.403.54.54 0 0 0-.4-.15.579.579 0 0 0-.42.17c-.117.114-.188.28-.213.5l-.885-.151c.061-.303.154-.545.279-.726.124-.182.297-.324.52-.428.221-.103.47-.155.746-.155.472 0 .85.15 1.135.45.234.247.352.525.352.835 0 .44-.24.79-.721 1.053.287.061.516.199.688.413a1.2 1.2 0 0 1 .258.776c0 .44-.16.815-.482 1.125-.32.31-.72.464-1.2.464-.453 0-.829-.13-1.127-.39a1.538 1.538 0 0 1-.52-1.025zM11 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM6 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" })))); };
export var CloseSvg = function () { return (React.createElement("svg", { width: "16px", role: "img", height: "16px", viewBox: "0 0 16 16", version: "1.1", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("title", null, "Close Icon"),
    React.createElement("desc", null, "Close"),
    React.createElement("g", { id: "*", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "*/Icons/Close/Default", fill: "#161616" },
            React.createElement("path", { d: "M7.25,7.5 L4.35355339,10.3964466 C4.15829124,10.5917088 4.15829124,10.9082912 4.35355339,11.1035534 L4.89644661,11.6464466 C5.09170876,11.8417088 5.40829124,11.8417088 5.60355339,11.6464466 L8.5,8.75 L11.4000829,11.6466557 C11.5951252,11.842138 11.9117076,11.8419508 12.1068543,11.6465733 C12.1069661,11.6464614 12.1070778,11.6463494 12.1068536,11.6459024 L12.647535,11.1035526 C12.8422838,10.9082028 12.8420403,10.5920403 12.6469908,10.3969908 L9.75,7.5 L12.6469908,4.60300918 C12.8420403,4.40795969 12.8422838,4.09179717 12.647535,3.89644745 L12.1068536,3.3540976 C11.9122284,3.15819997 11.5956463,3.15771229 11.4000836,3.35267341 C11.3999715,3.35278511 11.3998595,3.35289686 11.4000829,3.3533443 L8.5,6.25 L5.60355339,3.35355339 C5.40829124,3.15829124 5.09170876,3.15829124 4.89644661,3.35355339 L4.35355339,3.89644661 C4.15829124,4.09170876 4.15829124,4.40829124 4.35355339,4.60355339 L7.25,7.5 Z", id: "Shape" }))))); };
