import styled from '../styled';

const ConfirmationContainer = styled.div`
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 100px;
  font-weight: 300;
  font-size: 1.13em;
  color: #161616;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: table-cell;
    vertical-align: middle;
  }
`;

export default ConfirmationContainer;
