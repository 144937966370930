import React from 'react';
var One = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "53px", height: "14px", viewBox: "0 0 53 14", version: "1.1" },
        React.createElement("title", null, "Money One"),
        React.createElement("desc", null, "One."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#F6A623" },
                React.createElement("g", null,
                    React.createElement("path", { d: "M5,11.9875189 C7.24424394,11.8459844 8.24116608,10.5200371 8.24116608,9 C8.24116608,5.4057971 2.75706714,6.52173913 2.75706714,4.71014493 C2.75706714,4 3.37897527,3.50724638 4.34010601,3.50724638 C5.3295053,3.50724638 6.33303887,3.85507246 7.08215548,4.62318841 L8.01501767,3.36231884 C7.25452825,2.59532488 6.23012364,2.12149032 5,2.020378 L5,0.509826677 C5,0.228257178 4.76806641,0 4.5,0 L4.5,0 C4.22385763,0 4,0.216636951 4,0.49002971 L4,2.024313 C2.20471386,2.20428325 1.06095406,3.36361912 1.06095406,4.84057971 C1.06095406,8.39130435 6.545053,7.13043478 6.545053,9.15942029 C6.545053,9.8115942 6.02208481,10.4927536 4.66519435,10.4927536 C3.35070671,10.4927536 2.31890459,9.85507246 1.68286219,9.14492754 L0.75,10.4637681 C1.49815163,11.2564655 2.56531099,11.8461362 4,11.974067 L4,13.5095781 C4,13.7804307 4.23193359,14 4.5,14 L4.5,14 C4.77614237,14 5,13.7846293 5,13.492166 L5,11.9875189 Z" }))),
            React.createElement("g", { transform: "translate(44.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M5,11.9875189 C7.24424394,11.8459844 8.24116608,10.5200371 8.24116608,9 C8.24116608,5.4057971 2.75706714,6.52173913 2.75706714,4.71014493 C2.75706714,4 3.37897527,3.50724638 4.34010601,3.50724638 C5.3295053,3.50724638 6.33303887,3.85507246 7.08215548,4.62318841 L8.01501767,3.36231884 C7.25452825,2.59532488 6.23012364,2.12149032 5,2.020378 L5,0.509826677 C5,0.228257178 4.76806641,0 4.5,0 L4.5,0 C4.22385763,0 4,0.216636951 4,0.49002971 L4,2.024313 C2.20471386,2.20428325 1.06095406,3.36361912 1.06095406,4.84057971 C1.06095406,8.39130435 6.545053,7.13043478 6.545053,9.15942029 C6.545053,9.8115942 6.02208481,10.4927536 4.66519435,10.4927536 C3.35070671,10.4927536 2.31890459,9.85507246 1.68286219,9.14492754 L0.75,10.4637681 C1.49815163,11.2564655 2.56531099,11.8461362 4,11.974067 L4,13.5095781 C4,13.7804307 4.23193359,14 4.5,14 L4.5,14 C4.77614237,14 5,13.7846293 5,13.492166 L5,11.9875189 Z" })),
            React.createElement("g", { transform: "translate(33.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M5,11.9875189 C7.24424394,11.8459844 8.24116608,10.5200371 8.24116608,9 C8.24116608,5.4057971 2.75706714,6.52173913 2.75706714,4.71014493 C2.75706714,4 3.37897527,3.50724638 4.34010601,3.50724638 C5.3295053,3.50724638 6.33303887,3.85507246 7.08215548,4.62318841 L8.01501767,3.36231884 C7.25452825,2.59532488 6.23012364,2.12149032 5,2.020378 L5,0.509826677 C5,0.228257178 4.76806641,0 4.5,0 L4.5,0 C4.22385763,0 4,0.216636951 4,0.49002971 L4,2.024313 C2.20471386,2.20428325 1.06095406,3.36361912 1.06095406,4.84057971 C1.06095406,8.39130435 6.545053,7.13043478 6.545053,9.15942029 C6.545053,9.8115942 6.02208481,10.4927536 4.66519435,10.4927536 C3.35070671,10.4927536 2.31890459,9.85507246 1.68286219,9.14492754 L0.75,10.4637681 C1.49815163,11.2564655 2.56531099,11.8461362 4,11.974067 L4,13.5095781 C4,13.7804307 4.23193359,14 4.5,14 L4.5,14 C4.77614237,14 5,13.7846293 5,13.492166 L5,11.9875189 Z" })),
            React.createElement("g", { transform: "translate(22.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M5,11.9875189 C7.24424394,11.8459844 8.24116608,10.5200371 8.24116608,9 C8.24116608,5.4057971 2.75706714,6.52173913 2.75706714,4.71014493 C2.75706714,4 3.37897527,3.50724638 4.34010601,3.50724638 C5.3295053,3.50724638 6.33303887,3.85507246 7.08215548,4.62318841 L8.01501767,3.36231884 C7.25452825,2.59532488 6.23012364,2.12149032 5,2.020378 L5,0.509826677 C5,0.228257178 4.76806641,0 4.5,0 L4.5,0 C4.22385763,0 4,0.216636951 4,0.49002971 L4,2.024313 C2.20471386,2.20428325 1.06095406,3.36361912 1.06095406,4.84057971 C1.06095406,8.39130435 6.545053,7.13043478 6.545053,9.15942029 C6.545053,9.8115942 6.02208481,10.4927536 4.66519435,10.4927536 C3.35070671,10.4927536 2.31890459,9.85507246 1.68286219,9.14492754 L0.75,10.4637681 C1.49815163,11.2564655 2.56531099,11.8461362 4,11.974067 L4,13.5095781 C4,13.7804307 4.23193359,14 4.5,14 L4.5,14 C4.77614237,14 5,13.7846293 5,13.492166 L5,11.9875189 Z" })),
            React.createElement("g", { transform: "translate(11.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M5,11.9875189 C7.24424394,11.8459844 8.24116608,10.5200371 8.24116608,9 C8.24116608,5.4057971 2.75706714,6.52173913 2.75706714,4.71014493 C2.75706714,4 3.37897527,3.50724638 4.34010601,3.50724638 C5.3295053,3.50724638 6.33303887,3.85507246 7.08215548,4.62318841 L8.01501767,3.36231884 C7.25452825,2.59532488 6.23012364,2.12149032 5,2.020378 L5,0.509826677 C5,0.228257178 4.76806641,0 4.5,0 L4.5,0 C4.22385763,0 4,0.216636951 4,0.49002971 L4,2.024313 C2.20471386,2.20428325 1.06095406,3.36361912 1.06095406,4.84057971 C1.06095406,8.39130435 6.545053,7.13043478 6.545053,9.15942029 C6.545053,9.8115942 6.02208481,10.4927536 4.66519435,10.4927536 C3.35070671,10.4927536 2.31890459,9.85507246 1.68286219,9.14492754 L0.75,10.4637681 C1.49815163,11.2564655 2.56531099,11.8461362 4,11.974067 L4,13.5095781 C4,13.7804307 4.23193359,14 4.5,14 L4.5,14 C4.77614237,14 5,13.7846293 5,13.492166 L5,11.9875189 Z" })))));
};
export default One;
