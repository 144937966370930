import { jsx } from '@emotion/core';
var VideoIcon = function () {
    return (jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", role: "img" },
        jsx("title", null, "Video Attachment Icon"),
        jsx("desc", null, "Video Icon"),
        jsx("g", { fill: "#005EE0", fillRule: "evenodd" },
            jsx("rect", { width: "16", height: "16", fillOpacity: ".1", rx: "1" }),
            jsx("path", { d: [
                    'M11 6.25l1.4-1.05A1 1 0 0 1 13 5h.333c.369 0 .667.298.667.667v4.6',
                    '66a.667.667 0 0 1-.667.667H13a1 1 0 0 1-.6-.2L11 9.75v1.75a.5.5 0',
                    '0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.',
                    '5v1.75zm0 1.25v1l2 1.5V6l-2 1.5zm-1-.25V5H3v6h7V7.25z',
                ].join('') }))));
};
export default VideoIcon;
