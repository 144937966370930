import React from 'react';
import { ESymbolKey } from "@smartsheet/forms-model";
import Easy from "./Easy";
import Intermediate from "./Intermediate";
import Advanced from "./Advanced";
import ExpertsOnly from "./ExpertsOnly";
import { IconOptionImg } from "../../iconUtil";
var SkiIcon = function (_a) {
    var type = _a.type;
    var icon;
    switch (type) {
        case ESymbolKey.DIFFICULTY_4_EASY:
        case "Easy":
            icon = React.createElement(Easy, null);
            break;
        case ESymbolKey.DIFFICULTY_4_INTERMEDIATE:
        case "Intermediate":
            icon = React.createElement(Intermediate, null);
            break;
        case ESymbolKey.DIFFICULTY_4_HARD:
        case "Advanced":
            icon = React.createElement(Advanced, null);
            break;
        case ESymbolKey.DIFFICULTY_4_EXPERTSONLY:
        case "Experts Only":
            icon = React.createElement(ExpertsOnly, null);
            break;
        default:
            break;
    }
    return !!icon ? (React.createElement(IconOptionImg, null, icon)) : null;
};
export default SkiIcon;
