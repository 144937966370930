import { jsx } from '@emotion/core';
var WarningImageIcon = function (_a) {
    var customCss = _a.customCss;
    return (jsx("svg", { css: customCss, width: "16px", height: "16px", viewBox: "0 0 16 16", version: "1.1", xmlns: "http://www.w3.org/2000/svg", role: "img" },
        jsx("title", null, "Warning Icon"),
        jsx("desc", null, "Warning Icon"),
        jsx("g", { stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            jsx("g", null,
                jsx("circle", { fill: "#D0021B", cx: "8", cy: "8", r: "7" }),
                jsx("path", { d: [
                        'M7,5 L7,6 L7.21,8.505 C7.232,8.785 7.478,9 7.758,9 L8.243,9',
                        'C8.52595203,9.00043806 8.76324293,8.78648725 8.792,8.505 L9,6',
                        'L9,5 C9,4.444 8.553,4 8,4 C7.444,4 7,4.448 7,5 Z',
                    ].join(' '), fill: "#FFFFFF" }),
                jsx("path", { d: [
                        'M8,12 C8.55228475,12 9,11.5522847 9,11 C9,10.4477153',
                        '8.55228475,10 8,10 C7.44771525,10 7,10.4477153 7,11',
                        'C7,11.5522847 7.44771525,12 8,12 Z',
                    ].join(' '), fill: "#FFFFFF" })))));
};
export default WarningImageIcon;
