import { __assign } from "tslib";
import React from "react";
import { createPropsGetter } from "../utils";
import { buildAriaDescribedBy } from "../utils/stringUtils";
import CheckboxSprite from "./CheckboxSprite";
import { CheckboxLabelContainer, CheckboxContainer, InputHidden, InputVisible } from "./CheckboxElement.styled";
import { BasicLabel } from "./CheckboxLabels";
import { CheckboxType } from "./types";
var defaultProps = {
    /** Type of checkbox; default is native checkbox. Can also use flag/star sprite */
    checkboxType: CheckboxType.CHECKBOX,
    /** Value of checkbox */
    checked: false,
    /** Should checkbox be disabled */
    disabled: false,
    /** Should the checkbox be hidden */
    hidden: false,
    /** Change handler function
     *  @param newValue the new boolean `checked` value for the checkbox
     *  @returns void
     */
    onChange: function (newValue) { }
};
var getProps = createPropsGetter(defaultProps);
// TODO:
// Remove automatic font-weight. Auto-align text by default. Provide option components for various options.
// Fix spacing/alignment issues
// Add data-client-ids
// Test Symbol checkboxes
var CheckboxElement = function (props) {
    var _a = getProps(props), checkboxType = _a.checkboxType, checked = _a.checked, children = _a.children, dataClientId = _a.dataClientId, descriptionElemId = _a.descriptionElemId, disabled = _a.disabled, errorMessageElemId = _a.errorMessageElemId, hidden = _a.hidden, id = _a.id, label = _a.label, name = _a.name, onChange = _a.onChange;
    // Create a function which calls the onChange prop with opposite value
    var onChangeThunk = function () { return onChange(!checked); };
    var handleKeyDown = function (e) {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };
    var ariaDescribedBy = buildAriaDescribedBy(descriptionElemId, errorMessageElemId);
    var optionalAttrs = {};
    if (!!ariaDescribedBy)
        optionalAttrs["aria-describedby"] = ariaDescribedBy;
    if (!!dataClientId)
        optionalAttrs["data-client-id"] = dataClientId;
    if (checkboxType === CheckboxType.CHECKBOX) {
        return (React.createElement(CheckboxContainer, null,
            React.createElement(InputVisible, __assign({ "aria-label": label || name, checked: checked, disabled: disabled, id: id, name: name, onChange: onChangeThunk, onKeyDown: handleKeyDown, tabIndex: hidden ? -1 : 0, title: label || name, type: "checkbox" }, optionalAttrs)),
            React.createElement(CheckboxLabelContainer, { onClick: onChangeThunk }, typeof children === "string" ? (React.createElement(BasicLabel, null, children)) : (children))));
    }
    else {
        // Custom symbol checkbox
        return (React.createElement(CheckboxContainer, null,
            React.createElement(InputHidden, __assign({ "aria-label": label || name, checked: checked, disabled: disabled, id: id, name: name, onChange: onChangeThunk, onKeyDown: handleKeyDown, tabIndex: hidden ? -1 : 0, type: "checkbox" }, optionalAttrs)),
            React.createElement(CheckboxSprite, { checked: checked, type: checkboxType, onClick: onChangeThunk }),
            children));
    }
};
export default CheckboxElement;
