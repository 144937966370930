import { AppConstants } from "../app.constants";
/**
 * look up the fundamental base locale for the variations that can come from appcore
 */
export var LookupBaseLocale = {
    "de-DE": "de-DE",
    "de-LU": "de-DE",
    "de-AT": "de-DE",
    "de-CH": "de-DE",
    "en-US": "en-US",
    "en-AU": "en-US",
    "en-CA": "en-US",
    "en-IN": "en-US",
    "en-IE": "en-US",
    "en-MT": "en-US",
    "en-NZ": "en-US",
    "en-PH": "en-US",
    "en-SG": "en-US",
    "en-ZA": "en-US",
    "en-GB": "en-US",
    "es-AR": "es-ES",
    "es-BO": "es-ES",
    "es-CL": "es-ES",
    "es-CO": "es-ES",
    "es-CR": "es-ES",
    "es-EC": "es-ES",
    "es-SV": "es-ES",
    "es-ES": "es-ES",
    "es-US": "es-ES",
    "es-GT": "es-ES",
    "es-HN": "es-ES",
    "es-MX": "es-ES",
    "es-NI": "es-ES",
    "es-PA": "es-ES",
    "es-PY": "es-ES",
    "es-PE": "es-ES",
    "es-PR": "es-ES",
    "es-DO": "es-ES",
    "es-UY": "es-ES",
    "es-VE": "es-ES",
    "fr-BE": "fr-FR",
    "fr-CA": "fr-FR",
    "fr-FR": "fr-FR",
    "fr-LU": "fr-FR",
    "fr-CH": "fr-FR",
    "it-IT": "it-IT",
    "it-CH": "it-IT",
    "ja-JP": "ja-JP",
    "pt-BR": "pt-BR",
    "pt-PT": "pt-BR",
    "ru-RU": "ru-RU"
};
/**
 * Map appcore locales to MomentJS or other standard locales
 * @param locale
 */
export function rtnFormattedLocale(locale) {
    //defensive coding is not done intentionally, no undefined check
    var formattedLocale = getFormattedLocale(locale);
    switch (formattedLocale) {
        case "no-NO":
            //Norway needs to be manually remapped, datepicker has Norwegian language code as "nb" and app-core has it as "no"
            locale = "nb-NO";
            break;
        case "iw-IL":
            // iw-IL is "Hebrew - Israel", iw is an old language code so mapping it to the modern "he"
            locale = "he-IL";
            break;
        case "es-HN":
        case "es-NI":
        case "es-PR":
            // "Spanish - El Salvador" has been added to support Spanish with the "MM-DD-YYYY" format, mapping other similar contries to it
            locale = "es-SV";
            break;
        case "es-PA":
            // "Spanish - Panama" matches the MM/dd/YYYY format of "Spanish - US"
            locale = "es-US";
            break;
        case "en-IN":
        // Mapping all Arabic languages that use a period for numerics to GB english, loses translations but provides data consistency
        // TO DO: The set below will need to be updated if Smartsheet ever adds translation support for Arabic
        case "ar-BH":
        case "ar-EG":
        case "ar-IQ":
        case "ar-JO":
        case "ar-KW":
        case "ar-OM":
        case "ar-QA":
        case "ar-SA":
        case "ar-SD":
        case "ar-SY":
        case "ar-AE":
        case "ar-YE":
        // "Chinese - Singapore" in app-core does not match the date format of any other chinese locale so falling back to English and mapping to en-GB 
        // as we do not currently support Chinese translations.  Will need an update when smartsheet supports Chinese      
        case "zh-SG":
            locale = "en-GB";
            break;
        case "ar-LB":
        case "ar-LY":
            // ar-MA (Morocco), ar-TN (Tunisia) are also a valid Arabic comma separated cases that do not require mappings
            // ar-DZ (Algeria) uses commas for numerics but also enders the datepicker with arabic text and actual numbers
            locale = "ar-DZ";
            break;
        case "hi-US":
            // "hi-US" is called "hindi (India)" in app-core but it mapped to the US MM/dd/YY date format
            locale = "en-US";
            break;
        default:
            locale = "en-US";
    }
    return locale;
}
/**
 * Formats the locale to use dashes instead of underscores if necessary
 * @param locale
 */
export function getFormattedLocale(locale) {
    var localePattern = /[a-z]{2}_[A-Z]{2}$/gim;
    if (localePattern.test(locale)) {
        locale = locale.replace("_", "-");
    }
    return locale;
}
/**
 * Returns one of the below locale as per LookupBaseLocale config:
 * de-DE, en-US, es-ES, fr-FR, it-IT, ja-JP, pt-BR, ru-RU
 * @param locale
 */
export function getDisplayLocale(locale) {
    if (locale === void 0) { locale = AppConstants.DEFAULT_LOCALE; }
    var displayLocale = LookupBaseLocale[getFormattedLocale(locale)];
    if (!displayLocale) {
        console.warn("Unexpected language tag \"" + locale + "\". Defaulting to \"" + AppConstants.DEFAULT_LOCALE + "\".");
        return AppConstants.DEFAULT_LOCALE;
    }
    return displayLocale;
}
/**
 * Update the Smartsheet Help article URLs to the proper language page
 * verify that this function continues to work in the future as new languages are added
 * @param helpUrl
 * @param locale
 */
export function getLocalizedHelpUrl(helpUrl, locale) {
    if (locale === void 0) { locale = AppConstants.DEFAULT_LOCALE; }
    var languagePrefix = getLanguagePrefix(locale);
    if (languagePrefix === "en") {
        return helpUrl;
    }
    else {
        return helpUrl.replace("help.smartsheet.com/", "help.smartsheet.com/" + languagePrefix + "/");
    }
}
export function getLanguagePrefix(locale) {
    if (locale === void 0) { locale = AppConstants.DEFAULT_LOCALE; }
    return locale.substr(0, 2).toLowerCase();
}
