import { action } from 'typesafe-actions';
import { FormDefinition, FormSubmission, FormConfirmation, FormSubmissionResult, FormValues } from '../../app/types';
import { OpenFormRequestObject } from '../../app/containers/SubmitFormApp';

export enum SubmitFormActionType {
  OPEN_FORM_REQUEST = 'OPEN_FORM_REQUEST',
  OPEN_FORM_SUCCESS = 'OPEN_FORM_SUCCESS',
  OPEN_FORM_FAILURE = 'OPEN_FORM_FAILURE',

  SUBMIT_FORM_REQUEST = 'SUBMIT_FORM_REQUEST',
  SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCESS',
  SUBMIT_FORM_FAILURE = 'SUBMIT_FORM_FAILURE',

  UPDATE_FORM_REQUEST = 'UPDATE_FORM_REQUEST'
}

export interface OpenFormSuccessObject {
  form: FormDefinition
  confirmation: FormConfirmation
  formToken: string
  initialValues?: FormValues

}

export interface SubmitFormSuccessObject {
  submissionResult: FormSubmissionResult
  rawValues: FormValues
  updatedFormDefinition: FormDefinition,

}

export interface UpdateFormRequestObject {
  form: FormDefinition
}

export class SubmitFormActions {
  static openFormRequest = (requestObject:OpenFormRequestObject) => action(SubmitFormActionType.OPEN_FORM_REQUEST,requestObject)
  static openFormSuccess = (successObject: OpenFormSuccessObject) => action(SubmitFormActionType.OPEN_FORM_SUCCESS, successObject)
  static openFormFailure = (error: Error) => action(SubmitFormActionType.OPEN_FORM_FAILURE, error)

  static submitFormRequest = (submission: FormSubmission) => action(SubmitFormActionType.SUBMIT_FORM_REQUEST, submission)
  static submitFormSuccess = (successObject: SubmitFormSuccessObject) => action(SubmitFormActionType.SUBMIT_FORM_SUCCESS, successObject);
  static submitFormFailure = (error: Error) => action(SubmitFormActionType.SUBMIT_FORM_FAILURE, error);
  
  static updateFormRequest = (updateObject: UpdateFormRequestObject) => action(SubmitFormActionType.UPDATE_FORM_REQUEST, updateObject);
}