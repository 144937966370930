import { __assign, __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import styled from '@emotion/styled/macro';
import { createPropsGetter } from '../utils';
import Divider from '../Divider';
import TextInput from '../TextInput';
import { FormattedMessage } from "react-intl";
var defaultProps = {
    required: false,
    readonly: false,
    touched: false,
    hidden: false,
    onChange: function (event) { },
    submitMetrics: function (body) { }
};
var getProps = createPropsGetter(defaultProps);
export function isEmailValue(value) {
    if (value) {
        return value.email !== undefined;
    }
    else {
        return false;
    }
}
var EmailReceiptContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-wrap: wrap;\n    margin-top: 24px;\n    margin-bottom: -4px;\n"], ["\n    display: flex;\n    flex-wrap: wrap;\n    margin-top: 24px;\n    margin-bottom: -4px;\n"])));
var EmailReceiptLabel = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    font-size: 0.88em;\n    vertical-align: text-bottom;\n    color: #161616;\n    line-height: 15px;\n    margin-top: 3px;\n    margin-bottom: 3px;\n"], ["\n    display: flex;\n    align-items: center;\n    font-size: 0.88em;\n    vertical-align: text-bottom;\n    color: #161616;\n    line-height: 15px;\n    margin-top: 3px;\n    margin-bottom: 3px;\n"])));
var EmailReceiptCheckbox = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    line-height: 15px;\n    margin-left: 0;\n    margin-right: 6px;\n    height: 15px;\n    width: 15px;\n"], ["\n    line-height: 15px;\n    margin-left: 0;\n    margin-right: 6px;\n    height: 15px;\n    width: 15px;\n"])));
var ConfirmationEmailInput = /** @class */ (function (_super) {
    __extends(ConfirmationEmailInput, _super);
    function ConfirmationEmailInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.inputRef = React.createRef();
        _this.checkboxChangeHandler = function (value) {
            var props = getProps(_this.props);
            var isChecked = !props.value.emailRequested;
            props.submitMetrics({
                id: '',
                event: 'Mouse_Left_Click',
                element: 'Render_Send_Email_Copy',
                value: isChecked
            });
            props.onChange(__assign(__assign({}, props.value), { emailRequested: isChecked }));
        };
        _this.emailInputChangeHandler = function (value) {
            var props = getProps(_this.props);
            props.onChange(__assign(__assign({}, props.value), { email: value }));
        };
        // This prevents the form from being submitted when a user hits Enter or Space when the EmailReceiptCheckbox has focus
        _this.onKeyDownHandler = function (e) {
            if (e.key === "Enter") {
                e.preventDefault();
            }
            else if (e.key === "Space") {
                e.stopPropagation();
            }
        };
        return _this;
    }
    ConfirmationEmailInput.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.value.emailRequested &&
            !prevProps.value.emailRequested &&
            this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };
    ConfirmationEmailInput.prototype.render = function () {
        var _this = this;
        var props = getProps(this.props);
        return (React.createElement("div", { "data-client-id": "email_receipt_section" },
            React.createElement(Divider, { title: "email_receipt" }),
            React.createElement(EmailReceiptContainer, null,
                React.createElement(EmailReceiptLabel, null,
                    React.createElement(EmailReceiptCheckbox, { "data-client-id": 'email_receipt_checkbox', name: "EMAIL_RECEIPT_CHECKBOX", type: "checkbox", checked: props.value.emailRequested, value: props.value.emailRequested.toString(), onChange: function (event) { return _this.checkboxChangeHandler(event.target.value); }, 
                        // This prevents the form from being submitted when a user hits Enter or Space when the EmailReceiptCheckbox has focus
                        onKeyDown: this.onKeyDownHandler }),
                    React.createElement(FormattedMessage, { id: "elements.lbl_webForm_confirmationEmailFlag" }))),
            props.value.emailRequested && (React.createElement(TextInput, __assign({}, props, { error: props.error && props.error.email, innerInputRef: this.inputRef, localizationLabelId: "elements.lbl_webForm_confirmationEmailAddress", onChange: function (event) { return _this.emailInputChangeHandler(event.target.value); }, value: props.value.email })))));
    };
    return ConfirmationEmailInput;
}(React.Component));
export default ConfirmationEmailInput;
var templateObject_1, templateObject_2, templateObject_3;
