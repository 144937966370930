/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/core';
import { FormikErrors } from 'formik';
import { FormattedMessage } from 'react-intl';
import { FormValues, FormConfirmation } from '../../../app/types';
import WarningImageIcon from '../../../app/components/WarningImageIcon';
import FormattedErrorMessage from '../../../app/components/FormattedErrorMessage';
import styled from './styled';

interface InlineErrorProps {
  formikErrors?: FormikErrors<FormValues>;
  generalError?: Error;
  submitCount: number;
  wasRefreshed: boolean
  confirmation?: FormConfirmation
  scrollToTop?: () => void
}

const infoPanelBase = `
  text-align: left;
  border-radius: 2px;
  font-size: 0.88em;
  display: flex;
  align-items: center;
`;

const ErrorBox = styled.div`
  ${infoPanelBase};
  background-color: rgba(208, 1, 27, 0.25);
  margin: 1rem 0rem 1rem 0rem;
  padding: 0.6rem 0.6rem 0.5rem 0.5rem;
`;

const WarningImageStyled = css`
  margin-right: 0.5rem;
`;

const TooltipTitle = styled.span`
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: #161616;
  margin-top: 2px;
`;

const InlineError: React.FC<InlineErrorProps> = ({
  formikErrors,
  generalError,
  submitCount,
  scrollToTop,
  wasRefreshed,
}) => { 
  //scroll users to the top when "Submit" is clicked and errors are present
  React.useEffect(() => {
    if((formikErrors && Object.keys(formikErrors).length > 0) || generalError) {
      if(scrollToTop) {
        scrollToTop()
      }
    }
  }, [submitCount])

  if (generalError) {
    return (
      <ErrorBox>
        <WarningImageIcon css={WarningImageStyled} />
        <TooltipTitle>
          <FormattedErrorMessage error={generalError} />
        </TooltipTitle>
      </ErrorBox>
    );
  }

  if(wasRefreshed) {
    return (
      <ErrorBox>
        <WarningImageIcon css={WarningImageStyled} />
        <TooltipTitle>
          <FormattedMessage id="validations.refresh_forced" />
        </TooltipTitle>
      </ErrorBox>
    );
  }

  const hasFormikErrors = formikErrors && Object.keys(formikErrors).length > 0;

  if (submitCount > 0 && hasFormikErrors) {
  
    return (
      <ErrorBox>
        <WarningImageIcon css={WarningImageStyled} />
        <TooltipTitle data-client-id="form_error_msg">
          <FormattedMessage id="validations.ve_base" />
        </TooltipTitle>
      </ErrorBox>
    );
  }

  return null;
};

export default InlineError;
