import React from 'react';
var Full = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "58px", height: "6px", viewBox: "0 0 58 6", version: "1.1", role: "img" },
        React.createElement("title", null, "Progress Full"),
        React.createElement("desc", null, "Full progress."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#0073EC" },
                React.createElement("rect", { id: "Rectangle", x: "0", y: "0", width: "58", height: "6" })))));
};
export default Full;
