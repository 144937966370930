import { __makeTemplateObject } from "tslib";
import styled from '@emotion/styled/macro';
import * as React from 'react';
import MarkDownDescription from '../MarkDownDescription';
import LogicTag from '../LogicTag';
import LabelContainer from '../LabelContainer';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n"], ["\n"])));
var Title = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: #161616;\n  font-size: 1.4em;\n  font-weight: 300;\n  margin: 0;\n  margin-top: 3px;\n  white-space: pre-wrap;\n  & p {\n    margin-block: 2px;\n  }\n"], ["\n  color: #161616;\n  font-size: 1.4em;\n  font-weight: 300;\n  margin: 0;\n  margin-top: 3px;\n  white-space: pre-wrap;\n  & p {\n    margin-block: 2px;\n  }\n"])));
var Description = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 0.88em;\n  font-weight: regular;\n  white-space: pre-wrap;\n  line-height: 15px;\n  margin: 0;\n  & p {\n    margin-block: 2px;\n  }\n  & ul {\n      list-style-position: inside;\n      margin-block: 2px;\n  }\n  & ol {\n      list-style-position: inside;\n      margin-block: 2px;\n  }\n"], ["\n  font-size: 0.88em;\n  font-weight: regular;\n  white-space: pre-wrap;\n  line-height: 15px;\n  margin: 0;\n  & p {\n    margin-block: 2px;\n  }\n  & ul {\n      list-style-position: inside;\n      margin-block: 2px;\n  }\n  & ol {\n      list-style-position: inside;\n      margin-block: 2px;\n  }\n"])));
var Heading = function (_a) {
    var title = _a.title, logic = _a.logic, description = _a.description, richDescription = _a.richDescription, isRichTextEnabled = _a.isRichTextEnabled, richTitle = _a.richTitle;
    return (
    /* disabled rich text label until FORMS-3974 is resolved */
    React.createElement(Container, { "data-client-id": "container_" + title, "data-client-type": "heading" },
        React.createElement(LabelContainer, null,
            title && (React.createElement(Title, { "data-client-id": 'heading_' + title }, title)),
            logic && React.createElement(LogicTag, { logic: logic })),
        (isRichTextEnabled && richDescription) && React.createElement(Description, { "data-client-id": 'subheading_' + title, className: "rich-text-field-desc", dangerouslySetInnerHTML: { __html: richDescription } }) ||
            description && (React.createElement(Description, { "data-client-id": 'subheading_' + title }, MarkDownDescription(description)))));
};
export default Heading;
var templateObject_1, templateObject_2, templateObject_3;
