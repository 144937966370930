import { __makeTemplateObject } from "tslib";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { useEffect, useRef } from "react";
var isPhoneNumberFieldEmpty = function (value) {
    var firstUserCharOffset = value.charAt(value.indexOf(" ") + 1) !== "(" ? 1 : 2;
    var firstCharofUserInput = value.indexOf(" ") + firstUserCharOffset;
    return value.charAt(firstCharofUserInput) === '_';
};
var isPhoneNumberFieldFull = function (value) {
    return value.indexOf('_') < 0;
};
function usePrevious(value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    }, [value]);
    return ref.current;
}
;
var textInputBaseClass = "\ndisplay: block;\n&:hover {\n  border-color: #cccccc;\n};\nwidth: 100%;\nfont-size: 0.88em;\nbackground-color: #fff;\nborder-width: 1px;\nbox-sizing: border-box;\n-moz-box-sizing: border-box;\n-webkit-box-sizing: border-box;\n\n&:focus {\n  outline: 0;\n  border-color: #3490dc;\n};\nborder-radius: 2px;\n::-ms-clear {\n  display: none;\n}\n";
var ErrorSpan = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: block;\n  color: #d0011b;\n  font-size: 0.88em;\n  margin-top: 11px;\n  margin-bottom: 0.25rem;\n  display: flex;\n  align-items: center;\n"], ["\n  width: 100%;\n  display: block;\n  color: #d0011b;\n  font-size: 0.88em;\n  margin-top: 11px;\n  margin-bottom: 0.25rem;\n  display: flex;\n  align-items: center;\n"])));
var WarningImageStyled = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: 0.3rem;\n"], ["\n  margin-right: 0.3rem;\n"])));
var LogicInputLabel = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  word-wrap: break-all;\n"], ["\n  word-wrap: break-all;\n"]))); //Div is needed around label when logic tag is present
var TextAddOn = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-left: 8px;\n  padding-top: 6px;\n"], ["\n  padding-left: 8px;\n  padding-top: 6px;\n"])));
var TextAddOnRight = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding-right: 8px;\n  padding-top: 6px;\n"], ["\n  padding-right: 8px;\n  padding-top: 6px;\n"])));
export var Util = {
    isPhoneNumberFieldEmpty: isPhoneNumberFieldEmpty,
    isPhoneNumberFieldFull: isPhoneNumberFieldFull,
    usePrevious: usePrevious,
    textInputBaseClass: textInputBaseClass,
    ErrorSpan: ErrorSpan,
    WarningImageStyled: WarningImageStyled,
    LogicInputLabel: LogicInputLabel,
    TextAddOn: TextAddOn,
    TextAddOnRight: TextAddOnRight
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
