import React from 'react';
var AngleDown = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "13px", height: "13px", viewBox: "0 0 13 13", version: "1.1", role: "img" },
        React.createElement("title", null, "Arrow Angle Down"),
        React.createElement("desc", null, "Angle Down."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(1.000000, 0.000000)", fill: "#F6A623" },
                React.createElement("path", { d: "M4.59997559,5.80545404 L2.2044044,3.99975024 C1.98388992,3.83353358 1.93987284,3.52002606 2.10608949,3.29951158 C2.17115837,3.21318672 2.26245195,3.15027509 2.36628729,3.12020578 L10.360921,0.805068587 C10.6261655,0.728257385 10.9034565,0.881012762 10.9802677,1.14625727 C10.993357,1.19145741 11,1.23827903 11,1.28533626 L11,9.61901868 C11,9.89516106 10.7761424,10.1190187 10.5,10.1190187 C10.3916842,10.1190187 10.2862975,10.083845 10.1996945,10.0187894 L7.79542241,8.21271413 L4.48589421,12.6046064 C4.31970758,12.8251435 4.00620604,12.8692033 3.78566894,12.7030166 C3.78546808,12.7028653 3.78526733,12.7027138 3.7850667,12.7025621 L1.39768298,10.8978847 C1.17785836,10.7317143 1.13391951,10.4190111 1.29943776,10.198695 L4.59997559,5.80545404 Z", id: "Combined-Shape", transform: "translate(6.000000, 6.812264) scale(1, -1) translate(-6.000000, -6.812264) " })))));
};
export default AngleDown;
