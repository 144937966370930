import * as React from "react";
var CaretSvg = function (props) {
    return (React.createElement("svg", { "data-client-id": "caret_icon", width: "16px", height: "16px", viewBox: "0 0 16 16", version: "1.1", role: "img", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("title", null, "Caret Icon"),
        React.createElement("desc", null, "Caret symbol"),
        React.createElement("g", { stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { fill: props.color },
                React.createElement("path", { d: [
                        "M8.38951287,9.81740951 C8.3743818,9.83527284",
                        "8.35811596,9.85207547 8.34082332,9.86770589",
                        "C8.12569934,10.0621513 7.79871352,10.0396329",
                        "7.61048004,9.81740951 L5.12806062,6.88673235",
                        "C5.04550447,6.78926879 5,6.66416424 5,6.53465776",
                        "C5,6.23937443 5.23172713,6 5.51757703,6 L10.4824159,6",
                        "C10.607785,6 10.7288928,6.04700617 10.8232427,6.13228679",
                        "C11.0383667,6.32673222 11.0601658,6.664509",
                        "10.8719323,6.88673235 L8.38951287,9.81740951 Z"
                    ].join(" ") })))));
};
export default CaretSvg;
