import React from 'react';
var Stop = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "12px", height: "12px", viewBox: "0 0 12 12", version: "1.1", role: "img" },
        React.createElement("title", null, "Decision Stop"),
        React.createElement("desc", null, "Stop VCR."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(1.000000, 1.000000)", fill: "#444444" },
                React.createElement("rect", { x: "0", y: "0", width: "10", height: "10" })))));
};
export default Stop;
