import { jsx } from '@emotion/core';
var DefaultAttachmentIcon = function () {
    return (jsx("svg", { width: "16px", height: "16px", viewBox: "0 0 48 48", version: "1.1", role: "img", xmlns: "http://www.w3.org/2000/svg" },
        jsx("title", null, "Default Attachment Icon"),
        jsx("desc", null, "Default Attachment"),
        jsx("g", { stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            jsx("g", { fill: "#0073EC", fillRule: "nonzero" },
                jsx("g", null,
                    jsx("path", { d: [
                            'M13,48 C5.82029825,48 3.55271368e-15,42.1797017 1.77635684e-1',
                            '5,35 L1.77635684e-15,9 C1.0474678e-07,4.02943733 4.02943733,1',
                            '.8966082e-07 9,1.8966082e-07 C13.9705627,1.89660821e-07 17.99',
                            '99999,4.02943733 18,9 L18,35 C18,37.7614237 15.7614237,40 13,',
                            '40 C10.2385763,40 8,37.7614237 8,35 L8,13 C8,12.4477153 8.447',
                            '71525,12 9,12 C9.55228475,12 10,12.4477153 10,13 L10,35 C10,3',
                            '6.6568542 11.3431458,37.9999999 13,37.9999999 C14.6568542,37.',
                            '9999999 16,36.6568542 16,35 L16,9 C15.9999999,5.13400679 12.8',
                            '659932,2.0000001 9,2.0000001 C5.13400679,2.0000001 2.00000006',
                            ',5.13400679 2,9 L2,35 C2.00000009,41.0751322 6.92486782,45.99',
                            '99998 13,45.9999998 C19.0751322,45.9999998 23.9999999,41.0751',
                            '322 24,35 L24,13 C24,12.4477153 24.4477153,12 25,12 C25.55228',
                            '47,12 26,12.4477153 26,13 L26,35 C26,42.1797017 20.1797017,48',
                            '13,48 Z',
                        ].join('') }))))));
};
export default DefaultAttachmentIcon;
