import { __makeTemplateObject, __spreadArray } from "tslib";
import * as React from "react";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/core";
import { FormattedMessage } from "react-intl";
import WarningImageIcon from "../WarningImageIcon";
import CaretSvg from "./CaretSvg";
import { sanitizeHtmlID } from "../utils/stringUtils";
export var ValueType;
(function (ValueType) {
    ValueType["STRING"] = "STRING";
    ValueType["CONTACT"] = "CONTACT";
})(ValueType || (ValueType = {}));
var maxContactLimit = 20;
export var ErrorSpan = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nwidth: 100%;\ndisplay: block;\nfont-size: 0.88em;\ncolor: #d0011b;\nmargin-top: 11px;\nmargin-bottom: 0.25rem;\ndisplay: flex;\nalign-items: center;\n"], ["\nwidth: 100%;\ndisplay: block;\nfont-size: 0.88em;\ncolor: #d0011b;\nmargin-top: 11px;\nmargin-bottom: 0.25rem;\ndisplay: flex;\nalign-items: center;\n"])));
export var WarningImageStyled = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: 0.3rem;\n"], ["\n  margin-right: 0.3rem;\n"])));
// the criterion to decide if a select is a
// Contact List W/O a preferred contact list aka contact list w/o default options
export var isContactLstNoOptions = function (_a) {
    var valueType = _a.valueType, options = _a.options, multivalue = _a.multivalue, validateField = _a.validateField;
    return ((valueType === ValueType.CONTACT &&
        !!multivalue &&
        !validateField &&
        !!options &&
        !options.length));
};
var optionNullChecked = function (option) { return option || ""; };
// handles option creation
export var handleCreateOption = function (_a) {
    var option = _a.option, valueType = _a.valueType, isContactLstNoOptions = _a.isContactLstNoOptions, multivalue = _a.multivalue, isValidEmail = _a.isValidEmail, selectedOptions = _a.selectedOptions;
    var newOptionsArray = selectedOptions || [];
    switch (valueType) {
        case ValueType.CONTACT: {
            if (multivalue) {
                if (isValidEmail || isContactLstNoOptions) {
                    var newContact = { name: optionNullChecked(option), email: optionNullChecked(option), toString: function () { return optionNullChecked(option); } };
                    newOptionsArray = __spreadArray(__spreadArray([], newOptionsArray), [newContact]);
                    return {
                        state: { selectedOptions: newOptionsArray },
                        onChangeValue: newOptionsArray
                    };
                }
                else {
                    return { onChangeValue: newOptionsArray };
                }
            }
            else {
                var newContact = { name: optionNullChecked(option), email: optionNullChecked(option), toString: function () { return optionNullChecked(option); } };
                return { onChangeValue: newContact };
            }
        }
        case ValueType.STRING: {
            newOptionsArray = __spreadArray(__spreadArray([], newOptionsArray), [optionNullChecked(option)]);
            return multivalue ? {
                state: { selectedOptions: newOptionsArray },
                onChangeValue: newOptionsArray,
            }
                : { onChangeValue: optionNullChecked(option) };
        }
        default: {
            return { onChangeValue: optionNullChecked(option) };
        }
    }
};
// checks if creating an option will cause duplicates
var willOptCreationCauseDup = function (_a) {
    var option = _a.option, multivalue = _a.multivalue, valueType = _a.valueType, selectedOptions = _a.selectedOptions;
    // tells us if creating the option will cause a duplicate.
    // creating duplicates has the effect of throwing console errors and/or
    // submitting the form outright
    if (multivalue && option && selectedOptions) {
        if (valueType === ValueType.CONTACT) {
            for (var _i = 0, selectedOptions_1 = selectedOptions; _i < selectedOptions_1.length; _i++) {
                var contact = selectedOptions_1[_i];
                var contactOption = contact;
                if ((contactOption.email &&
                    option.toLocaleLowerCase() === contactOption.email.toLocaleLowerCase()) || (!contactOption.email && option.toLocaleLowerCase() === contactOption.name.toLocaleLowerCase())) {
                    return true;
                }
            }
        }
        else {
            for (var _b = 0, selectedOptions_2 = selectedOptions; _b < selectedOptions_2.length; _b++) {
                var opt = selectedOptions_2[_b];
                if (typeof opt === "string") {
                    if (option.toLocaleLowerCase() === opt.toLocaleLowerCase()) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
};
// handles the behavoir of select options when a key is pressed
var onKeyDown = function (_a) {
    var e = _a.e, valueType = _a.valueType, validateField = _a.validateField, isContactLstNoOptions = _a.isContactLstNoOptions, multivalue = _a.multivalue, willOptCreationCauseDup = _a.willOptCreationCauseDup, isValidEmail = _a.isValidEmail, inputValue = _a.inputValue, selectRef = _a.selectRef, selectedOptions = _a.selectedOptions, editableOptions = _a.editableOptions;
    // prevent the user for typing backslashes at it causes regex's to become invalid messing up the search and causing 
    // console errors
    if (!validateField && e && e.keyCode === 220) {
        return { preventDefault: true };
    }
    if (e && (e.key === "Enter" || e.key === "Tab")) {
        if (willOptCreationCauseDup && multivalue) {
            // prevent duplicates from being processed
            return { preventDefault: true };
        }
        /*
        * This block will prevent a submission if
        * 1) Enter is pressed in a contact dropdown when all the options have been selected
        * 2) Enter is pressed, but the menu is closed
        * 3) When enter is pressed, in a restricted dropdown, but no options match
        */
        if (valueType === ValueType.STRING ||
            (valueType === ValueType.CONTACT &&
                !isContactLstNoOptions)) {
            var menuIsOpen = selectRef && selectRef.state.menuIsOpen;
            var focusedOption = selectRef && selectRef.select.state.focusedOption;
            if ((e && e.key === "Enter" &&
                // scenarios 2) and 3) respectively
                (!menuIsOpen || !focusedOption && validateField)
                ||
                    // scenario 1)
                    (valueType === ValueType.CONTACT &&
                        selectedOptions && editableOptions &&
                        selectedOptions.length === editableOptions.length && !inputValue))) {
                return { preventDefault: true };
            }
        }
        else {
            if (valueType === ValueType.CONTACT) {
                if (isContactLstNoOptions) {
                    if (isValidEmail) {
                        // option: the option to be created
                        // inputValue: empty string as the input needs to be cleared
                        return {
                            preventDefault: true,
                            option: inputValue,
                            state: {
                                invalidEmail: false,
                            },
                            inputValue: "",
                        };
                    }
                    else {
                        // option: the option to be created if inputValue is not an empty string
                        // inputValue: empty string as the input needs to be cleared
                        return {
                            preventDefault: true,
                            option: inputValue || undefined,
                            state: {
                                invalidEmail: !!inputValue
                            },
                            inputValue: ""
                        };
                    }
                }
            }
        }
    }
    return {};
};
// handles the behavoir of a contact list when it's blurred aka loses focus
var onNoOptContactListBlur = function (_a) {
    var inputValue = _a.inputValue, isValidEmail = _a.isValidEmail, isContactLstNoOptions = _a.isContactLstNoOptions;
    var isInputValidEmail = !inputValue || isValidEmail;
    if (isContactLstNoOptions) {
        if (isInputValidEmail) {
            return {
                option: inputValue,
                state: { invalidEmail: false }
            };
        }
        else {
            if (inputValue) {
                return {
                    option: inputValue,
                    state: { invalidEmail: true }
                };
            }
        }
    }
    return {};
};
// determines what happens when the user clicks an option in a restricted dropdown
var restrictedOnChange = function (_a) {
    var mappedSelectedOptions = _a.mappedSelectedOptions, options = _a.options;
    if (options) {
        if (Array.isArray(mappedSelectedOptions)) {
            var selectedValue = options.filter(function (option, index) {
                // since the string is in the format "index_value" this gives us the index
                return mappedSelectedOptions.some(function (_a) {
                    var value = _a.value;
                    return index === Number(value.substring(0, value.indexOf("_")));
                });
            });
            return { onChangeValue: selectedValue };
        }
        else {
            if (mappedSelectedOptions) {
                var value = mappedSelectedOptions.value;
                // since the string is in the format "index_value" this gives us the index
                return { onChangeValue: options[Number(value.substring(0, value.indexOf("_")))] };
            }
        }
    }
    return {};
};
// determines what happens when the user clicks an option in an unrestricted dropdown
var unrestrictedOnChange = function (_a) {
    var mappedSelectedOptions = _a.mappedSelectedOptions, options = _a.options;
    if (options) {
        if (Array.isArray(mappedSelectedOptions)) {
            var selectedValue = options.filter(function (option, index) {
                // since the string is in the format "index_value" this gives us the index
                return mappedSelectedOptions.some(function (_a) {
                    var value = _a.value;
                    return index === Number(value.substring(0, value.indexOf("_")));
                });
            });
            return {
                onChangeValue: selectedValue,
                state: { selectedOptions: selectedValue },
            };
        }
        else {
            return {
                // since the string is in the format "index_value" this gives us the index
                state: { invalidEmail: false },
                onChangeValue: mappedSelectedOptions ? options[Number(mappedSelectedOptions.value.substring(0, mappedSelectedOptions.value.indexOf("_")))] : undefined,
            };
        }
    }
    return {};
};
var toggleDropDownIndicator = function (isContactLstNoOptions, borderless) {
    return !isContactLstNoOptions ? (React.createElement("span", { style: { marginRight: 6 } },
        React.createElement(CaretSvg, { color: borderless ? "#005EE0" : "#161616" }))) : null;
};
var renderErrorMsg = function (inputId, dataClientId, formattedMessageId) {
    return (React.createElement(SelectInputErrorMessage, { inputId: inputId, dataClientId: dataClientId },
        React.createElement(FormattedMessage, { id: formattedMessageId })));
};
// chooses the appropriate error message
var chooseErrorMsg = function (editableOptions, selectedOptions, required, error, isContactLstNoOptions, inputId, multivalue, valueType, inputValue) {
    if (error) {
        if (selectedOptions.length > maxContactLimit) {
            return renderErrorMsg(inputId, "component_error_multi_contact_limit_exceeded_msg", "messages.msg_multiContact_maximumLimit");
        }
        else {
            if (!isContactLstNoOptions) {
                if (!multivalue && valueType === ValueType.CONTACT && !!inputValue) {
                    return renderErrorMsg(inputId, "component_error_invalid_email_msg", "messages.msg_fieldEmailValidation");
                }
                else {
                    return renderErrorMsg(inputId, "component_error_required_msg", "messages.msg_fieldRequired");
                }
            }
            else {
                if (!editableOptions.length) {
                    if (required) {
                        return renderErrorMsg(inputId, "component_error_required_msg", "messages.msg_fieldRequired");
                    }
                }
                else {
                    return renderErrorMsg(inputId, "component_error_invalid_email_msg", "messages.msg_fieldEmailValidation");
                }
            }
        }
    }
};
var generateErrorId = function (inputId) { return "error_msg_" + sanitizeHtmlID(inputId); };
var SelectInputErrorMessage = function (_a) {
    var inputId = _a.inputId, dataClientId = _a.dataClientId, children = _a.children;
    return (React.createElement(ErrorSpan, { "data-client-id": dataClientId },
        React.createElement(WarningImageIcon, { customCss: WarningImageStyled }),
        React.createElement("span", { role: "alert", id: generateErrorId(inputId) }, children)));
};
var Util = {
    handleCreateOption: handleCreateOption,
    isContactLstNoOptions: isContactLstNoOptions,
    onKeyDown: onKeyDown,
    restrictedOnChange: restrictedOnChange,
    unrestrictedOnChange: unrestrictedOnChange,
    chooseErrorMsg: chooseErrorMsg,
    toggleDropDownIndicator: toggleDropDownIndicator,
    willOptCreationCauseDup: willOptCreationCauseDup,
    onNoOptContactListBlur: onNoOptContactListBlur,
    generateErrorId: generateErrorId,
};
export default Util;
var templateObject_1, templateObject_2;
