/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/core';
import { FormattedMessage } from 'react-intl';
import { resolveAssetUrl } from '../../../app/utils';
import ModalContent from '../ModalContent';
import spinnerGif from './img_spinner32_alt_start.gif';

const messageBox = css`
  font-size: 0.88em;
  padding: 20px;
  text-align: center;
  width: 202px;
`;

class FormSubmissionPopup extends React.Component {
  public render() {
    return (
      <ModalContent>
        <div css={messageBox}>
          <img alt='spinner' src={resolveAssetUrl(spinnerGif)} />
          <p>
            <FormattedMessage id="messages.msg_webForm_processing" />
          </p>
        </div>
      </ModalContent>
    );
  }
}

export default FormSubmissionPopup;
