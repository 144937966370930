import * as React from 'react';
import styled from '@emotion/styled/macro';

const DisplayContainer = styled.div<ConditionalDisplayProps>`
    transition: opacity ${props => props.isHiddenByConditionalLogic ? '0.4s': '1s'} ease-in-out, max-height ${props => props.isHiddenByConditionalLogic ? '0.7s': '0.4s'} ease-in-out;    
    opacity: ${props => props.isHiddenByConditionalLogic ? '0': '1'};
    max-height: ${props => props.isHiddenByConditionalLogic ? '0': '5000vh'};
    overflow: ${props => props.isHiddenByConditionalLogic ? 'hidden': 'visible'};

    & > div > input:not([type="checkbox"]), & > div > div {
      margin-bottom: 6px;
      font-size: 0.88em;
    }

    & > div {
      margin-top: 18px;
      margin-bottom: 6px;
    }
`;
interface ConditionalDisplayProps {
  isHiddenByConditionalLogic?: boolean
}

const conditionalDisplay = <T extends object>(
  WrappedComponent: React.ComponentType<T>
) => {
  return class ConditionalDisplay extends React.Component<
    T & ConditionalDisplayProps
  > {       
      render() {
          const {isHiddenByConditionalLogic} = this.props;
          return <DisplayContainer isHiddenByConditionalLogic={isHiddenByConditionalLogic}><WrappedComponent {...this.props} /></DisplayContainer>;
      }
  };
};

export default conditionalDisplay;