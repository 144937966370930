import React from 'react';
var EmptyEffort = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "53px", height: "16px", viewBox: "0 0 53 16", version: "1.1", role: "img" },
        React.createElement("title", null, "Effort Empty"),
        React.createElement("desc", null, "Empty."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(13.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M0,6.0048815 C0,4.89761602 0.893899798,4 2.0048815,4 L2.9951185,4 C4.10238398,4 5,4.8938998 5,6.0048815 L5,8.50230122 C5,8.77717266 4.76806641,9 4.5,9 L4,9 L3.5409171,14.5089948 C3.51831921,14.7801695 3.28596497,15 2.99531555,15 L2.00468445,15 C1.72595492,15 1.48100996,14.7721195 1.4590829,14.5089948 L1,9 L0.5,9 C0.223857625,9 0,8.78515625 0,8.50230122 L0,6.0048815 Z M2.49333333,0 C3.32,0 4,0.669421488 4,1.5 C4,2.33057851 3.32,3 2.49333333,3 C1.68,3 1,2.33057851 1,1.5 C1,0.669421488 1.68,0 2.49333333,0 Z", id: "Combined-Shape" })),
            React.createElement("g", { transform: "translate(2.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M0,6.0048815 C0,4.89761602 0.893899798,4 2.0048815,4 L2.9951185,4 C4.10238398,4 5,4.8938998 5,6.0048815 L5,8.50230122 C5,8.77717266 4.76806641,9 4.5,9 L4,9 L3.5409171,14.5089948 C3.51831921,14.7801695 3.28596497,15 2.99531555,15 L2.00468445,15 C1.72595492,15 1.48100996,14.7721195 1.4590829,14.5089948 L1,9 L0.5,9 C0.223857625,9 0,8.78515625 0,8.50230122 L0,6.0048815 Z M2.49333333,0 C3.32,0 4,0.669421488 4,1.5 C4,2.33057851 3.32,3 2.49333333,3 C1.68,3 1,2.33057851 1,1.5 C1,0.669421488 1.68,0 2.49333333,0 Z", id: "Combined-Shape" })),
            React.createElement("g", { transform: "translate(24.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M0,6.0048815 C0,4.89761602 0.893899798,4 2.0048815,4 L2.9951185,4 C4.10238398,4 5,4.8938998 5,6.0048815 L5,8.50230122 C5,8.77717266 4.76806641,9 4.5,9 L4,9 L3.5409171,14.5089948 C3.51831921,14.7801695 3.28596497,15 2.99531555,15 L2.00468445,15 C1.72595492,15 1.48100996,14.7721195 1.4590829,14.5089948 L1,9 L0.5,9 C0.223857625,9 0,8.78515625 0,8.50230122 L0,6.0048815 Z M2.49333333,0 C3.32,0 4,0.669421488 4,1.5 C4,2.33057851 3.32,3 2.49333333,3 C1.68,3 1,2.33057851 1,1.5 C1,0.669421488 1.68,0 2.49333333,0 Z", id: "Combined-Shape" })),
            React.createElement("g", { transform: "translate(35.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M0,6.0048815 C0,4.89761602 0.893899798,4 2.0048815,4 L2.9951185,4 C4.10238398,4 5,4.8938998 5,6.0048815 L5,8.50230122 C5,8.77717266 4.76806641,9 4.5,9 L4,9 L3.5409171,14.5089948 C3.51831921,14.7801695 3.28596497,15 2.99531555,15 L2.00468445,15 C1.72595492,15 1.48100996,14.7721195 1.4590829,14.5089948 L1,9 L0.5,9 C0.223857625,9 0,8.78515625 0,8.50230122 L0,6.0048815 Z M2.49333333,0 C3.32,0 4,0.669421488 4,1.5 C4,2.33057851 3.32,3 2.49333333,3 C1.68,3 1,2.33057851 1,1.5 C1,0.669421488 1.68,0 2.49333333,0 Z", id: "Combined-Shape" })),
            React.createElement("g", { transform: "translate(46.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M0,6.0048815 C0,4.89761602 0.893899798,4 2.0048815,4 L2.9951185,4 C4.10238398,4 5,4.8938998 5,6.0048815 L5,8.50230122 C5,8.77717266 4.76806641,9 4.5,9 L4,9 L3.5409171,14.5089948 C3.51831921,14.7801695 3.28596497,15 2.99531555,15 L2.00468445,15 C1.72595492,15 1.48100996,14.7721195 1.4590829,14.5089948 L1,9 L0.5,9 C0.223857625,9 0,8.78515625 0,8.50230122 L0,6.0048815 Z M2.49333333,0 C3.32,0 4,0.669421488 4,1.5 C4,2.33057851 3.32,3 2.49333333,3 C1.68,3 1,2.33057851 1,1.5 C1,0.669421488 1.68,0 2.49333333,0 Z", id: "Combined-Shape" })))));
};
export default EmptyEffort;
