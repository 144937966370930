import { __makeTemplateObject, __spreadArray } from "tslib";
import styled from "@emotion/styled/macro";
import * as React from "react";
import { TooltipRebuild } from '../Tooltip';
import DOMPurify from 'dompurify';
import { isStringArray, isContactArray, isContact, NonBooleanOperatorType } from '@smartsheet/forms-model';
import moment from "moment";
import BlueLogicIcon from "./logicBlueIcon";
var Tag = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    z-index: 1;\n    cursor: pointer;\n    height: 12px;\n    padding: 0 10px 8px 5px;\n"], ["\n    z-index: 1;\n    cursor: pointer;\n    height: 12px;\n    padding: 0 10px 8px 5px;\n"])));
var NotOperatorMap = new Map([
    ['IS', 'IS_NOT'],
    ['IS_BLANK', 'IS_NOT_BLANK'],
    ['IS_WITHIN', 'IS_NOT_WITHIN'],
    ['IS_BETWEEN', 'IS_NOT_BETWEEN'],
    ['IS_CHECKED', 'IS_NOT_CHECKED'],
    ['CONTAINS', 'DOES_NOT_CONTAIN']
]);
var DateValueString;
(function (DateValueString) {
    DateValueString["TODAY"] = "today";
    DateValueString["YESTERDAY"] = "yesterday";
    DateValueString["TOMORROW"] = "tomorrow";
    DateValueString["THE_NEXT_WEEK"] = "the next week";
    DateValueString["ONE_WEEK_FROM_NOW"] = "one week from now";
    DateValueString["THE_NEXT_MONTH"] = "the next month";
    DateValueString["ONE_MONTH_FROM_NOW"] = "one month from now";
    DateValueString["THE_PAST_WEEK"] = "the past week";
    DateValueString["ONE_WEEK_AGO"] = "one week ago";
    DateValueString["THE_PAST_MONTH"] = "the past month";
    DateValueString["ONE_MONTH_AGO"] = "one month ago";
    DateValueString["THE_NEXT_YEAR"] = "the next year";
    DateValueString["THE_PAST_YEAR"] = "the past year";
    DateValueString["DAYS_FROM_NOW"] = "{0} days from now";
    DateValueString["DAYS_AGO"] = "{0} days ago";
    DateValueString["PAST_DAYS"] = "the past {0} days";
    DateValueString["NEXT_DAYS"] = "the next {0} days";
    DateValueString["EXACT_DATE"] = "exact date: {0}";
})(DateValueString || (DateValueString = {}));
var validateLogic = function (logicEl) {
    if (logicEl) {
        var rootPredicate = logicEl.predicate;
        if (Array.isArray(rootPredicate.predicates) && rootPredicate.predicates.length === 0) {
            return false;
        }
    }
    return true;
};
var translateLogic = function (logic) {
    var translatedLogic = '';
    var translatedLogicSubstringsMap = {};
    if (logic != null) {
        logic.forEach(function (logicEl) {
            if (validateLogic(logicEl.rule)) {
                var tempTranslatedLogicSubstring = "";
                if (logicEl.rule.predicate != null) {
                    tempTranslatedLogicSubstring += "<span style=\"color: #000;line-height: 10px;\">" + constructHTMLOfPredicate(logicEl.rule.predicate, false, [], logicEl.fieldName) + "</span>";
                    if (typeof translatedLogicSubstringsMap[logicEl.rule.type] === 'undefined') {
                        translatedLogicSubstringsMap[logicEl.rule.type] = [];
                    }
                    translatedLogicSubstringsMap[logicEl.rule.type].push(tempTranslatedLogicSubstring);
                }
            }
        });
    }
    for (var type in translatedLogicSubstringsMap) {
        if (type === 'SHOW_COMPONENT') {
            translatedLogic += "<p>Show this field when</p>";
            var predicateSep = "<span style=\"text-transform:lowercase\"> or </span><br/>";
            translatedLogic += "<p>" + translatedLogicSubstringsMap[type].join(predicateSep) + "</p>";
        }
    }
    // https://github.com/wwayne/react-tooltip#security-note
    translatedLogic = DOMPurify.sanitize(translatedLogic);
    return "<div style=\"line-height: 20px;\">" + translatedLogic + "</div>";
};
// Standard escaping of HTML tags as first level of safety
var replaceHTMLTags = function (value) {
    if (value != null) {
        return value
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }
    return '';
};
var constructHTMLOfPredicate = function (rootPredicate, negate, predicateStrArray, fieldName) {
    var childPredicate = rootPredicate.predicates;
    var notPredicate = rootPredicate.predicate;
    negate = rootPredicate.type === 'NOT' ? !negate : negate;
    if (childPredicate && childPredicate.length) {
        childPredicate.forEach(function (pred) {
            predicateStrArray = __spreadArray([], constructHTMLOfPredicate(pred, negate, predicateStrArray, fieldName));
        });
    }
    else if (notPredicate) {
        predicateStrArray = __spreadArray([], constructHTMLOfPredicate(notPredicate, negate, predicateStrArray, fieldName));
    }
    else if (rootPredicate.binding) {
        var valuesString = constructHTMLOfValues(rootPredicate, rootPredicate.type);
        var escFieldName = replaceHTMLTags(fieldName);
        var operatorString = negate ? NotOperatorMap.get(rootPredicate.type) : rootPredicate.type;
        var predicateStr = "<span style=\"word-break: break-word;font-weight:bold\">" + escFieldName + "</span> <span style=\"text-transform:lowercase\">" + (operatorString ? operatorString.replace(/_/g, " ") : '') + "</span> <span style=\"word-break: break-word;font-weight:bold\">" + valuesString + "</span>";
        predicateStrArray = __spreadArray(__spreadArray([], predicateStrArray), [predicateStr]);
    }
    return predicateStrArray;
};
var constructHTMLOfValues = function (predicate, operator) {
    var value = predicate.value;
    var values = predicate.values;
    var start = predicate.start;
    var end = predicate.end;
    var val = (value && value.value) || (values && values.values);
    var valueString = '';
    switch (operator) {
        case NonBooleanOperatorType.CONTAINS:
            valueString = val;
            break;
        case NonBooleanOperatorType.IS_BLANK:
        case NonBooleanOperatorType.IS_CHECKED:
            valueString = '';
            break;
        case NonBooleanOperatorType.IS_ANY_OF:
        case NonBooleanOperatorType.IS_NONE_OF:
        case NonBooleanOperatorType.IS_EXACTLY:
        case NonBooleanOperatorType.HAS_ALL_OF:
        case NonBooleanOperatorType.HAS_NONE_OF:
        case NonBooleanOperatorType.HAS_ANY_OF:
            valueString = getSelectInputValueString(val) || '';
            break;
        case NonBooleanOperatorType.IS_BEFORE:
        case NonBooleanOperatorType.IS_AFTER:
        case NonBooleanOperatorType.IS_BETWEEN:
        case NonBooleanOperatorType.IS_WITHIN:
            valueString = getDateValueString(value || { start: start, end: end }, operator) || '';
            break;
        case NonBooleanOperatorType.IS:
            valueString = getDateValueString(value, operator) || getSelectInputValueString(val) || '';
    }
    valueString = replaceHTMLTags(valueString);
    return "<span>" + valueString + "</span>";
};
var getSelectInputValueString = function (value) {
    var valueString = null;
    if (Array.isArray(value)) {
        if (isStringArray(value)) {
            valueString = value.join(', ');
        }
        else if (isContactArray(value)) {
            valueString = contactArrayJoin(value);
        }
    }
    else if (isContact(value)) {
        valueString = value.name;
    }
    else if (typeof value === 'string') {
        valueString = value;
    }
    return valueString;
};
var contactArrayJoin = function (values) {
    return values.reduce(function (result, value) {
        return result + value.name + ', ';
    }, '').slice(0, -2);
};
var getDateValueString = function (value, operator) {
    var dateString = null;
    var val = value && value.value;
    if (val && val.unit && val.unit === 'DAYS') {
        val = val.value;
    }
    if (typeof val === 'number') {
        switch (val) {
            case 0:
                dateString = DateValueString.TODAY;
                break;
            case 1:
                dateString = DateValueString.TOMORROW;
                break;
            case -1:
                dateString = DateValueString.YESTERDAY;
                break;
            case 7:
                dateString = operator === 'IS_WITHIN' ? DateValueString.THE_NEXT_WEEK : DateValueString.ONE_WEEK_FROM_NOW;
                break;
            case -7:
                dateString = operator === 'IS_WITHIN' ? DateValueString.THE_PAST_WEEK : DateValueString.ONE_WEEK_AGO;
                break;
            case 31:
                dateString = operator === 'IS_WITHIN' ? DateValueString.THE_NEXT_MONTH : DateValueString.ONE_MONTH_FROM_NOW;
                break;
            case -31:
                dateString = operator === 'IS_WITHIN' ? DateValueString.THE_PAST_MONTH : DateValueString.ONE_MONTH_AGO;
                break;
            case 365:
                dateString = DateValueString.THE_NEXT_YEAR;
                break;
            case -365:
                dateString = DateValueString.THE_PAST_YEAR;
                break;
            default:
                dateString = val > 0 ?
                    operator === 'IS_WITHIN' ? DateValueString.NEXT_DAYS.replace('{0}', val.toString()) : DateValueString.DAYS_FROM_NOW.replace('{0}', val.toString())
                    : operator === 'IS_WITHIN' ? DateValueString.PAST_DAYS.replace('{0}', val.toString()) : DateValueString.DAYS_AGO.replace('{0}', val.toString());
                break;
        }
    }
    else if (typeof val === 'string' && value.type === "DATE_TIME") {
        dateString = DateValueString.EXACT_DATE.replace('{0}', moment(val.split('T')[0]).format('LL'));
    }
    else if (operator === 'IS_BETWEEN') {
        var startDate = value.start.value.split('T')[0];
        var endDate = value.end.value.split('T')[0];
        dateString = moment(startDate).format('LL') + " - " + moment(endDate).format('LL');
    }
    return dateString;
};
var LogicTag = function (props) {
    var logic = props.logic;
    React.useEffect(function () {
        TooltipRebuild();
    }, []);
    return (React.createElement(Tag, { "data-client-id": "component_logic_label_child", "data-tip": translateLogic(logic), "data-html": true, "data-for": 'global' },
        React.createElement(BlueLogicIcon, null)));
};
export default LogicTag;
var templateObject_1;
