import { __makeTemplateObject } from "tslib";
import styled from "@emotion/styled/macro";
import React from "react";
import { OutlineButton, PrimaryButton, CloseButton } from "../Button";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 10;\n  background-color: rgba(0, 0, 0, 0.3);\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 10;\n  background-color: rgba(0, 0, 0, 0.3);\n"])));
var ModalWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: ", "px;\n    height: ", "px;\n    border-radius: 2px;\n    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2), 0 0 2px 0 rgba(0, 0, 0, 0.05);\n    background-color: #ffffff;\n    margin: auto;\n    font-family: Roboto;\n    font-size: 1em;\n    font-weight: normal;\n    font-style: normal;\n    font-stretch: normal;\n    line-height: 1.15;\n    letter-spacing: normal;\n"], ["\n    width: ", "px;\n    height: ", "px;\n    border-radius: 2px;\n    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2), 0 0 2px 0 rgba(0, 0, 0, 0.05);\n    background-color: #ffffff;\n    margin: auto;\n    font-family: Roboto;\n    font-size: 1em;\n    font-weight: normal;\n    font-style: normal;\n    font-stretch: normal;\n    line-height: 1.15;\n    letter-spacing: normal;\n"])), function (props) { return props.width; }, function (props) { return props.height; });
var ModalHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    height: 50px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 20px;\n"], ["\n    height: 50px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 20px;\n"])));
var HeaderTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 1.13em;\n    font-weight: 300;\n    line-height: 1.17;\n"], ["\n    font-size: 1.13em;\n    font-weight: 300;\n    line-height: 1.17;\n"])));
var ModalContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    padding: 5px 20px 17px 20px;\n"], ["\n    padding: 5px 20px 17px 20px;\n"])));
var ModalFooter = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;    \n    padding: 5px 20px 5px 10px;\n    flex-direction: row-reverse;\n"], ["\n    display: flex;\n    align-items: center;    \n    padding: 5px 20px 5px 10px;\n    flex-direction: row-reverse;\n"])));
var AlignButton = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin: 0 10px;\n"], ["\n    margin: 0 10px;\n"])));
var ConfirmationModal = function (props) {
    var title = props.title, content = props.content, btnLeft = props.btnLeft, btnCenter = props.btnCenter, btnRight = props.btnRight, onLeftBtnClick = props.onLeftBtnClick, onCenterBtnClick = props.onCenterBtnClick, onRightBtnClick = props.onRightBtnClick, onCloseBtnClick = props.onCloseBtnClick, height = props.height, width = props.width;
    return (React.createElement(Wrapper, null,
        React.createElement(ModalWrapper, { "data-client-id": "confirmation_modal", height: height, width: width },
            React.createElement(ModalHeader, null,
                React.createElement(HeaderTitle, null, title),
                React.createElement(CloseButton, { "data-client-id": 'confirmation_modal_close', onClick: onCloseBtnClick })),
            React.createElement(ModalContent, null, content),
            React.createElement(ModalFooter, null,
                React.createElement(PrimaryButton, { "data-client-id": 'confirmation_modal_btn_primary', onClick: onRightBtnClick }, btnRight),
                btnCenter && React.createElement(AlignButton, null,
                    React.createElement(OutlineButton, { "data-client-id": 'confirmation_modal_btn_center', onClick: onCenterBtnClick }, btnCenter)),
                btnLeft && React.createElement(AlignButton, null,
                    React.createElement(OutlineButton, { "data-client-id": 'confirmation_modal_btn_left', onClick: onLeftBtnClick }, btnLeft))))));
};
export default ConfirmationModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
