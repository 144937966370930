import * as React from 'react';
import styled from '@emotion/styled/macro';
import { FormConfirmation } from '../../../../app/types';
import { FormTitle } from '../../../../app/components/FormHeader';
import FormPage from '../StyledComponents/FormPage';
import FormPanel from '../StyledComponents/FormPanel';
import FormLogo from './FormLogo';
import InlineConfirmation from './InlineConfirmation';

const RichTitle = styled.div`
  & p {
    margin-block: 2px;
  }
  & ol {
    margin-block: 2px;
    list-style-position: inside;
  }
  & ul {
    margin-block: 2px;
    list-style-position: inside;
  }
  font-size: 32px;
`;

// CondensedLayout is used to render the Classic
// (also known as Branded) and Plain form styles.
interface CondensedLayoutProps {
  confirmation?: FormConfirmation;
  renderForm: (scrollToTop: () => void) => React.ReactNode;
  shouldRenderInlineConfirmation: boolean;
  shouldRenderLogo: boolean;
  title?: string;
  richTitle?: string;
  richDescription?: string;
  isRichTextEnabled?: boolean;
}

const CondensedLayout: React.FC<CondensedLayoutProps> = ({
  confirmation,
  renderForm,
  shouldRenderInlineConfirmation,
  shouldRenderLogo,
  title,
  richTitle,
  isRichTextEnabled,
}) => (
  <FormPage>
    {shouldRenderLogo && <FormLogo />}
    <FormPanel role="form">
      {shouldRenderInlineConfirmation && (
        <InlineConfirmation confirmation={confirmation} />
      )}
      {(isRichTextEnabled && richTitle) && <RichTitle dangerouslySetInnerHTML={{__html: richTitle}} data-client-id="form_title"/> ||
       title && <FormTitle data-client-id="form_title">{title}</FormTitle>}
      {renderForm( () => window.scroll(0,0))}
    </FormPanel>
  </FormPage>
);

export default CondensedLayout;
