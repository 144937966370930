import { jsx } from '@emotion/core';
var ImageIcon = function () {
    return (jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", role: "img" },
        jsx("title", null, "Image Attachment Icon"),
        jsx("desc", null, "Image Icon"),
        jsx("g", { fill: "#005EE0", fillRule: "evenodd", transform: "translate(0 -.003)" },
            jsx("rect", { width: "16", height: "16", fillOpacity: ".1", rx: "1" }),
            jsx("path", { fillRule: "nonzero", d: [
                    'M6.973 13h-4.09a.5.5 0 0 1-.429-.757l2.117-3.528a.5.5 0 0 1 .858 ',
                    '0l1.123 1.871 2.527-3.932a.5.5 0 0 1 .842 0l3.584 5.576a.5.5 0 0 ',
                    '1-.42.77H6.972zm.424-1.005L7.4 12h4.768L9.5 7.85l-2.373 3.69.27.4',
                    '55.149.248a.5.5 0 0 1-.43.757h-.143l.424-1.005zM3.767 12h2.467L5 ',
                    '9.944 3.766 12zM4.5 6.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-1a1 1 0 1 ',
                    '0 0-2 1 1 0 0 0 0 2z',
                ].join('') }))));
};
export default ImageIcon;
