import {Contact, isContact, isContactArray, isStringArray, NonBooleanOperatorType, FactValue, LogicDateValue, LogicExactDateValue, LogicInBetweenDateValue} from "../../../app/types";
import _isEqual from "lodash.isequal";
import _xorWith from "lodash.xorwith";
import _findIndex from "lodash.findindex";
import _some from "lodash.some";
import _every from "lodash.every";
import moment, { Moment } from "moment";
import { CheckboxOption } from "@smartsheet/forms-components/esm/Checkbox/MultiCheckboxInput";

enum DatePart {
    DAY = 'day',
    MONTH = 'month',
    YEAR = 'year',
    WEEK = 'week'
}

const NotOperatorMap: Map<string, string> = new Map([
    [NonBooleanOperatorType.IS, NonBooleanOperatorType.IS_NOT],
    [NonBooleanOperatorType.IS_BLANK, NonBooleanOperatorType.IS_NOT_BLANK],
    [NonBooleanOperatorType.IS_BETWEEN, NonBooleanOperatorType.IS_NOT_BETWEEN],
    [NonBooleanOperatorType.IS_WITHIN, NonBooleanOperatorType.IS_NOT_WITHIN],
    [NonBooleanOperatorType.IS_CHECKED, NonBooleanOperatorType.IS_NOT_CHECKED],
    [NonBooleanOperatorType.CONTAINS, NonBooleanOperatorType.DOES_NOT_CONTAIN]
]);

const isDateValue = (value: any): value is LogicDateValue => {
    if((value as LogicExactDateValue) && value.type === 'DATE_TIME' && typeof value.value === 'string' && value.value.trim() !== '') {
        return true;
    }else if((value as LogicInBetweenDateValue) && value.start && value.end && value.start.type === 'DATE_TIME' &&  value.end.type === 'DATE_TIME') {
        return value.start.value.trim() !== '' && value.end.value.trim() !== '';
    }
    return typeof value === 'number';
}
    // Check if there's any multi checkbox value selected and compare it with logic
const isMultiCheckboxValue = (factValue: FactValue, jsonValue: FactValue, condition: string) => {
    if (Array.isArray(factValue) && factValue.length && (factValue[0] as CheckboxOption).checked != null) {
        const checkedValue: string[] = [];
        factValue.forEach((fact: any) => {
           if (fact.checked) {
               checkedValue.push(fact.value as string);
           }
        });
        if (condition === 'hasAnyOf') {
            if (isStringArray(jsonValue) && isStringArray(checkedValue) && checkedValue.length > 0) {
                return _some(jsonValue, (val) => checkedValue.indexOf(val) > -1);
            }
        } else if (condition === 'hasAllOf') {
            if (isStringArray(jsonValue) && isStringArray(checkedValue) && checkedValue.length > 0) {
                return _every(jsonValue, (val) => checkedValue.indexOf(val) > -1);
            }
        }  else if (condition === 'hasNoneOf') {
            if (isStringArray(jsonValue) && isStringArray(checkedValue) && checkedValue.length > 0) {
                return _every(jsonValue, (val) => checkedValue.indexOf(val) === -1);
            }
        }  else if (condition === 'isExactly') {
            if (isStringArray(jsonValue) && isStringArray(checkedValue) && checkedValue.length > 0) {
                return _xorWith<string | Contact | CheckboxOption>(checkedValue, jsonValue, _isEqual).length === 0;
            }
        } 
    }
    return false;
}

const getLogicDateValue = (dateValue: any): Moment[] => {
    if (typeof dateValue === 'number') {
        return [moment().add(dateValue, DatePart.DAY)];
    }
    else if((dateValue as LogicExactDateValue) && dateValue.type && dateValue.type === "DATE_TIME"){        
        return [moment(dateValue.value.split('T')[0])];
    }else{        
        return (dateValue as LogicInBetweenDateValue) && [moment(dateValue.start.value.split('T')[0]), moment(dateValue.end.value.split('T')[0])];
    }
}

const is = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (isDateValue(jsonValue) && typeof factValue === 'string' && factValue !== '') {
        const [logicValue] = getLogicDateValue(jsonValue);
        const fact = moment(factValue, moment.localeData().longDateFormat('L'));               
        if (fact.isValid() && logicValue.isValid()) {                        
            return logicValue.isSame(fact, DatePart.DAY);
        }        
        return false;
    }
    return _isEqual(factValue, jsonValue) && factValue !== '';
}

const isNot = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (isDateValue(jsonValue) && typeof factValue === 'string' && factValue !== '') {
        const [logicValue] = getLogicDateValue(jsonValue);
        const fact: Moment = moment(factValue, moment.localeData().longDateFormat('L'));
        if (fact.isValid() && logicValue.isValid()) {
            return !fact.isSame(logicValue, DatePart.DAY);
        }
        return false;
    }
    return !_isEqual(factValue, jsonValue) && factValue !== '';
}

const contains = (factValue: FactValue, jsonValue: FactValue): boolean => {

    if (typeof factValue === 'string' && factValue.trim() !== "" && typeof jsonValue === 'string' && jsonValue.trim() !== "") {
        return factValue.toLowerCase().indexOf(jsonValue.toLowerCase()) > -1;
    } else if (typeof factValue === 'number' && typeof jsonValue === 'string' && jsonValue.trim() !== "") { // numeric, percentage, currency
        return factValue.toString().indexOf(jsonValue.toLowerCase()) > -1;
    }
    return false;
}

const doesNotContain = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (typeof factValue === 'string' && factValue.trim() !== ""&& typeof jsonValue === 'string' && jsonValue.trim() !== "") {
        return factValue.toLowerCase().indexOf(jsonValue.toLowerCase()) === -1;
    } else if (typeof factValue === 'number' && typeof jsonValue === 'string' && jsonValue.trim() !== "") { // numeric, percentage, currency
        return factValue.toString().indexOf(jsonValue.toLowerCase()) === -1;
    }
    return false;
}

const isAnyOf = (factValue: FactValue, jsonValue: FactValue): boolean => {    
    if (isStringArray(jsonValue) && typeof factValue === 'string') {
        return jsonValue.indexOf(factValue) > -1;
    }
    if (isContactArray(jsonValue) && isContact(factValue)) {
        return _findIndex<Contact>(jsonValue, factValue) > -1;
    }
    return false;
}

const isNoneOf = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (isStringArray(jsonValue) && typeof factValue === 'string' && factValue !== '') {
        return jsonValue.indexOf(factValue) === -1;
    }
    if (isContactArray(jsonValue) && isContact(factValue)) {
        return _findIndex<Contact>(jsonValue, factValue) === -1;
    }
    return false;
}

const isBlank = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (typeof factValue === 'string') {
        return factValue.trim() === "";
    }

    // Phone, number, percentage
    if (typeof factValue === 'number') {
        return factValue.toString().length === 0;
    }
    // Check if there's any multi checkbox value selected
    if (Array.isArray(factValue) && factValue.length && (factValue[0] as CheckboxOption).checked != null) {
        const checkedValue: string[] = [];
        factValue.forEach((fact: any) => {
           if (fact.checked) {
               checkedValue.push(fact.value as string);
           }
        });
        return checkedValue.length === 0;
    }

    if (Array.isArray(factValue)) {
        return factValue.length === 0;
    }
    return false;    
}

const isNotBlank = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (typeof factValue === 'string') {
        return factValue.trim() !== "";
    }

    // Phone, number, percentage
    if (typeof factValue === 'number') {
        return factValue.toString().length !== 0;
    }
    if (isContact(factValue)) {
        return true;
    }
        // Check if there's any multi checkbox value selected
    if (Array.isArray(factValue) && factValue.length && (factValue[0] as CheckboxOption).checked != null) {
        const checkedValue: string[] = [];
        factValue.forEach((fact: any) => {
           if (fact.checked) {
               checkedValue.push(fact.value as string);
           }
        });
        return checkedValue.length > 0;
    }
    
    if (Array.isArray(factValue)) {
        return factValue.length > 0;
    }
    return false;
}

const hasAnyOf = (factValue: FactValue, jsonValue: FactValue): boolean => {    
    if (isStringArray(jsonValue) && isStringArray(factValue) && factValue.length > 0) {
        return _some(jsonValue, (val) => factValue.indexOf(val) > -1);
    }
    if (isContactArray(jsonValue) && isContactArray(factValue) && factValue.length > 0) {
        return _some(jsonValue, (val) => _findIndex<Contact>(factValue, val) > -1);
    }
    return isMultiCheckboxValue(factValue, jsonValue, 'hasAnyOf');      
}

const hasAllOf = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (isStringArray(jsonValue) && isStringArray(factValue) && factValue.length > 0) {
        return _every(jsonValue, (val) => factValue.indexOf(val) > -1);
    }
    if (isContactArray(jsonValue) && isContactArray(factValue) && factValue.length > 0) {
        return _every(jsonValue, (val)=> _findIndex<Contact>(factValue, val) > -1);
    }
    return isMultiCheckboxValue(factValue, jsonValue, 'hasAllOf');
}

const isExactly = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if(Array.isArray(jsonValue) && (isStringArray(factValue) || isContactArray(factValue)) && Array.isArray(factValue) && factValue.length > 0) {                
        return _xorWith<string | Contact | CheckboxOption>(factValue, jsonValue, _isEqual).length === 0;
    }
    return isMultiCheckboxValue(factValue, jsonValue, 'isExactly');   
}

const hasNoneOf = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (isStringArray(jsonValue) && isStringArray(factValue) && factValue.length > 0) {
        return _every(jsonValue, (val) => factValue.indexOf(val) === -1);
    }
    if (isContactArray(jsonValue) && isContactArray(factValue) && factValue.length > 0) {
        return _every(jsonValue, (val)=> _findIndex<typeof val>(factValue, val) === -1);
    }
    return isMultiCheckboxValue(factValue, jsonValue, 'hasNoneOf');
}

const isChecked = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (typeof factValue === 'boolean') {
        return factValue;
    }
    return false;
}

const isNotChecked = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (typeof factValue === 'boolean') {
        return !factValue;
    }
    return false;
}

const isBefore = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (isDateValue(jsonValue) && typeof factValue === 'string') {
        const [logicValue] = getLogicDateValue(jsonValue);
        const fact: Moment = moment(factValue, moment.localeData().longDateFormat('L'));
        if (fact.isValid() && logicValue.isValid()) {
            return fact.isBefore(logicValue, DatePart.DAY);
        }
        return false;
    }
    return false;
}

const isAfter = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (isDateValue(jsonValue) && typeof factValue === 'string') {
        const [logicValue] = getLogicDateValue(jsonValue);
        const fact: Moment = moment(factValue, moment.localeData().longDateFormat('L'));
        if (fact.isValid() && logicValue.isValid()) {
            return fact.isAfter(logicValue, DatePart.DAY);
        }
        return false;
    }
    return false;
}

const isBetween = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (isDateValue(jsonValue) && typeof factValue === 'string') {
        const [startValue, endValue] = getLogicDateValue(jsonValue);
        const fact: Moment = moment(factValue, moment.localeData().longDateFormat('L'));
        if (fact.isValid() && startValue.isValid() && endValue.isValid()) {
            if (startValue.isSameOrBefore(endValue, DatePart.DAY)) {
                return fact.isBetween(startValue, endValue, DatePart.DAY, '[]');
            } else {
                return fact.isBetween(endValue, startValue, DatePart.DAY, '[]');
            }
        }
        return false;
    }
    return false;
}

const isNotBetween = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (isDateValue(jsonValue) && typeof factValue === 'string') {
        const [startValue, endValue] = getLogicDateValue(jsonValue);
        const fact: Moment = moment(factValue, moment.localeData().longDateFormat('L'));
        if (fact.isValid() && startValue.isValid() && endValue.isValid()) {
            if (startValue.isSameOrBefore(endValue, DatePart.DAY)) {
                return !fact.isBetween(startValue, endValue, DatePart.DAY, '[]');
            } else {
                return !fact.isBetween(endValue, startValue, DatePart.DAY, '[]');
            }
        }
        return false;
    }
    return false;
}

const isWithin = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (isDateValue(jsonValue) && typeof factValue === 'string') {
        const [startValue] = getLogicDateValue(jsonValue);
        const endValue: Moment = moment();
        const fact: Moment = moment(factValue, moment.localeData().longDateFormat('L'));
        if (fact.isValid() && startValue.isValid() && endValue.isValid()) {
            if (startValue.isSameOrBefore(endValue, DatePart.DAY)) {
                return fact.isBetween(startValue, endValue, DatePart.DAY, '[]');
            } else {
                return fact.isBetween(endValue, startValue, DatePart.DAY, '[]');
            }
        }
        return false;
    }
    return false;
}

const isNotWithin = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if (isDateValue(jsonValue) && typeof factValue === 'string') {
        const [startValue] = getLogicDateValue(jsonValue);
        const endValue: Moment = moment();
        const fact: Moment = moment(factValue, moment.localeData().longDateFormat('L'));
        if (fact.isValid() && startValue.isValid() && endValue.isValid()) {
            if (startValue.isSameOrBefore(endValue, DatePart.DAY)) {
                return !fact.isBetween(startValue, endValue, DatePart.DAY, '[]');
            } else {
                return !fact.isBetween(endValue, startValue, DatePart.DAY, '[]');
            }
        }
        return false;
    }
    return false;
}

const runtimeIs = (factValue: FactValue, jsonValue: FactValue): boolean => {
    if(typeof factValue === 'boolean') {
        return factValue;
    }
    return true;
}


export const Operators: {[key: string]: (factValue: FactValue, jsonValue: FactValue) => boolean} = {
    [NonBooleanOperatorType.IS]:  is,
    [NonBooleanOperatorType.IS_NOT]: isNot,
    [NonBooleanOperatorType.IS_ANY_OF]: isAnyOf,
    [NonBooleanOperatorType.IS_NONE_OF]: isNoneOf,
    [NonBooleanOperatorType.IS_BLANK]: isBlank,
    [NonBooleanOperatorType.IS_NOT_BLANK]: isNotBlank,
    [NonBooleanOperatorType.HAS_ANY_OF]: hasAnyOf,
    [NonBooleanOperatorType.HAS_ALL_OF]: hasAllOf,
    [NonBooleanOperatorType.IS_EXACTLY]: isExactly,
    [NonBooleanOperatorType.HAS_NONE_OF]: hasNoneOf,
    [NonBooleanOperatorType.IS_CHECKED]: isChecked,
    [NonBooleanOperatorType.IS_NOT_CHECKED]: isNotChecked,
    [NonBooleanOperatorType.IS_BEFORE]: isBefore,
    [NonBooleanOperatorType.IS_AFTER]: isAfter,
    [NonBooleanOperatorType.IS_BETWEEN]: isBetween,
    [NonBooleanOperatorType.IS_NOT_BETWEEN]: isNotBetween,
    [NonBooleanOperatorType.IS_WITHIN]: isWithin,
    [NonBooleanOperatorType.IS_NOT_WITHIN]: isNotWithin,
    [NonBooleanOperatorType.CONTAINS]: contains,
    [NonBooleanOperatorType.DOES_NOT_CONTAIN]: doesNotContain,
    [NonBooleanOperatorType.RUNTIME_IS]: runtimeIs,
}

export const getOperator = (operator: string, negate: boolean): string => {
    return negate ? NotOperatorMap.get(operator)! : operator;
}