import { jsx } from '@emotion/core';
var AudioIcon = function () {
    return (jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", role: "img" },
        jsx("title", null, "Audio Attachment Icon"),
        jsx("desc", null, "Audio Icon"),
        jsx("g", { fill: "#005EE0", fillRule: "evenodd", transform: "translate(0 -.003)" },
            jsx("rect", { width: "16", height: "16", fillOpacity: ".1", rx: "1" }),
            jsx("path", { d: [
                    'M12.055 4.227a.5.5 0 1 1 .89-.454C13.647 5.149 14 6.545 14 7.957c',
                    '0 1.415-.352 2.837-1.051 4.263a.5.5 0 0 1-.898-.44c.634-1.293.949',
                    '-2.566.949-3.823 0-1.25-.314-2.491-.945-3.73zm-2.5 2a.5.5 0 1 1 .',
                    '89-.454c.369.722.555 1.459.555 2.205 0 .747-.185 1.496-.551 2.242',
                    'a.5.5 0 0 1-.898-.44c.3-.613.449-1.213.449-1.802 0-.584-.147-1.16',
                    '6-.445-1.75zM2 6.995c0-.273.214-.549.484-.616L4 6l3.2-2.4c.442-.3',
                    '31.8-.144.8.395v8.01c0 .55-.355.729-.8.395L4 10l-1.516-.379A.663.',
                    '663 0 0 1 2 9.005v-2.01zM3 8.72l1.438.36L7 11V5L4.438 6.921 3 7.2',
                    '81v1.438z',
                ].join('') }))));
};
export default AudioIcon;
