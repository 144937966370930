/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import { StyledFormTheme } from '../styled';
import { submitMetrics } from '../../../utils';

interface FormLogoProps {
  backgroundPosition?: 'left' | 'center';
  theme?: StyledFormTheme
}

const FormLogo: React.FC<FormLogoProps> = ({
  backgroundPosition = 'center',
  theme,
}) => {
  const backgroundUrl = theme && theme.style.logo.image;
  const hasBackground = backgroundUrl && backgroundUrl !== 'none';

  if (hasBackground) {
    // submit metrics if rendered form has a logo
    submitMetrics({
      id: '',
      event: 'Form_Render_BackgroundLogo',
      element: 'Render_BackgroundLogo',
      value: '',
    });

    return (<div
      aria-label="Logo"
      title="Logo"
      css={{
        background: `url(${backgroundUrl}) no-repeat`,
        backgroundPosition,
        backgroundSize: 'contain',
        margin: '10px 0px 10px 0px',
        maxHeight: 60,
        minHeight: 60,
        width: 240,
      }}
    />);

  }

  // return empty fragment if the logo should be hidden for accessibility
  return <React.Fragment/>;
};

export default withTheme(FormLogo);
