export class OpenFormError extends Error {
  public name = 'OpenFormError';
}

export class NetworkError extends Error {
  public name = 'NetworkError';
}

export class HttpResponseError extends Error {
  public readonly message: string;
  public readonly errorType?: HttpResponseErrorType;
  public readonly timeStamp?: string;
  public readonly messageParams?: { [key: string]: string; };

  constructor(
    message: string,
    errorType?: HttpResponseErrorType,
    timeStamp?: string,
    messageParams?:{}
  ) {
    super(message);
    this.message = message;
    this.errorType = errorType;
    this.timeStamp = timeStamp;
    this.name = 'HttpResponseError';
    this.messageParams = messageParams;
  }
}
// See service-forms/forms-core/src/main/java/com/smartsheet/forms/exceptions/Error.java
export enum HttpResponseErrorType {
  DEFAULT = 'DEFAULT',
  FORM_NOT_FOUND = 'FORM_NOT_FOUND',
  FORM_NOT_ACTIVE = 'FORM_NOT_ACTIVE',
  GRID_LOCK_NOT_AWARDED = 'GRID_LOCK_NOT_AWARDED',
  INVALID_REQUEST_CAPTCHA_TOKEN_TIMEDOUT_OR_DUPLICATE='INVALID_REQUEST_CAPTCHA_TOKEN_TIMEDOUT_OR_DUPLICATE',
  INVALID_REQUEST_CLIENT_VERSION_UPDATE_REQUIRED = 'INVALID_REQUEST_CLIENT_VERSION_UPDATE_REQUIRED',
  INVALID_REQUEST_FILE_EMPTY = 'INVALID_REQUEST_FILE_EMPTY',
  INVALID_REQUEST_FILE_UPLOAD_FAILURE = 'INVALID_REQUEST_FILE_UPLOAD_FAILURE',
  INVALID_REQUEST_INVALID_CONTACT_EMAIL = 'INVALID_REQUEST_INVALID_CONTACT_EMAIL',
  INVALID_REQUEST_INVALID_DURATION_VALUE = 'INVALID_REQUEST_INVALID_DURATION_VALUE',
  INVALID_REQUEST_INVALID_FILE_EXTENSION = 'INVALID_REQUEST_INVALID_FILE_EXTENSION',
  INVALID_REQUEST_MAX_MULTIPICKLIST_CHAR_COUNT_EXCEEDED = 'INVALID_REQUEST_MAX_MULTIPICKLIST_CHAR_COUNT_EXCEEDED',
  INVALID_SUBMISSION_TOKEN = 'INVALID_SUBMISSION_TOKEN',
  UNEXPECTED_FILE_UPLOAD_FAILURE = 'UNEXPECTED_FILE_UPLOAD_FAILURE',
}

export function isOpenFormError(error?: Error): error is OpenFormError {
  return error ? error.name === 'OpenFormError' : false;
}

export function isNetworkError(error?: Error): error is NetworkError {
  return error ? error.name === 'NetworkError' : false;
}

export function isHttpResponseError(error?: Error): error is HttpResponseError {
  return error ? error.name === 'HttpResponseError' : false;
}
