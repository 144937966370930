import { __makeTemplateObject } from "tslib";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/core";
export var CheckboxImgSpan = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    @media screen and (-webkit-min-device-pixel-ratio: 0) {\n        margin-left: -8px !important;\n    }\n    @supports (-ms-ime-align: auto) {\n    }\n    @media screen and (-ms-high-contrast: active),\n        screen and (-ms-high-contrast: none) {\n        margin-left: -21.5px !important;\n    }\n    @-moz-document url-prefix() {\n        margin-left: -21.5px !important;\n    }\n    input:focus + & {\n        outline-color: #0175ff;\n        outline-style: auto;\n        outline-width: 1px;\n        outline-offset: 2px;\n    }\n"], ["\n    @media screen and (-webkit-min-device-pixel-ratio: 0) {\n        margin-left: -8px !important;\n    }\n    @supports (-ms-ime-align: auto) {\n    }\n    @media screen and (-ms-high-contrast: active),\n        screen and (-ms-high-contrast: none) {\n        margin-left: -21.5px !important;\n    }\n    @-moz-document url-prefix() {\n        margin-left: -21.5px !important;\n    }\n    input:focus + & {\n        outline-color: #0175ff;\n        outline-style: auto;\n        outline-width: 1px;\n        outline-offset: 2px;\n    }\n"])));
export var CheckboxLabelContainer = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-left: 7px;\n    font-size: 0.88em;\n    pointer-events: auto;\n    cursor: pointer;\n    word-wrap: break-word;\n    overflow-wrap: break-word;\n    flex: 1 0 0;\n"], ["\n    margin-left: 7px;\n    font-size: 0.88em;\n    pointer-events: auto;\n    cursor: pointer;\n    word-wrap: break-word;\n    overflow-wrap: break-word;\n    flex: 1 0 0;\n"])));
export var WarningImageStyled = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin-right: 0.3rem;\n"], ["\n    margin-right: 0.3rem;\n"])));
export var InputVisible = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    opacity: 1;\n    margin: 0;\n    margin-top: 1px;\n    height: 16px;\n    align-self: normal;\n    width: 16px;\n    border: 1px solid #cccccc;\n    border-radius: 2px;\n    background-color: #ffffff;\n    cursor: pointer;\n    &:hover {\n        border: 1px solid #a0a0a0;\n        background-color: #f3f3f3;\n    }\n    &:checked:hover {\n        background-color: #005cbc;\n        border: 1px solid #005cbc;\n    }\n    &:checked {\n        border: 1px solid #529aff;\n        background-color: #529aff;\n        display: flex;\n        justify-content: center;\n        &:after {\n            content: \"\";\n            width: 4px;\n            height: 8px;\n            border-width: 0 2px 2px 0;\n            transform: rotate(45deg);\n        }\n    }\n    &:not(:first-of-type) {\n        margin-top: 7px;\n    }\n"], ["\n    opacity: 1;\n    margin: 0;\n    margin-top: 1px;\n    height: 16px;\n    align-self: normal;\n    width: 16px;\n    border: 1px solid #cccccc;\n    border-radius: 2px;\n    background-color: #ffffff;\n    cursor: pointer;\n    &:hover {\n        border: 1px solid #a0a0a0;\n        background-color: #f3f3f3;\n    }\n    &:checked:hover {\n        background-color: #005cbc;\n        border: 1px solid #005cbc;\n    }\n    &:checked {\n        border: 1px solid #529aff;\n        background-color: #529aff;\n        display: flex;\n        justify-content: center;\n        &:after {\n            content: \"\";\n            width: 4px;\n            height: 8px;\n            border-width: 0 2px 2px 0;\n            transform: rotate(45deg);\n        }\n    }\n    &:not(:first-of-type) {\n        margin-top: 7px;\n    }\n"])));
export var InputHidden = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: block;\n    border-width: 1px;\n    margin-top: 0px;\n    appearance: none;\n    opacity: 0;\n"], ["\n    display: block;\n    border-width: 1px;\n    margin-top: 0px;\n    appearance: none;\n    opacity: 0;\n"])));
export var CheckboxContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0;\n    margin-bottom: 8px;\n    margin-right: 18px;\n    align-items: center;\n\n    & > input:not([type=\"checkbox\"]),\n    & > div {\n        margin-top: 7px;\n        margin-bottom: -7px;\n    }\n"], ["\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0;\n    margin-bottom: 8px;\n    margin-right: 18px;\n    align-items: center;\n\n    & > input:not([type=\"checkbox\"]),\n    & > div {\n        margin-top: 7px;\n        margin-bottom: -7px;\n    }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
