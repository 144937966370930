import React from 'react';
var Yes = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14px", height: "14px", viewBox: "0 0 14 14", version: "1.1", role: "img" },
        React.createElement("title", null, "Decision Yes"),
        React.createElement("desc", null, "Yes."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", null,
                React.createElement("g", { fill: "#57B370" },
                    React.createElement("circle", { id: "Oval", cx: "7", cy: "7", r: "7" })),
                React.createElement("path", { d: "M3.64644661,6.43933983 L2.93933983,7.14644661 C2.74407768,7.34170876 2.74407768,7.65829124 2.93933983,7.85355339 L6.18356532,11.0977789 C6.37882746,11.293041 6.69540995,11.293041 6.8906721,11.0977789 C6.90242059,11.0860304 6.91357619,11.0737036 6.92409736,11.0608444 L12.0905762,4.74625914 C12.26544,4.53253673 12.2339388,4.21752538 12.0202164,4.0426616 L11.2462591,3.40942381 C11.0325367,3.23456002 10.7175254,3.26606115 10.5426616,3.47978356 L6.42576258,8.51154902 L4.35355339,6.43933983 C4.15829124,6.24407768 3.84170876,6.24407768 3.64644661,6.43933983 Z", id: "Line", fill: "#FFFFFF" })))));
};
export default Yes;
