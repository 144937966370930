import { jsx } from '@emotion/core';
import AudioIcon from './AudioIcon';
import DefaultAttachmentIcon from './DefaultAttachmentIcon';
import EvernoteIcon from './EvernoteIcon';
import HtmlIcon from './HtmlIcon';
import ImageIcon from './ImageIcon';
import MsExcelIcon from './MsExcelIcon';
import MsPowerpointIcon from './MsPowerpointIcon';
import MsProjectIcon from './MsProjectIcon';
import MsWordIcon from './MsWordIcon';
import VectorIcon from './VectorIcon';
import VideoIcon from './VideoIcon';
import ZipIcon from './ZipIcon';
var AttachmentsIcon = function (_a) {
    var fileExtension = _a.fileExtension;
    switch (fileExtension) {
        case 'doc':
        case 'docx':
            return jsx(MsWordIcon, null);
        case 'ppt':
        case 'pptx':
            return jsx(MsPowerpointIcon, null);
        case 'xls':
        case 'xlsx':
            return jsx(MsExcelIcon, null);
        case 'mpp':
        case 'mpd':
        case 'mpt':
            return jsx(MsProjectIcon, null);
        case 'html':
        case 'htm':
            return jsx(HtmlIcon, null);
        case 'wav':
        case 'mp3':
        case 'wma':
        case 'ogg':
            return jsx(AudioIcon, null);
        case 'avi':
        case 'flv':
        case 'wmv':
        case 'mov':
        case 'mp4':
            return jsx(VideoIcon, null);
        case 'jpg':
        case 'png':
        case 'gif':
        case 'bmp':
        case 'jpeg':
        case 'psd':
            return jsx(ImageIcon, null);
        case 'svg':
            return jsx(VectorIcon, null);
        case 'enex':
            return jsx(EvernoteIcon, null);
        case 'zip':
        case 'rar':
        case 'iso':
            return jsx(ZipIcon, null);
        default:
            return jsx(DefaultAttachmentIcon, null);
    }
};
export default AttachmentsIcon;
