var countryList = ['us', 'ar', 'at', 'au', 'be', 'br', 'bg', 'ca', 'cl', 'cn', 'hl', 'cy', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'gr', 'hk', 'hu', 'in', 'il', 'ie', 'it', 'jp', 'lv', 'lt', 'lu', 'mt', 'mx', 'my', 'nl', 'nz', 'no', 'pl', 'pt', 'ro', 'ru', 'sk', 'si', 'sg', 'za', 'es', 'kr', 'se', 'ch', 'gb', 'ae', 'bh', 'kw', 'sa', 'ua', 'qa'];
export var areaCodeDisplayMask = {
    1: ['us', '+1 (###) ###-####'],
    7: ['ru', '+7 (###) ###-##-##'],
    27: ['za', '+27 ## ### ####'],
    30: ['gr', '+30 ## #### ####'],
    31: ['nl', '+31 ## ########'],
    32: ['be', '+32 ### ## ## ##'],
    33: ['fr', '+33 # ## ## ## ##'],
    34: ['es', '+34 ### ### ###'],
    36: ['hu', '+36 ## ### ###'],
    39: ['it', '+39 ### #######'],
    40: ['ro', '+40 ### ### ###'],
    41: ['ch', '+41 ## ### ## ##'],
    43: ['at', '+43 ### #######'],
    44: ['gb', '+44 #### ######'],
    45: ['dk', '+45 ## ## ## ##'],
    46: ['se', '+46 (###) ###-###'],
    47: ['no', '+47 ### ## ###'],
    48: ['pl', '+48 ###-###-###'],
    49: ['de', '+49 #### ########'],
    52: ['mx', '+52 ### ### ####'],
    54: ['ar', '+54 (##) ########'],
    55: ['br', '+55 (##) #########'],
    56: ['cl', '+56 ## ### ####'],
    60: ['my', '+60 #-###-####'],
    61: ['au', '+61 (##) #### ####'],
    64: ['nz', '+64 ###-###-####'],
    65: ['sg', '+65 ####-####'],
    81: ['jp', '+81 ## #### ####'],
    82: ['kr', '+82 ### #### ####'],
    86: ['cn', '+86 ##-#########'],
    91: ['in', '+91 #####-#####'],
    351: ['pt', '+351 ### ### ###'],
    352: ['lu', '+352 ## ## ## ##'],
    353: ['ie', '+353 ## #######'],
    356: ['mt', '+356 #### ####'],
    357: ['cy', '+357 ## ######'],
    358: ['fi', '+358 ## ### ## ##'],
    359: ['bg', '+359 ## ### ####'],
    370: ['lt', '+370 ### #####'],
    371: ['lv', '+371 ## ### ###'],
    372: ['ee', '+372 #### ######'],
    380: ['ua', '+380 (###) ###-####'],
    386: ['si', '+386 # ### ## ##'],
    420: ['cz', '+420 ### ### ###'],
    421: ['sk', '+421 ### ### ###'],
    852: ['hk', '+852 #### ####'],
    965: ['kw', '+965 #### ####'],
    966: ['sa', '+966 ## ### ####'],
    971: ['ae', '+971 # ### ####'],
    972: ['il', '+972 ### ### ####'],
    973: ['bh', '+973 #### ####'],
    974: ['qa', '+974 #### ####'],
};
var countryPhoneFormat = {
    us: '+1 (###) ###-####',
    ae: '+971 # ### ####',
    ar: '+54 (##) ########',
    at: '+43 ### #######',
    au: '+61 (##) #### ####',
    be: '+32 ### ## ## ##',
    br: '+55 (##) #########',
    bg: '+359 ## ### ####',
    bh: '+973 #### ####',
    ca: '+1 (###) ###-####',
    ch: '+41 ## ### ## ##',
    cl: '+56 ## ### ####',
    cn: '+86 ##-#########',
    cy: '+357 ## ######',
    cz: '+420 ### ### ###',
    de: '+49 #### ########',
    dk: '+45 ## ## ## ##',
    ee: '+372 #### ######',
    es: '+34 ### ### ###',
    fi: '+358 ## ### ## ##',
    fr: '+33 # ## ## ## ##',
    gb: '+44 #### ######',
    gr: '+30 ## #### ####',
    hk: '+852 #### ####',
    hu: '+36 ## ### ###',
    in: '+91 #####-#####',
    ie: '+353 ## #######',
    il: '+972 ### ### ####',
    it: '+39 ### #######',
    jp: '+81 ## #### ####',
    kr: '+82 ### #### ####',
    kw: '+965 #### ####',
    lt: '+370 ### #####',
    lv: '+371 ## ### ###',
    lu: '+352 ## ## ## ##',
    mt: '+356 #### ####',
    mx: '+52 ### ### ####',
    my: '+60 #-###-####',
    nl: '+31 ## ########',
    nz: '+64 ###-###-####',
    no: '+47 ### ## ###',
    pl: '+48 ###-###-###',
    pt: '+351 ### ### ###',
    qa: '+974 #### ####',
    ro: '+40 ### ### ###',
    ru: '+7 (###) ###-##-##',
    se: '+46 (###) ###-###',
    sg: '+65 ####-####',
    si: '+386 # ### ## ##',
    sa: '+966 ## ### ####',
    sk: '+421 ### ### ###',
    ua: '+380 (###) ###-####',
    za: '+27 ## ### ####' //
};
export var options = [
    { value: 'USD', label: 'United States, Dollar (USD)' },
    { value: 'ARS', label: 'Argentina, Peso (ARS)' },
    { value: 'AUD', label: 'Australia, Dollar (AUD)' },
    { value: 'BRL', label: 'Brazil, Real (BRL)' },
    { value: 'CAD', label: 'Canada, Dollar (CAD)' },
    { value: 'CLP', label: 'Chile, Peso (CLP)' },
    { value: 'CNY', label: 'China, Yuan (CNY)' },
    { value: 'DKK', label: 'Denmark, Krone (DKK)' },
    { value: 'EUR', label: 'Euro (EUR)' },
    { value: 'HKD', label: 'Hong Kong, Dollar (HKD)' },
    { value: 'INR', label: 'India, Rupee (INR)' },
    { value: 'ILS', label: 'Israel, New Shekel (ILS)' },
    { value: 'JPY', label: 'Japan, Yen (JPY)' },
    { value: 'MXN', label: 'Mexico, Peso (MXN)' },
    { value: 'NZD', label: 'New Zealand, Dollar (NZD)' },
    { value: 'NOK', label: 'Norway, Krone (NOK)' },
    { value: 'RUB', label: 'Russia, Ruble (RUB)' },
    { value: 'SGD', label: 'Singapore, Dollar (SGD)' },
    { value: 'ZAR', label: 'South Africa, Rand (ZAR)' },
    { value: 'KRW', label: 'South Korea, Won (KRW)' },
    { value: 'SEK', label: 'Sweden, Krona (SEK)' },
    { value: 'CHF', label: 'Switzerland, Franc (CHF)' },
    { value: 'GBP', label: 'United Kingdom, Pound (GBP)' },
];
var ValidationTypes;
(function (ValidationTypes) {
    ValidationTypes["CURRENCY"] = "CURRENCY";
    ValidationTypes["PERCENTAGE"] = "PERCENTAGE";
    ValidationTypes["EMAIL"] = "EMAIL";
    ValidationTypes["NUMERIC"] = "NUMERIC";
    ValidationTypes["PHONE"] = "PHONE";
})(ValidationTypes || (ValidationTypes = {}));
var InputTypes;
(function (InputTypes) {
    InputTypes["NUMBER"] = "number";
    InputTypes["EMAIL"] = "email";
})(InputTypes || (InputTypes = {}));
var MaxLength = 4000;
var thousandSeparator = ',';
var decimalSeparator = '.';
var prefix = '';
var suffix = '';
var getPhoneInputProps = function (name) {
    return {
        name: name,
        "aria-hidden": true,
        "aria-label": "phone picker"
    };
};
export var Util = {
    countryList: countryList,
    options: options,
    countryPhoneFormat: countryPhoneFormat,
    ValidationTypes: ValidationTypes,
    thousandSeparator: thousandSeparator,
    decimalSeparator: decimalSeparator,
    prefix: prefix,
    suffix: suffix,
    getPhoneInputProps: getPhoneInputProps,
    InputTypes: InputTypes,
    MaxLength: MaxLength,
};
