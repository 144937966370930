import React from 'react';
var NoPain = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18px", height: "18px", viewBox: "0 0 18 18", version: "1.1", role: "img" },
        React.createElement("title", null, "No Pain"),
        React.createElement("desc", null, "No pain."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(1.000000, 1.000000)" },
                React.createElement("path", { d: "M8,0 C12.42,0 16,3.58 16,8 C16,12.42 12.42,16 8,16 C3.58,16 0,12.42 0,8 C0,3.58 3.58,0 8,0 Z", id: "Stroke-306", fill: "#444444" }),
                React.createElement("path", { d: "M8,1 C4.13228475,1 1,4.13228475 1,8 C1,11.8677153 4.13228475,15 8,15 C11.8677153,15 15,11.8677153 15,8 C15,4.13228475 11.8677153,1 8,1 Z", id: "Path", fill: "#F6D123" }),
                React.createElement("path", { d: "M12.031753,9.32465813 C12.1285917,9.06605247 12.4167362,8.93491438 12.6753419,9.03175303 C12.9339475,9.12859167 13.0650856,9.41673621 12.968247,9.67534187 C12.2264625,11.6562625 10.2458479,13 8,13 C5.75415206,13 3.7735375,11.6562625 3.03175303,9.67534187 C2.93491438,9.41673621 3.06605247,9.12859167 3.32465813,9.03175303 C3.58326379,8.93491438 3.87140833,9.06605247 3.96824697,9.32465813 C4.56235286,10.9112061 6.16719099,12 8,12 C9.83280901,12 11.4376471,10.9112061 12.031753,9.32465813 Z M6,5.5 C6.55333333,5.5 7,6.05833333 7,6.75 C7,7.44166667 6.55333333,8 6,8 C5.44666667,8 5,7.44166667 5,6.75 C5,6.05833333 5.44666667,5.5 6,5.5 Z M10,5.5 C10.5533333,5.5 11,6.05833333 11,6.75 C11,7.44166667 10.5533333,8 10,8 C9.44666667,8 9,7.44166667 9,6.75 C9,6.05833333 9.44666667,5.5 10,5.5 Z", id: "Combined-Shape", fillOpacity: "0.7", fill: "#000000" })))));
};
export default NoPain;
