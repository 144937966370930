import React from 'react';
var EmptySignal = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "11px", height: "14px", viewBox: "0 0 11 14", version: "1.1", role: "img" },
        React.createElement("title", null, "Signal Empty"),
        React.createElement("desc", null, "Empty signal."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#CCCCCC" },
                React.createElement("rect", { id: "Rectangle-3", x: "9", y: "0", width: "2", height: "14", rx: "0.5" }),
                React.createElement("rect", { id: "Rectangle-3", x: "6", y: "3", width: "2", height: "11", rx: "0.5" }),
                React.createElement("rect", { id: "Rectangle-3", x: "3", y: "6", width: "2", height: "8", rx: "0.5" }),
                React.createElement("rect", { id: "Rectangle-3", x: "0", y: "9", width: "2", height: "5", rx: "0.5" })))));
};
export default EmptySignal;
