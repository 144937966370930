import { jsx } from '@emotion/core';
var BlueLogicIcon = function () {
    return (jsx("svg", { role: "img", "data-client-id": "component_logic_icon", width: "12px", height: "12px", viewBox: "0 0 12 12", version: "1.1", xmlns: "http://www.w3.org/2000/svg" },
        jsx("title", null, "Logic Tag"),
        jsx("desc", null, "Logic Tag"),
        jsx("g", { id: "Page-1", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            jsx("g", { id: "Design--Eval---1", transform: "translate(-150.000000, -227.000000)", fill: "#767676" },
                jsx("g", { id: "Elements/Tags/New", transform: "translate(142.000000, 223.000000)" },
                    jsx("path", { d: "M11.0638298,4 C11.3458475,4 11.5744681,4.22862055 11.5744681,4.5106383 L11.5744681,5.27659574 L16.4255319,5.27659574 L16.4255319,4.5106383 C16.4255319,4.22862055 16.6541525,4 16.9361702,4 L19.4893617,4 C19.7713794,4 20,4.22862055 20,4.5106383 L20,7.06382979 C20,7.34584753 19.7713794,7.57446809 19.4893617,7.57446809 L16.9361702,7.57446809 C16.6541525,7.57446809 16.4255319,7.34584753 16.4255319,7.06382979 L16.4255319,6.29787234 L13.7882282,6.29789946 C13.8375272,6.43593631 13.8675827,6.58311736 13.8751639,6.7362118 L13.8770666,6.81323685 L13.8770666,13.1962156 L13.8784673,13.2343251 C13.896986,13.4853337 14.0969543,13.6858832 14.3477091,13.7053106 L14.3877049,13.7068539 L16.4255319,13.7067234 L16.4255319,12.9361702 C16.4255319,12.6541525 16.6541525,12.4255319 16.9361702,12.4255319 L19.4893617,12.4255319 C19.7713794,12.4255319 20,12.6541525 20,12.9361702 L20,15.4893617 C20,15.7713794 19.7713794,16 19.4893617,16 L16.9361702,16 C16.6541525,16 16.4255319,15.7713794 16.4255319,15.4893617 L16.4255319,14.728 L14.3877049,14.7281305 L14.3302739,14.7270738 C13.5298873,14.6975746 12.886346,14.0540332 12.8568467,13.2536466 L12.85579,13.1962156 L12.85579,6.81323685 L12.8543894,6.77512732 C12.8358707,6.52411875 12.6359024,6.32356925 12.3851476,6.30414182 L12.3451517,6.30259855 L11.5744681,6.30246809 L11.5744681,7.06382979 C11.5744681,7.34584753 11.3458475,7.57446809 11.0638298,7.57446809 L8.5106383,7.57446809 C8.22862055,7.57446809 8,7.34584753 8,7.06382979 L8,4.5106383 C8,4.22862055 8.22862055,4 8.5106383,4 L11.0638298,4 Z", id: "Path" }))))));
};
export default BlueLogicIcon;
