export function sanitizeHtmlID(str) {
    // replace non-alphanumeric characters
    // \W matches any non-word character (word character is alphanum or underscore)
    return str.replace(/\W/g, "_");
}
export function generateAriaDescribedBy(descriptionId, hasDescription, errorId, hasError) {
    var includedIds = [];
    if (hasDescription) {
        includedIds.push(descriptionId);
    }
    if (hasError) {
        includedIds.push(errorId);
    }
    return includedIds.join(" ");
}
/**
 * Shortcut for quickly generating aria-describedby attr
 * @param descriptionId HTML id of the description element
 * @param errorId HTML id of the error element
 * @returns string; if both ids falsy, will return empty string
 */
export function buildAriaDescribedBy(descriptionId, errorId) {
    var ids = [descriptionId, errorId].filter(function (val) { return !!val; }).join(" ");
    return ids;
}
