import React from 'react';
import { ESymbolKey } from "@smartsheet/forms-model";
import Play from "./Play";
import Pause from "./Pause";
import Rewind from "./Rewind";
import Stop from "./Stop";
import FastForward from "./FastForward";
import { IconOptionImg } from "../../iconUtil";
var VcrIcon = function (_a) {
    var type = _a.type;
    var icon;
    switch (type) {
        case ESymbolKey.DECISION_VCR_PLAY:
        case "Play":
            icon = React.createElement(Play, null);
            break;
        case ESymbolKey.DECISION_VCR_PAUSE:
        case "Pause":
            icon = React.createElement(Pause, null);
            break;
        case ESymbolKey.DECISION_VCR_REWIND:
        case "Rewind":
            icon = React.createElement(Rewind, null);
            break;
        case ESymbolKey.DECISION_VCR_STOP:
        case "Stop":
            icon = React.createElement(Stop, null);
            break;
        case ESymbolKey.DECISION_VCR_FASTFORWARD:
        case "Fast Forward":
            icon = React.createElement(FastForward, null);
            break;
        default:
            break;
    }
    return !!icon ? (React.createElement(IconOptionImg, null, icon)) : null;
};
export default VcrIcon;
