import { __assign, __extends, __makeTemplateObject, __rest } from "tslib";
/** @jsx jsx */
import "react-dates/initialize";
import { DayPickerSingleDateController } from "react-dates";
import * as React from "react";
import moment from "moment";
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled/macro";
import defaultTheme from "../defaultTheme";
import CalendarButton from "./CalendarButton";
import reactDatesCss from "./reactDatesCss";
var MAX_INPUT_SIZE = 100;
var Input = styled.input(function (_a) {
    var _b = _a.theme, theme = _b === void 0 ? defaultTheme : _b, error = _a.error, props = __rest(_a, ["theme", "error"]);
    return "\n  border: 1px solid " + (error ? "#d0011b" : "#ccc") + ";\n  border-radius: 2px;\n  box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  -webkit-box-sizing: border-box;\n  color: " + defaultTheme.style.font.readOnlyColor + ";\n  font-family: Roboto;\n  font-size: 0.88em;\n  max-height: 30px;\n  min-height: 30px;\n  padding: 7px;\n  width: 115px;\n\n  &:hover {\n    border-color: " + (error ? "#d0011b" : "#A0A0A0") + "\n  }\n  \n  &:focus {\n    outline: 0;\n    border-color: " + (error ? "#d0011b" : "#005EE0") + "\n  }\n\n  ::-ms-clear {\n    display: none;\n  }\n";
});
var dayPickerWrapper = "\n  position: relative;\n  left: 59px;\n\n  .DayPicker {\n    border-radius: 2px;\n    border: 1px solid #aaa;\n    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2), 0 0 2px 0 rgba(0, 0, 0, 0.05);\n    box-sizing: 'border-box';\n    z-index: 1;\n  }\n\n  .CalendarDay__selected {\n    background: #0073ec;\n    border: #585858;\n  }\n";
var dayPickerWrapperOpenDown = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    ", ";\n\n    bottom: -145px;\n\n    .DayPicker {\n        :after,\n        :before {\n            bottom: 100%;\n            left: 50%;\n            border: solid transparent;\n            content: \" \";\n            height: 0;\n            width: 0;\n            position: absolute;\n            pointer-events: none;\n        }\n\n        :after {\n            border-color: rgba(255, 255, 255, 0);\n            border-bottom-color: #fff;\n            border-width: 8px;\n            margin-left: -88px;\n        }\n\n        :before {\n            border-color: rgba(170, 170, 170, 0);\n            border-bottom-color: #aaa;\n            border-width: 9px;\n            margin-left: -89px;\n        }\n    }\n"], ["\n    ", ";\n\n    bottom: -145px;\n\n    .DayPicker {\n        :after,\n        :before {\n            bottom: 100%;\n            left: 50%;\n            border: solid transparent;\n            content: \" \";\n            height: 0;\n            width: 0;\n            position: absolute;\n            pointer-events: none;\n        }\n\n        :after {\n            border-color: rgba(255, 255, 255, 0);\n            border-bottom-color: #fff;\n            border-width: 8px;\n            margin-left: -88px;\n        }\n\n        :before {\n            border-color: rgba(170, 170, 170, 0);\n            border-bottom-color: #aaa;\n            border-width: 9px;\n            margin-left: -89px;\n        }\n    }\n"])), dayPickerWrapper);
var dayPickerWrapperOpenUp = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    ", "\n\n    bottom: 180px;\n\n    .DayPicker {\n        :after,\n        :before {\n            top: 100%;\n            left: 50%;\n            border: solid transparent;\n            content: \" \";\n            height: 0;\n            width: 0;\n            position: absolute;\n            pointer-events: none;\n        }\n\n        :after {\n            border-color: rgba(255, 255, 255, 0);\n            border-top-color: #fff;\n            border-width: 8px;\n            margin-left: -89px;\n        }\n\n        :before {\n            border-color: rgba(170, 170, 170, 0);\n            border-top-color: #aaa;\n            border-width: 9px;\n            margin-left: -89px;\n        }\n    }\n"], ["\n    ", "\n\n    bottom: 180px;\n\n    .DayPicker {\n        :after,\n        :before {\n            top: 100%;\n            left: 50%;\n            border: solid transparent;\n            content: \" \";\n            height: 0;\n            width: 0;\n            position: absolute;\n            pointer-events: none;\n        }\n\n        :after {\n            border-color: rgba(255, 255, 255, 0);\n            border-top-color: #fff;\n            border-width: 8px;\n            margin-left: -89px;\n        }\n\n        :before {\n            border-color: rgba(170, 170, 170, 0);\n            border-top-color: #aaa;\n            border-width: 9px;\n            margin-left: -89px;\n        }\n    }\n"])), dayPickerWrapper);
var DatePicker = /** @class */ (function (_super) {
    __extends(DatePicker, _super);
    function DatePicker() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            openDirection: "down",
            showCalendar: false
        };
        _this.handleChangeInput = function (event) {
            _this.props.onChange(event.target.value);
        };
        _this.handleClickCalendarButton = function () {
            var verticalSpace = window.innerHeight +
                document.documentElement.scrollTop -
                (_this.inputEl ? _this.inputEl.offsetTop : 0);
            var openDirection = verticalSpace < 300 ? "up" : "down";
            _this.setState({
                openDirection: openDirection,
                showCalendar: true
            });
        };
        _this.isDayHighlighted = function (day) {
            return day.isSame(moment(), "day");
        };
        _this.handleDateChange = function (date) {
            _this.setState({ showCalendar: false }, function () {
                // adding this transformation to make sure not passing default moment obj to reducer to avoid breaking the format validation logic
                // we do not parse with moment.UTC here because it is an unambigious date being provided by the picker, see https://momentjs.com/docs/#/parsing/
                moment.isMoment(date)
                    ? _this.props.onChange(moment(date).format("L"))
                    : _this.props.onChange(date);
            });
        };
        _this.handleFocusChange = function () {
            _this.setState({ showCalendar: false });
        };
        _this.handleOutsideClick = function () {
            _this.setState({ showCalendar: false });
        };
        return _this;
    }
    DatePicker.prototype.maybeRenderCalendar = function () {
        if (!this.state.showCalendar) {
            return null;
        }
        var value = this.props.value;
        var openDirection = this.state.openDirection;
        var parsedValue = moment.utc(value, "L");
        return (jsx("div", { css: openDirection === "up"
                ? dayPickerWrapperOpenUp
                : dayPickerWrapperOpenDown },
            jsx(DayPickerSingleDateController, { date: parsedValue.isValid() ? parsedValue : null, daySize: 30, focused: true, isDayHighlighted: this.isDayHighlighted, onDateChange: this.handleDateChange, onFocusChange: this.handleFocusChange, onOutsideClick: this.handleOutsideClick, hideKeyboardShortcutsPanel: true, withPortal: true })));
    };
    DatePicker.prototype.render = function () {
        var _a;
        var _this = this;
        var _b = this.props, error = _b.error, name = _b.name, placeholder = _b.placeholder, title = _b.title, disabled = _b.disabled, value = _b.value, onBlur = _b.onBlur, isHiddenByConditionalLogic = _b.isHiddenByConditionalLogic, ariaDescribedBy = _b.ariaDescribedBy;
        var handleKeyDown = function (e) {
            if (e.key === "Enter") {
                e.preventDefault();
            }
        };
        return (jsx("div", { css: reactDatesCss },
            jsx(Input, __assign({ "data-client-id": "date_" + name, id: "date_" + name, "aria-label": name }, (ariaDescribedBy && (_a = {}, _a['aria-describedby'] = ariaDescribedBy, _a)), { tabIndex: isHiddenByConditionalLogic ? -1 : 0, error: error, name: name, placeholder: placeholder, maxLength: MAX_INPUT_SIZE, title: title, disabled: disabled, onChange: this.handleChangeInput, onBlur: onBlur, onKeyDown: handleKeyDown, ref: function (el) { return (_this.inputEl = el); }, value: moment.isMoment(value)
                    ? moment.utc(value).format("L")
                    : value || "" })),
            jsx(CalendarButton, { "data-client-id": "date_input_open_calendar", isHiddenByConditionalLogic: isHiddenByConditionalLogic, onClick: this.handleClickCalendarButton, disabled: disabled }),
            this.maybeRenderCalendar()));
    };
    return DatePicker;
}(React.Component));
export default DatePicker;
var templateObject_1, templateObject_2;
