/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/core';
import { FormConfirmation } from '../../../../../app/types';
import InlineConfirmation from '../InlineConfirmation';
import { FormContentSection } from '../../StyledComponents/FormPage';

interface DynamicSectionProps {
  confirmation?: FormConfirmation;
  renderForm: (scrollToTop: () => void) => React.ReactNode;
  shouldRenderInlineConfirmation: boolean;
}

const DynamicSection: React.FC<DynamicSectionProps> = ({
  confirmation,
  renderForm,
  shouldRenderInlineConfirmation,
}) => {
  const sectionRef = React.useRef<HTMLElement>(null)
  React.useEffect(() => {
    if(shouldRenderInlineConfirmation && sectionRef.current) {
      sectionRef.current.scrollTop = 0
    }
  }, [shouldRenderInlineConfirmation])
  
  const scrollToTop = () => {
    if(sectionRef.current) {
      sectionRef.current.scrollTop = 0
    }
  }

  return (
    <FormContentSection
      aria-label="form content"
      ref={sectionRef}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto 0',
          maxWidth: 576,
          width: '100%',
        }}
      >
        {shouldRenderInlineConfirmation && (
          <InlineConfirmation confirmation={confirmation} />
        )}
        <div
          css={{
            alignSelf: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 30,
          }}
        >
          {renderForm(scrollToTop)}
        </div>
      </div>
    </FormContentSection>
  );
}

export default DynamicSection;
