import { __makeTemplateObject } from "tslib";
import { createPropsGetter } from "../utils";
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled/macro";
import InputLabel from "../InputLabel";
import InputDescription from "../InputDescription";
import InputContainer from "../InputContainer";
import LabelContainer from "../LabelContainer";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import WarningImageIcon from "../WarningImageIcon";
import DatePicker from "./DatePicker";
import LogicTag from "../LogicTag";
import SourceLogicTag from "../SourceLogicTag";
import { generateAriaDescribedBy, sanitizeHtmlID } from "../utils/stringUtils";
var defaultProps = {
    readonly: false,
    required: false,
    disabled: false,
    touched: false,
    hidden: false,
    validateField: false,
    onChange: function (date) { },
    onBlur: function (e) { }
};
var getProps = createPropsGetter(defaultProps);
var ErrorSpan = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    display: block;\n    font-size: 0.88em;\n    color: #d0011b;\n    margin-top: 11px;\n    margin-bottom: 0.25rem;\n    display: flex;\n    align-items: center;\n"], ["\n    width: 100%;\n    display: block;\n    font-size: 0.88em;\n    color: #d0011b;\n    margin-top: 11px;\n    margin-bottom: 0.25rem;\n    display: flex;\n    align-items: center;\n"])));
var WarningImageStyled = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-right: 0.3rem;\n"], ["\n    margin-right: 0.3rem;\n"])));
var LogicInputLabel = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    word-wrap: break-all;\n"], ["\n    word-wrap: break-all;\n"]))); //Div is needed around label when logic tag is present
var DateInput = function (props) {
    var _a = getProps(props), value = _a.value, borderless = _a.borderless, localizationLabelId = _a.localizationLabelId, name = _a.name, placeholder = _a.placeholder, label = _a.label, logic = _a.logic, hasSourceLogic = _a.hasSourceLogic, isHiddenByConditionalLogic = _a.isHiddenByConditionalLogic, onSourceLogicTagClick = _a.onSourceLogicTagClick, title = _a.title, description = _a.description, richDescription = _a.richDescription, isRichTextEnabled = _a.isRichTextEnabled, required = _a.required, disabled = _a.disabled, onChange = _a.onChange, onBlur = _a.onBlur, error = _a.error, componentKey = _a.componentKey;
    var hasLogic = logic != null;
    var dataClientIdSuffix = label != null ? label : name;
    var labelValue;
    var id = "date_" + dataClientIdSuffix;
    var descriptionElemId = "description_" + sanitizeHtmlID(dataClientIdSuffix);
    var errorElemId = "error_msg_" + sanitizeHtmlID(dataClientIdSuffix);
    var describedByIds = generateAriaDescribedBy(descriptionElemId, !!description, errorElemId, !!error);
    if (localizationLabelId != null) {
        labelValue = (jsx(InputLabel, { dataClientIdSuffix: dataClientIdSuffix, forElement: id, required: required, localizationLabelId: localizationLabelId }, " "));
    }
    else {
        labelValue = (jsx(InputLabel, { dataClientIdSuffix: dataClientIdSuffix, forElement: id, required: required }, label != null ? label : ""));
    }
    return (jsx(InputContainer, { "data-client-id": "container_" + dataClientIdSuffix, "data-client-type": "date", css: { marginTop: borderless ? "0px" : "",
            '& p': {
                marginTop: '5px',
                marginBlock: '2px',
                fontSize: '14.12px',
            },
            '& ul': {
                listStylePosition: 'inside',
                fontSize: '14.12px',
                marginBlock: '2px'
            },
            '& ol': {
                listStylePosition: 'inside',
                fontSize: '14.12px',
                marginBlock: '2px'
            },
            display: 'block'
        }, id: componentKey },
        label && !hasLogic && !hasSourceLogic && labelValue,
        label && (hasLogic || hasSourceLogic) && (jsx(LabelContainer, { style: { paddingRight: "8px" } },
            jsx(LogicInputLabel, null, labelValue),
            hasSourceLogic && (jsx(SourceLogicTag, { onClickHandler: onSourceLogicTagClick })),
            logic && jsx(LogicTag, { logic: logic }))),
        (isRichTextEnabled && richDescription) &&
            jsx("div", { id: descriptionElemId, className: "rich-text-field-desc", dangerouslySetInnerHTML: { __html: richDescription } })
            || description != null && (jsx(InputDescription, { id: descriptionElemId }, description)),
        jsx(DatePicker, { ariaDescribedBy: describedByIds, error: error, isHiddenByConditionalLogic: isHiddenByConditionalLogic, name: dataClientIdSuffix, placeholder: placeholder, title: title, disabled: disabled, onBlur: onBlur, onChange: onChange, value: value }),
        error && value != "" && (jsx(ErrorSpan, { "data-client-id": "component_error_msg" },
            jsx(WarningImageIcon, { customCss: WarningImageStyled }),
            jsx(FormattedMessage, { id: "validations.date_wrongFormat", values: {
                    0: moment()
                        .creationData()
                        .locale.longDateFormat("L")
                } }))),
        error && value === "" && (jsx(ErrorSpan, { "data-client-id": "component_error_msg" },
            jsx(WarningImageIcon, { customCss: WarningImageStyled }),
            jsx("span", { id: errorElemId, role: "alert" },
                jsx(FormattedMessage, { id: "messages.msg_fieldRequired" }),
                " ")))));
};
export default DateInput;
var templateObject_1, templateObject_2, templateObject_3;
