import React from 'react';
var Right = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14px", height: "12px", viewBox: "0 0 14 12", version: "1.1", role: "img" },
        React.createElement("title", null, "Directions Right"),
        React.createElement("desc", null, "Right."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#444444" },
                React.createElement("path", { d: "M6.8,11.4 L13.4666667,6.4 C13.6875806,6.23431458 13.7323521,5.9209139 13.5666667,5.7 C13.5382395,5.66209717 13.5045695,5.62842712 13.4666667,5.6 L6.8,0.6 C6.5790861,0.434314575 6.26568542,0.4790861 6.1,0.7 C6.03508894,0.786548085 6,0.891814893 6,1 L6,4 L0.5,4 C0.223857625,4 -3.38176876e-17,4.22385763 0,4.5 L0,7.5 C3.38176876e-17,7.77614237 0.223857625,8 0.5,8 L6,8 L6,11 C6,11.2761424 6.22385763,11.5 6.5,11.5 C6.60818511,11.5 6.71345191,11.4649111 6.8,11.4 Z", id: "Combined-Shape" })))));
};
export default Right;
