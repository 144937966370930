import React from 'react';
import { ESymbolKey } from "@smartsheet/forms-model";
import Yes from "./Yes";
import No from "./No";
import Hold from "./Hold";
import { IconOptionImg } from "../../iconUtil";
var DecisionSymbolIcon = function (_a) {
    var type = _a.type;
    var icon;
    switch (type) {
        case ESymbolKey.DECISION_SYMBOLS_YES:
        case "Yes":
            icon = React.createElement(Yes, null);
            break;
        case ESymbolKey.DECISION_SYMBOLS_NO:
        case "No":
            icon = React.createElement(No, null);
            break;
        case ESymbolKey.DECISION_SYMBOLS_HOLD:
        case "Hold":
            icon = React.createElement(Hold, null);
            break;
        default:
            break;
    }
    return !!icon ? (React.createElement(IconOptionImg, null, icon)) : null;
};
export default DecisionSymbolIcon;
