import { DataValue, FormDefinition, FormValues } from "../../app/types/form";

export interface FormSubmission {
  formKey: string
  formToken?: string;
  files: {[key: string]: File}
  data: {[key: string]: DataValue<any>}
  rawValues: FormValues;
  form?: FormDefinition
}

export interface FormSubmissionResult {
  readonly submissionId: string
  readonly confirmation: FormConfirmation
  readonly token: string;
  readonly error?: string
}

export enum FormConfirmationType{
  MESSAGE = "MESSAGE",
  REDIRECT = "REDIRECT",
  RELOAD = "RELOAD"
}

export interface RedirectConfirmation {
  readonly type: FormConfirmationType.REDIRECT
  readonly redirectUrl: string
  readonly reloadDisabled: boolean
}

export interface ReloadConfirmation {
  readonly type: FormConfirmationType.RELOAD
  readonly message: string
  readonly hideFooterOnConfirmation: boolean
  readonly reloadDisabled: boolean
}

export interface MessageConfirmation {
  readonly type: FormConfirmationType.MESSAGE
  readonly message: string
  readonly hideFooterOnConfirmation: boolean
  readonly reloadDisabled: boolean
}

export type FormConfirmation =
  MessageConfirmation |
  ReloadConfirmation |
  RedirectConfirmation
