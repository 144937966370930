import { jsx } from '@emotion/core';
var MsWordIcon = function () {
    return (jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", role: "img", viewBox: "0 0 16 16" },
        jsx("title", null, "MS Word Attachment Icon"),
        jsx("desc", null, "Ms Word Icon"),
        jsx("g", { fill: "none", fillRule: "nonzero" },
            jsx("path", { fill: "#185ABD", d: [
                    'M1.333 0h13.334A1.333 1.333 0 0 1 16 1.333v13.334A1.333 1.333 0 0',
                    '1 14.667 16H1.333A1.333 1.333 0 0 1 0 14.667V1.333C0 .597.597 0 1',
                    '.333 0z',
                ].join('') }),
            jsx("path", { fill: "#FFF", d: [
                    'M11.32 12H9.806L8.042 6.48 6.193 12H4.681L3 4h1.513l1.176 5.6 1.7',
                    '65-5.44h1.26l1.681 5.44L11.571 4H13z',
                ].join('') }))));
};
export default MsWordIcon;
