import { __assign, __extends, __makeTemplateObject } from "tslib";
/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from "react";
import { createPropsGetter } from "../utils";
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled/macro";
import InputLabel from "../InputLabel";
import InputLabelRight from "../InputLabelRight";
import InputDescription from "../InputDescription";
import InputContainer from "../InputContainer";
import CheckboxFormattingStyles from "./CheckboxFormattingStyles";
import { FormattedMessage } from "react-intl";
import WarningImageIcon from "../WarningImageIcon";
import SymbolCheckboxLabelRight from "../InputSpanRight";
import LogicTag from "../LogicTag";
import SourceLogicTag from "../SourceLogicTag";
import LabelContainer from "../LabelContainer";
import { generateAriaDescribedBy, sanitizeHtmlID } from "../utils/stringUtils";
export var CheckboxInputCaptionLocation;
(function (CheckboxInputCaptionLocation) {
    CheckboxInputCaptionLocation["RIGHT"] = "RIGHT";
    CheckboxInputCaptionLocation["ABOVE"] = "ABOVE";
})(CheckboxInputCaptionLocation || (CheckboxInputCaptionLocation = {}));
export var CheckboxType;
(function (CheckboxType) {
    CheckboxType["CHECKBOX"] = "CHECKBOX";
    CheckboxType["STAR"] = "STAR";
    CheckboxType["FLAG"] = "FLAG";
})(CheckboxType || (CheckboxType = {}));
var defaultProps = {
    value: false,
    readonly: false,
    required: false,
    disabled: false,
    touched: false,
    hidden: false,
    checkboxType: CheckboxType.CHECKBOX,
    captionLocation: CheckboxInputCaptionLocation.ABOVE,
    onChange: function (value) { }
};
var getProps = createPropsGetter(defaultProps);
var checkBoxClass = "\n  display: block;\n  border-width: 1px;\n  margin-top: 0px;\n";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 14px;\n    width: 14px;\n    pointer-events: auto;\n    position: relative;\n    ", ";\n    margin-bottom: 5px !important;\n    svg {\n        cursor: pointer;\n    }\n"], ["\n    height: 14px;\n    width: 14px;\n    pointer-events: auto;\n    position: relative;\n    ", ";\n    margin-bottom: 5px !important;\n    svg {\n        cursor: pointer;\n    }\n"])), function (props) { return props.isLabelRight && "margin-top: 0 !important;"; });
var InputVisible = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    opacity: 1;\n    margin: 0;\n    height: 16px;\n    width: 16px;\n    border: 1px solid #cccccc;\n    border-radius: 2px;\n    background-color: #ffffff;\n    position: absolute;\n    cursor: pointer;\n    &:hover {\n        border: 1px solid #a0a0a0;\n        background-color: #f3f3f3;\n    }\n    &:checked:hover {\n        background-color: #005cbc;\n        border: 1px solid #005cbc;\n    }\n    &:checked {\n        border: 1px solid #529aff;\n        background-color: #529aff;\n        display: flex;\n        justify-content: center;\n        &:after {\n            content: \"\";\n            width: 4px;\n            height: 8px;\n            border-width: 0 2px 2px 0;\n            transform: rotate(45deg);\n        }\n    }\n    &:not(:first-of-type) {\n        margin-top: 7px;\n    }\n"], ["\n    opacity: 1;\n    margin: 0;\n    height: 16px;\n    width: 16px;\n    border: 1px solid #cccccc;\n    border-radius: 2px;\n    background-color: #ffffff;\n    position: absolute;\n    cursor: pointer;\n    &:hover {\n        border: 1px solid #a0a0a0;\n        background-color: #f3f3f3;\n    }\n    &:checked:hover {\n        background-color: #005cbc;\n        border: 1px solid #005cbc;\n    }\n    &:checked {\n        border: 1px solid #529aff;\n        background-color: #529aff;\n        display: flex;\n        justify-content: center;\n        &:after {\n            content: \"\";\n            width: 4px;\n            height: 8px;\n            border-width: 0 2px 2px 0;\n            transform: rotate(45deg);\n        }\n    }\n    &:not(:first-of-type) {\n        margin-top: 7px;\n    }\n"])));
var InputHidden = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    ", ";\n    appearance: none;\n    opacity: 0;\n"], ["\n    ", ";\n    appearance: none;\n    opacity: 0;\n"])), checkBoxClass);
var ErrorSpan = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 100%;\n    display: block;\n    font-size: 0.88em;\n    color: #d0011b;\n    margin-top: 11px;\n    margin-bottom: 0.25rem;\n    display: flex;\n    align-items: center;\n"], ["\n    width: 100%;\n    display: block;\n    font-size: 0.88em;\n    color: #d0011b;\n    margin-top: 11px;\n    margin-bottom: 0.25rem;\n    display: flex;\n    align-items: center;\n"])));
var WarningImageStyled = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin-right: 0.3rem;\n"], ["\n    margin-right: 0.3rem;\n"])));
var CheckboxImgSpan = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    @media screen and (-webkit-min-device-pixel-ratio: 0) {\n        margin-left: -8px !important;\n    }\n    @supports (-ms-ime-align: auto) {\n    }\n    @media screen and (-ms-high-contrast: active),\n        screen and (-ms-high-contrast: none) {\n        margin-left: -21.5px !important;\n    }\n    @-moz-document url-prefix() {\n        margin-left: -21.5px !important;\n    }\n    input:focus + & {\n        outline-color: #0175ff;\n        outline-style: auto;\n        outline-width: 1px;\n        outline-offset: 2px;\n    }\n"], ["\n    @media screen and (-webkit-min-device-pixel-ratio: 0) {\n        margin-left: -8px !important;\n    }\n    @supports (-ms-ime-align: auto) {\n    }\n    @media screen and (-ms-high-contrast: active),\n        screen and (-ms-high-contrast: none) {\n        margin-left: -21.5px !important;\n    }\n    @-moz-document url-prefix() {\n        margin-left: -21.5px !important;\n    }\n    input:focus + & {\n        outline-color: #0175ff;\n        outline-style: auto;\n        outline-width: 1px;\n        outline-offset: 2px;\n    }\n"])));
var CheckboxImgSpanRight = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin-left: 7px;\n    pointer-events: auto;\n    cursor: pointer;\n    word-wrap: break-word;\n    flex: 1;\n"], ["\n    margin-left: 7px;\n    pointer-events: auto;\n    cursor: pointer;\n    word-wrap: break-word;\n    flex: 1;\n"])));
var CheckboxInput = /** @class */ (function (_super) {
    __extends(CheckboxInput, _super);
    function CheckboxInput(props) {
        var _this = _super.call(this, props) || this;
        _this.onChangeFunc = function (checked) { return function (e) {
            var newState = {
                hover: _this.state.hover
            };
            _this.setState(newState);
            getProps(_this.props).onChange(!checked);
        }; };
        _this.onMouseOverFunc = function () { return function (event) {
            var newState = {
                hover: !_this.state.hover
            };
            _this.setState(newState);
        }; };
        _this.state = {
            hover: false
        };
        return _this;
    }
    CheckboxInput.prototype.setBackgroundImg = function (styleType, checked) {
        var backgroundImage = CheckboxFormattingStyles.CheckboxStar;
        switch (styleType) {
            case CheckboxType.STAR: {
                if (checked) {
                    if (this.state.hover) {
                        backgroundImage =
                            CheckboxFormattingStyles.CheckboxStarHoverDark;
                    }
                    else {
                        backgroundImage =
                            CheckboxFormattingStyles.CheckboxStarFilled;
                    }
                }
                else {
                    if (this.state.hover) {
                        backgroundImage =
                            CheckboxFormattingStyles.CheckboxStarHoverLight;
                    }
                    else {
                        backgroundImage = CheckboxFormattingStyles.CheckboxStar;
                    }
                }
                break;
            }
            case CheckboxType.FLAG: {
                if (checked) {
                    if (this.state.hover) {
                        backgroundImage =
                            CheckboxFormattingStyles.CheckboxFlagHoverDark;
                    }
                    else {
                        backgroundImage =
                            CheckboxFormattingStyles.CheckboxFlagChecked;
                    }
                }
                else {
                    if (this.state.hover) {
                        backgroundImage =
                            CheckboxFormattingStyles.CheckboxFlagHoverLight;
                    }
                    else {
                        backgroundImage = CheckboxFormattingStyles.CheckboxFlag;
                    }
                }
                break;
            }
            default: {
                backgroundImage = CheckboxFormattingStyles.NoBackgroundImg;
                break;
            }
        }
        return backgroundImage;
    };
    CheckboxInput.prototype.render = function () {
        var _a, _b;
        var _c = getProps(this.props), name = _c.name, label = _c.label, logic = _c.logic, isHiddenByConditionalLogic = _c.isHiddenByConditionalLogic, hasSourceLogic = _c.hasSourceLogic, onSourceLogicTagClick = _c.onSourceLogicTagClick, value = _c.value, description = _c.description, required = _c.required, disabled = _c.disabled, checkboxType = _c.checkboxType, error = _c.error, captionLocation = _c.captionLocation, componentKey = _c.componentKey, richDescription = _c.richDescription, isRichTextEnabled = _c.isRichTextEnabled;
        var handleKeyDown = function (e) {
            if (e.key === "Enter") {
                e.preventDefault();
            }
        };
        var hasLogic = logic != null;
        var dataClientIdSuffix = label != null ? label : name;
        var id = "checkbox_id_" + name;
        var descriptionElemId = "description_" + sanitizeHtmlID(dataClientIdSuffix);
        var errorMessageId = "error_msg_" + sanitizeHtmlID(dataClientIdSuffix);
        var describedByIds = generateAriaDescribedBy(descriptionElemId, !!description, errorMessageId, !!error);
        return (jsx(InputContainer, { "data-client-id": "container_" + dataClientIdSuffix, "data-client-type": "checkbox", "data-client-label-position": captionLocation, "data-client-symbol-type": checkboxType, css: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n                    margin-bottom: -11px;\n                    ", "\n                        & > p {\n                          margin-top: 6px;\n                        }   \n                "], ["\n                    margin-bottom: -11px;\n                    ", "\n                        & > p {\n                          margin-top: 6px;\n                        }   \n                "])), captionLocation == CheckboxInputCaptionLocation.RIGHT &&
                (hasLogic || hasSourceLogic) &&
                "align-items: center;"), id: componentKey },
            label &&
                !hasLogic &&
                !hasSourceLogic &&
                captionLocation == CheckboxInputCaptionLocation.ABOVE && (jsx(InputLabel, { dataClientIdSuffix: dataClientIdSuffix, forElement: id, required: required }, label)),
            label &&
                (hasLogic || hasSourceLogic) &&
                captionLocation == CheckboxInputCaptionLocation.ABOVE && (jsx(LabelContainer, { style: { paddingRight: "8px", width: "100%" } },
                jsx("div", null,
                    jsx(InputLabel, { forElement: id, required: required, dataClientIdSuffix: dataClientIdSuffix }, label)),
                hasSourceLogic && (jsx(SourceLogicTag, { onClickHandler: onSourceLogicTagClick })),
                logic && jsx(LogicTag, { logic: logic }))),
            (isRichTextEnabled && richDescription) &&
                jsx("div", { css: {
                        width: '-webkit-fill-available',
                        '& p': {
                            marginBlock: '2px',
                            fontSize: '14.12px',
                        },
                        '& ul': {
                            listStylePosition: 'inside',
                            fontSize: '14.12px',
                            marginBlock: '2px',
                        },
                        '& ol': {
                            listStylePosition: 'inside',
                            fontSize: '14.12px',
                            marginBlock: '2px',
                        },
                    }, id: descriptionElemId, className: "rich-text-field-desc", dangerouslySetInnerHTML: { __html: richDescription } })
                || description && (jsx(InputDescription, { id: descriptionElemId }, description)),
            checkboxType == CheckboxType.CHECKBOX ? (jsx(Wrapper, { isLabelRight: captionLocation ===
                    CheckboxInputCaptionLocation.RIGHT &&
                    !description },
                jsx(InputVisible, __assign({}, (describedByIds && (_a = {}, _a['aria-describedby'] = describedByIds, _a)), { tabIndex: isHiddenByConditionalLogic ? -1 : 0, id: id, "data-client-id": "checkbox_" + dataClientIdSuffix, type: "checkbox", name: name, checked: value, disabled: disabled, "aria-label": label, title: label, onChange: this.onChangeFunc(value), onKeyDown: handleKeyDown })))) : (jsx(InputHidden, __assign({}, (describedByIds && (_b = {}, _b['aria-describedby'] = describedByIds, _b)), { tabIndex: isHiddenByConditionalLogic ? -1 : 0, id: id, type: "checkbox", name: name, checked: value, disabled: disabled, onChange: this.onChangeFunc(value), onKeyDown: handleKeyDown }))),
            checkboxType == CheckboxType.CHECKBOX ? (jsx(CheckboxImgSpanRight, { onClick: this.onChangeFunc(value) },
                label &&
                    !hasLogic &&
                    !hasSourceLogic &&
                    captionLocation ==
                        CheckboxInputCaptionLocation.RIGHT && (jsx(InputLabelRight, { dataClientIdSuffix: dataClientIdSuffix, forElement: id, required: required, isHelpTextOffsetNeeded: description !== undefined }, label)),
                label &&
                    (hasLogic || hasSourceLogic) &&
                    captionLocation ==
                        CheckboxInputCaptionLocation.RIGHT && (jsx(LabelContainer, { style: { paddingRight: "8px" } },
                    jsx(InputLabelRight, { dataClientIdSuffix: dataClientIdSuffix, forElement: id, required: required, isHelpTextOffsetNeeded: description !== undefined }, label),
                    hasSourceLogic && (jsx(SourceLogicTag, { onClickHandler: onSourceLogicTagClick })),
                    logic && jsx(LogicTag, { logic: logic }))))) : (jsx(CheckboxImgSpan, { onClick: this.onChangeFunc(value), "data-client-id": "checkbox_caption_" + name, css: this.setBackgroundImg(checkboxType, value), onMouseOver: this.onMouseOverFunc, onMouseLeave: this.onMouseOverFunc })),
            label &&
                captionLocation == CheckboxInputCaptionLocation.RIGHT &&
                checkboxType !== CheckboxType.CHECKBOX && (jsx(SymbolCheckboxLabelRight, { forElement: id, required: required }, label)),
            error && (jsx(ErrorSpan, { "data-client-id": "component_error_msg" },
                jsx(WarningImageIcon, { customCss: WarningImageStyled }),
                jsx("span", { id: errorMessageId, role: "alert" },
                    jsx(FormattedMessage, { id: "validations.checkbox_required", values: { 0: label } }))))));
    };
    return CheckboxInput;
}(React.Component));
export default CheckboxInput;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
