import React from 'react';
var Intermediate = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "11px", height: "12px", viewBox: "0 0 11 12", version: "1.1" },
        React.createElement("title", null, "Difficulty Intermediate"),
        React.createElement("desc", null, "Intermediate."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(1.000000, 1.000000)", fill: "#0073EC" },
                React.createElement("rect", { id: "Rectangle", x: "0", y: "0", width: "10", height: "10" })))));
};
export default Intermediate;
