import React from 'react';
var Quarter = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", role: "img", width: "14px", height: "14px", viewBox: "0 0 14 14", version: "1.1" },
        React.createElement("title", null, "Harvey Ball Quarter"),
        React.createElement("desc", null, "Quarter."),
        React.createElement("defs", null,
            React.createElement("rect", { id: "path-1", x: "0", y: "0", width: "14", height: "14", rx: "7" })),
        React.createElement("g", { id: "img_pl_harvey025", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", null,
                React.createElement("g", null,
                    React.createElement("mask", { id: "mask-2", fill: "white" },
                        React.createElement("use", { xlinkHref: "#path-1" })),
                    React.createElement("use", { id: "Mask", fill: "#CCCCCC", xlinkHref: "#path-1" }),
                    React.createElement("rect", { id: "Rectangle-3", fill: "#444444", mask: "url(#mask-2)", x: "7", y: "-2.22044605e-15", width: "7", height: "7" }))))));
};
export default Quarter;
