import * as React from 'react';
var SmallCrossSvg = function () {
    return (React.createElement("svg", { width: "16px", height: "16px", viewBox: "0 0 16 16", version: "1.1", role: "img", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("title", null, "Remove File icon"),
        React.createElement("desc", null, "Remove"),
        React.createElement("g", { stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { fill: "#444444" },
                React.createElement("path", { d: [
                        'M9.55908552,9.5 L11.8537708,7.20531471 C12.0487431,7.01034245 1',
                        '2.0487431,6.69422996 11.8537708,6.4992577 L11.5007423,6.1462292',
                        'C11.30577,5.95125693 10.9896575,5.95125693 10.7946853,6.1462292',
                        'L8.5,8.44091448 L6.20531471,6.1462292 C6.01034245,5.95125693 5.',
                        '69422996,5.95125693 5.4992577,6.1462292 L5.1462292,6.4992577 C4',
                        '.95125693,6.69422996 4.95125693,7.01034245 5.1462292,7.20531471',
                        'L7.44091448,9.5 L5.1462292,11.7946853 C4.95125693,11.9896575 4.',
                        '95125693,12.30577 5.1462292,12.5007423 L5.4992577,12.8537708 C5',
                        '.69422996,13.0487431 6.01034245,13.0487431 6.20531471,12.853770',
                        '8 L8.5,10.5590855 L10.7946853,12.8537708 C10.9896575,13.0487431',
                        '11.30577,13.0487431 11.5007423,12.8537708 L11.8537708,12.500742',
                        '3 C12.0487431,12.30577 12.0487431,11.9896575 11.8537708,11.7946',
                        '853 L9.55908552,9.5 Z',
                    ].join('') })))));
};
export default SmallCrossSvg;
