import { isContact, FormComponentDefinitionType, FormValues } from '../../../app/types';
import { isEmailValue } from '@smartsheet/forms-components';

function noValuesSet(
  values: FormValues,
  hiddenComponents: FormComponentDefinitionType[]
): boolean {
  const hiddenKeys = hiddenComponents.map(o => o.key);

  for (const [key, value] of Object.entries(values)) {
    if (hiddenKeys.includes(key)) {
      continue;
    }
    // Numeric, Currency, Percentage
    if (typeof value === 'number' && value){
      return false;
    }

    if (typeof value === 'string' && value && value.trim() !== '' && key !== 'CAPTCHA_KEY') {
      return false;
    }

    if (typeof value === 'boolean' && value) {
      return false;
    }

    if (isEmailValue(value) && value.email && key !== 'EMAIL_RECEIPT') {
      return false;
    }

    if (Array.isArray(value) && value.length && !(value[0] instanceof File)) {
      return false;
    }

    if (isContact(value) && value.name) {
      return false;
    }
  }

  return true;
}

export default noValuesSet;
