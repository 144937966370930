import React from 'react';
var Half = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "58px", height: "6px", viewBox: "0 0 58 6", version: "1.1", role: "img" },
        React.createElement("title", null, "Progress Half"),
        React.createElement("desc", null, "Half progress."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", null,
                React.createElement("path", { d: "M29,0 L57.5,0 C57.7761424,-5.07265313e-17 58,0.223857625 58,0.5 L58,5.5 C58,5.77614237 57.7761424,6 57.5,6 L29,6 L29,0 Z", id: "Rectangle", fill: "#CCCCCC" }),
                React.createElement("path", { d: "M0.5,0 L29,0 L29,6 L0.5,6 C0.223857625,6 3.38176876e-17,5.77614237 0,5.5 L0,0.5 C-3.38176876e-17,0.223857625 0.223857625,5.07265313e-17 0.5,0 Z", id: "Rectangle", fill: "#0073EC" })))));
};
export default Half;
