import { Engine, EngineOptions, RuleProperties } from 'json-rules-engine';
import {Operators} from '../../../app/components/ConditionalLogic';

let engine: Engine;

export const getEngine = (rules?: Array<RuleProperties>, options?: EngineOptions): Engine => {
    if (!engine) {
        engine = new Engine(rules, options);
        for (let key in Operators) {
            engine.addOperator(key, Operators[key]);
        }
    }
    return engine;
}

export const addRulesToEngine = (rules: Array<RuleProperties>): void => {
    if (!engine) {
        getEngine(rules);
    } else {
        rules.forEach(rule => engine.addRule(rule));
    }
}