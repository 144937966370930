import React from 'react';
var Rewind = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "15px", height: "14px", viewBox: "0 0 15 14", version: "1.1", role: "img" },
        React.createElement("title", null, "Decision Rewind"),
        React.createElement("desc", null, "Rewind VCR."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(1.000000, 1.000000)", fill: "#444444" },
                React.createElement("path", { d: "M9.4,2.8 L4.4,9.46666667 C4.23431458,9.68758057 3.9209139,9.73235209 3.7,9.56666667 C3.66209717,9.53823954 3.62842712,9.5045695 3.6,9.46666667 L-1.4,2.8 C-1.56568542,2.5790861 -1.5209139,2.26568542 -1.3,2.1 C-1.21345191,2.03508894 -1.10818511,2 -1,2 L9,2 C9.27614237,2 9.5,2.22385763 9.5,2.5 C9.5,2.60818511 9.46491106,2.71345191 9.4,2.8 Z", transform: "translate(4.000000, 6.000000) scale(1, -1) rotate(90.000000) translate(-4.000000, -6.000000) " }),
                React.createElement("path", { d: "M15.4,2.8 L10.4,9.46666667 C10.2343146,9.68758057 9.9209139,9.73235209 9.7,9.56666667 C9.66209717,9.53823954 9.62842712,9.5045695 9.6,9.46666667 L4.6,2.8 C4.43431458,2.5790861 4.4790861,2.26568542 4.7,2.1 C4.78654809,2.03508894 4.89181489,2 5,2 L15,2 C15.2761424,2 15.5,2.22385763 15.5,2.5 C15.5,2.60818511 15.4649111,2.71345191 15.4,2.8 Z", transform: "translate(10.000000, 6.000000) scale(1, -1) rotate(90.000000) translate(-10.000000, -6.000000) " })))));
};
export default Rewind;
