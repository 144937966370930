import * as React from 'react';
import { FormConfirmation, FormTheme, ThemeType } from '../../../../app/types';
import FormLevelErrorBoundary from '../FormLevelErrorBoundary';
import ThemedAppView from './ThemedAppView';
import CondensedLayout from './CondensedLayout';
import ClassicLayout from './ClassicLayout';
import SplitLayout from './SplitLayout';
import FullPageConfirmation from './FullPageConfirmation';
import FullPageError from './FullPageError';

interface MainContentProps {
  confirmation?: FormConfirmation;
  description?: string;
  documentTitle?: string;
  error?: Error;
  formKey: string;
  formTheme?: FormTheme;
  isModernLayout: boolean;
  renderForm: (scrollToTop: () => void) => React.ReactNode;
  shouldRenderFullPageConfirmation: boolean;
  shouldRenderFullPageError: boolean;
  shouldRenderInlineConfirmation: boolean;
  shouldRenderLogo: boolean;
  title?: string;
  richTitle?: string;
  richDescription?: string;
  isRichTextEnabled?: boolean;
  hideFooterOnFormConfirmationPage: boolean;
  disableFooterFeatureFlag: boolean;
}

const MainContent: React.FC<MainContentProps> = props => {
  const {
    confirmation,
    documentTitle,
    error,
    formKey,
    formTheme,
    isModernLayout,
    isRichTextEnabled,
    shouldRenderFullPageConfirmation,
    shouldRenderFullPageError,
    hideFooterOnFormConfirmationPage,
    disableFooterFeatureFlag
  } = props;

  let Layout = ClassicLayout;

  if (isModernLayout) {
    Layout = SplitLayout;
  } else if (formTheme && formTheme.type === ThemeType.DEFAULT) {
    Layout = CondensedLayout;
  }

  return (
    <ThemedAppView documentTitle={documentTitle} formTheme={formTheme}>
      <FormLevelErrorBoundary>
        {error && shouldRenderFullPageError ? (
          <FullPageError error={error} />
        ) : shouldRenderFullPageConfirmation ? (
          <FullPageConfirmation confirmation={confirmation} disableFooterFeatureFlag={disableFooterFeatureFlag} formKey={formKey} hideFooterOnFormConfirmationPage={hideFooterOnFormConfirmationPage}/>
        ) : (
          <Layout {...props} />
        )}
      </FormLevelErrorBoundary>
    </ThemedAppView>
  );
};

export default MainContent;
