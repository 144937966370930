import * as React from 'react';
import { resolveAssetUrl } from '../../../app/utils';
import warningIcon from './alert-red.svg';


export type WarningImageIconProps = {
    className?: string
}

const WarningImageIcon: React.FC<WarningImageIconProps> = (props) => {
    return(
        <img alt='WarningIcon' className={props.className} src={resolveAssetUrl(warningIcon)} />
    )
  }
  
export default WarningImageIcon