import styled from '@emotion/styled/macro';

export const FormButton = styled.button`
border-radius: 2px;
font-size: 0.88em;
background-color: #0073ec;
align-self: flex-end;
border-style: none;
color: #fff;
margin-top: 20px;
margin-bottom: 0px;
padding-top: .5em;
padding-bottom: .5em;
padding-left: .75em;
padding-right: .75em;
min-width: 70px;
height: 30px;

&:disabled {
        background-color: #595959
    }
    &:enabled {
        &:hover {
            background-color: #3490dc
        }
    }
`

export const FormOutlineBorderButton = styled(FormButton)`
    :disabled {
       background-color: #ccc;
       color: white;
       &:hover {
            background-color: #ccc;
        }
    }
    background: none;
    border-radius: 2px;
    border: solid 1px #005ee0;
    color:#005EE0;
    outline: none;
    &:hover {
        border-radius: 2px;
        background-color: rgba(0, 94, 224, 0.1) !important;
    }
`;

export const FormButtonContainer = styled.div`
justify-content: flex-end;
margin-top: 5px;
`
