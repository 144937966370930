import { __makeTemplateObject } from "tslib";
import * as React from 'react';
import styled from '@emotion/styled/macro';
var Link = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: #0073EC;\n  word-break: break-all;\n  white-space: normal;\n"], ["\n  color: #0073EC;\n  word-break: break-all;\n  white-space: normal;\n"])));
var URL_PATTERN = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:'"$(),.;]*[-A-Z0-9+&@#\/%=~_|!:'"$(),.;])|(^|[^\/])(www\.[\S]+(\b|$))/gim;
var MarkDownDescription = function (text) {
    var parts = [];
    text.split(URL_PATTERN).forEach(function (part, index) {
        if (index % 3 === 0) {
            parts.push(part);
        }
        else if (index % 3 == 1) {
            var href = part;
            if (part && part.length > 3 && part.startsWith('www')) {
                href = "http://" + href;
            }
            if (part) {
                parts.push(React.createElement(Link, { href: href, key: index, rel: "noopener noreferrer", target: "_blank" }, part));
            }
        }
    });
    return parts;
};
export default MarkDownDescription;
var templateObject_1;
