import React from 'react';
var Full = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14px", height: "14px", viewBox: "0 0 14 14", version: "1.1", role: "img" },
        React.createElement("title", null, "Harvey Ball Full"),
        React.createElement("desc", null, "Full."),
        React.createElement("defs", null,
            React.createElement("rect", { id: "path-1", x: "0", y: "0", width: "14", height: "14", rx: "7" })),
        React.createElement("g", null,
            React.createElement("g", null,
                React.createElement("mask", { id: "mask-2", fill: "white" },
                    React.createElement("use", { xlinkHref: "#path-1" })),
                React.createElement("use", { id: "Mask", fill: "#444444", xlinkHref: "#path-1" })))));
};
export default Full;
