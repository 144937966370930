import React from 'react';
var Up = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "12px", height: "14px", viewBox: "0 0 12 14", version: "1.1", role: "img" },
        React.createElement("title", null, "Arrow Up"),
        React.createElement("desc", null, "Up arrow."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#57B370" },
                React.createElement("path", { d: "M11.4,6.8 L6.4,13.4666667 C6.23431458,13.6875806 5.9209139,13.7323521 5.7,13.5666667 C5.66209717,13.5382395 5.62842712,13.5045695 5.6,13.4666667 L0.6,6.8 C0.434314575,6.5790861 0.4790861,6.26568542 0.7,6.1 C0.786548085,6.03508894 0.891814893,6 1,6 L4,6 L4,0.5 C4,0.223857625 4.22385763,-4.7846199e-18 4.5,-5.55111512e-17 L7.5,0 C7.77614237,-5.07265313e-17 8,0.223857625 8,0.5 L8,6 L11,6 C11.2761424,6 11.5,6.22385763 11.5,6.5 C11.5,6.60818511 11.4649111,6.71345191 11.4,6.8 Z", id: "Combined-Shape", transform: "translate(6.000000, 7.000000) scale(1, -1) translate(-6.000000, -7.000000) " })))));
};
export default Up;
