import React from 'react';
import { ESymbolKey } from "@smartsheet/forms-model";
import Mild from "./Mild";
import NoPain from "./NoPain";
import Moderate from "./Moderate";
import Extreme from "./Extreme";
import Severe from "./Severe";
import VerySevere from "./VerySevere";
import { IconOptionImg } from "../../iconUtil";
var PainIcon = function (_a) {
    var type = _a.type;
    var icon;
    switch (type) {
        case ESymbolKey.PAIN_6_MILD:
        case "Mild":
            icon = React.createElement(Mild, null);
            break;
        case ESymbolKey.PAIN_6_NOPAIN:
        case "No Pain":
            icon = React.createElement(NoPain, null);
            break;
        case ESymbolKey.PAIN_6_MODERATE:
        case "Moderate":
            icon = React.createElement(Moderate, null);
            break;
        case ESymbolKey.PAIN_6_EXTREME:
        case "Extreme":
            icon = React.createElement(Extreme, null);
            break;
        case ESymbolKey.PAIN_6_SEVERE:
        case "Severe":
            icon = React.createElement(Severe, null);
            break;
        case ESymbolKey.PAIN_6_VERYSEVERE:
        case "Very Severe":
            icon = React.createElement(VerySevere, null);
            break;
        default:
            break;
    }
    return !!icon ? (React.createElement(IconOptionImg, null, icon)) : null;
};
export default PainIcon;
