import styled from '../styled';

const ErrorPanel = styled.div`
  background-color: ${(props:any) => props.theme.style.panel.color};
  border-radius: 2px;
  box-shadow: ${(props:any) => props.theme.style.boxShadow};
  color: #161616;
  display: block;
  font-size: 1.13em;
  font-weight: 300;
  margin-top: 32px;
  padding: 50px 30px;
`;

export default ErrorPanel;
