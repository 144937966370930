import { __makeTemplateObject } from "tslib";
import * as React from 'react';
import styled from '@emotion/styled/macro';
import { createPropsGetter } from '../utils';
var defaultProps = {
    required: false
};
var getProps = createPropsGetter(defaultProps);
var RequiredFlagSpan = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: #d0011b;\n  margin-left: 4px;\n"], ["\n  color: #d0011b;\n  margin-left: 4px;\n"])));
var InputLabelRight = function (props) {
    var children = getProps(props).children;
    var Label = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 0.88em;\n  font-weight: bold;\n  display: block;\n  margin-left: 0px;\n  margin-top: '0px';\n  "], ["\n  font-size: 0.88em;\n  font-weight: bold;\n  display: block;\n  margin-left: 0px;\n  margin-top: '0px';\n  "])));
    return (React.createElement(Label, { htmlFor: props.forElement, "data-client-id": props.dataClientIdSuffix != null ? "label_" + props.dataClientIdSuffix : "label" },
        children,
        props.required && (React.createElement(RequiredFlagSpan, { "data-client-id": "required_indicator" }, "*"))));
};
export default InputLabelRight;
var templateObject_1, templateObject_2;
