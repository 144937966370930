import React from 'react';
var Cloudy = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18px", height: "10px", viewBox: "0 0 18 10", version: "1.1", role: "img" },
        React.createElement("title", null, "Weather Cloudy"),
        React.createElement("desc", null, "Cloudy weather."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { transform: "translate(1.000000, 0.000000)", fill: "#CCCCCC" },
                React.createElement("path", { d: "M3.5,10 C1.56700338,10 0,8.43299662 0,6.5 C0,4.7259908 1.31983451,3.26024546 3.03113375,3.03113375 C3.26024546,1.31983451 4.7259908,0 6.5,0 C7.68314501,0 8.72917444,0.587061631 9.36268613,1.48578275 C9.83348619,1.17856066 10.3958906,1 11,1 C12.6568542,1 14,2.34314575 14,4 C15.6568542,4 17,5.34314575 17,7 C17,8.65685425 15.6568542,10 14,10 L3.5,10 Z" })))));
};
export default Cloudy;
