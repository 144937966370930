import { RuleProperties, Event, TopLevelCondition, ConditionProperties, NestedCondition, AnyConditions } from "json-rules-engine";
import { EventType, BooleanOperatorType, LogicDefinition, NonBooleanPredicate, isNonBooleanPredicate, FormValues, isLogicDefinition, FormComponentDefinitionType, isLogicDefinitionArray } from "../../../app/types";
import {getOperator} from "../../../app/components/ConditionalLogic";

export interface RuleEvent extends Event {
    params: {
        key: string,
        property: string,
        valueOnSuccess: boolean,
        valueOnFailure: boolean
    }
}

type AdjacencyGraph = {
    [vertex: string]: string[]
}

const EventValueMap:Map<string, any> = new Map([
    [EventType.SHOW, {property: 'isHiddenByConditionalLogic', valueOnSuccess: false, valueOnFailure: true}],
    [EventType.HIDE, {property: 'isHiddenByConditionalLogic', valueOnSuccess: true, valueOnFailure: false}]
])

/**
 * Helper method to convert predicate to compatible version
 * @param predicate 
 * @param negate 
 * @param initialFormValues 
 */
const buildCondition = (predicate: NonBooleanPredicate, negate: boolean, initialFormValues: FormValues): ConditionProperties | null => {
    if (isNonBooleanPredicate(predicate, initialFormValues)) {        
        const conditionProperties: ConditionProperties = {
            fact: predicate.key!,
            operator: getOperator(predicate.type, negate),
            value: predicate.value
        };
        return conditionProperties;
    }
    return null;
}

/**
 * Helper method to convert predicates to compatible version
 * @param predicate 
 * @param conditions 
 * @param negateResult 
 * @param initialFormValues 
 */
const buildConditions = (predicate: any | undefined, conditions: NestedCondition[], negateResult: boolean, initialFormValues: FormValues): NestedCondition[] => {
    const predicates = predicate.predicates;
    const notPredicate = predicate.predicate;        
    if (predicates && Array.isArray(predicates) && predicates.length) {
        predicates.forEach((pred)=> {
            if (pred.type === BooleanOperatorType.OR || pred.type === BooleanOperatorType.AND) {
                let conditionPropertiesList: NestedCondition[] = [];
                conditionPropertiesList = buildConditions(pred, conditionPropertiesList, negateResult, initialFormValues);
                const rootCondition: TopLevelCondition = negateResult ? 
                    pred.type === BooleanOperatorType.OR ? {all: conditionPropertiesList} : {any: conditionPropertiesList} :
                    pred.type === BooleanOperatorType.OR ? {any: conditionPropertiesList} : {all: conditionPropertiesList};
                conditions = [...conditions, rootCondition];                
            } else if (pred.type === BooleanOperatorType.NOT) {
                conditions = [...buildConditions(pred, conditions, !negateResult, initialFormValues)];                
            } else {
                const currCondition = buildCondition(pred, negateResult, initialFormValues);
                conditions = currCondition == null ? [...conditions] : [...conditions, currCondition];             
           }                      
        });
    } else if(notPredicate && Array.isArray(notPredicate) && notPredicate.length){
        notPredicate.forEach((pred)=> {
            const currCondition = buildCondition(pred, negateResult, initialFormValues);
            conditions = currCondition == null ? [...conditions] : [...conditions, currCondition];
        });
    }

    return conditions;
}

/**
 * findChainDepth runs dfs on each neighbor of the node to find out the max depth
 * A->B->C, A->D if this is the graph, the depth for A would be 2 because of A->B->C
 * @param graph 
 * @param node 
 */
const findChainDepth = (graph: AdjacencyGraph, node: string):number => {
    const neighbors: string[] = graph[node];
    let maxLevel = 0;
    if(neighbors.length) {
        for(let i = 0; i < neighbors.length; i++) {
            const visited = {};
            const neighbor = neighbors[i];
            maxLevel = Math.max(dfs(graph, neighbor, visited, 0), maxLevel);            
        };
    }    
    return maxLevel;
}

/**
 * dfs method does depth first traversal on graph and returns the level the node is located.
 * root node (no dependency) has level 0.
 * @param graph 
 * @param node 
 * @param visited 
 * @param level 
 */

const dfs = (graph: AdjacencyGraph, node: string, visited: {[vertex:string]:boolean}, level: number): number => {
    if(!visited[node]){
        visited[node] = true;
        const neighbors: string[] = graph[node];
        for(let i = 0; i < neighbors.length; i++) {
            const neighbor = neighbors[i];            
            return dfs(graph, neighbor, visited, level+1);
        };
    }
    return level;
}

/**
 * setPriorityOnRules finds depth of each rule in graph and sets priority on it.
 * Priority is inverse of how deep the rule. If its root (no dependency), then it gets max priority
 * @param ruleArr 
 * @param graph 
 */
const setPriorityOnRules = (ruleArr: RuleProperties[], graph: AdjacencyGraph): RuleProperties[] => {
    let rules = [...ruleArr];
    let maxLevel:number  = 0;
    let ruleLevelMap:{[key:string]: number} = {};
    // Initialize ruleLevelMap
    rules.forEach((rule:RuleProperties)=>{
        const event = rule.event as RuleEvent;
        ruleLevelMap[`rule_${event.params.key}_${event.type}`] = findChainDepth(graph, `rule_${event.params.key}_${event.type}`);
        maxLevel = Math.max(ruleLevelMap[`rule_${event.params.key}_${event.type}`], maxLevel);
    });
    // Set priorities
    rules.forEach((rule:RuleProperties)=>{
        const event = rule.event as RuleEvent;
        const ruleLevelName = `rule_${event.params.key}_${event.type}`;
        rule.priority = maxLevel - ruleLevelMap[ruleLevelName] + 1;
        const conditions = (rule.conditions as AnyConditions).any;
        let newConditions: NestedCondition[] = [];
        conditions.forEach((condition: NestedCondition)=> {
            const {fact:parentKey} = condition as ConditionProperties;
            const parentRuleLevelName = `rule_${parentKey}_${event.type}`;
            let additionalCondition:ConditionProperties = {
                fact: `${parentRuleLevelName}_passed`,
                operator: 'RUNTIME_IS',
                value: true
            };
            newConditions.push({all: [additionalCondition, condition]});
        });
        rule.conditions = {any:[...newConditions]};      
    });
    return rules;
}

/**
 * createRulesPerField creates single rule compatible with json-rules-engine
 * @param logicArr 
 * @param parentKey 
 * @param initialFormValues 
 */
export const createRulesPerField = (logicArr: LogicDefinition[], parentKey: string, initialFormValues: FormValues): RuleProperties[] => {    
    let rulesList: RuleProperties[] = [];    
    if (Array.isArray(logicArr) && logicArr.length) {
        logicArr.forEach((logic)=> {
            if (isLogicDefinition(logic, initialFormValues)) {
                const eventType: string = logic.type;
                const {property, valueOnSuccess, valueOnFailure} = EventValueMap.get(eventType);
                const event: RuleEvent = {
                    type: eventType,
                    params: {
                        key: parentKey,
                        property,
                        valueOnSuccess,
                        valueOnFailure
                    }
                };
                if (logic.predicate) {
                    const rootPredicate = logic.predicate;
                    let conditionPropertiesList: NestedCondition[] = [];                
                    conditionPropertiesList = buildConditions(rootPredicate, conditionPropertiesList, false, initialFormValues);
                    const rootCondition: TopLevelCondition =  rootPredicate.type === BooleanOperatorType.OR ? {any: conditionPropertiesList} : {all: conditionPropertiesList};
                    const rule:RuleProperties = {
                        conditions: rootCondition,
                        event,
                        onSuccess: (event: Event, almanac, ruleResult) => {
                            const ev = event as RuleEvent;
                            almanac.addRuntimeFact(`rule_${ev.params.key}_${ev.type}_passed`, true);
                        },
                        onFailure: (event: Event, almanac, ruleResult) => {
                            const ev = event as RuleEvent;
                            almanac.addRuntimeFact(`rule_${ev.params.key}_${ev.type}_passed`, false);
                        }
                    };
                    rulesList.push(rule);                    
                }
            }           
        });
    }
    return rulesList;
}

/**
 * createAdjacencyGraph creates a graph where nodes are rules and neighbors are the rules on which parent node depends on.
 * This graph is used to determine which rule should run first in case of nested logic conditions
 * @param rules 
 */
const createAdjacencyGraph = (rules: RuleProperties[]): AdjacencyGraph => {
    let graph = {} as AdjacencyGraph;
    if(rules.length) {
        rules.forEach((rule)=>{
            const event = rule.event as RuleEvent;       
            let adjacentVertices: string[] = [];     
            const conditions = (rule.conditions as AnyConditions).any;
            conditions.forEach((condition: NestedCondition)=>{
                const {fact:parentKey} = condition as ConditionProperties;
                if(!graph[`rule_${parentKey}_${event.type}`]) {
                    graph[`rule_${parentKey}_${event.type}`] = [];
                }
                adjacentVertices.push(`rule_${parentKey}_${event.type}`);
            });            
            graph[`rule_${event.params.key}_${event.type}`] = adjacentVertices;
        });
    }
    return graph;
}

/**
 * createEngineCompRules method creates rules which are compatible with json-rules-engine
 * @param formComponents 
 * @param initialValues 
 */

export const createEngineCompRules = (formComponents: FormComponentDefinitionType[], initialValues: FormValues): RuleProperties[] => {
    let rulesList:RuleProperties[] = [];
    if (formComponents && formComponents.length) {
        formComponents.forEach((component)=>{
            if(component.logic && isLogicDefinitionArray(component.logic, initialValues!) && component.key) {            
            rulesList = [...rulesList, ...createRulesPerField(component.logic as LogicDefinition[], component.key, initialValues!)];
            }
        });
    }
    const graph = createAdjacencyGraph(rulesList);    
    return setPriorityOnRules(rulesList, graph);
}