import React from 'react';
var Medium = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14px", height: "3px", viewBox: "0 0 14 3", version: "1.1", role: "img" },
        React.createElement("title", null, "Priority Medium"),
        React.createElement("desc", null, "Medium Priority."),
        React.createElement("defs", null),
        React.createElement("g", { stroke: "none", fill: "none" },
            React.createElement("g", { transform: "translate(2.000000, 0.000000)", fill: "#F6A623" },
                React.createElement("rect", { id: "Rectangle", x: "0", y: "0", width: "10", height: "3" })))));
};
export default Medium;
