/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/core';
import ModalPortal from './ModalPortal';

const overlay = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #000;
  text-align: center;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
`;

const content = css`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20;
  border-radius: 2px;
  background-color: #fff;
  font-family: Roboto;
  transform: translate(-50%, -50%);
  overflow-wrap: break-word;

  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2), 0 0 2px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2),
    0 0 2px 0 rgba(0, 0, 0, 0.05);

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`;

interface ModalContentProps {
  children?: React.ReactNode;
}

class ModalContent extends React.Component<ModalContentProps> {
  public render() {
    return (
      <ModalPortal>
        <div css={overlay} />
        <div css={content}>
          {this.props.children}
        </div>
      </ModalPortal>
    );
  }
}

export default ModalContent;
