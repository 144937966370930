import { FormComponentDefinitionType, FormComponentType, Contact, isContact, FormValues, FormConfirmation } from "../../../app/types";
import { applyMultiContactListLogic } from "@smartsheet/forms-model";
import * as React from 'react'
import { FormStatusType } from '../../../app/store/sagas';
import { FieldProps, Field } from "formik";
import { Moment } from "moment";
import { submitMetrics } from "../../../app/utils";

import {
  CheckboxInput,
  MultiCheckboxInput, MultiCheckboxDirection,
  ConfirmationEmailInput,
  DateInput,
  Divider,
  EmailValue,
  FileDescription,
  FileUpload,
  Heading,
  RadioInput,
  SelectInput,
  TextInput,
} from '@smartsheet/forms-components';
import { StringValue } from '@smartsheet/forms-model';
import conditionalUpdate from "../../../app/components/ConditionalUpdate";
import conditionalDisplay from "../../../app/components/ConditionalDisplay";

function applyMultiListLogic(value: any) {
  return value.filter(function (val:string) {
    return val.length > 0;
  });
}
const isDefaultSet = (component: any, option: any) => {
  if (component.defaultValue != null) {
      if (component.defaultValue.type === 'MULTI_PICKLIST') {
          return Array.isArray(component.defaultValue.value) ?
              (component.defaultValue.value.indexOf(option.value) >= 0 ? true : false) :
              (component.defaultValue.value === option.value);
      }
  }
  return false;
}
const symbolKeysReducer = (map: any, current: any) => {
  const { value, symbolKey } = (current as StringValue)
  if (!map[value]) {
    map[value] = symbolKey;
  }
  return map;
};


const WrappedDivider = conditionalDisplay(Divider);
const WrappedHeading = conditionalDisplay(Heading);
const WrappedTextInput = conditionalDisplay(TextInput);
const TextField: React.FC<FieldProps<FormValues> & React.ComponentProps<typeof TextInput>> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched, submitCount },
  ...props
}) => {
  const changeHandler = (value: string) => {
    setFieldValue(field.name, value)
    setFieldTouched(field.name, true)
  }
  return <WrappedTextInput {...props} {...field} submitCount={submitCount} onPhoneChange={changeHandler} error={touched[field.name] ? errors[field.name] : undefined} touched={Boolean(touched[field.name])}></WrappedTextInput>
}

const WrappedCheckboxInput = conditionalDisplay(CheckboxInput);
const CheckboxField: React.FC<FieldProps<FormValues> & React.ComponentProps<typeof CheckboxInput>> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props
}) => {
  const changeHandler = (value: boolean) => {
    setFieldValue(field.name, value)
    setFieldTouched(field.name, true)
  }
  return <WrappedCheckboxInput {...props} {...field} onChange={changeHandler} error={touched[field.name] ? errors[field.name] : undefined} touched={Boolean(touched[field.name])}></WrappedCheckboxInput>
}

const WrappedMultiCheckboxInput = conditionalDisplay(conditionalUpdate(MultiCheckboxInput));
const MultiCheckboxField: React.FC<FieldProps<FormValues> & React.ComponentProps<typeof MultiCheckboxInput>> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched, isSubmitting },
  ...props
}) => {
  const changeHandler = (values: any) => {
    setFieldValue(field.name, values)
    setFieldTouched(field.name, true)    
    return values;
  }

  props.values = field.value ? field.value : props.values;
  return <WrappedMultiCheckboxInput {...props} {...field} onChange={changeHandler} error={touched[field.name] ? errors[field.name] : undefined} touched={Boolean(touched[field.name])}></WrappedMultiCheckboxInput>;
}

const WrappedSelectInput = conditionalDisplay(conditionalUpdate(SelectInput));
const SelectField: React.FC<FieldProps<FormValues> & React.ComponentProps<typeof SelectInput>> = ({
  field,
  options,
  form: { touched, errors, setFieldValue, setFieldTouched, isSubmitting },
  ...props
}) => {
  const changeHandler = (value: any) => {
    if (props.multivalue) {
      if(isContact(value[0])) {
        value = applyMultiContactListLogic(value, field.value);
      } else {
        value = applyMultiListLogic(value);
      }
    }
    setFieldValue(field.name, value)
    setFieldTouched(field.name, true)    
    return value;
  }
  return <WrappedSelectInput {...props} {...field} options={options}  isSubmitting={isSubmitting} onChange={changeHandler} error={touched[field.name] ? errors[field.name] : undefined} touched={Boolean(touched[field.name])}></WrappedSelectInput>;
}

const WrappedRadioInput = conditionalDisplay(RadioInput);
const RadioField: React.FC<FieldProps<FormValues> & React.ComponentProps<typeof RadioInput>> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props
}) => {
  const changeHandler = (value: (string | Contact), event: React.ChangeEvent<any>) => {
    setFieldValue(field.name, value)
    setFieldTouched(field.name, true)
  }
  return <WrappedRadioInput {...props} {...field} onChange={changeHandler} error={touched[field.name] ? errors[field.name] : undefined} touched={Boolean(touched[field.name])}></WrappedRadioInput>
}

const WrappedDateInput = conditionalDisplay(DateInput);
const DateField: React.FC<FieldProps<FormValues> & React.ComponentProps<typeof DateInput>> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props
}) => {
  const changeHandler = (date: Moment | string | null) => {
    setFieldValue(field.name, date)
    setFieldTouched(field.name, true)
  }
  return <WrappedDateInput {...props} {...field} onChange={changeHandler} error={touched[field.name] ? errors[field.name] : undefined} touched={Boolean(touched[field.name])} ></WrappedDateInput>
}

const WrappedConfirmationEmailInput = conditionalDisplay(ConfirmationEmailInput);
const ConfirmationEmailInputField: React.FC<FieldProps<FormValues> & React.ComponentProps<typeof ConfirmationEmailInput>> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props
}) => {
  const changeHandler = (emailValue: EmailValue) => {
    setFieldValue(field.name, emailValue)
    //the endsWith regex blocks the error from dislaying until user clicks away from the field
    if (emailValue.email) {
      if ((emailValue.email.length > 0) && (emailValue.email.endsWith("^d+$"))) {
        setFieldTouched(field.name, true)
      }
    }
  }
  return <WrappedConfirmationEmailInput {...field} {...props} error={touched[field.name] ? errors[field.name] : undefined} touched={Boolean(touched[field.name])} onChange={changeHandler}></WrappedConfirmationEmailInput>
}

const WrappedFileUpload = conditionalDisplay(FileUpload);
const FileUploadField: React.FC<FieldProps<FormValues> & React.ComponentProps<typeof FileUpload>> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props
}) => {
  const changeHandler = (fileDescriptions: FileDescription[]) => {
    setFieldValue(field.name, fileDescriptions.map(description => description.file))
    setFieldTouched(field.name, true)
  }
  return <WrappedFileUpload {...props} {...field} onFilesChanged={changeHandler} error={touched[field.name] ? errors[field.name] : undefined} />
}

export const createFieldFromDefinition = (isRichTextEnabled: boolean | undefined, component: FormComponentDefinitionType, index: number, confirmation: FormConfirmation, formStatusType: FormStatusType) => {  
  switch (component.type) {
    case FormComponentType.TEXT_INPUT: return (
      <Field
        key={component.key}
        isRichTextEnabled={isRichTextEnabled}
        component={TextField}
        name={component.key}
        label={component.label}
        description={component.description}
        richDescription={component.richDescription}
        required={component.required}
        lines={component.lines}
        validation={component.validation}
        hidden={component.hidden}
        componentKey={component.key}
        isHiddenByConditionalLogic={component.isHiddenByConditionalLogic}
        defaultValue={component.defaultValue}
        confirmation={confirmation}
      />
    )
    case FormComponentType.CHECKBOX_INPUT: return (
      <Field
        key={component.key}
        isRichTextEnabled={isRichTextEnabled}
        component={CheckboxField}
        name={component.key}
        label={component.label}
        description={component.description}
        richDescription={component.richDescription}
        required={component.required}
        checkboxType={component.checkboxType}
        hidden={component.hidden}
        captionLocation={component.captionLocation}
        componentKey={component.key}
        isHiddenByConditionalLogic={component.isHiddenByConditionalLogic}
      />
    )
    case FormComponentType.MULTI_CHECKBOX_INPUT: {
      const values: {}[] = [];
      component.options.forEach(option => {
          values.push({
              value: option.value,
              checked: isDefaultSet(component, option)
        })
      });
      return (

      <Field
        key={component.key}
        isRichTextEnabled={isRichTextEnabled}
        component={MultiCheckboxField}
        name={component.key}
        label={component.label}
        values={values}
        description={component.description}
        richDescription={component.richDescription}
        required={component.required}
        checkboxType={component.checkboxType}
        hidden={component.hidden}
        direction={component.direction === "HORIZONTAL" ? MultiCheckboxDirection.HORIZONTAL : MultiCheckboxDirection.VERTICAL}
        //captionLocation={component.captionLocation}
        componentKey={component.key}
        isHiddenByConditionalLogic={component.isHiddenByConditionalLogic}
      />
    )
    }
    case FormComponentType.SELECT_INPUT:
      let optionArr = component.options.slice();
      if(component.allowUnset === undefined || component.allowUnset === true){
        //need empty options for single select
        optionArr.unshift({
          type: 'String',
          value: ''
        });
      }
      
      const selectSymbolKeys = component.symbolType && component.options ? component.options.reduce(symbolKeysReducer, {}) : null;
      
      return (
        <Field
          isRichTextEnabled={isRichTextEnabled}
          key={component.key}
          component={SelectField}
          name={component.key}
          label={component.label}
          richDescription={component.richDescription}
          description={component.description}
          required={component.required}
          options={optionArr.map(option => option.value)}
          symbolType={component.symbolType}
          symbolKeys={selectSymbolKeys}
          hidden={component.hidden}
          selectDataType={component.selectDataType}
          validateField={component.validateField}
          validation={component.validation}
          valueType={component.valueType}
          multivalue={component.multivalue}
          componentKey={component.key}
          isHiddenByConditionalLogic={component.isHiddenByConditionalLogic}
          isSubmitting={component.isSubmitting}
          confirmation={confirmation}
          formBuilder={false}
          formStatusType={formStatusType}
        />
      );

    case FormComponentType.RADIO_INPUT:
      const radioSymbolKeys = component.symbolType && component.options ? component.options.reduce(symbolKeysReducer, {}) : null;
      return (
        <Field
          key={component.key}
          component={RadioField}
          isRichTextEnabled={isRichTextEnabled}
          name={component.key}
          label={component.label}
          richDescription={component.richDescription}
          description={component.description}
          required={component.required}
          horizontal={component.direction === "HORIZONTAL"}
          options={component.options.map(option => option.value)}
          hidden={component.hidden}
          symbolType={component.symbolType}
          symbolKeys={radioSymbolKeys}
          componentKey={component.key}
          isHiddenByConditionalLogic={component.isHiddenByConditionalLogic}
          //radio input needs static defaultValue passed to prevent a minor UI bug where multiple identical radio options can be selected
          defaultValue={component.defaultValue ? component.defaultValue.value : undefined}
        />
      )
    case FormComponentType.DATE_INPUT: return (
      <Field
        key={component.key}
        component={DateField}
        isRichTextEnabled={isRichTextEnabled}
        name={component.key}
        label={component.label}
        richDescription={component.richDescription}
        description={component.description}
        required={component.required}
        hidden={component.hidden}
        validateField={component.validateField}
        componentKey={component.key}
        isHiddenByConditionalLogic={component.isHiddenByConditionalLogic}
      />
    )
    case FormComponentType.EMAIL_RECEIPT_INPUT: return (
      <Field
        key={component.key}
        component={ConfirmationEmailInputField}
        name={component.key}
        hidden={component.hidden}
        submitMetrics={submitMetrics}
        componentKey={component.key}
        isHiddenByConditionalLogic={component.isHiddenByConditionalLogic}
      />
    )

    case FormComponentType.FILE_UPLOAD: return (
      <Field
        key={component.key}
        component={FileUploadField}
        name={component.key}
        isRichTextEnabled={isRichTextEnabled}
        label={component.label}
        richDescription={component.richDescription}
        description={component.description}
        required={component.required}
        hidden={component.hidden}
        maxFileCount={component.maxFileCount}
        maxFileSize={component.maxFileSize}
        forbidFileTypes={component.forbidFileTypes}
        componentKey={component.key}
        isHiddenByConditionalLogic={component.isHiddenByConditionalLogic}
      />
    )
    case FormComponentType.HEADING: return (
      <WrappedHeading
        key={"Heading-" + index}
        title={component.title}
        richTitle={component.richTitle}
        isRichTextEnabled={isRichTextEnabled}
        richDescription={component.richDescription}
        description={component.description}
        hidden={component.hidden}
        isHiddenByConditionalLogic={component.isHiddenByConditionalLogic}
      />
    )
    case FormComponentType.DIVIDER: return (
      <WrappedDivider
        key={"Divider-" + index}
        title={component.title || ""}
        isHiddenByConditionalLogic={component.isHiddenByConditionalLogic}
      />
    )

    case FormComponentType.CAPTCHA: return
  }
}
