import React from 'react';
var Up = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "12px", height: "9px", viewBox: "0 0 12 9", version: "1.1", role: "img" },
        React.createElement("title", null, "Directions Up"),
        React.createElement("desc", null, "Up."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#57B370" },
                React.createElement("path", { d: "M11.4,0.8 L6.4,7.46666667 C6.23431458,7.68758057 5.9209139,7.73235209 5.7,7.56666667 C5.66209717,7.53823954 5.62842712,7.5045695 5.6,7.46666667 L0.6,0.8 C0.434314575,0.5790861 0.4790861,0.265685425 0.7,0.1 C0.786548085,0.0350889359 0.891814893,1.30895584e-16 1,1.11022302e-16 L11,-1.16573418e-15 C11.2761424,-1.21646071e-15 11.5,0.223857625 11.5,0.5 C11.5,0.608185107 11.4649111,0.713451915 11.4,0.8 Z", id: "Combined-Shape", transform: "translate(6.000000, 4.000000) scale(1, -1) translate(-6.000000, -4.000000) " })))));
};
export default Up;
