import sprites from './sprites';
var backgroundImage = "url(" + sprites + ")";
export default {
    Hidden: {
        display: 'none'
    },
    NoBackgroundImg: {
        backgroundImage: 'none',
        height: '15px',
        width: '15px',
        backgroundPosition: '-0px -0px',
        margin: '0px 0px 0px 0px'
    },
    CheckboxStar: {
        backgroundImage: backgroundImage,
        height: '15px',
        width: '15px',
        backgroundPosition: '-10px -160px',
        margin: '10px 0px 0px -17px'
    },
    CheckboxStarFilled: {
        backgroundImage: backgroundImage,
        height: '15px',
        width: '15px',
        backgroundPosition: '-10px -238px',
        margin: '10px 0px 0px -17px'
    },
    CheckboxStarHoverDark: {
        backgroundImage: backgroundImage,
        height: '15px',
        width: '15px',
        backgroundPosition: '-10px -264px',
        margin: '10px 0px 0px -17px'
    },
    CheckboxStarHoverLight: {
        backgroundImage: backgroundImage,
        height: '15px',
        width: '15px',
        backgroundPosition: '-10px -186px',
        margin: '10px 0px 0px -17px'
    },
    CheckboxFlag: {
        backgroundImage: backgroundImage,
        height: '15px',
        width: '15px',
        backgroundPosition: '-10px -314px',
        margin: '10px 0px 0px -17px'
    },
    CheckboxFlagChecked: {
        backgroundImage: backgroundImage,
        height: '15px',
        width: '15px',
        backgroundPosition: '-10px -394px',
        margin: '10px 0px 0px -17px'
    },
    CheckboxFlagHoverLight: {
        backgroundImage: backgroundImage,
        height: '15px',
        width: '15px',
        backgroundPosition: '-10px -366px',
        margin: '10px 0px 0px -17px'
    },
    CheckboxFlagHoverDark: {
        backgroundImage: backgroundImage,
        height: '15px',
        width: '15px',
        backgroundPosition: '-10px -420px',
        margin: '10px 0px 0px -17px'
    },
    PushRight60: {
        marginleft: '60px'
    }
};
