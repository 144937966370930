export default {
    style: {
        background: {
            color: '#fff;',
        },
        logo: {
            image: '',
        },
        panel: {
            color: '#ffffff;',
            padding: '30px',
        },
        font: {
            readOnlyColor: '#161616;',
            readOnlyColorLight: '#767676;',
            editableColor: '#161616;',
            family: "'Roboto', sans-serif",
        },
        boxShadow: '0 11px 5px 0 rgba(0,0,0,0.2), 0 1px 2px 0 rgba(0,0,0,0.2);',
        justifyContent: 'center',
    },
};
