import React from 'react';
var High = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "4px", height: "13px", viewBox: "0 0 4 13", version: "1.1", role: "img" },
        React.createElement("title", null, "Priority High"),
        React.createElement("desc", null, "High priority."),
        React.createElement("defs", null),
        React.createElement("g", { stroke: "none", fill: "none" },
            React.createElement("g", { fill: "#D0011B" },
                React.createElement("path", { d: "M0.5,1.5 C0.5,0.671572875 1.16579723,0 2,0 L2,0 C2.82842712,0 3.5,0.665797234 3.5,1.5 L3.5,3 L3.08256483,8.00922203 C3.03696553,8.55641359 2.55613518,9 2,9 L2,9 C1.44771525,9 0.962418735,8.54902482 0.917435169,8.00922203 L0.5,3 L0.5,1.5 Z", id: "Rectangle" }),
                React.createElement("rect", { id: "Rectangle", x: "0.5", y: "10", width: "3", height: "3", rx: "1.5" })))));
};
export default High;
