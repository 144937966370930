import { __makeTemplateObject } from "tslib";
import { jsx } from '@emotion/core';
import styled from "@emotion/styled/macro";
import { FormattedMessage } from 'react-intl';
import * as SvgImages from "../Svg";
var Wrapper = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  svg {\n    cursor: pointer;\n  }\n"], ["\n  svg {\n    cursor: pointer;\n  }\n"])));
var DateInputIcon = function () { return (jsx(FormattedMessage, { id: "messages.msg_hov_webForm_dateField_trigger" }, function (tooltip) {
    return jsx(Wrapper, { title: (typeof tooltip === 'string') ? tooltip : '' },
        jsx(SvgImages.CalendarSvg, null));
})); };
export default DateInputIcon;
var templateObject_1;
