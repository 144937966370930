import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled/macro';

export type MobileBannerState = {
    visible: boolean
}

const BannerCloser = styled.div`
  cursor: pointer;
`
const bannerTabletBreakpointSize = "43.75rem";
const tabletOrLarger = `(min-width: ${bannerTabletBreakpointSize})`


const Banner = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: #FFFFFF;
  font-family: Roboto;
  line-height: 1.125rem;
  font-size: 0.875rem;
  padding-top: 0.9375rem;
  padding-left: 1rem;
  padding-right: 1.375rem;
  padding-bottom: 1rem;
  box-shadow: 0 0.25rem 0.625rem 0 rgba(0,0,0,0.75);
  `

const MobileBannerContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  @media ${tabletOrLarger}{
    margin-left: 35vw;
  }
`

const BannerHeader = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media ${tabletOrLarger}{
    margin-left: 35vw;
  }
`;

const BannerMessage = styled.span`
  color: #111111;
`

const OpenLink = styled.a`
  color: #444444;
  text-decoration: none;
  cursor:pointer;
`

const OpenLinkContainer = styled.div`
  width: 8.25rem;
  height: 2.5rem;
  display: flex;
  background-color: #EEEEEE;
  justify-content: center;
  font-weight: 600;
  align-items: center;
  border-radius: 0.25rem;
  text-align: center;
`

const DownloadLinkContainer = styled.div`
  font-weight: bold;
  height: 2.5rem;
  width: 7.3125rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background-color: #3576BA;
  text-align: center;
`;

const DownloadLink = styled.a`
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
`

export type MobileBannerProps = {
    publishKey: string,
    forceHidden: boolean
}

const android: boolean = /(android)/i.test(window.navigator.userAgent);
const iOS: boolean = !!navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform)
const isMobileAppWebView: boolean = /smartsheet/i.test(window.navigator.userAgent);
// Checking if localStorage exists on sites which have cookies disabled returns an error, we must catch this error for forms within iframes
let browserSupportsLocalStorage: boolean = true
try {
  localStorage.setItem("__test", "true")
  localStorage.removeItem("__test")
} catch (exception) {
  browserSupportsLocalStorage = false
}

class MobileBanner extends React.Component<MobileBannerProps, MobileBannerState> {
    state = {visible: false}

    hideMobileBanner = () => {
      if (browserSupportsLocalStorage) {
        localStorage.setItem("smartsheet-webform-mobilebanner-hidden", "true")
        this.setState({visible: false})
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps: MobileBannerProps) {
      if (browserSupportsLocalStorage && !isMobileAppWebView && (iOS || android)) {
        const mobileBannerHidden = localStorage.getItem("smartsheet-webform-mobilebanner-hidden")
        if (mobileBannerHidden !== "true" && !nextProps.forceHidden) {
          this.setState({
            visible: true
          })
        }
      }
    }

    render() {

        const { visible } = this.state
        const androidLinkURL = "intent://" + window.location.hostname + "/b/form/" + this.props.publishKey + "#Intent;scheme=smartsheet;package=com.smartsheet.android;end;"
        const androidDownloadURL = "https://play.google.com/store/apps/details?id=com.smartsheet.android&referrer=utm_source%3Dform_banner%26utm_campaign%3Dform_banner_promo%26anid%3Dadmob"
        const iOSLinkURL = "smartsheet://" + window.location.hostname + "/b/form/" + this.props.publishKey
        const iOSDownloadURL = "https://itunes.apple.com/app/apple-store/id568421135?pt=461799&ct=Forms_Banner_Ad&mt=8"

        return(
            visible && (
                <Banner data-client-id="mobile_banner">
                  <BannerHeader>
                    <BannerMessage data-client-id="mobile_banner_message"><FormattedMessage id="banners.msg_mobile_banner"></FormattedMessage></BannerMessage>
                    <BannerCloser data-client-id="mobile_banner_close" onClick={this.hideMobileBanner}>✖</BannerCloser>
                  </BannerHeader>
                  <MobileBannerContainer>
                    <DownloadLinkContainer><DownloadLink data-client-id="mobile_banner_download_link" href={iOS ? iOSDownloadURL : androidDownloadURL}><FormattedMessage id="banners.btn_mobile_download"></FormattedMessage></DownloadLink></DownloadLinkContainer>
                    <OpenLinkContainer><OpenLink data-client-id="mobile_banner_open_in_app_link" href={iOS ? iOSLinkURL : androidLinkURL}><FormattedMessage id="banners.btn_mobile_open"></FormattedMessage></OpenLink></OpenLinkContainer>
                  </MobileBannerContainer>
                </Banner>
            )
        )
    }
}

export default MobileBanner