import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { resolveAssetUrl } from '../../../app/utils';
import spinner from './img_spinner32_alt_start.gif';
import warningIcon from '../../../assets/icons/alert-red.svg';

class ImagePreloader extends React.Component {
  private el: HTMLElement = document.createElement('div');

  private renderDummyImages() {
    const urls = [spinner, warningIcon];

    return urls
      .map(resolveAssetUrl)
      .map((src, index) => <img alt='preloader' src={src} key={index} />);
  }

  public render() {
    // Render the dummy images into a dettached
    // portal so they don't clutter up the DOM.
    return ReactDOM.createPortal(this.renderDummyImages(), this.el);
  }
}

export default ImagePreloader;
