import { jsx } from '@emotion/core';
var MsExcelIcon = function () {
    return (jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", role: "img" },
        jsx("title", null, "MS Excel Attachment Icon"),
        jsx("desc", null, "Ms Excel Icon"),
        jsx("g", { fill: "none", fillRule: "nonzero" },
            jsx("path", { fill: "#107C41", d: [
                    'M1.333 0h13.334C15.403 0 16 .597 16 1.333v13.334c0 .736-.597 1.33',
                    '3-1.333 1.333H1.333A1.333 1.333 0 0 1 0 14.667V1.333C0 .597.597 0',
                    '1.333 0z',
                ].join('') }),
            jsx("path", { fill: "#FFF", d: [
                    'M4.5 12l2.536-4.011L4.713 4h1.869L7.85 6.55c.116.241.196.422.24.5',
                    '4h.017c.083-.193.17-.38.262-.563L9.724 4h1.716L9.057 7.967 11.5 1',
                    '2H9.675L8.21 9.2a2.36 2.36 0 0 1-.174-.374h-.022c-.043.127-.1.248',
                    '-.17.362L6.336 12H4.5z',
                ].join('') }))));
};
export default MsExcelIcon;
