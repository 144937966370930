import React from 'react';
var No = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14px", height: "12px", viewBox: "0 0 14 12", version: "1.1", role: "img" },
        React.createElement("title", null, "Decision Hold"),
        React.createElement("desc", null, "Hold."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#F6A623" },
                React.createElement("path", { d: "M7.43188945,0.740381915 L13.561371,11.2480645 C13.7005111,11.4865904 13.619943,11.7927493 13.381417,11.9318895 C13.304949,11.9764958 13.2180088,12 13.1294815,12 L0.8705185,12 C0.594376125,12 0.3705185,11.7761424 0.3705185,11.5 C0.3705185,11.4114727 0.394022698,11.3245325 0.438629049,11.2480645 L6.56811055,0.740381915 C6.70725069,0.501855958 7.01340956,0.421287836 7.25193551,0.560427977 C7.32643733,0.603887371 7.38843006,0.665880098 7.43188945,0.740381915 Z", id: "Rectangle" })))));
};
export default No;
