/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import { FormConfirmation } from '../../../../../app/types';
import { isDarkBackground, submitMetrics } from '../../../../../app/utils';
import { StyledFormTheme } from '../../styled';
import StaticSection from './StaticSection';
import DynamicSection from './DynamicSection';
import { SplitLayoutWrapper } from '../../StyledComponents/FormPage';

// SplitLayout is used to render the Modern form style.
interface SplitLayoutProps {
  confirmation?: FormConfirmation;
  description?: string;
  isRichTextEnabled?: boolean;
  renderForm: (scrollToTop: () => void) => React.ReactNode;
  shouldRenderInlineConfirmation: boolean;
  title?: string;
  richTitle?: string;
  richDescription?: string;
  theme?: StyledFormTheme;
}

const SplitLayout: React.FC<SplitLayoutProps> = ({
  confirmation,
  description,
  renderForm,
  shouldRenderInlineConfirmation,
  theme,
  title,
  richTitle,
  richDescription,
  isRichTextEnabled
}) => {
  const style = theme && theme.style;
  const background = style && style.background;
  const backgroundColor = background && background.color;
  const backgroundImage = background && background.image;
  const fontFamily = style && style.font && style.font.family;

  //submit metrics for rendered forms with form image or color
  if (backgroundImage) {
    submitMetrics({
      id: '',
      event: 'Form_Render_BackgroundImage',
      element: 'Render_BackgroundImage',
      value: '',
    });
  } else if (backgroundColor) {
    submitMetrics({
      id: '',
      event: 'Form_Render_BackgroundColor',
      element: 'Render_BackgroundColor',
      value: '',
    });
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflowWrap: 'break-word',
        width: '100vw',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        fontFamily: 'Roboto',
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          /* This IE10+ specific CSS is needed to prevent a double scroll bar from appearing in IE11
           *  We don't want to force this css onto other browsers this forces the left section of modern form
           *  layout to not be fully static/scrollable. */
          height: '100%',
        },
      }}
    >
      <SplitLayoutWrapper 
        isRichTextEnabled={isRichTextEnabled}
        //set color to undefined to show image instead of white
        backgroundColor={backgroundImage ? '#fff' : backgroundColor}
        fontFamily={fontFamily}
      >
        <StaticSection
          isRichTextEnabled={isRichTextEnabled}
          description={description}
          backgroundImage={backgroundImage}
          textColor={(backgroundImage) ? 'rgb(0, 0, 0)' :
          (isDarkBackground(backgroundColor) ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)')}
          title={title}
          richTitle={richTitle}
          richDescription={richDescription}
        />
        <DynamicSection
          confirmation={confirmation}
          renderForm={renderForm}
          shouldRenderInlineConfirmation={shouldRenderInlineConfirmation}
        />
        <div
          css={{
            backgroundColor: '#fff',
            display: 'none',
            flexGrow: 1,
          }}
        />
      </SplitLayoutWrapper>
    </div>
  );
};

export default withTheme(SplitLayout);
