import { jsx } from '@emotion/core';
var EvernoteIcon = function () {
    return (jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", role: "img" },
        jsx("title", null, "Evernote Attachment Icon"),
        jsx("desc", null, "Evernote Icon"),
        jsx("g", { fill: "none", fillRule: "evenodd" },
            jsx("rect", { width: "16", height: "16", fill: "#20C05C", rx: "1" }),
            jsx("path", { fill: "#666", d: [
                    'M10.448 7.676a2.383 2.383 0 0 0-.462.028c.041-.33.177-.736.659-.7',
                    '2.533.02.607.525.61.868a2.184 2.184 0 0 0-.807-.176zm2.28-3.588c-',
                    '.09-.479-.373-.715-.63-.808-.276-.1-.837-.204-1.542-.288a11.922 1',
                    '1.922 0 0 0-1.636-.049c-.048-.332-.28-.635-.54-.74-.692-.28-1.761',
                    '-.213-2.036-.135-.218.06-.46.186-.594.38a.885.885 0 0 0-.149.525c',
                    '0 .131.004.439.007.713l.007.522a.443.443 0 0 1-.442.443H4.051c-.2',
                    '39 0-.422.04-.561.104a.783.783 0 0 0-.315.252c-.15.202-.176.451-.',
                    '175.705 0 0 .002.208.052.61.042.312.378 2.486.697 3.147.124.258.2',
                    '06.365.45.478.542.233 1.78.492 2.36.567.579.074.943.23 1.16-.226 ',
                    '0 0 .043-.113.101-.278.188-.572.215-1.08.215-1.447 0-.037.054-.04',
                    '.054 0 0 .26-.05 1.177.641 1.423.273.097.839.184 1.413.252.52.06.',
                    '897.265.897 1.603 0 .815-.17.926-1.061.926-.722 0-.997.019-.997-.',
                    '557 0-.465.458-.416.798-.416.152 0 .042-.114.042-.4 0-.287.178-.4',
                    '52.01-.456-1.175-.032-1.867-.001-1.867 1.474 0 1.34.51 1.588 2.17',
                    '8 1.588 1.308 0 1.769-.043 2.308-1.725.107-.332.366-1.345.522-3.0',
                    '46.099-1.075-.093-4.322-.246-5.141zm-8.73.236h1.123a.117.117 0 0 ',
                    '0 .116-.116s-.013-.966-.013-1.234V2.97c0-.22.046-.412.126-.573l.0',
                    '38-.073a.023.023 0 0 0-.012.007l-2.18 2.17a.025.025 0 0 0-.008.01',
                    '3l.115-.057c.19-.086.42-.133.696-.133z',
                ].join('') }))));
};
export default EvernoteIcon;
