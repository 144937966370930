import { jsx } from '@emotion/core';
var VectorIcon = function () {
    return (jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", role: "img" },
        jsx("title", null, "Vector Attachment Icon"),
        jsx("desc", null, "Vector Icon"),
        jsx("g", { fill: "#005EE0", fillRule: "evenodd" },
            jsx("rect", { width: "16", height: "16", fillOpacity: ".1", rx: "1" }),
            jsx("path", { d: [
                    'M12.37 10.395l-1.284-1.284a4.994 4.994 0 0 1-1.16 3.968 1 1 0 1 1',
                    '-.72-.696 4.002 4.002 0 0 0 .26-4.783h-.617a.5.5 0 0 1-.5-.5v-.61',
                    '5a4.002 4.002 0 0 0-4.778.263.999.999 0 1 1-.697-.72 4.994 4.994 ',
                    '0 0 1 3.968-1.16L5.553 3.58a1 1 0 1 1 .746-.668L8.99 5.6h.86a.5.5',
                    '0 0 1 .5.5v.86l2.689 2.69a1 1 0 1 1-.668.745z',
                ].join('') }))));
};
export default VectorIcon;
