/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/core';
import { FormConfirmation, FormConfirmationType } from '../../../../app/types';
import FormFooter from '../../../../app/components/FormFooter';
import ConfirmationMessage from '../../../../app/components/ConfirmationMessage';
import ConfirmationContainer from '../StyledComponents/ConfirmationContainer';
import FormContent from '../StyledComponents/FormContent';
import FormPage from '../StyledComponents/FormPage';
import FormPanel from '../StyledComponents/FormPanel';
import FormLogo from './FormLogo';

interface FullPageConfirmationProps {
  confirmation?: FormConfirmation;
  formKey: string;
  hideFooterOnFormConfirmationPage: boolean;
  disableFooterFeatureFlag: boolean;
}

const FullPageConfirmation: React.FC<FullPageConfirmationProps> = ({
  confirmation,
  formKey,hideFooterOnFormConfirmationPage, disableFooterFeatureFlag
}) => {
  const message =
    confirmation && confirmation.type !== FormConfirmationType.REDIRECT
      ? confirmation.message
      : '';

  return (
    <FormPage>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <FormLogo />
      </div>
      <FormPanel>
        <FormContent>
          <ConfirmationContainer>
            <ConfirmationMessage message={message} />
          </ConfirmationContainer>
        </FormContent>
        <FormFooter
          hideCreateYourOwnForm={hideFooterOnFormConfirmationPage}
          hidePoweredBySmartsheet={hideFooterOnFormConfirmationPage}
          disableFooterFeatureFlag={disableFooterFeatureFlag}
          hidePrivacyPolicyReportAbuse
          publishKey={formKey}
          onConfirmationPage={true}
          hideRecaptchaMessage
        />
      </FormPanel>
    </FormPage>
  );
};

export default FullPageConfirmation;
