export var CheckboxInputCaptionLocation;
(function (CheckboxInputCaptionLocation) {
    CheckboxInputCaptionLocation["RIGHT"] = "RIGHT";
    CheckboxInputCaptionLocation["ABOVE"] = "ABOVE";
})(CheckboxInputCaptionLocation || (CheckboxInputCaptionLocation = {}));
export var CheckboxType;
(function (CheckboxType) {
    CheckboxType["CHECKBOX"] = "CHECKBOX";
    CheckboxType["STAR"] = "STAR";
    CheckboxType["FLAG"] = "FLAG";
})(CheckboxType || (CheckboxType = {}));
