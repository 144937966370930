import { __makeTemplateObject } from "tslib";
/**
 * @todo refactor and keep only one toast component
 */
import * as React from 'react';
import styled from '@emotion/styled/macro';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as SvgImages from "../Svg";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 12px;\n    margin-bottom: 30px;\n    display: flex;\n    border-radius: 2px;\n    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 0 1px 2px rgba(246, 166, 35, 0.2) inset;\n    border: solid 1px #f6a623;\n    background-color: #ffffff;\n    justify-content: space-between;\n"], ["\n    padding: 12px;\n    margin-bottom: 30px;\n    display: flex;\n    border-radius: 2px;\n    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 0 1px 2px rgba(246, 166, 35, 0.2) inset;\n    border: solid 1px #f6a623;\n    background-color: #ffffff;\n    justify-content: space-between;\n"])));
var Message = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    min-height: 30px;\n"], ["\n    min-height: 30px;\n"])));
var Close = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    cursor: pointer;\n"], ["\n    cursor: pointer;\n"])));
var Toast = function (props) {
    var closeHandler = props.closeHandler, addClose = props.addClose, messageId = props.messageId;
    return (React.createElement(Container, null,
        React.createElement(Message, null,
            React.createElement(FormattedMessage, { id: messageId })),
        addClose && React.createElement(Close, { onClick: closeHandler },
            React.createElement(SvgImages.CloseSvg, null))));
};
export default injectIntl(Toast);
var templateObject_1, templateObject_2, templateObject_3;
