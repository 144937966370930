import React from 'react';
var Moderate = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18px", height: "18px", viewBox: "0 0 18 18", version: "1.1", role: "img" },
        React.createElement("title", null, "Pain Moderate"),
        React.createElement("desc", null, "Moderate Pain."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", null,
                React.createElement("path", { d: "M8,0 C12.42,0 16,3.58 16,8 C16,12.42 12.42,16 8,16 C3.58,16 0,12.42 0,8 C0,3.58 3.58,0 8,0 Z", id: "Stroke-306", fill: "#444444" }),
                React.createElement("path", { d: "M8,1 C4.13228475,1 1,4.13228475 1,8 C1,11.8677153 4.13228475,15 8,15 C11.8677153,15 15,11.8677153 15,8 C15,4.13228475 11.8677153,1 8,1 Z", id: "Path", fill: "#F2C43A" }),
                React.createElement("path", { d: "M11.1234786,10.1710142 C11.3051724,9.9630672 11.6210387,9.94178478 11.8289858,10.1234786 C12.0369328,10.3051724 12.0582152,10.6210387 11.8765214,10.8289858 C11.2405767,11.5568191 9.71637173,12 8,12 C6.28362827,12 4.75942335,11.5568191 4.1234786,10.8289858 C3.94178478,10.6210387 3.9630672,10.3051724 4.17101423,10.1234786 C4.37896126,9.94178478 4.69482758,9.9630672 4.8765214,10.1710142 C5.27960249,10.6323371 6.54408299,11 8,11 C9.45591701,11 10.7203975,10.6323371 11.1234786,10.1710142 Z M9.37873219,3.98507125 C9.11083473,3.91809689 8.94795439,3.64662964 9.01492875,3.37873219 C9.08190311,3.11083473 9.35337036,2.94795439 9.62126781,3.01492875 L11.6212678,3.51492875 C11.8891653,3.58190311 12.0520456,3.85337036 11.9850713,4.12126781 C11.9180969,4.38916527 11.6466296,4.55204561 11.3787322,4.48507125 L9.37873219,3.98507125 Z M6.62126781,3.98507125 L4.62126781,4.48507125 C4.35337036,4.55204561 4.08190311,4.38916527 4.01492875,4.12126781 C3.94795439,3.85337036 4.11083473,3.58190311 4.37873219,3.51492875 L6.37873219,3.01492875 C6.64662964,2.94795439 6.91809689,3.11083473 6.98507125,3.37873219 C7.05204561,3.64662964 6.88916527,3.91809689 6.62126781,3.98507125 Z M6,6 C6.55333333,6 7,6.44666667 7,7 C7,7.55333333 6.55333333,8 6,8 C5.44666667,8 5,7.55333333 5,7 C5,6.44666667 5.44666667,6 6,6 Z M10,6 C10.5533333,6 11,6.44666667 11,7 C11,7.55333333 10.5533333,8 10,8 C9.44666667,8 9,7.55333333 9,7 C9,6.44666667 9.44666667,6 10,6 Z", id: "Combined-Shape", fillOpacity: "0.7", fill: "#000000" })))));
};
export default Moderate;
