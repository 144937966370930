import { FormTheme } from '../../../app/types';
import modernLogoOnDark from '../../../assets/logos/modern-logo-on-dark.svg';
import modernLogoOnLight from '../../../assets/logos/modern-logo-on-light.svg';
import styled, { CreateStyled } from '@emotion/styled/macro';
import { resolveAssetUrl, isDarkBackground } from '../../../app/utils';

//const defaultLogoOnDark = 'assets/logos/logo-smartsheet-on-dark.svg';
//const defaultLogoOnLight = 'assets/logos/logo-smartsheet-on-light.svg';
//const modernLogoOnDark = 'assets/logos/modern-logo-on-dark.svg';
//const modernLogoOnLight = 'assets/logos/modern-logo-on-light.svg';

export const DEFAULT_THEME = {
    style: {
      background: {
        color: "#fff;",
          image: ""
      },
      logo: {
        image: resolveAssetUrl(modernLogoOnDark)
      },
      panel: {
        color: "#ffffff;",
        padding: "30px"
      },
      font:{
          readOnlyColor: "#161616;",  //label, dateinput
          readOnlyColorLight: "#767676;", // component description
          editableColor: "#161616;", // text input, form title, form description
          family: "'Roboto', sans-serif",
      },      
      boxShadow: "0 11px 5px 0 rgba(0,0,0,0.2), 0 1px 2px 0 rgba(0,0,0,0.2);",
      justifyContent: "center"
    }
  }

const PLAIN_STYLE_THEME = {
    style: {
        logo: {
            image: "none"
        },
        background: {
            color: "",
            image: "",
        },
        panel: {
            color: "",
            padding: "30px 10px 30px 30px"
        },
        font: DEFAULT_THEME.style.font,        
        boxShadow: "",
        justifyContent: "left"
    }
  }



export type StyledFormTheme = typeof DEFAULT_THEME 

const getLogoUrl = (theme: FormTheme): string => {
  const smartsheetLogoOnDark = resolveAssetUrl(modernLogoOnDark)

  const smartsheetLogoOnLight = resolveAssetUrl(modernLogoOnLight)

  if (!theme.style) {
    return smartsheetLogoOnDark;
  }

  if (theme.style.logo && theme.style.logo.image) {
    return theme.style.logo.image;
  }

  if (theme.style.background && !isDarkBackground(theme.style.background.color)) {
    return smartsheetLogoOnLight;
  }

  return smartsheetLogoOnDark;
};

export const wrapTheme = (theme?: FormTheme): StyledFormTheme => {
    if (!theme){
        return DEFAULT_THEME;
    }
    else if(theme && theme.type && theme.type === "DEFAULT"){
        return PLAIN_STYLE_THEME;
    }

    const background = theme && theme.style && theme.style.background;
    return {
        style: {
            logo: {
                image: getLogoUrl(theme)
            },
            background: {
                color: (background && background.color) || DEFAULT_THEME.style.background.color,
                image: background && background.image
            },
            panel: {
                color: DEFAULT_THEME.style.panel.color,
                padding: DEFAULT_THEME.style.panel.padding
            },
            font: DEFAULT_THEME.style.font,
            boxShadow: DEFAULT_THEME.style.boxShadow,
            justifyContent: DEFAULT_THEME.style.justifyContent
        }
    } as StyledFormTheme
}

export default styled as CreateStyled<StyledFormTheme>
