import React from 'react';
import { ESymbolKey } from "@smartsheet/forms-model";
import High from "./High";
import Medium from "./Medium";
import Low from "./Low";
import { IconOptionImg } from "../../iconUtil";
var PriorityHmlIcon = function (_a) {
    var type = _a.type;
    var icon;
    switch (type) {
        case ESymbolKey.PRIORITY_HML_HIGH:
        case ESymbolKey.PRIORITY_HIGH:
        case "High":
            icon = React.createElement(High, null);
            break;
        case ESymbolKey.PRIORITY_HML_MEDIUM:
        case "Medium":
            icon = React.createElement(Medium, null);
            break;
        case ESymbolKey.PRIORITY_HML_LOW:
        case ESymbolKey.PRIORITY_LOW:
        case "Low":
            icon = React.createElement(Low, null);
            break;
        default:
            break;
    }
    return !!icon ? (React.createElement(IconOptionImg, null, icon)) : null;
};
export default PriorityHmlIcon;
