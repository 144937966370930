import React from 'react';
var Easy = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "12px", height: "12px", viewBox: "0 0 12 12", version: "1.1", role: "img" },
        React.createElement("title", null, "Difficulty Easy"),
        React.createElement("desc", null, "Easy."),
        React.createElement("defs", null),
        React.createElement("g", null,
            React.createElement("g", { fill: "#57B370" },
                React.createElement("circle", { id: "Oval", cx: "6", cy: "6", r: "6" })))));
};
export default Easy;
