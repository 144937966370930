import * as React from 'react';
import styled from '../../../app/containers/SubmitFormApp/styled';
import axios from 'axios';

const ErrorPage = styled.div`
  background-color: #003059;
  height: 100vh;
  padding-top: 50px;
`;

const ErrorPanel = styled.div`
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 11px 5px 0 rgba(0,0,0,0.2), 0 1px 2px 0 rgba(0,0,0,0.2);
  color: #161616;
  display: block;
  font-size: 1.13em;
  font-weight: 300;
  padding: 50px 30px;
  font-family: 'Roboto', sans-serif;
  margin: 0px auto;
  width: 610px;
`;

interface TopLevelErrorBoundaryProps {
  children?: React.ReactNode;
}

interface TopLevelErrorBoundaryState {
  error?: Error;
}

class TopLevelErrorBoundary extends React.Component<
  TopLevelErrorBoundaryProps
> {
  public static getDerivedStateFromError(error: Error) {
    return {
      error,
    };
  }

  public readonly state: TopLevelErrorBoundaryState = {
    error: undefined,
  };

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    TopLevelErrorBoundary.submitErrorToAPI()
  }

  public static submitErrorToAPI(){
    const API_ENDPOINT = process.env.FORM_API_ENDPOINT || window.formEndpoint || "http://localhost:17610"

    axios.request<String>({
      baseURL: API_ENDPOINT,
      url: `/api/public/uilog`,
      method: "POST",
      headers: {
          "x-smar-publish-key": window.publishKey,
          "x-smar-form-app-version": window.formAppVersion
      }
    })
  }

  public render() {
    if (this.state.error) {
      return (
        <ErrorPage>
          <ErrorPanel>
            An unexpected error has occurred. If the problem continues, please visit <a href="https://www.smartsheet.com/gethelp">www.smartsheet.com/gethelp.</a>
          </ErrorPanel>
        </ErrorPage>
      );
    }

    return this.props.children;
  }
}

export default TopLevelErrorBoundary;