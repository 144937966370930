import { __assign, __extends, __makeTemplateObject, __spreadArray } from "tslib";
/** @jsx jsx */
/*
 * if a dropdown is restricted, it means the checkbox "Restrict to dropdown values only" in the form builder is checked and you coannot create new options in form render
 * if a dropdown is unrestricted, it means the checkbox "Restrict to dropdown values only" in the form builder is unchecked and you can create new options in form render
 * a contact dropdown that doesn't have predefinded contacts "preferred contact list" will automatically be unrestricted
 *
 * As of right now multicontact no options is displaying as a text input. We were going to make it a select,
 * however it would take more bandwith to solve than we were willing to spend.
 * If you want to make it into a select input again, instructions are in SheetCreateRowFormValidator.java:443
 * After which, it will behave as expected on the form render with email and max contact limit validation intact
 * Keep in mind however, that you may experience null pointer exceptions in the form-builder when you try to set a default value.
 */
import * as React from "react";
import v4 from "uuid/v4";
import * as yup from "yup";
import { createPropsGetter } from "../utils";
import { generateAriaDescribedBy, sanitizeHtmlID } from "../utils/stringUtils";
import styled from "@emotion/styled/macro";
import { jsx } from "@emotion/core";
import InputLabel from "../InputLabel";
import InputDescription from "../InputDescription";
import InputContainer from "../InputContainer";
import LabelContainer from "../LabelContainer";
import Select, { Creatable, createFilter, components } from "react-select";
import { isContact } from "@smartsheet/forms-model";
import SymbolIcon from "../utils/Icons/SymbolIcon";
import { injectIntl } from "react-intl";
import LogicTag from "../LogicTag";
import SourceLogicTag from "../SourceLogicTag";
import _debounce from "lodash.debounce";
import { isEmpty } from "lodash";
import { IconSelectOption, IconOptionLabel } from "../utils/iconUtil";
import Util from "./SelectInputUtil";
import { FormConfirmationType } from "@smartsheet/forms-model";
import { AppConstants } from "../app.constants";
export var ValueType;
(function (ValueType) {
    ValueType["STRING"] = "STRING";
    ValueType["CONTACT"] = "CONTACT";
})(ValueType || (ValueType = {}));
export var FormStatusType;
(function (FormStatusType) {
    FormStatusType["SUCCESS"] = "OPEN_FORM_SUCCESS";
    FormStatusType["FAILURE"] = "SUBMIT_FORM_FAILURE";
    FormStatusType["EMPTY"] = "";
})(FormStatusType || (FormStatusType = {}));
var defaultProps = {
    readonly: false,
    required: false,
    disabled: false,
    touched: false,
    multivalue: false,
    validateField: false,
    hidden: false,
    isSubmitting: false,
    onBlur: function (e) { }
};
var getProps = createPropsGetter(defaultProps);
var LogicInputLabel = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    word-wrap: break-all;\n"], ["\n    word-wrap: break-all;\n"]))); //Div is needed around label when logic tag is present
var customStyles = {
    container: function (properties, _a) {
        var selectProps = _a.selectProps;
        var label = (selectProps.value && selectProps.value.label) || "";
        var width = label.length * 8 + 30 + "px";
        if (selectProps.customStyleProps.autoResize) {
            return __assign(__assign({}, properties), { width: width, maxWidth: selectProps.customStyleProps.maxWidth });
        }
        else {
            return __assign(__assign({}, properties), { width: "100%" });
        }
    },
    valueContainer: function (properties) { return (__assign(__assign({}, properties), { padding: "0 5px", marginTop: "auto", marginBottom: "auto" })); },
    control: function (properties, state) { return (__assign(__assign({}, properties), { alignItems: "baseline", border: state.selectProps.customStyleProps.borderless
            ? "none"
            : "solid", borderWidth: "1px", borderTopWidth: "1px", borderBottomWidth: "1px", borderLeftWidth: "1px", borderRightWidth: "1px", borderTopColor: state.selectProps.customStyleProps.error
            ? "#d0011b"
            : state.isFocused
                ? "#005EE0 !important"
                : "#cccccc", borderBottomColor: state.selectProps.customStyleProps.error
            ? "#d0011b"
            : state.isFocused
                ? "#005EE0 !important"
                : "#cccccc", borderLeftColor: state.selectProps.customStyleProps.error
            ? "#d0011b"
            : state.isFocused
                ? "#005EE0 !important"
                : "#cccccc", borderRightColor: state.selectProps.customStyleProps.error
            ? "#d0011b"
            : state.isFocused
                ? "#005EE0 !important"
                : "#cccccc", borderRadius: "2px", marginTop: "0rem", fontSize: "13px", minHeight: "30px", boxShadow: "none", 
        // Limit to 5 rows with scroll
        maxHeight: "115px", overflowY: "auto", 
        // Limit to 5 rows with scroll
        "&:hover": {
            backgroundColor: state.selectProps.customStyleProps.borderless
                ? "#F3F3F3"
                : properties.backgroundColor,
            cursor: state.selectProps.customStyleProps.isContactLstNoOptions
                ? "text"
                : "pointer",
            borderTopColor: state.selectProps.customStyleProps.error
                ? "#d0011b"
                : "#A0A0A0",
            borderBottomColor: state.selectProps.customStyleProps.error
                ? "#d0011b"
                : "#A0A0A0",
            borderLeftColor: state.selectProps.customStyleProps.error
                ? "#d0011b"
                : "#A0A0A0",
            borderRightColor: state.selectProps.customStyleProps.error
                ? "#d0011b"
                : "#A0A0A0"
        }, backgroundColor: state.isFocused && state.selectProps.customStyleProps.borderless
            ? properties.backgroundColor + " !important"
            : properties.backgroundColor, color: state.isFocused && state.selectProps.customStyleProps.borderless
            ? "#005EE0"
            : properties.color })); },
    singleValue: function (properties, state) { return (__assign(__assign({}, properties), { color: "inherit" })); },
    placeholder: function (properties) { return (__assign(__assign({}, properties), { color: "#767676" })); },
    dropdownIndicator: function (properties, state) { return (__assign(__assign({}, properties), { padding: "0rem .25rem" })); },
    menu: function (properties, _a) {
        var selectProps = _a.selectProps;
        return (__assign(__assign({}, properties), { fontSize: "13px", borderRadius: "0px", marginTop: "0px", boxShadow: "0 1px 5px rgba(0,0,0,0.2), 0 1px 2px rgba(0,0,0,0.2)", width: selectProps.customStyleProps.autoResize ? "auto" : "100%", whiteSpace: selectProps.customStyleProps.autoResize
                ? "nowrap"
                : properties.whiteSpace }));
    },
    multiValueRemove: function (properties) { return (__assign(__assign({}, properties), { color: "#767676", "&:hover": {
            color: "#444444",
            backgroundColor: "transparent",
            cursor: "pointer"
        } })); },
    multiValueLabel: function (properties) { return (__assign(__assign({}, properties), { "&:hover": {
            cursor: "pointer"
        } })); },
    option: function (properties) { return (__assign(__assign({}, properties), { padding: ".1rem .5rem .1rem .5rem", minHeight: "1.5rem", whiteSpace: "pre-wrap" })); },
    indicatorSeparator: function (properties) { return (__assign(__assign({}, properties), { background: "none" })); },
    clearIndicator: function (properties) { return (__assign(__assign({}, properties), { padding: "0px" })); }
};
function getOptionLabel(option) {
    if (isContact(option)) {
        var contact = option;
        if (contact) {
            if (contact.name && contact.name === contact.email) {
                return contact.email;
            }
            //return (contact.name && contact.email) ? `${contact.name} (${contact.email})` : (contact.email || contact.name)
            else if (contact.name && contact.email) {
                return contact.name + " (" + contact.email + ")";
            }
            else if (contact.email) {
                return contact.email;
            }
            else if (contact.name) {
                return contact.name;
            }
        }
    }
    return option;
}
var getValueIndex = function (value, options) {
    /*return options.findIndex(option => {
    if (typeof value === "string" && typeof option === "string"){
      return value === option
    } else if (typeof value === "string"){
      return value === (option as Contact).email
    } else if (typeof option == "string"){
      if(value as Contact){
        return (value as Contact).email === option
      }
      return false
    } else {
      if(value as Contact){
        return (value as Contact).email === (option as Contact).email
      }
      return false
    }
  })*/
    //below was done to replicate options.findIndex code above which is incompatible with IE11
    for (var i = 0; i < options.length; i++) {
        if (typeof value === "string" && typeof options[i] === "string") {
            if (options[i] === value) {
                return i;
            }
        }
        else if (typeof value === "string") {
            if (value === options[i].email) {
                return i;
            }
        }
        else if (typeof options[i] === "string") {
            if (value) {
                if (value.email === options[i]) {
                    return i;
                }
            }
        }
        else {
            if (value) {
                if (value.email != null &&
                    value.email === options[i].email) {
                    return i;
                }
                else if (value.name != null &&
                    value.name === options[i].name) {
                    return i;
                }
            }
        }
    }
    return 0;
};
/*
  This was created to allow us to remove the 'aria-autocomplete' property from the Input sub-component of Select.
  I tried doing this by modifying customStyles (above) to include the input components but the properties passed
  in there do not actually contain the 'aria-autocomplete' property.  It must be added at a later time to the
  input component.  This approach solves the problem.
  'props' is immutable hence the copying to a temp variable.
 */
var Input = function (props) {
    var tempProps = __assign({}, props);
    delete tempProps["aria-autocomplete"];
    return jsx(components.Input, __assign({}, tempProps));
};
var SelectInput = /** @class */ (function (_super) {
    __extends(SelectInput, _super);
    function SelectInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // confirmationType needs to be a state variable because it gets set to undefined when
        // a submission happens.
        _this.state = {
            inputValue: "",
            selectedOptions: [],
            editableOptions: __spreadArray([], _this.props.options),
            invalidEmail: false,
            isContactLstNoOptions: false,
            confirmationType: ""
        };
        _this.focusedOption = null;
        _this.inputId = "select_input_" + (_this.props.label != null ? _this.props.label : _this.props.name);
        _this.errorId = Util.generateErrorId(_this.inputId);
        _this.descriptionId = "description_" + sanitizeHtmlID(_this.inputId);
        // when the component mounts set the confirmation type and restore form values if applicable.
        // form values need to be restored if the confirmation type is not reload, or there are default values from the form builder
        _this.componentDidMount = function () {
            var _a = getProps(_this.props), onChange = _a.onChange, formBuilder = _a.formBuilder, value = _a.value, options = _a.options, valueType = _a.valueType, multivalue = _a.multivalue, validateField = _a.validateField, confirmation = _a.confirmation;
            var selectedOptions = _this.state.selectedOptions;
            if (!formBuilder) {
                var isContactLstNoOptions = Util.isContactLstNoOptions({
                    options: options,
                    valueType: valueType,
                    multivalue: multivalue,
                    validateField: validateField
                });
                if (confirmation && confirmation.type) {
                    _this.setState({
                        isContactLstNoOptions: isContactLstNoOptions,
                        confirmationType: confirmation.type
                    });
                }
                if (!validateField) {
                    if (value) {
                        if (multivalue) {
                            // repopulate the select with previous/default values if applicable
                            var multiValueLst_1 = value;
                            if (multiValueLst_1 &&
                                multiValueLst_1 !== selectedOptions) {
                                // I had to use a set state inside a set state because the form was remembering the previous value.
                                // After all the components had rendered, it would then start duplicating values, so I cleared out the
                                // previous value from the form before setting
                                if (Array.isArray(value) &&
                                    value.length &&
                                    onChange) {
                                    _this.setState({ selectedOptions: [] }, function () {
                                        onChange([]);
                                        _this.setState({ selectedOptions: multiValueLst_1 }, function () { return onChange(multiValueLst_1); });
                                    });
                                }
                            }
                        }
                    }
                }
            }
        };
        _this.setAriaDescribedByForErrors = function (hasDescription, hasError) {
            // This must be set manually because it's not supported by react-select library.
            // Ongoing pull request which might potentially address this:
            // https://github.com/JedWatson/react-select/pull/4082
            var ariaDescribedBy = generateAriaDescribedBy(_this.descriptionId, !!hasDescription, _this.errorId, !!hasError);
            if (ariaDescribedBy) {
                var inputElem = document.getElementById(_this.inputId);
                inputElem &&
                    inputElem.setAttribute("aria-describedby", ariaDescribedBy);
            }
        };
        // When the component updates, generate the editable if new options are selected. If the form submitted reset it if it's reload.
        _this.componentDidUpdate = function (prevProps, prevState) {
            var _a = _this.state, selectedOptions = _a.selectedOptions, confirmationType = _a.confirmationType;
            var _b = getProps(_this.props), options = _b.options, isSubmitting = _b.isSubmitting, error = _b.error, validateField = _b.validateField, formStatusType = _b.formStatusType, multivalue = _b.multivalue, value = _b.value, onChange = _b.onChange, formBuilder = _b.formBuilder, description = _b.description;
            _this.setAriaDescribedByForErrors(!!description, !!error);
            if (!formBuilder) {
                if (!validateField) {
                    var generateEditableOptions = function () {
                        if (confirmationType === FormConfirmationType.RELOAD &&
                            formStatusType === FormStatusType.SUCCESS &&
                            prevProps.formStatusType === FormStatusType.FAILURE) {
                            // need to reset editable options if there is an submit failure followed by a success or
                            // the selected options on the form failure will appear in the dropdown
                            return options;
                        }
                        else {
                            var optionsnSet_1 = new Set();
                            options.forEach(function (option) {
                                return optionsnSet_1.add(JSON.stringify(option));
                            });
                            var editableOptions_1 = __spreadArray([], options);
                            selectedOptions.forEach(function (opt) {
                                if (!optionsnSet_1.has(JSON.stringify(opt))) {
                                    editableOptions_1.push(opt);
                                }
                            });
                            return editableOptions_1;
                        }
                    };
                    if (selectedOptions !== prevState.selectedOptions ||
                        formStatusType !== prevProps.formStatusType) {
                        _this.setState({
                            editableOptions: generateEditableOptions()
                        });
                    }
                    // prevent the selects from resetting if there is an error
                    if (!isSubmitting && !error) {
                        if (confirmationType === FormConfirmationType.RELOAD) {
                            if (prevProps.isSubmitting &&
                                formStatusType === FormStatusType.SUCCESS) {
                                // reset the input if the form successfuly submitted and the confirmation type is reload
                                // no onChange is necessary because the form clears out all inputs on reload, we just need to reset the state variable
                                if (multivalue && onChange) {
                                    // set the selected options to match the value that's passed in to the select input. This ensures we have the correct default value
                                    var multiValueLst = value;
                                    _this.setState({
                                        selectedOptions: multiValueLst
                                    });
                                }
                                _this.handleInputChange("");
                            }
                        }
                    }
                }
            }
        };
        // handles the creation of new options
        _this.handleCreateOption = function (option) {
            var _a = getProps(_this.props), onChange = _a.onChange, multivalue = _a.multivalue, valueType = _a.valueType;
            var _b = _this.state, selectedOptions = _b.selectedOptions, isContactLstNoOptions = _b.isContactLstNoOptions;
            var willOptCreationCauseDup = Util.willOptCreationCauseDup({
                option: option,
                multivalue: multivalue,
                valueType: valueType,
                selectedOptions: selectedOptions
            });
            var optionObj = !willOptCreationCauseDup
                ? Util.handleCreateOption({
                    option: option,
                    valueType: valueType,
                    isContactLstNoOptions: isContactLstNoOptions,
                    multivalue: multivalue,
                    selectedOptions: selectedOptions,
                    isValidEmail: _this.isValidEmail(option)
                })
                : {};
            /*
             * After calling the util function, if the dropdown is multi you need to set the new selectedOptions and call onChange with the onChangeValue
             * Also be sure to clear the input value in case of multivalue since it may not
             *  automatically clear.
             *
             * if you have a single contact or string dropdown, you just call onChange() as there is only one option to select. Here too, be sure to clear out the input value.
             */
            if (!isEmpty(optionObj) && onChange) {
                if (optionObj.state &&
                    optionObj.state.selectedOptions &&
                    optionObj.onChangeValue) {
                    _this.setState({ selectedOptions: optionObj.state.selectedOptions }, function () {
                        onChange(optionObj.onChangeValue);
                        if (multivalue) {
                            _this.handleInputChange("");
                        }
                    });
                }
                else {
                    if (optionObj.onChangeValue) {
                        onChange(optionObj.onChangeValue);
                        if (multivalue) {
                            _this.handleInputChange("");
                        }
                    }
                }
            }
        };
        // Determines whether, a particular string is a valid email
        _this.isValidEmail = function (option) {
            return _this.props.multivalue
                ? AppConstants.VALID_EMAIL_REGEX.test(option.toLocaleLowerCase())
                : yup
                    .string()
                    .lowercase()
                    .trim()
                    .email()
                    .required()
                    .isValidSync(option);
        };
        _this.handleInputChange = function (inputValue, actionMeta) {
            if (inputValue !== _this.state.inputValue &&
                (!actionMeta || actionMeta.action === "input-change")) {
                _this.setState({
                    inputValue: inputValue
                });
            }
        };
        // When a dropdown blurs (loses focus) ensure the value persists if the dropdown is a contact list with no predefined options
        _this.onNoOptContactListBlur = function () {
            var _a = _this.state, inputValue = _a.inputValue, isContactLstNoOptions = _a.isContactLstNoOptions;
            var optionObj = Util.onNoOptContactListBlur({
                inputValue: inputValue,
                isContactLstNoOptions: isContactLstNoOptions,
                isValidEmail: _this.isValidEmail(inputValue)
            });
            if (!isEmpty(optionObj)) {
                if (optionObj.option) {
                    _this.handleCreateOption(optionObj.option);
                }
                if (_this.state.isContactLstNoOptions && _this.props.multivalue) {
                    _this.handleInputChange("");
                }
                if (optionObj.state && optionObj.state.invalidEmail !== undefined) {
                    _this.setState({ invalidEmail: optionObj.state.invalidEmail });
                }
            }
        };
        // Handles what happens when a key is pressed. It also triggers an option creation if enter or tab is pressed
        _this.onKeyDown = function (e) {
            var _a = getProps(_this.props), valueType = _a.valueType, multivalue = _a.multivalue, validateField = _a.validateField;
            var _b = _this.state, isContactLstNoOptions = _b.isContactLstNoOptions, inputValue = _b.inputValue, selectedOptions = _b.selectedOptions, editableOptions = _b.editableOptions;
            // When tab is pressed in a contact list when all the options have been selected, it doesn't go to the next field.
            // If the user selects tab, when there's still an input value it will try to create a new option, however if there is not an input value, it blurs the current select dropdown.
            var isUnrestrictedMultiContactListAllOptionsSelectedNoInputValue = !validateField &&
                multivalue &&
                valueType === ValueType.CONTACT &&
                _this.state.selectedOptions.length ===
                    _this.state.editableOptions.length &&
                !inputValue;
            if (e.key === "Tab" &&
                isUnrestrictedMultiContactListAllOptionsSelectedNoInputValue &&
                _this.selectRef &&
                _this.selectRef.select.select) {
                _this.selectRef.select.select.blur();
            }
            else {
                var willOptCreationCauseDup = (inputValue && e.key === "Enter") || e.key === "Tab"
                    ? Util.willOptCreationCauseDup({
                        option: inputValue,
                        multivalue: multivalue,
                        valueType: valueType,
                        selectedOptions: _this.state.selectedOptions
                    })
                    : false;
                var optionObj = Util.onKeyDown({
                    e: e,
                    valueType: valueType,
                    validateField: validateField,
                    isContactLstNoOptions: isContactLstNoOptions,
                    multivalue: multivalue,
                    willOptCreationCauseDup: willOptCreationCauseDup,
                    inputValue: inputValue,
                    selectRef: _this.selectRef,
                    selectedOptions: selectedOptions,
                    editableOptions: editableOptions,
                    isValidEmail: _this.isValidEmail(inputValue)
                });
                if (!isEmpty(optionObj)) {
                    if (optionObj.inputValue || optionObj.inputValue === "") {
                        _this.handleInputChange(optionObj.inputValue);
                    }
                    if (optionObj.option) {
                        _this.handleCreateOption(optionObj.option);
                    }
                    // I checked for undefined as invalidEmail and onChangeValue could potentially be falsy's
                    if (optionObj.state &&
                        optionObj.state.invalidEmail !== undefined) {
                        _this.setState({
                            invalidEmail: optionObj.state.invalidEmail
                        });
                    }
                    if (optionObj.preventDefault) {
                        e.preventDefault();
                    }
                }
            }
        };
        // handles adding the correct option when a value is clicked from the menu in a restricted dropdown
        _this.restrictedOnChange = function (mappedSelectedOptions, correctOptArray) {
            var onChange = getProps(_this.props).onChange;
            var optionObj = Util.restrictedOnChange({
                mappedSelectedOptions: mappedSelectedOptions,
                options: correctOptArray
            });
            if (onChange) {
                onChange(optionObj.onChangeValue || "");
            }
        };
        // handles adding the correct option when a value is clicked from the menu in a unrestricted dropdown
        _this.unrestrictedOnChange = function (mappedSelectedOptions, correctOptArray) {
            var onChange = getProps(_this.props).onChange;
            var optionObj = Util.unrestrictedOnChange({
                mappedSelectedOptions: mappedSelectedOptions,
                options: correctOptArray
            });
            if (optionObj.state && optionObj.state.selectedOptions && onChange) {
                _this.setState({
                    selectedOptions: onChange(optionObj.state.selectedOptions)
                });
            }
            else if (onChange &&
                optionObj.state &&
                optionObj.state.invalidEmail !== undefined) {
                _this.setState({ invalidEmail: optionObj.state.invalidEmail }, function () {
                    return onChange(optionObj.onChangeValue || "");
                });
            }
        };
        _this.closeMenuOnScroll = _debounce(function (event) {
            event.stopPropagation();
            var target = event.target;
            return target.className.indexOf("react-select__menu-list") === -1;
        }, 100, { leading: true });
        return _this;
    }
    SelectInput.prototype.render = function () {
        var _this = this;
        var labelId = v4();
        // changed formatting of the props because it was getting difficult to read
        var _a = getProps(this.props), _b = _a.autoResize, autoResize = _b === void 0 ? false : _b, localizationLabelId = _a.localizationLabelId, _c = _a.autoMenuPlacement, autoMenuPlacement = _c === void 0 ? false : _c, _d = _a.maxWidth, maxWidth = _d === void 0 ? "100%" : _d, borderless = _a.borderless, labelless = _a.labelless, label = _a.label, logic = _a.logic, hasSourceLogic = _a.hasSourceLogic, onSourceLogicTagClick = _a.onSourceLogicTagClick, value = _a.value, description = _a.description, richDescription = _a.richDescription, isRichTextEnabled = _a.isRichTextEnabled, options = _a.options, required = _a.required, disabled = _a.disabled, onBlur = _a.onBlur, error = _a.error, componentKey = _a.componentKey, intl = _a.intl, name = _a.name, symbolType = _a.symbolType, symbolKeys = _a.symbolKeys, validateField = _a.validateField, isHiddenByConditionalLogic = _a.isHiddenByConditionalLogic, multivalue = _a.multivalue, formBuilder = _a.formBuilder;
        var customStyleProps = {
            autoResize: autoResize,
            borderless: borderless,
            maxWidth: maxWidth,
            error: !!error,
            invalidEmail: this.state.invalidEmail,
            isContactLstNoOptions: this.state.isContactLstNoOptions
        };
        // denotes whether we should be using options, or editable options.
        // If we are on the form builder, using editable options breaks expected functionality
        var correctOptArray = formBuilder
            ? options
            : this.state.editableOptions;
        // a string will be used for the value. It will be as following "index_value"
        /*
         * mappedOptions will take the format of options described on line 102 and
         * create a transformed version in the following format:
         * [{ value: "0_firstOpt", label: "firstOpt" }, { value: "1_secondOpt", label: "secondOpt" }]
         * mappedValue does the same thing but just with the value
         */
        var mappedOptions = correctOptArray.map(function (option, index) { return ({
            value: index + "_" + getOptionLabel(option),
            label: getOptionLabel(option)
        }); });
        var mappedValue = Array.isArray(value)
            ? value.map(function (v) { return ({
                value: getValueIndex(v, correctOptArray) + "_" + getOptionLabel(v),
                label: getOptionLabel(v)
            }); })
            : value === ""
                ? undefined
                : {
                    value: getValueIndex(value, correctOptArray) + "_" + getOptionLabel(value),
                    label: getOptionLabel(value)
                };
        var stringify = function (option) { return String(option.label); };
        var filterOption = createFilter({
            ignoreCase: true,
            // ¯\_(ツ)_/¯
            // Important: don't set this to true, will slow down long lists 10X
            ignoreAccents: false,
            // ¯\_(ツ)_/¯
            stringify: stringify
        });
        // settting a type for the option causes react-select to break
        var formatOptionWithImage = function (option) {
            if (!symbolType || !symbolKeys || !option.label) {
                return option.label;
            }
            else {
                var symbol = symbolKeys[option.label];
                return (jsx(IconSelectOption, null,
                    jsx(SymbolIcon, { symbolType: symbolType, symbolKey: symbol }),
                    jsx(IconOptionLabel, null, option.label)));
            }
        };
        // settting a type for the option causes react-select to break
        var optionWithLabelOnly = function (option) { return option.label; };
        var hasLogic = logic != null;
        var getTitle = function () {
            var title = "";
            if (mappedValue != null) {
                if (Array.isArray(mappedValue)) {
                }
                else if (typeof mappedValue === "object" &&
                    !!mappedValue.label) {
                    title = mappedValue.label;
                }
            }
            return title;
        };
        // selects the correct dropdown configuration
        var toggleSelect = function () {
            if (validateField) {
                // restricted dropdown
                return (jsx(Select, { ref: function (el) { return (_this.selectRef = el); }, classNamePrefix: "react-select", "aria-label": intl.formatMessage({
                        id: "elements.lbl_select"
                    }), "aria-labelledby": labelId, tabIndex: isHiddenByConditionalLogic ? "-1" : "0", inputId: _this.inputId, components: {
                        DropdownIndicator: function (_a) {
                            var isFocused = _a.isFocused, selectProps = _a.selectProps;
                            return Util.toggleDropDownIndicator(_this.state.isContactLstNoOptions, isFocused &&
                                selectProps.customStyleProps.borderless);
                        },
                        Input: Input
                    }, placeholder: intl.formatMessage({
                        id: "elements.lbl_select"
                    }), value: mappedValue || null, isDisabled: disabled, styles: customStyles, closeMenuOnSelect: !multivalue, isMulti: multivalue, menuPlacement: "auto", menuPosition: autoMenuPlacement ? "fixed" : "absolute", closeMenuOnScroll: autoMenuPlacement
                        ? function (event) { return _this.closeMenuOnScroll(event); }
                        : false, customStyleProps: customStyleProps, options: mappedOptions, onBlur: onBlur, formatOptionLabel: symbolType
                        ? formatOptionWithImage
                        : optionWithLabelOnly, blurInputOnSelect: multivalue ? false : true, onInputChange: _this.handleInputChange, onKeyDown: _this.onKeyDown, filterOption: filterOption, onChange: function (mappedSelectedOptions) {
                        return _this.restrictedOnChange(mappedSelectedOptions, correctOptArray);
                    } }));
            }
            else if (_this.state.isContactLstNoOptions) {
                // No options are passed in contact unrestricted dropdown
                return (jsx(Creatable, { ref: function (el) { return (_this.selectRef = el); }, classNamePrefix: "react-select", "aria-labelledby": labelId, "aria-label": intl.formatMessage({
                        id: "elements.lbl_select_enter_value"
                    }), tabIndex: isHiddenByConditionalLogic ? "-1" : "0", inputId: _this.inputId, components: {
                        DropdownIndicator: function (_a) {
                            var isFocused = _a.isFocused, selectProps = _a.selectProps;
                            return Util.toggleDropDownIndicator(_this.state.isContactLstNoOptions, isFocused &&
                                selectProps.customStyleProps.borderless);
                        },
                        Input: Input
                    }, onCreateOption: _this.handleCreateOption, inputValue: _this.state.inputValue, placeholder: "", value: _this.state.inputValue || multivalue
                        ? mappedValue || null
                        : null, isDisabled: disabled, styles: customStyles, menuIsOpen: false, isMulti: multivalue, onInputChange: _this.handleInputChange, onKeyDown: _this.onKeyDown, options: mappedOptions, onBlur: _this.onNoOptContactListBlur, customStyleProps: customStyleProps, formatCreateLabel: function (input) { return "" + input; }, filterOption: filterOption, onChange: function (mappedSelectedOptions) {
                        return _this.unrestrictedOnChange(mappedSelectedOptions, correctOptArray);
                    } }));
            }
            else {
                // regular unrestricted dropdown
                return (jsx(Creatable, { ref: function (el) { return (_this.selectRef = el); }, classNamePrefix: "react-select", "aria-label": intl.formatMessage({
                        id: "elements.lbl_select_enter_value"
                    }), "aria-labelledby": labelId, tabIndex: isHiddenByConditionalLogic ? "-1" : "0", inputId: _this.inputId, isClearable: true, components: {
                        DropdownIndicator: function (_a) {
                            var isFocused = _a.isFocused, selectProps = _a.selectProps;
                            return Util.toggleDropDownIndicator(_this.state.isContactLstNoOptions, isFocused &&
                                selectProps.customStyleProps.borderless);
                        },
                        Input: Input
                    }, onCreateOption: _this.handleCreateOption, placeholder: intl.formatMessage({
                        id: "elements.lbl_select_enter_value"
                    }), value: mappedValue || null, formatOptionLabel: symbolType
                        ? formatOptionWithImage
                        : optionWithLabelOnly, isDisabled: disabled, styles: customStyles, closeMenuOnSelect: !multivalue, isMulti: multivalue, menuPlacement: "auto", menuPosition: autoMenuPlacement ? "fixed" : "absolute", closeMenuOnScroll: autoMenuPlacement
                        ? function (event) { return _this.closeMenuOnScroll(event); }
                        : false, onInputChange: _this.handleInputChange, options: mappedOptions, customStyleProps: customStyleProps, formatCreateLabel: function (input) { return "" + input; }, blurInputOnSelect: multivalue ? false : true, onKeyDown: _this.onKeyDown, filterOption: filterOption, onChange: function (mappedSelectedOptions) {
                        return _this.unrestrictedOnChange(mappedSelectedOptions, correctOptArray);
                    } }));
            }
        };
        var dataClientIdSuffix = label != null ? label : name;
        var labelValue;
        if (localizationLabelId != null) {
            labelValue = (jsx(InputLabel, { dataClientIdSuffix: dataClientIdSuffix, forElement: this.inputId, labelId: labelId, required: required, localizationLabelId: localizationLabelId }, " "));
        }
        else {
            labelValue = (jsx(InputLabel, { dataClientIdSuffix: dataClientIdSuffix, forElement: this.inputId, labelId: labelId, required: required }, label != null ? label : ""));
        }
        return (jsx(InputContainer, { role: "application", title: getTitle(), "data-client-id": "container_" + dataClientIdSuffix, "data-client-type": "dropdown", "data-client-symbol-set": symbolType, css: {
                marginTop: borderless || labelless ? "0px" : ""
            }, id: componentKey },
            label &&
                !labelless &&
                !borderless &&
                (!hasLogic && !hasSourceLogic) &&
                labelValue,
            label && (hasLogic || hasSourceLogic) && !labelless && (jsx(LabelContainer, null,
                !borderless ? (jsx(LogicInputLabel, null, labelValue)) : null,
                hasSourceLogic && (jsx(SourceLogicTag, { onClickHandler: onSourceLogicTagClick })),
                logic && jsx(LogicTag, { logic: logic }))),
            (isRichTextEnabled && richDescription) &&
                jsx("div", { className: "rich-text-field-desc", id: this.descriptionId, css: {
                        width: '-webkit-fill-available',
                        '& p': { fontSize: '14.12px', marginBlock: '2px' },
                        '& ul': {
                            listStylePosition: 'inside',
                            fontSize: '14.12px',
                            marginBlock: '2px'
                        },
                        '& ol': {
                            listStylePosition: 'inside',
                            fontSize: '14.12px',
                            marginBlock: '2px'
                        },
                    }, dangerouslySetInnerHTML: { __html: richDescription } })
                || description && (jsx(InputDescription, { id: this.descriptionId }, description)),
            toggleSelect(),
            Util.chooseErrorMsg(this.state.editableOptions, this.state.selectedOptions, required, !!error, this.state.isContactLstNoOptions, this.inputId, multivalue, this.props.valueType, this.state.inputValue)));
    };
    return SelectInput;
}(React.Component));
export default injectIntl(SelectInput);
var templateObject_1;
