import { __makeTemplateObject } from "tslib";
import * as React from 'react';
import styled from '@emotion/styled/macro';
import { createPropsGetter } from '../utils';
import { FormattedMessage } from 'react-intl';
var defaultProps = {
    required: false,
    localizationLabelId: "",
};
var getProps = createPropsGetter(defaultProps);
var Label = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: block;\n  font-weight: bold;\n  font-size: 0.88em;\n  color: #161616;\n  white-space: pre-wrap;\n  word-wrap: break-all;\n  margin-bottom: 6px;\n"], ["\n  width: 100%;\n  display: block;\n  font-weight: bold;\n  font-size: 0.88em;\n  color: #161616;\n  white-space: pre-wrap;\n  word-wrap: break-all;\n  margin-bottom: 6px;\n"])));
var RequiredFlagSpan = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: #d0011b;\n  margin-left: 4px;\n"], ["\n  color: #d0011b;\n  margin-left: 4px;\n"])));
var InputLabel = function (props) {
    var children = getProps(props).children;
    return (React.createElement(Label, { htmlFor: props.forElement, id: props.labelId, "data-client-id": props.dataClientIdSuffix != null ? "label_" + props.dataClientIdSuffix : "label" },
        props.localizationLabelId && props.localizationLabelId != "" && React.createElement(FormattedMessage, { id: props.localizationLabelId }),
        children,
        props.required && (React.createElement(RequiredFlagSpan, { "data-client-id": "required_indicator" }, "*"))));
};
export default InputLabel;
var templateObject_1, templateObject_2;
