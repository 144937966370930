import "./publicPaths";
import "./polyfills";
import './initialize-libs';
import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import { ConnectedRouter } from 'react-router-redux';
import 'react-phone-input-2/lib/style.css';
import { createBrowserHistory } from 'history';
import { configureStore } from './app/store';
import { App } from './app';

// prepare store
const history = createBrowserHistory();
const store = configureStore(history);

const appComponent =
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>

function isIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11

  return (msie > -1 || trident > -1);
}

if(isIE()) {
  window.location.href = 'https://t.smartsheet.com/en/browser-support-message';
} else {
  ReactDOM.render(appComponent, document.getElementById("root"));
}


