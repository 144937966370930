import { jsx } from '@emotion/core';
var ZipIcon = function () {
    return (jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", role: "img" },
        jsx("title", null, "Zip File Attachment Icon"),
        jsx("desc", null, "Zip File Icon"),
        jsx("g", { fill: "#005EE0", fillRule: "evenodd", transform: "translate(0 -.003)" },
            jsx("rect", { width: "16", height: "16", fillOpacity: ".1", rx: "1" }),
            jsx("path", { d: "" }),
            jsx("path", { d: [
                    'M13.571 3c.237 0 .429.204.429.455v9.09c0',
                    '.251-.192.455-.429.455H2.43C2.192 13 2 12.796 2',
                    '12.545V4.364c0-.251.192-.455.429-.455h5.393a.417.417 0 0 0',
                    '.304-.133l.606-.643A.417.417 0 0 1 9.035 3h4.536zM9',
                    '5H3v7h10V5h-3v1H9V5zM8 8.999A1 1 0 0 1 9 8V7h1v1a1 1 0 0 1 1',
                    '1v.8A1.2 1.2 0 0 1 9.8 11h-.6A1.2 1.2 0 0 1 8 9.8V9 9zM9.5',
                    '10a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9 6v1H8V6h1z',
                ].join(' ') }))));
};
export default ZipIcon;
