import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {HttpResponseErrorType, isHttpResponseError} from '../../../app/errors';

const FormattedErrorMessage: React.FC<{ error?: Error }> = props => {
  const { error } = props;
  React.useEffect(() => window.scroll(0,0), [error])

  if (isHttpResponseError(error)) {
    if (error.errorType === HttpResponseErrorType.FORM_NOT_FOUND) {
      return (
          <div style={{textAlign:"center"}}><FormattedMessage id="messages.form_not_found" /></div>
      );
    }

    if (error.errorType === HttpResponseErrorType.FORM_NOT_ACTIVE) {
      return (
          <div style={{textAlign:"center"}}><FormattedMessage id="messages.form_not_active" /></div>
      );
    }

    if (error.errorType === HttpResponseErrorType.INVALID_REQUEST_FILE_EMPTY) {
      return (
        <FormattedMessage values={{0: error.messageParams ? error.messageParams['fileName'] : ''}} id="messages.invalid_request_file_empty" />
      );
    }

    // S3 Entity too small and Malformed POST Request - 400 invalid request
    // other s3 file upload exceptions - 500
    if (error.errorType === HttpResponseErrorType.INVALID_REQUEST_FILE_UPLOAD_FAILURE || error.errorType === HttpResponseErrorType.UNEXPECTED_FILE_UPLOAD_FAILURE) {
      return (
          <FormattedMessage values={{0: error.messageParams ? error.messageParams['fileName'] : ''}} id="messages.msg_ur_uploadError" />
      );
    }

    if (error.errorType === HttpResponseErrorType.INVALID_REQUEST_INVALID_FILE_EXTENSION) {
      return (
          <FormattedMessage values={{0: error.messageParams ? error.messageParams['fileName'] : ''}} id="validations.uploadField_unsupportedFileType" />
      );
    }

    if (error.errorType === HttpResponseErrorType.GRID_LOCK_NOT_AWARDED) {
      return (
          <FormattedMessage id="messages.grid_lock_not_awarded" />
      );
    }

    if (error.errorType === HttpResponseErrorType.INVALID_REQUEST_MAX_MULTIPICKLIST_CHAR_COUNT_EXCEEDED) {
      return (
          <FormattedMessage id="messages.max_multipicklist_char_count_exceeded" />
      );
    }

    if (error.errorType === HttpResponseErrorType.INVALID_REQUEST_INVALID_CONTACT_EMAIL) {
      return (
          <FormattedMessage values={{0: error.messageParams ? error.messageParams['email'] : ''}} id="messages.invalid_contact_email" />
      );
    }

    if (error.errorType === HttpResponseErrorType.INVALID_REQUEST_INVALID_DURATION_VALUE) {
      return (
          <FormattedMessage id="messages.invalid_duration_value" />
      );
    }

    // TODO: Add a language element and translations for this.
    // if (
    //   error.errorType ===
    //   HttpResponseErrorType.INVALID_REQUEST_CAPTCHA_TOKEN_TIMEDOUT_OR_DUPLICATE
    // ) {
    //   return (
    //     <FormattedMessage id="messages.invalid_request_captcha_token_timedout" />
    //   );
    // }
  }

  return (
    <FormattedMessage
      id="messages.msg_ur_appError"
    />
  );
};

export default FormattedErrorMessage;
